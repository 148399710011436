import { TopicStat } from '../types/Dashboard';

export const formatTopicStats = (stats: TopicStat[]) => {
  const topicStats: {
    [topicName: string]: {
      correct: number;
      total: number;
      percentage: number;
      yield: number;
    };
  } = {};
  stats.forEach((t: TopicStat) => {
    topicStats[t.name] = {
      correct: t.stats.answeredCorrectlyCount,
      total: t.stats.answeredCorrectlyCount + t.stats.answeredIncorrectlyCount,
      percentage:
        (t.stats.answeredCorrectlyCount /
          (t.stats.answeredCorrectlyCount + t.stats.answeredIncorrectlyCount)) *
        100,
      yield: t.yield,
    };
  });
  return topicStats;
};
