import { BlockTimeSetting } from '../types/Study';

export const formatAccommodation = (
  accommodation: BlockTimeSetting | undefined,
  shorthand: boolean = false
) => {
  if (!accommodation || accommodation === BlockTimeSetting.default) return '';
  if (shorthand) {
    return formatShorthand(accommodation);
  }
  return accommodation.charAt(0).toUpperCase() + accommodation.slice(1);
};

const formatShorthand = (accommodation: BlockTimeSetting) => {
  switch (accommodation) {
    case BlockTimeSetting.default:
      return '';
    case BlockTimeSetting.quarter:
      return 'x1.25';
    case BlockTimeSetting.half:
      return 'x1.5';
    case BlockTimeSetting.double:
      return 'x2';
  }
};
