import { TopicTreeNode } from '../types/TopicCustomization';

export const flattenTopicTree = (
  topicFilters: TopicTreeNode[]
): TopicTreeNode[] => {
  let flatTopics: TopicTreeNode[] = [];
  topicFilters.forEach((topic) => {
    const topicObj: TopicTreeNode = {
      id: topic.id,
      name: topic.name,
      path: topic.path,
      level: topic.level,
      active: topic.active,
      articleId: topic.articleId,
    };
    flatTopics.push(topicObj);
    if (topic.children) {
      flatTopics = flatTopics.concat(flattenTopicTree(topic.children));
    }
  });
  return flatTopics;
};
