import React, { useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import {
  centerCirclePlugin1,
  centerCirclePlugin2,
  centerCirclePlugin3,
  formatTopicRadarLabels,
  topicRadarOptions,
} from '../../../../utils/topicRadar';

ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

declare module 'chart.js' {
  interface Scale {
    xCenter?: number;
    yCenter?: number;
    drawingArea: number;
  }
}

ChartJS.register(centerCirclePlugin1, centerCirclePlugin2, centerCirclePlugin3);

interface TopicRadarProps {
  isSummary: boolean;
  topicProgression:
    | {
        [topicName: string]: {
          correct: number;
          total: number;
          percentage: number;
        };
      }
    | null
    | undefined;
}

const TopicRadar: React.FC<TopicRadarProps> = ({
  isSummary,
  topicProgression,
}) => {
  const chartRef = useRef<ChartJS<'radar'> | null>(null);

  const windowWidth = useWindowWidth();

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.resize();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!topicProgression) return null;

  const reducedTopicPercentage = Object.keys(topicProgression).reduce(
    (acc, key) => {
      acc[key] = topicProgression[key].percentage;
      return acc;
    },
    {} as { [key: string]: number }
  );

  const filteredTopicLabels = Object.keys(reducedTopicPercentage).map((label) =>
    formatTopicRadarLabels(label)
  );
  const filteredTopicData = Object.values(reducedTopicPercentage);

  const data = {
    labels: filteredTopicLabels,
    datasets: [
      {
        data: filteredTopicData,
        fill: true,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderColor: '#FEFEFE',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        pointHoverBorderColor: 'transparent',
      },
    ],
  };

  return (
    <>
      {filteredTopicLabels.length > 0 && (
        <div
          style={
            isSummary
              ? {
                  position: 'relative',
                  width: '100%',
                  height: windowWidth < 560 ? '400px' : '450px',
                  padding: '1.5rem',
                }
              : { position: 'relative' }
          }
        >
          <Radar ref={chartRef} data={data} options={topicRadarOptions} />
        </div>
      )}
    </>
  );
};

export default TopicRadar;
