import { PushNotifications, Token } from '@capacitor/push-notifications';
import axiosInstance from './axiosCreator';
import { Device } from '@capacitor/device';

export async function initializePushNotifications(userId: string) {
  const deviceId = await Device.getId();

  if (!deviceId.identifier) {
    // console.log('No device ID found');
    return;
  }

  let permStatus = await PushNotifications.checkPermissions();
  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }
  if (permStatus.receive !== 'granted') {
    return;
  }

  await PushNotifications.register();

  // Called when registration successfully returns a device token
  PushNotifications.addListener('registration', (token: Token) => {
    sendTokenToBackend(token.value, deviceId.identifier, userId);
  });

  // Called when a push notification is received
  PushNotifications.addListener('pushNotificationReceived', (notification) => {
    console.log('Push notification received:', notification);
    // Handle foreground notification presentation here if needed.
  });

  // Called when the user taps on a notification
  PushNotifications.addListener('pushNotificationActionPerformed', (action) => {
    console.log('Push notification action performed:', action);
    // Navigate user or handle the action as necessary.
  });
}

async function sendTokenToBackend(
  token: string,
  deviceId: string,
  userId: string
) {
  try {
    const response = await axiosInstance.post(
      `/apn/${userId}/register-device`,
      { token, deviceId }
    );
    return response.data;
  } catch (error) {
    console.error('Error sending token to backend:', error);
  }
}
