import React, { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';

type FadeInComponentProps = {
  children: ReactNode;
};

const FadeInComponent: React.FC<FadeInComponentProps> = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5, // Adjust this value as needed
  });

  return (
    <div ref={ref} className={`fade-element ${inView ? 'slide-up' : ''}`}>
      {children}
    </div>
  );
};

export default FadeInComponent;
