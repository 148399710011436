// hooks/useExamTimer.ts
import { useState, useEffect, useRef } from 'react';
import { BlockType } from '../../../../types/Study';

interface UseExamTimerProps {
  initialDuration: number;
  blockType: BlockType;
  isStarted: boolean;
  isSubmitted: boolean;
  isReviewPhase: boolean;
  onTimerEnd: () => void;
  blockId: string;
}

/**
 * Manages the exam timer state and logic.
 * Handles starting, stopping, counting up/down, and timeout events.
 */
export function useExamTimer({
  initialDuration,
  blockType,
  isStarted,
  isSubmitted,
  isReviewPhase,
  onTimerEnd,
  blockId,
}: UseExamTimerProps) {
  const [timeRemaining, setTimeRemaining] = useState(initialDuration);
  const timeRemainingRef = useRef(timeRemaining);
  const hasStartedRef = useRef(false);

  // Keep the ref updated whenever the state changes
  useEffect(() => {
    timeRemainingRef.current = timeRemaining;
  }, [timeRemaining]);

  // Reset timer state if the initial duration changes or block changes
  useEffect(() => {
    setTimeRemaining(initialDuration);
    hasStartedRef.current = false; // Reset the started flag when block changes
  }, [initialDuration, blockId]);

  // Main effect for handling the timer interval
  useEffect(() => {
    // Conditions where the timer should NOT run
    if (!isStarted || isSubmitted || isReviewPhase) {
      return;
    }

    // For timed mode, also check initial duration
    if (blockType === BlockType.timed && !initialDuration) {
      return;
    }

    let intervalId: NodeJS.Timeout | undefined;

    if (blockType === BlockType.timed) {
      // --- Timed Mode ---
      // Prevent timer from starting if already at or below zero
      if (timeRemaining <= 0) {
        return;
      }
      intervalId = setInterval(() => {
        setTimeRemaining((prevTime) => {
          // Check before decrementing to avoid going negative unnecessarily
          // and handle the final tick correctly
          if (prevTime <= 1) {
            clearInterval(intervalId);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else {
      // --- Tutor Mode (or other non-timed modes) ---
      intervalId = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime + 1);
      }, 1000);
    }

    // Cleanup function: Clear interval when effect dependencies change or component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    blockType,
    isStarted,
    isSubmitted,
    isReviewPhase,
    timeRemaining,
    initialDuration,
  ]);

  // Effect specifically to trigger the onTimerEnd callback in timed mode
  useEffect(() => {
    if (
      isStarted &&
      !isSubmitted &&
      !isReviewPhase &&
      blockType === BlockType.timed &&
      timeRemaining <= 0 &&
      hasStartedRef.current // Only trigger if we've actually started the timer
    ) {
      console.log('Timer Hook: Triggering onTimerEnd');
      onTimerEnd();
    }
  }, [
    timeRemaining,
    blockType,
    isStarted,
    isSubmitted,
    isReviewPhase,
    onTimerEnd,
  ]);

  // Track when the timer actually starts
  useEffect(() => {
    if (isStarted && !isSubmitted && !isReviewPhase) {
      // For timed mode, require initialDuration > 0
      // For tutor mode, allow any initial duration
      if (blockType === BlockType.timed ? initialDuration > 0 : true) {
        hasStartedRef.current = true;
      }
    }
  }, [isStarted, isSubmitted, isReviewPhase, initialDuration, blockType]);

  return {
    timeRemaining,
    timeRemainingRef,
  };
}
