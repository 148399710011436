import {
  ArrowUpCircle,
  Users,
  CreditCard,
  Gift,
  LogOut,
  Sliders,
  Tool,
  Wind,
  Settings,
} from 'react-feather';
import OraLogo from '../OraLogo';
import { TabTypes } from '../../../types/Global';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { hapticsImpactLight } from '../../../utils/haptics';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import ReferralModal from '../ReferralModal';
import { Capacitor } from '@capacitor/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { logout } from '../../../slices/auth/authSlice';
import AmbassadorModal from '../AmbassadorModal';
import TrialDisplay from '../TrialDisplay';
import { HomeActive } from '../../../assets/svgs/HomeActive';
import { Home } from '../../../assets/svgs/Home';
import { Chart } from '../../../assets/svgs/Chart';
import { ChartActive } from '../../../assets/svgs/ChartActive';
import { BookActive } from '../../../assets/svgs/BookActive';
import { Book } from '../../../assets/svgs/Book';
import { UserActive } from '../../../assets/svgs/UserActive';
import { User } from '../../../assets/svgs/User';
import { Help } from '../../../assets/svgs/Help';
import { HelpActive } from '../../../assets/svgs/HelpActive';

interface NavBarProps {}

const NavBar: React.FC<NavBarProps> = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const subscription = useSelector(
    (state: RootState) => state.subscription.subscription
  );

  const [activeTab, setActiveTab] = useState<TabTypes>(TabTypes.Home);
  const [referFriendModalIsOpen, setReferFriendModalIsOpen] = useState(false);
  const [ambassadorModalIsOpen, setAmbassadorModalIsOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const accountButtonRef = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname === '/') {
      setActiveTab(TabTypes.Home);
    } else if (window.location.pathname === '/performance') {
      setActiveTab(TabTypes.Stats);
    } else if (window.location.pathname === '/library') {
      setActiveTab(TabTypes.Library);
    } else if (window.location.pathname === '/roadmap') {
      setActiveTab(TabTypes.Roadmap);
    } else if (window.location.pathname === '/account') {
      setActiveTab(TabTypes.Account);
    } else if (window.location.pathname === '/help') {
      setActiveTab(TabTypes.Help);
    } else {
      setActiveTab(TabTypes.null);
    }
  }, [navigate]);

  const accountMenuOpen = Boolean(menuAnchorEl);
  const menuStyle = {
    '& .MuiPaper-root': {
      width: accountButtonRef.current?.offsetWidth,
      minWidth: 'auto',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      transform: 'translateY(-3.25rem) !important',
    },
  };
  const handleAccountMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleAccountMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const renderTopOptions = () => {
    return (
      <div className="navbar_options">
        <button
          className={`navbar_options_option ${activeTab === TabTypes.Home ? 'active' : ''}`}
          onClick={() => {
            hapticsImpactLight();
            navigate('/');
          }}
        >
          {activeTab === TabTypes.Home ? <HomeActive /> : <Home />}
          <span>Home</span>
        </button>
        {currentUser?.isOnboarded && (
          <>
            <button
              className={`navbar_options_option ${activeTab === TabTypes.Stats ? 'active' : ''}`}
              onClick={() => {
                hapticsImpactLight();
                navigate('/performance');
              }}
            >
              {activeTab === TabTypes.Stats ? <ChartActive /> : <Chart />}
              <span>Performance</span>
            </button>
            <button
              className={`navbar_options_option ${activeTab === TabTypes.Library ? 'active' : ''}`}
              onClick={() => {
                hapticsImpactLight();
                navigate('/library');
              }}
            >
              {activeTab === TabTypes.Library ? <BookActive /> : <Book />}
              <span>Library</span>
            </button>
          </>
        )}
      </div>
    );
  };

  const renderBottomOptions = () => {
    return (
      <div className="navbar_options">
        {currentUser?.isOnboarded && (
          <>
            {subscription?.status === 'TRIAL' && !currentUser?.ambassador && (
              <TrialDisplay />
            )}
            {subscription?.groupId &&
              subscription?.groupStatus === 'PENDING' && (
                <button
                  className={`navbar_options_option navbar_options_option--subscribe`}
                  onClick={() => {
                    hapticsImpactLight();
                    navigate(`/group/${subscription?.groupId}`);
                  }}
                >
                  <Users />
                  <span>View Group</span>
                </button>
              )}
            {!Capacitor.isNativePlatform() &&
              !currentUser?.ambassador &&
              subscription?.status !== 'ACTIVE' &&
              subscription?.status !== 'GRANDFATHERED' &&
              subscription?.groupStatus !== 'PENDING' && (
                <button
                  className={`navbar_options_option navbar_options_option--subscribe`}
                  onClick={() => {
                    hapticsImpactLight();
                    navigate('/pricing');
                  }}
                >
                  <ArrowUpCircle />
                  <span>Upgrade Now</span>
                </button>
              )}
            {currentUser?.role === 'admin' && !currentUser?.demoMode && (
              <button
                className={`navbar_options_option`}
                onClick={() => {
                  hapticsImpactLight();
                  navigate('/admin');
                }}
              >
                <Tool />
                <span>Admin Dashboard</span>
              </button>
            )}
            {currentUser?.isApprovedReferrer &&
              currentUser?.referralId &&
              !currentUser?.ambassador && (
                <button
                  className="navbar_options_option"
                  onClick={() => {
                    hapticsImpactLight();
                    setReferFriendModalIsOpen(true);
                  }}
                >
                  <Gift />
                  <span>Refer a Friend</span>
                </button>
              )}
            {currentUser && currentUser?.ambassador && (
              <button
                id="ambassador-button"
                className="navbar_options_option"
                onClick={() => {
                  hapticsImpactLight();
                  setAmbassadorModalIsOpen(true);
                }}
              >
                <Wind />
                <span>Peer Advocate</span>
              </button>
            )}
            <button
              className={`navbar_options_option ${activeTab === TabTypes.Help ? 'active' : ''}`}
              onClick={() => {
                hapticsImpactLight();
                navigate('/help');
              }}
            >
              {activeTab === TabTypes.Help ? <HelpActive /> : <Help />}
              <span>Help</span>
            </button>
          </>
        )}
        <button
          ref={accountButtonRef}
          className={`navbar_options_option ${activeTab === TabTypes.Account ? 'active' : ''} ${currentUser?.profilePictureUrl ? 'has-avatar' : ''}`}
          id="account-menu-button"
          aria-controls={accountMenuOpen ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={accountMenuOpen ? 'true' : undefined}
          onClick={(e) => {
            hapticsImpactLight();
            handleAccountMenuClick(e);
          }}
        >
          {currentUser?.profilePictureUrl ? (
            <img
              className="app-bar-user-avatar"
              src={currentUser?.profilePictureUrl}
              alt={currentUser?.name || 'Profile Picture'}
            />
          ) : activeTab === TabTypes.Account ? (
            <UserActive />
          ) : (
            <User />
          )}
          <span>Account</span>
        </button>
        <Menu
          id="account-menu"
          aria-labelledby="account-menu-button"
          anchorEl={menuAnchorEl}
          open={accountMenuOpen}
          onClose={handleAccountMenuClose}
          sx={menuStyle}
        >
          {currentUser?.isOnboarded && (
            <>
              <MenuItem
                onClick={() => {
                  handleAccountMenuClose();
                  navigate('/account?sub=profile');
                  hapticsImpactLight();
                }}
                disableRipple
              >
                <User />
                <span>Profile</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAccountMenuClose();
                  navigate('/account?sub=study-settings');
                  hapticsImpactLight();
                }}
                disableRipple
              >
                <Settings />
                <span>Study Settings</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAccountMenuClose();
                  navigate('/account?sub=preferences');
                  hapticsImpactLight();
                }}
                disableRipple
              >
                <Sliders />
                <span>Preferences</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAccountMenuClose();
                  navigate('/account?sub=billing');
                  hapticsImpactLight();
                }}
                disableRipple
              >
                <CreditCard />
                <span>Billing</span>
              </MenuItem>
              <Divider
                sx={{
                  margin: '.25rem 0 0 0 !important',
                  borderColor: 'rgba(255, 255, 255, 0.1)',
                }}
              />
            </>
          )}
          <MenuItem
            style={!currentUser?.isOnboarded ? { marginTop: '.25rem' } : {}}
            onClick={() => {
              handleAccountMenuClose();
              hapticsImpactLight();
              dispatch(logout());
              navigate('/auth');
            }}
            disableRipple
          >
            <LogOut />
            <span>Log out</span>
          </MenuItem>
        </Menu>
      </div>
    );
  };

  return (
    <nav className={`navbar ${accountMenuOpen ? 'account-menu-open' : ''}`}>
      <div className="navbar_icons">
        <div className="navbar_icons_top">
          <div className="app-icon">
            <OraLogo type={'link'} url={'/'} />
          </div>
          {renderTopOptions()}
        </div>
        <div className="navbar_icons_bottom">{renderBottomOptions()}</div>
      </div>
      <div className="navbar_full">
        <div className="navbar_full_top">
          <div className="app-icon">
            <OraLogo type={'link'} url={'/'} />
          </div>
          {renderTopOptions()}
        </div>
        <div className="navbar_full_bottom">{renderBottomOptions()}</div>
      </div>
      {currentUser &&
        currentUser?.isApprovedReferrer &&
        currentUser?.referralId && (
          <ReferralModal
            currentUser={currentUser}
            referFriendModalIsOpen={referFriendModalIsOpen}
            setReferFriendModalIsOpen={setReferFriendModalIsOpen}
          />
        )}
      {currentUser && currentUser?.ambassador && (
        <AmbassadorModal
          currentUser={currentUser}
          ambassadorModalIsOpen={ambassadorModalIsOpen}
          setAmbassadorModalIsOpen={setAmbassadorModalIsOpen}
        />
      )}
    </nav>
  );
};

export default NavBar;
