import axiosInstance from './axiosCreator';

interface ReferralRequest {
  referrerId: string;
  referredEmail: string;
}

interface AcceptReferralRequest {
  referredId: string;
}

export const addReferral = async (referralData: ReferralRequest) => {
  try {
    const response = await axiosInstance.post('/referral', referralData);
    return response.data;
  } catch (error) {
    console.error('Error adding referral:', error);
    throw error;
  }
};

export const acceptReferral = async (
  referrerId: string,
  acceptData: AcceptReferralRequest
) => {
  try {
    const response = await axiosInstance.put(
      `/referral/${referrerId}/accept`,
      acceptData
    );
    return response.data;
  } catch (error) {
    console.error('Error accepting referral:', error);
    throw error;
  }
};

export const getReferrals = async (userId: string) => {
  try {
    const response = await axiosInstance.get(`/referral/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching referrals:', error);
    throw error;
  }
};

export const getAcceptedReferrals = async (referrerId: string) => {
  try {
    const response = await axiosInstance.get(
      `/referral/${referrerId}/accepted`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching accepted referrals:', error);
    throw error;
  }
};

export const getReferralInfo = async () => {
  try {
    const response = await axiosInstance.get(`/referral/report`);
    return response.data;
  } catch (error) {
    console.error('Error fetching referral info:', error);
    throw error;
  }
};
