import axiosInstance from './axiosCreator';
import { SubscriptionPlan } from '../types/Subscription';

export interface SubscriptionStatusResponse {
  status:
    | 'TRIAL'
    | 'ACTIVE'
    | 'CANCELED'
    | 'PAST_DUE'
    | 'UNPAID'
    | 'GRANDFATHERED'
    | 'NO_SUBSCRIPTION';
  plan?: SubscriptionPlan;
  trialEnd?: string;
  renewsAt?: string;
  billsAt?: string;
  cancelAt?: string;
  price?: string;
  hasAccess: boolean;
  groupId?: string;
  groupStatus: 'PENDING' | 'COMPLETE' | 'EXPIRED';
  percentOff?: number;
  discountEnd?: string;
}

// Add this interface definition
export interface Invoice {
  id: string;
  number: string;
  created: number;
  period_start: number;
  period_end: number;
  amount_due: number;
  amount_paid: number;
  status: string;
  hosted_invoice_url?: string;
  invoice_pdf?: string;
  lines?: {
    data: Array<{
      description: string;
      amount: number;
    }>;
  };
}

/**
 * Get the current subscription status for the logged-in user.
 * Includes details such as trial end, subscription end, plan, and access flag.
 */
export const getSubscriptionStatus =
  async (): Promise<SubscriptionStatusResponse | void> => {
    try {
      const response = await axiosInstance.get('/subscription/status');
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription status:', error);
    }
  };

/**
 * Creates a checkout session for the selected subscription plan.
 * The backend checks if a Stripe customer exists (or creates one) and returns a checkout session URL.
 */
export const createCheckoutSession = async (
  plan: SubscriptionPlan
): Promise<{ url: string } | void> => {
  try {
    const response = await axiosInstance.post('/subscription/checkout', {
      plan,
    });
    return response.data;
  } catch (error) {
    console.error('Error creating checkout session:', error);
  }
};

/**
 * Retrieves a customer portal session URL from the backend.
 * This URL directs the user to Stripe's hosted portal for subscription management.
 */
export const getCustomerPortalSession = async (): Promise<{
  url: string;
} | void> => {
  try {
    const response = await axiosInstance.get('/subscription/portal-session');
    return response.data;
  } catch (error) {
    console.error('Error getting customer portal session:', error);
  }
};

/**
 * For testing: Checkout Button Handler
 * Calls the createCheckoutSession endpoint with a dummy plan (here, "MONTHLY")
 * and if successful, redirects the user to the returned checkout session URL.
 */
export const handleCheckout = async (plan: SubscriptionPlan) => {
  try {
    const session = await createCheckoutSession(plan);
    if (session?.url) {
      window.location.href = session.url;
    } else {
      console.error('Failed to create checkout session');
    }
  } catch (error) {
    console.error('Error initiating checkout:', error);
  }
};

/**
 * Cancels the user's current subscription.
 * The subscription will remain active until the end of the current billing period.
 */
export const cancelSubscription = async (): Promise<void> => {
  try {
    await axiosInstance.post('/subscription/cancel');
  } catch (error) {
    console.error('Error cancelling subscription:', error);
    throw error;
  }
};

/**
 * Reactivates a previously cancelled subscription.
 * Only works if the subscription is still within its active period.
 */
export const reactivateSubscription = async (): Promise<void> => {
  try {
    await axiosInstance.post('/subscription/reactivate');
  } catch (error) {
    console.error('Error reactivating subscription:', error);
    throw error;
  }
};

export const getTrialers = async () => {
  try {
    const response = await axiosInstance.get('/subscription/trialers');
    return response.data;
  } catch (error) {
    console.error('Error getting trialers:', error);
  }
};

/**
 * Retrieves the customer's billing details, payment methods, and subscription information.
 */
export const getBillingInformation = async () => {
  try {
    const response = await axiosInstance.get('/subscription/billing');
    return response.data;
  } catch (error) {
    console.error('Error fetching billing information:', error);
    throw error;
  }
};

/**
 * Updates the customer's billing details.
 * @param billingDetails - Object containing billing information to update (all fields optional)
 */
export const updateBillingDetails = async (billingDetails: {
  name?: string;
  email?: string;
  phone?: string;
  address?: {
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    postal_code?: string;
    country?: string;
  };
  tax_id?: string;
}) => {
  try {
    const response = await axiosInstance.put(
      '/subscription/billing',
      billingDetails
    );
    return response.data;
  } catch (error) {
    console.error('Error updating billing details:', error);
    throw error;
  }
};

/**
 * Adds a new payment method to the customer's account.
 * @param paymentMethodId - The payment method ID obtained from Stripe.js
 */
export const addPaymentMethod = async (paymentMethodId: string) => {
  try {
    const response = await axiosInstance.post('/subscription/payment-methods', {
      paymentMethodId,
    });
    return response.data;
  } catch (error) {
    console.error('Error adding payment method:', error);
    throw error;
  }
};

/**
 * Removes a payment method from the customer's account.
 * @param paymentMethodId - The ID of the payment method to remove
 */
export const removePaymentMethod = async (paymentMethodId: string) => {
  try {
    const response = await axiosInstance.delete(
      `/subscription/payment-methods/${paymentMethodId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error removing payment method:', error);
    throw error;
  }
};

/**
 * Sets a payment method as the default for the customer.
 * @param paymentMethodId - The ID of the payment method to set as default
 */
export const setDefaultPaymentMethod = async (paymentMethodId: string) => {
  try {
    const response = await axiosInstance.put(
      `/subscription/payment-methods/${paymentMethodId}/default`
    );
    return response.data;
  } catch (error) {
    console.error('Error setting default payment method:', error);
    throw error;
  }
};

/**
 * Retrieves all invoices for the customer.
 */
export const getInvoices = async () => {
  try {
    const response = await axiosInstance.get('/subscription/invoices');
    return response.data;
  } catch (error) {
    console.error('Error fetching invoices:', error);
    throw error;
  }
};

/**
 * Retrieves a specific invoice by ID.
 * @param invoiceId - The ID of the invoice to retrieve
 */
export const getInvoiceById = async (invoiceId: string) => {
  try {
    const response = await axiosInstance.get(
      `/subscription/invoices/${invoiceId}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching invoice:', error);
    throw error;
  }
};

/**
 * Creates a Stripe Setup Intent for securely collecting payment method details.
 * @returns The client secret needed for Stripe.js integration or undefined on error
 */
export const createSetupIntent = async (): Promise<{
  clientSecret: string;
} | void> => {
  try {
    const response = await axiosInstance.post('/subscription/setup-intent');
    return response.data;
  } catch (error) {
    console.error('Error creating setup intent:', error);
  }
};
