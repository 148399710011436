import SessionOverview from './SessionOverview';
import Ring from '../../../assets/images/ring.svg';
import { hapticsImpactMedium } from '../../../utils/haptics';
import { StartType } from '../../../types/Study';
import SnoozeSession from '../SnoozeSession';
import { useEffect, useState } from 'react';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';

interface StartProps {
  startTransition?: () => void;
  isStarted: boolean;
  type: StartType;
  action?: () => void;
  isExtendingSession: boolean;
  setIsExtendingSession: (isExtendingSession: boolean) => void;
  isSwitchingExam?: boolean;
  isLoadedStudySessionAndContent: boolean;
  isLoadingStudySessionAndContent: boolean;
}

const Start: React.FC<StartProps> = ({
  startTransition,
  isStarted,
  type,
  action,
  isExtendingSession,
  setIsExtendingSession,
  isSwitchingExam,
  isLoadedStudySessionAndContent,
  isLoadingStudySessionAndContent,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const subscription = useSelector((state: RootState) => state.subscription);
  const [isModifyingToday, setIsModifyingToday] = useState(false);

  useEffect(() => {
    if (isExtendingSession) {
      setIsModifyingToday(true);
    }
  }, [isExtendingSession]);

  return (
    <div className="start-container">
      {!isExtendingSession && (
        <div className="start">
          <div className="start_ring">
            <button
              onClick={() => {
                if (type === StartType.Home) {
                  hapticsImpactMedium();
                  if (startTransition) {
                    startTransition();
                  }
                  const floater = document.querySelector(
                    '.__floater'
                  ) as HTMLDivElement;
                  if (floater) {
                    floater.style.display = 'none';
                  }
                } else {
                  if (action) {
                    action();
                  }
                }
              }}
              disabled={
                !isLoadedStudySessionAndContent ||
                isLoadingStudySessionAndContent ||
                isSwitchingExam ||
                isModifyingToday ||
                (!subscription?.subscription?.hasAccess &&
                  !currentUser?.ambassador)
              }
              className="start_ring_inner"
            >
              <h1 className="start_ring_inner_content">
                <span>{isStarted ? 'Resume' : 'Start'}</span>
                <span className="session-label">&nbsp;Session</span>
              </h1>
              <div className="start_ring_inner_wrapper">
                <img src={Ring} alt="" />
              </div>
            </button>
            <SnoozeSession />
          </div>
        </div>
      )}
      {type === StartType.Home && (
        <SessionOverview
          isSwitchingExam={isSwitchingExam}
          isModifyingToday={isModifyingToday}
          setIsModifyingToday={setIsModifyingToday}
          isExtendingSession={isExtendingSession}
          setIsExtendingSession={setIsExtendingSession}
        />
      )}
    </div>
  );
};

export default Start;
