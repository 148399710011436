import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchQbankPerformanceStats } from '../../services/stats';
import { useHotkeys } from 'react-hotkeys-hook';
import QBankPerformance from '../../components/Dashboard/QBankPerformance';
import { RootState } from '../../store/store';
import Heatmap from '../../components/Dashboard/Heatmap';
import DonutChart from 'react-donut-chart';
import { fetchAllExamSessions } from '../../services/studySession';
import { StudySession } from '../../types/Study';
import { fetchVignetteCompletion } from '../../services/vignettes';
import { fetchFlashcardCompletion } from '../../services/flashcards';
import { UserExam } from '../../types/User';
import FlashcardTrend from '../../components/Dashboard/FlashcardTrend';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import TopicPerformance from './TopicPerformance';

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam: UserExam) => exam.primary);

  const [qbankPerformanceData, setQbankPerformanceData] = useState(null);
  const [flashcardTrendData, setFlashcardTrendData] = useState<
    { date: string; completedFlashcardCount: number }[]
  >([]);
  const [vignettesCorrect, setVignettesCorrect] = useState(0);
  const [flashcardsSeen, setFlashcardsSeen] = useState(0);
  const [vignetteCompletionPercentage, setVignetteCompletionPercentage] =
    useState(0);
  const [flashcardCompletionPercentage, setFlashcardCompletionPercentage] =
    useState(0);
  const [mainChartIsReady, setMainChartIsReady] = useState(false);

  const navigate = useNavigate();

  useHotkeys(
    'esc',
    () => {
      navigate('/');
    },
    { keyup: true }
  );

  useEffect(() => {
    if (primaryExam) {
      calculateContentCompletion(primaryExam);
      fetchQbankPerformanceStats().then((response) => {
        setQbankPerformanceData(response);
        setMainChartIsReady(true);
      });
      fetchAllPastStudySessions();
    }
  }, [primaryExam]);

  const fetchAllPastStudySessions = async () => {
    if (primaryExam) {
      fetchAllExamSessions(primaryExam.id).then((response) => {
        if (response) {
          const sessions = [...response];
          const data = sessions.map((session: StudySession) => {
            return {
              date: session.date,
              completedFlashcardCount: session.completedFlashcardCount,
            };
          });
          setFlashcardTrendData(data);
          setMainChartIsReady(true);
        }
      });
    }
  };

  const calculateContentCompletion = async (primaryExam: UserExam) => {
    if (currentUser && primaryExam) {
      if (primaryExam.isUsingQbank) {
        fetchVignetteCompletion(currentUser.id, primaryExam.id).then(
          (response) => {
            if (response) {
              setVignettesCorrect(response.correctVignetteCount);
              setVignetteCompletionPercentage(response.percentCompleted);
            }
          }
        );
      }
      if (primaryExam.isUsingFlashcards) {
        fetchFlashcardCompletion(currentUser.id, primaryExam.id).then(
          (response) => {
            if (response) {
              setFlashcardsSeen(response.seenFlashcardCount);
              setFlashcardCompletionPercentage(response.percentCompleted);
            }
          }
        );
      }
    }
  };

  const areAllValuesDefined = (...values: (number | null | undefined)[]) =>
    values.every((value) => value !== undefined && value !== null);

  return (
    <Page type="dashboard">
      <div className="dashboard">
        <div className="dashboard_wrapper">
          <div
            className={`dashboard_wrapper_header ${
              mainChartIsReady ? 'is-ready' : ''
            }`}
          >
            <div className="exam-title">
              <span>{primaryExam?.name || ''}</span>
              <h1>Performance</h1>
            </div>
          </div>
          <div
            className={`dashboard_wrapper_body ${
              mainChartIsReady ? 'is-ready' : ''
            }`}
          >
            <div className="dashboard_wrapper_body_main">
              {primaryExam?.isUsingQbank && (
                <QBankPerformance
                  data={qbankPerformanceData}
                  showPreviousBlocks={() => navigate('/past-blocks')}
                />
              )}
              {!primaryExam?.isUsingQbank && (
                <FlashcardTrend data={flashcardTrendData} />
              )}
            </div>
            <div className="dashboard_wrapper_body_panel">
              <div className="block block--dashboard">
                <div className="block_body">
                  <h2 className="m-t-0">Content Completion</h2>
                  {primaryExam && (
                    <div className="mastery-rings">
                      {primaryExam.isUsingQbank && (
                        <>
                          {areAllValuesDefined(
                            vignetteCompletionPercentage,
                            vignettesCorrect,
                            primaryExam.selectedVignetteCount
                          ) && (
                            <div className="donut">
                              <DonutChart
                                height={120}
                                width={120}
                                data={[
                                  {
                                    label: 'Correct',
                                    value: vignetteCompletionPercentage,
                                  },
                                  {
                                    label: '',
                                    value: 100 - vignetteCompletionPercentage,
                                  },
                                ]}
                                colors={[
                                  'var(--green)',
                                  'rgba(255,255,255,.1)',
                                ]}
                                strokeColor={'transparent'}
                                innerRadius={0.75}
                                selectedOffset={0}
                                legend={false}
                                interactive={false}
                              />
                              <div className="donut_label">
                                <div className="donut_label_completed">
                                  {vignettesCorrect}
                                </div>
                                <div className="donut_label_total">
                                  {primaryExam.selectedVignetteCount}
                                </div>
                              </div>
                              <div className="donut_outer-label">QBank</div>
                            </div>
                          )}
                          {!areAllValuesDefined(
                            vignetteCompletionPercentage,
                            vignettesCorrect,
                            primaryExam.selectedVignetteCount
                          ) && (
                            <Skeleton
                              sx={{
                                bgcolor: 'rgba(255,255,255,.05)',
                                borderRadius: '1rem',
                              }}
                              variant="rectangular"
                              animation="wave"
                              width={120}
                              height={140}
                            />
                          )}
                        </>
                      )}
                      {primaryExam.isUsingFlashcards && (
                        <>
                          {areAllValuesDefined(
                            flashcardCompletionPercentage,
                            flashcardsSeen,
                            primaryExam.selectedFlashcardCount
                          ) && (
                            <div className="donut">
                              <DonutChart
                                height={120}
                                width={120}
                                data={[
                                  {
                                    label: 'Correct',
                                    value: flashcardCompletionPercentage,
                                  },
                                  {
                                    label: '',
                                    value: 100 - flashcardCompletionPercentage,
                                  },
                                ]}
                                colors={[
                                  'var(--green)',
                                  'rgba(255,255,255,.1)',
                                ]}
                                strokeColor={'transparent'}
                                innerRadius={0.75}
                                selectedOffset={0}
                                legend={false}
                                interactive={false}
                              />
                              <div className="donut_label">
                                <div className="donut_label_completed">
                                  {flashcardsSeen}
                                </div>
                                <div className="donut_label_total">
                                  {primaryExam.selectedFlashcardCount}
                                </div>
                              </div>
                              <div className="donut_outer-label">
                                Flashcards
                              </div>
                            </div>
                          )}
                          {!areAllValuesDefined(
                            flashcardCompletionPercentage,
                            flashcardsSeen,
                            primaryExam.selectedFlashcardCount
                          ) && (
                            <Skeleton
                              sx={{
                                bgcolor: 'rgba(255,255,255,.05)',
                                borderRadius: '1rem',
                              }}
                              variant="rectangular"
                              animation="wave"
                              width={120}
                              height={140}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {primaryExam && (
                <div className="block block--dashboard">
                  <div className="block_body">
                    <h2 className="m-t-0">Study Streak</h2>
                    <Heatmap examId={primaryExam.id} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={`dashboard_wrapper_body dashboard_wrapper_body--topics ${
              mainChartIsReady ? 'is-ready' : ''
            }`}
          >
            <div className="block block--dashboard block--dashboard--topics">
              <div className="block_body">
                <h2 className="m-t-0">Topic Performance</h2>
                <TopicPerformance />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;
