// React Core
import React, { useEffect, useMemo, useRef, useState, useReducer } from 'react';

// External Libraries
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Popover } from '@mui/material';
import { posthog } from 'posthog-js';
import { useHotkeys } from 'react-hotkeys-hook';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import {
  AlertTriangle,
  Copy,
  MessageSquare,
  Repeat,
  Type,
} from 'react-feather';

// --- UI Reducer ---
import { examUIReducer, initialUIState } from './examUIReducer';

// Redux Store & Slices
import { RootState } from '../../../store/store';
import { updateBlockFE } from '../../../slices/blocks/blocksSlice';
import { setTodaySession } from '../../../slices/todaySession/todaySessionSlice';
import { setUserInfo } from '../../../slices/auth/authSlice';

// Services
import {
  addBlockResponse,
  submitBlock,
  updateBlockBE,
  updateBlockNotes,
} from '../../../services/blocks';
import { updateStudySession } from '../../../services/studySession';
import {
  fetchLinkedFlashcards,
  submitTutorVignette,
} from '../../../services/vignettes';
import { updateCurrentUserInfo } from '../../../services/auth';

// Types
import { Vignette, VignetteContent } from '../../../types/Vignette';
import {
  Block,
  BlockTimeSetting,
  BlockType,
  PanelType,
  StudyPhase,
} from '../../../types/Study';
import { Flashcard } from '../../../types/Flashcard';

// Components
import StudyPanel from '../StudyPanel';
import ExamHeader from './ExamHeader';
import Footer from './Footer';
import LabValues from './LabValues';
import StartScreen from './StartScreen';
import Calculator from './Calculator';
import AnswerOptions from './AnswerOptions';
import Notes from './Notes';
import ConfirmEndModal from './ConfirmEndModal';
import ImagePopover from './ImagePopover';
import ExplanationDisplay from './ExplanationDisplay';
import QuestionListDrawer from './QuestionListDrawer';
import NonAuthFeatureTour from './NonAuthFeatureTour';
import AuthFeatureTour from './AuthFeatureTour';

// Custom Hooks
import useWindowWidth from '../../../hooks/useWindowWidth';
import useWindowHeight from '../../../hooks/useWindowHeight';
import { useSystemTheme } from '../../../hooks/useSystemTheme';
import useHighlighting from './Highlighting/highlighting';
import useFeatureTours from './Hooks/useFeatureTours';
import { useParsedVignetteContent } from './Hooks/useParsedVignetteContent';
import { useExamTimer } from './Hooks/useExamTimer';
import useAnswerCrossouts from './Hooks/useAnswerCrossouts';

// Utils
import { useRefreshStudySessionAndContent } from '../../../utils/refreshStudySession';
import { hapticsImpactLight } from '../../../utils/haptics';
import { copyToClipboard } from '../../../utils/copyToClipboard';

// Assets
import NotesIcon from '../../../assets/images/standard/notes.svg';
import CalculatorIcon from '../../../assets/images/standard/calculator.svg';
import { Drag } from '../../../assets/svgs/Drag';

interface ExamProps {
  activeBlockId: string;
  currentBlockNumber: number;
  nextBlock?: () => void;
  changePhase?: (phase: StudyPhase, options?: object) => void;
  vignettes: Vignette[];
  isStart: boolean;
  setIsStart?: (isStart: boolean) => void;
  fadeInOutFlashLoader: () => void;
  fadeInFlashLoader: () => void;
  fadeOutFlashLoader: () => void;
  reviewQbankBlock?: (blockId: string) => void;
  panelType: PanelType;
  setPanelType: (panelType: PanelType) => void;
  isDemo?: boolean;
  isStartingPhase: boolean;
  setIsStartingPhase: (isStartingPhase: boolean) => void;
}

const Exam: React.FC<ExamProps> = ({
  activeBlockId,
  currentBlockNumber,
  nextBlock,
  changePhase,
  vignettes,
  isStart,
  setIsStart,
  fadeInOutFlashLoader,
  fadeInFlashLoader,
  fadeOutFlashLoader,
  reviewQbankBlock,
  panelType,
  setPanelType,
  isDemo = false,
  isStartingPhase,
  setIsStartingPhase,
}) => {
  // -------- Hooks for Context and Navigation --------
  const dispatchRedux = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // -------- Redux State Selectors --------
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const activeBlock = useSelector(
    (state: RootState) => state.blocks.find((b) => b.id === activeBlockId)!
  );
  const primaryExam = useSelector((state: RootState) =>
    state.auth.userInfo?.exams.find((b) => b.primary === true)
  );
  const blockCount = useSelector((state: RootState) => state.blocks.length);

  // -------- UI State (Managed by Reducer) --------
  const [uiState, uiDispatch] = useReducer(examUIReducer, initialUIState);

  // -------- Other Local State --------
  const [theme, setTheme] = useState('default');
  const [linkedFlashcards, setLinkedFlashcards] = useState<Flashcard[]>([]);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [activeBlockType, setActiveBlockType] = useState(activeBlock?.type);
  const [qBankDemoQuestionCorrect, setQBankDemoQuestionCorrect] = useState<
    boolean | null
  >(null);
  const [activeVignette, setActiveVignette] = useState(
    vignettes[activeQuestionIndex]
  );
  const [activeVignetteContent, setActiveVignetteContent] = useState<
    VignetteContent | undefined
  >(activeVignette?.contents[0]);
  const [keybindsDisabled, setKeybindsDisabled] = useState(false);
  const [vignetteNotes, setVignetteNotes] = useState('');
  const [reportValue, setReportValue] = useState('');

  // -------- Refs --------
  const activeBlockRef = useRef(activeBlock);
  const contextMenuAnchor = useRef<HTMLButtonElement>(null);
  const leftColumnRef = useRef<HTMLDivElement>(null);
  const rightColumnRef = useRef<HTMLDivElement>(null);
  const labValuesRef = useRef<HTMLDivElement>(null);

  // -------- Custom Hooks --------
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();
  const systemTheme = useSystemTheme();

  const { refreshFlashcards } = useRefreshStudySessionAndContent();

  // Highlighting Hook
  const { highlightTarget } = useHighlighting(
    isStart,
    activeBlock,
    activeVignetteContent,
    todaySession
  );
  const nonAuthTourStep1Ref = highlightTarget;
  const nonAuthTourStep2Ref = useRef<HTMLDivElement>(null);

  // Feature Tour Hook
  const {
    showQBankTour,
    isQBankTourRunning,
    currentQBankTourStepIndex,
    qbankStepSelectors,
    handleSeenQBankTour,
    setIsQBankTourRunning,
    isNonAuthTourRunning,
    currentNonAuthStepIndex,
    nonAuthStepTargets,
    signalDemoFirstAnswerSubmitted,
    createAccountModalIsOpen,
    setCreateAccountModalIsOpen,
    allDemoQuestionsSubmitted,
    handleStepChange,
    handleTourEnd,
  } = useFeatureTours({
    isDemo,
    isStart,
    currentUser,
    nonAuthTourStep1Ref,
    nonAuthTourStep2Ref,
    windowWidth,
    activeBlock,
  });

  // Answer Crossouts Hook
  useAnswerCrossouts({
    activeBlock,
    activeVignette,
    activeVignetteContent,
    isDemo,
  });

  // Exam Timer Hook
  const { timeRemaining, timeRemainingRef } = useExamTimer({
    initialDuration: activeBlock?.duration || 0,
    blockType: activeBlockType,
    isStarted: !isStart,
    isSubmitted: activeBlock.isSubmitted,
    isReviewPhase: todaySession.phase === StudyPhase.qbankReview,
    onTimerEnd: () => handleTimerEnd(),
    blockId: activeBlockId,
  });

  // Needs to be defined before useParsedVignetteContent hook
  // Returns the user's answer for the vignette
  const storedAnswer = (vignetteId: string) => {
    if (activeBlock.answers) {
      const answerObj = activeBlock.answers[vignetteId];
      return answerObj?.answer || null;
    }
    return null;
  };

  // Vignette Content Parsing Hook
  const { parsedExhibit, parsedExplanation } = useParsedVignetteContent({
    activeVignetteContent,
    shouldRenderAnswerAndExplanation: () => shouldRenderAnswerAndExplanation(),
    userAnswer: storedAnswer(activeVignette?.id || ''),
  });

  // -------- Hotkeys --------
  useHotkeys('comma', () => keybinds('comma'), { keyup: true });
  useHotkeys('period', () => keybinds('period'), { keyup: true });
  useHotkeys('r', () => keybinds('r'), { keyup: true });
  useHotkeys('l', () => keybinds('l'), { keyup: true });
  useHotkeys('f', () => keybinds('f'), { keyup: true });
  useHotkeys('e', () => keybinds('e'), { keyup: true });
  useHotkeys('escape', () => keybinds('esc'), { keyup: true });

  const keybinds = (key: string) => {
    if (key === 'esc') {
      setKeybindsDisabled(false);
      setPanelType(PanelType.explanation);
    }
    if (keybindsDisabled) return;
    if (key === 'r') {
      setPanelType(PanelType.report);
    }
    if (key === 'l') {
      setPanelType(PanelType.library);
    }
    if (key === 'f') {
      if (currentUser && !primaryExam?.isUsingFlashcards) return;
      setPanelType(PanelType.flashcards);
    }
    if (key === 'e') {
      setPanelType(PanelType.explanation);
    }
  };

  // -------- Use Effects --------

  // Sets up initial state, adds/removes global listeners, and performs cleanup on unmount
  useEffect(() => {
    // Protects against leaving exam page open overnight
    if (activeBlock === null || activeBlock === undefined) {
      if (todaySession?.vignetteCount === 0) {
        if (changePhase) changePhase(StudyPhase.flashcard);
      } else {
        navigate('/');
      }
    }
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('resize', handlePanelResize);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('resize', handlePanelResize);
      document.body.classList.remove('in-exam');
      document.body.classList.remove('exam--standard');
      document.body.classList.remove('exam--timed');
      document.body.classList.remove('exam--tutor');
      document.body.classList.remove('dark');
      if (
        (currentUser && currentUser.theme === 'dark') ||
        (currentUser &&
          currentUser.theme === 'system' &&
          systemTheme === 'dark')
      ) {
        document.body.classList.add('theme-dark');
      }
      if (activeBlockRef.current) {
        cleanupExam(activeBlockRef.current);
      }
    };
  }, []);

  // Updates the active vignette when the question index or vignettes change
  useEffect(() => {
    setActiveVignette(vignettes[activeQuestionIndex]);
  }, [activeQuestionIndex, vignettes]);

  // Toggles the 'in-exam' body class based on the `isStart` state
  useEffect(() => {
    if (!isStart) {
      document.body.classList.add('in-exam');
    } else {
      document.body.classList.remove('in-exam');
    }
  }, [isStart]);

  // Adjusts bottom padding on content columns for mobile when lab values are shown
  useEffect(() => {
    if (uiState.showLabValues && windowWidth < 900) {
      const labValuesHeight = labValuesRef.current?.clientHeight;
      if (shouldRenderAnswerAndExplanation()) {
        if (rightColumnRef.current) {
          rightColumnRef.current.style.paddingBottom = `${labValuesHeight}px`;
        }
      } else {
        if (leftColumnRef.current) {
          leftColumnRef.current.style.paddingBottom = `${labValuesHeight}px`;
        }
      }
    } else {
      if (leftColumnRef.current) {
        leftColumnRef.current.style.paddingBottom = '1.5rem';
      }
      if (rightColumnRef.current) {
        rightColumnRef.current.style.paddingBottom = '0';
      }
    }
  }, [
    leftColumnRef,
    rightColumnRef,
    windowWidth,
    windowHeight,
    uiState.showLabValues,
    activeBlock,
    activeVignette,
  ]);

  // Fades out the loader shortly after starting a QBank review session
  useEffect(() => {
    if (!isStart && todaySession.phase === StudyPhase.qbankReview) {
      setTimeout(() => {
        fadeOutFlashLoader();
      }, 250);
    }
  }, [isStart, todaySession, fadeOutFlashLoader]);

  // Automatically starts or resumes the block when component mounts or relevant state changes
  useEffect(() => {
    if (activeBlock && isStart && isStartingPhase) {
      setIsStartingPhase(false);
      if (!activeBlock.isStarted) {
        if (currentUser?.preferredBlockType === 'tutor') {
          startBlock(BlockType.tutor);
        }
        if (currentUser?.preferredBlockType === 'timed') {
          startBlock(BlockType.timed);
        }
      }
      if (activeBlock.isStarted) {
        resumeBlock();
      }
    }
  }, [activeBlock, isStart, currentUser, isStartingPhase]);

  // Resets question index and applies theme based on block status and start state
  useEffect(() => {
    if (activeBlock && isStart) {
      if (activeBlockType === BlockType.tutor) {
        const answeredQuestions = Object.keys(activeBlock.answers).length;
        setActiveQuestionIndex(answeredQuestions);
      }
      if (activeBlock.isSubmitted) {
        setActiveQuestionIndex(0);
      }
      if (
        (currentUser && currentUser.theme === 'dark') ||
        (currentUser &&
          currentUser.theme === 'system' &&
          systemTheme === 'dark')
      ) {
        document.body.classList.add('theme-dark');
      }
    }
    if (!isStart) {
      document.body.classList.remove('theme-dark');
    }
  }, [activeBlock, isStart, currentUser, systemTheme, activeBlockType]);

  // Updates local block ref and type state when the Redux block changes
  useEffect(() => {
    activeBlockRef.current = activeBlock;
    if (activeBlock) {
      setActiveBlockType(activeBlock.type);
    }
  }, [activeBlock]);

  // Automatically submits the block in Tutor mode if all questions are submitted
  useEffect(() => {
    if (!activeBlock) return;
    if (activeBlock.type === BlockType.tutor && !activeBlock.isSubmitted) {
      if (
        Object.keys(activeBlock.answers).length === activeBlock.vignetteCount &&
        Object.values(activeBlock.answers).every((answer) => answer.submitted)
      ) {
        endBlock();
      }
    }
  }, [activeBlock?.answers]);

  // Adds/removes CSS classes to body based on block type and exam active state
  useEffect(() => {
    if (isStart) {
      document.body.classList.remove('exam--standard');
      document.body.classList.remove('exam--timed');
      document.body.classList.remove('exam--tutor');
    } else {
      document.body.classList.add('exam--standard');
      if (activeBlockType === BlockType.timed) {
        document.body.classList.add('exam--timed');
      }
      if (activeBlockType === BlockType.tutor) {
        document.body.classList.add('exam--tutor');
      }
    }
  }, [activeBlockType, isStart, todaySession.phase]);

  // Ensures the `activeBlockRef` remains up-to-date
  useEffect(() => {
    activeBlockRef.current = activeBlock;
  }, [activeBlock?.id]);

  // Bypass start screen when reviewing QBank
  useEffect(() => {
    if (
      todaySession.phase === StudyPhase.qbankReview ||
      todaySession.isReview
    ) {
      if (setIsStart) setIsStart(false);
    }
  }, [todaySession, setIsStart]);

  // Updates the active vignette content when the active block or vignette changes
  useEffect(() => {
    setActiveVignetteContent(activeVignette?.contents[0]);
  }, [activeBlock, activeVignette]);

  // Resets scroll, clears report input, and loads notes for the new vignette
  useEffect(() => {
    setNotes();
    // Reset Scroll Positions
    const contentContainer = document.querySelector('.content-area_main');
    const panelGroup = document.getElementById('panel-group');
    const leftPanel = document.getElementById('left-panel');
    const rightPanel = document.getElementById('right-panel');
    setReportValue('');
    if (contentContainer) {
      contentContainer.scrollTop = 0;
    }
    if (panelGroup) {
      panelGroup.scrollTop = 0;
    }
    if (leftPanel) {
      leftPanel.scrollTop = 0;
    }
    if (rightPanel) {
      rightPanel.scrollTop = 0;
    }
  }, [activeVignette?.id]);

  // Fetches linked flashcards when vignette changes or exam starts/resumes (if applicable)
  useEffect(() => {
    if (!isStart) {
      if (activeBlock.type === BlockType.tutor || activeBlock.isSubmitted) {
        if (activeVignette && (primaryExam?.isUsingFlashcards || isDemo)) {
          getLinkedFlashcards(activeVignette.id);
        }
      }
    }
  }, [activeVignette?.id, isStart, activeBlock]);

  // Triggers panel resize logic once the right column ref is available (i.e. on load)
  useEffect(() => {
    handlePanelResize();
  }, [rightColumnRef.current]);

  // -------- Action Functions --------

  const startBlock = async (type: BlockType) => {
    fadeInOutFlashLoader();
    setTimeout(() => {
      if (setIsStart) setIsStart(false);
    }, 1000);
    if (!activeBlock) return;
    if (!activeBlock.isStarted) {
      const blockTime = computeBlockTime(
        currentUser?.preferredBlockTimeSetting || BlockTimeSetting.default,
        type,
        activeBlock.vignetteCount
      );
      const updatedBlock = {
        ...activeBlock,
        isStarted: true,
        type: type,
        duration: blockTime,
        timeSetting:
          currentUser?.preferredBlockTimeSetting || BlockTimeSetting.default,
      };
      posthog?.capture(type + '_block_start');
      try {
        const response = await updateBlockBE(activeBlock.id, updatedBlock);
        dispatchRedux(updateBlockFE(response));
      } catch (error) {
        console.error('Error updating block: ', error);
      }
    }
  };

  const endBlock = async () => {
    const scoreObj = calculateScore();
    const updatedBlock = {
      ...activeBlock,
      isSubmitted: true,
      duration: 0,
      ...scoreObj,
    };
    if (activeBlockType === BlockType.timed) {
      setActiveQuestionIndex(0);
    }
    const isTimedMode = activeBlockType === BlockType.timed;
    if (!isDemo) {
      activeBlockType === BlockType.timed
        ? posthog?.capture('timed_block_submit')
        : posthog?.capture('tutor_block_submit');
    }
    submitBlock(updatedBlock.id, scoreObj, isTimedMode, isDemo || false);
    dispatchRedux(updateBlockFE(updatedBlock));
    updateStudySessionScoreCounts(scoreObj);
  };

  const resumeBlock = async () => {
    fadeInOutFlashLoader();
    setTimeout(() => {
      if (setIsStart) setIsStart(false);
    }, 1000);
  };

  const pauseBlock = async () => {
    if (changePhase) changePhase(StudyPhase.qbank);
    hapticsImpactLight();
    enqueueSnackbar('QBank progress saved.', {
      autoHideDuration: 3000,
    });
    if (todaySession.phase === StudyPhase.qbank) {
      updateBlockTime();
    }
    refreshFlashcards();
  };

  const continueExam = async () => {
    setActiveQuestionIndex(0);
    if (nextBlock) {
      nextBlock();
    }
  };

  const handleTimerEnd = () => {
    console.log('No Time Remaining, End Block');
    enqueueSnackbar('The block time has ended.', { autoHideDuration: 5000 });
    endBlock();
  };

  const updateBlockTime = async () => {
    if (!activeBlockRef.current) return;
    const updatedBlock = {
      ...activeBlockRef.current,
      duration: timeRemainingRef.current,
    };
    try {
      fadeInOutFlashLoader();
      const response = await updateBlockBE(
        activeBlockRef.current.id,
        updatedBlock
      );
      dispatchRedux(updateBlockFE(response));
    } catch (error) {
      console.error('Error updating block: ', error);
    }
  };

  const prevQuestion = async () => {
    if (activeQuestionIndex !== 0) {
      setActiveQuestionIndex(activeQuestionIndex - 1);
    }
  };

  const nextQuestion = async () => {
    if (activeQuestionIndex !== vignettes.length - 1) {
      setActiveQuestionIndex(activeQuestionIndex + 1);
    }
  };

  const setAnswer = async (
    vignette: Vignette,
    answerChoice: string | undefined | null,
    submit: boolean
  ) => {
    if (!answerChoice) {
      if (activeBlock.answers && activeBlock.answers[vignette.id]) {
        answerChoice = activeBlock.answers[vignette.id].answer;
      } else {
        return;
      }
    }
    const currVignetteContent = vignette.contents[0];

    const updatedBlock = {
      ...activeBlock,
      answers: {
        ...activeBlock.answers,
        [vignette.id]: {
          correctAnswer: currVignetteContent.correctAnswer,
          answer: answerChoice,
          submitted: submit,
          vignetteContentId: currVignetteContent.id,
        },
      },
      answerCrossouts: activeBlock?.answerCrossouts,
    };

    if (activeBlockType === BlockType.timed) {
      if (activeBlock.answers && !activeBlock.answers[vignette.id]) {
        posthog?.capture('timed_question_answer');
      }
    }

    if (submit && activeBlockType === BlockType.tutor) {
      submitTutorVignette(
        vignette.id,
        answerChoice,
        currVignetteContent.correctAnswer,
        submit,
        isDemo
      );
      posthog?.capture('tutor_question_submit', { isDemo: isDemo });
      if (windowWidth < 900) {
        setTimeout(() => {
          if (nonAuthTourStep2Ref.current) {
            nonAuthTourStep2Ref.current.style.scrollMarginTop = '24px';
            nonAuthTourStep2Ref.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }
        }, 50);
      }
      setQBankDemoQuestionCorrect(
        answerChoice === currVignetteContent.correctAnswer
      );
    }

    addBlockResponse(
      updatedBlock.id,
      vignette.id,
      answerChoice,
      currVignetteContent.correctAnswer,
      submit,
      currVignetteContent.id
    );
    dispatchRedux(updateBlockFE(updatedBlock));
  };

  const updateStudySessionScoreCounts = async (scoreObj: {
    correctCount: number;
    incorrectCount: number;
    omittedCount: number;
  }) => {
    const vignetteCorrectCount =
      todaySession.vignetteCorrectCount + scoreObj.correctCount;
    const vignetteIncorrectCount =
      todaySession.vignetteIncorrectCount + scoreObj.incorrectCount;
    const vignetteOmittedCount =
      todaySession.vignetteOmittedCount + scoreObj.omittedCount;

    const updatedStudySession = {
      ...todaySession,
      vignetteCorrectCount,
      vignetteIncorrectCount,
      vignetteOmittedCount,
    };

    const response = await updateStudySession(
      todaySession.id,
      updatedStudySession
    );
    dispatchRedux(setTodaySession(response));
  };

  const setNotes = () => {
    if (activeBlock?.notes[activeVignette?.id]) {
      setVignetteNotes(activeBlock.notes[activeVignette?.id]);
    } else {
      setVignetteNotes('');
    }
  };

  const updateVignetteNotes = async () => {
    uiDispatch({ type: 'TOGGLE_NOTES' }); // Dispatch action to close notes
    const res = await updateBlockNotes(
      activeBlockId,
      activeVignette.id,
      vignetteNotes
    );
    if (res) {
      dispatchRedux(updateBlockFE(res));
    }
  };

  const clearVignetteNotes = async () => {
    setVignetteNotes('');
    const res = await updateBlockNotes(activeBlockId, activeVignette.id, '');
    if (res) {
      dispatchRedux(updateBlockFE(res)); // Use dispatchRedux
    }
  };

  const getLinkedFlashcards = async (id: string) => {
    const linkedFlashcards = await fetchLinkedFlashcards(id);
    setLinkedFlashcards(linkedFlashcards);
  };

  const toggleTheme = () => {
    if (theme === 'default') {
      document.body.classList.add('dark');
      setTheme('dark');
    } else {
      document.body.classList.remove('dark');
      setTheme('default');
    }
  };

  // REMOVED: toggleLabValues, toggleCalculator, toggleNotes - will use uiDispatch directly

  const handlePanelResize = () => {
    setTimeout(() => {
      if (rightColumnRef.current) {
        const width = rightColumnRef.current.getBoundingClientRect().width;
        updateHeaderWidth(width);
      }
    }, 100);
  };

  const updateHeaderWidth = (width: number) => {
    const header = document.querySelector('.study-panel_header') as HTMLElement;
    if (header) {
      header.style.width = `${width}px`;
    }
  };

  const updateMobileTextSize = async (
    newSize: 'smaller' | 'default' | 'larger'
  ) => {
    const updateUser = await updateCurrentUserInfo({
      vignetteMobileTextSize: newSize,
    });
    if (updateUser) {
      dispatchRedux(setUserInfo(updateUser));
      if (
        (currentUser && currentUser.theme === 'dark') ||
        (currentUser &&
          currentUser.theme === 'system' &&
          systemTheme === 'dark')
      ) {
        setTimeout(() => {
          document.body.classList.remove('theme-dark');
        }, 0);
      }
    }
  };

  const handleBeforeUnload = async () => {
    console.log('The page is being unloaded or refreshed.');
    if (activeBlockRef.current && timeRemainingRef) {
      const updatedBlock = {
        ...activeBlockRef.current,
        duration: timeRemainingRef.current,
      };
      dispatchRedux(updateBlockFE(updatedBlock));
    }
  };

  const cleanupExam = async (activeBlock: Block) => {
    if (!activeBlock.id) return;
    console.log('Exam Unmounting');
    if (todaySession.phase === StudyPhase.qbank) {
      console.log('Block Active During Unmount, Update Backend');
      updateBlockTime();
    }
  };

  // -------- Helper Functions --------

  const computeBlockTime = (
    preferredBlockTimeSetting: BlockTimeSetting,
    type: BlockType,
    vignetteCount: number
  ) => {
    if (type === BlockType.timed) {
      return computeAdditionalTime(
        preferredBlockTimeSetting,
        vignetteCount * 90
      );
    } else {
      return 0;
    }
  };

  const computeAdditionalTime = (
    preferredBlockTimeSetting: BlockTimeSetting,
    duration: number
  ) => {
    if (preferredBlockTimeSetting === BlockTimeSetting.quarter) {
      return duration * 1.25;
    }
    if (preferredBlockTimeSetting === BlockTimeSetting.half) {
      return duration * 1.5;
    }
    if (preferredBlockTimeSetting === BlockTimeSetting.double) {
      return duration * 2;
    }
    return duration;
  };

  const shouldRenderAnswerAndExplanation = () => {
    if (!activeBlock) return false;
    if (activeBlock.isSubmitted) {
      return true;
    }
    if (
      activeBlock.type === BlockType.tutor &&
      questionIsSubmitted(activeVignette?.id)
    ) {
      return true;
    }
    return false;
  };

  const hasAnsweredQuestions = useMemo(() => {
    return activeBlock && Object.keys(activeBlock.answers).length > 0;
  }, [activeBlock]);

  const isAnswered = (vignetteId: string): boolean => {
    if (!activeBlock) return false;
    return Boolean(
      (activeBlock.type === BlockType.tutor &&
        activeBlock.answers &&
        activeBlock.answers[vignetteId] &&
        activeBlock.answers[vignetteId].submitted) ||
        (activeBlock.type === BlockType.timed &&
          activeBlock.answers &&
          activeBlock.answers[vignetteId])
    );
  };

  const questionIsSubmitted = (id: string | undefined) => {
    if (!id || !activeBlock) return false;
    return activeBlock.answers[id]?.submitted;
  };

  const vignettesRemaining = () => {
    let count = 0;
    vignettes.forEach((v) => {
      if (!isAnswered(v.id)) {
        count++;
      }
    });
    return count;
  };

  const calculateCorrectCount = () => {
    if (!activeBlock || !activeBlock.answers) return 0;
    return Object.values(activeBlock.answers).reduce(
      (correctCount, currentAnswer) => {
        if (currentAnswer.answer === currentAnswer.correctAnswer) {
          return correctCount + 1;
        }
        return correctCount;
      },
      0
    );
  };

  const calculateScore = () => {
    if (!activeBlock)
      return { correctCount: 0, incorrectCount: 0, omittedCount: 0 };
    const correctCount = calculateCorrectCount();
    const incorrectCount =
      Object.keys(activeBlock.answers || {}).length - correctCount;
    const omittedCount =
      activeBlock.vignetteCount - correctCount - incorrectCount;
    return {
      correctCount,
      incorrectCount,
      omittedCount,
    };
  };

  // --- Internal Render Functions ---
  const renderSwitchPhaseButton = () => {
    if (hasAnsweredQuestions) return null;
    if (todaySession.phase === StudyPhase.qbankReview) return null;
    if (activeBlock?.isSubmitted) return null;
    if (isDemo) return null;

    const toggleBlockType = async () => {
      if (changePhase) changePhase(StudyPhase.qbank);
      const newBlockType =
        activeBlock.type === BlockType.timed
          ? BlockType.tutor
          : BlockType.timed;
      const newDuration = computeBlockTime(
        currentUser?.preferredBlockTimeSetting || BlockTimeSetting.default,
        newBlockType,
        activeBlock.vignetteCount
      );
      try {
        const response = await updateBlockBE(activeBlock.id, {
          ...activeBlock,
          type: newBlockType,
          duration: newDuration,
          timeSetting:
            currentUser?.preferredBlockTimeSetting || BlockTimeSetting.default,
        });
        dispatchRedux(updateBlockFE(response));
      } catch (error) {
        console.error('Error updating block: ', error);
      }
    };

    return (
      <button
        className="button button--glass button--switch-block-type"
        onClick={toggleBlockType}
      >
        <Repeat />
        Change to {activeBlock.type === BlockType.timed
          ? 'Untimed'
          : 'Timed'}{' '}
        Block
      </button>
    );
  };

  const renderMobileTextSizeButton = () => {
    return (
      <div className="button button--text-size">
        <div className="button--text-size_wrapper">
          <button
            className={`button--text-size_wrapper_option ${!currentUser || currentUser?.vignetteMobileTextSize === 'smaller' ? 'is-selected' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              updateMobileTextSize('smaller');
            }}
          >
            A
          </button>
          <button
            className={`button--text-size_wrapper_option button--text-size_wrapper_option--middle ${currentUser?.vignetteMobileTextSize === 'default' ? 'is-selected' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              updateMobileTextSize('default');
            }}
          >
            A
          </button>
          <button
            className={`button--text-size_wrapper_option ${currentUser?.vignetteMobileTextSize === 'larger' ? 'is-selected' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              updateMobileTextSize('larger');
            }}
          >
            A
          </button>
        </div>
      </div>
    );
  };

  const renderFunctionMenu = () => {
    if (!uiState.functionMenuIsOpen) return null;
    return (
      <div
        className="function-menu"
        role="button"
        aria-label="Close Function Menu"
        onClick={() => {
          hapticsImpactLight();
          uiDispatch({ type: 'SET_FUNCTION_MENU_OPEN', payload: false });
        }}
      >
        <div className="function-menu_options">
          <button
            onClick={() => {
              hapticsImpactLight();
              uiDispatch({ type: 'TOGGLE_NOTES' });
            }}
          >
            <img
              src={NotesIcon}
              alt="Notes"
              style={{ transform: 'scale(1.25), translateY(2px)' }}
            />
            Notes
          </button>
          <button
            onClick={() => {
              hapticsImpactLight();
              uiDispatch({ type: 'TOGGLE_CALCULATOR' });
            }}
          >
            <img src={CalculatorIcon} alt="Calculator" />
            Calculator
          </button>
          {shouldRenderAnswerAndExplanation() && (
            <button
              onClick={() => {
                hapticsImpactLight();
                setPanelType(PanelType.report);
                uiDispatch({ type: 'SET_FUNCTION_MENU_OPEN', payload: false });
              }}
            >
              <AlertTriangle />
              Report
            </button>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            <Type />
            Text Size
            {renderMobileTextSizeButton()}
          </div>
          <button
            onClick={() => {
              hapticsImpactLight();
              window.Intercom('showNewMessage');
              uiDispatch({ type: 'SET_FUNCTION_MENU_OPEN', payload: false });
            }}
          >
            <MessageSquare />
            Help
          </button>
          {renderSwitchPhaseButton()}
        </div>
      </div>
    );
  };

  // --- Main Render Function ---
  return (
    <div
      className={`exam${currentUser?.vignetteTextSize === 'larger' ? ' text-size-larger' : ''}${currentUser?.vignetteTextSize === 'largest' ? ' text-size-largest' : ''}${currentUser?.vignetteMobileTextSize === 'smaller' ? ' text-size-mobile-smaller' : ''}${currentUser?.vignetteMobileTextSize === 'larger' ? ' text-size-mobile-larger' : ''}`}
    >
      {isStart && !todaySession.isReview && (
        <>
          <StartScreen
            activeBlock={activeBlock}
            currentBlockNumber={currentBlockNumber}
            timeRemaining={timeRemaining}
            startBlock={startBlock}
            resumeBlock={resumeBlock}
            primaryExam={primaryExam}
            fadeInFlashLoader={fadeInFlashLoader}
            reviewQbankBlock={reviewQbankBlock}
          />
        </>
      )}
      {!isStart &&
        activeBlock && ( // Added safety check for activeBlock
          <>
            <QuestionListDrawer
              isOpen={uiState.questionsMenuIsOpen}
              onClose={() => {
                uiDispatch({ type: 'SET_QUESTIONS_MENU_OPEN', payload: false });
                hapticsImpactLight();
              }}
              vignettes={vignettes}
              activeQuestionIndex={activeQuestionIndex}
              activeBlock={activeBlock}
              onQuestionSelect={(index) => {
                setActiveQuestionIndex(index);
                uiDispatch({ type: 'SET_QUESTIONS_MENU_OPEN', payload: false });
                hapticsImpactLight();
                setIsQBankTourRunning(false);
              }}
              isAnswered={isAnswered}
            />
            <div
              className={`main${activeBlock.isSubmitted ? ' reviewing' : ''}${uiState.reportCardClassActive ? ' report-card-open' : ''}`}
            >
              <ExamHeader
                currentBlockType={activeBlockType}
                currentBlockNumber={currentBlockNumber}
                currentBlockId={activeBlockId}
                currentQuestion={activeQuestionIndex}
                currentVignetteId={activeVignette?.id}
                totalQuestions={vignettes.length}
                timeRemaining={timeRemaining}
                isSubmitted={activeBlock.isSubmitted}
                prevQuestion={prevQuestion}
                nextQuestion={nextQuestion}
                toggleTheme={toggleTheme}
                toggleLabValues={() =>
                  uiDispatch({ type: 'TOGGLE_LAB_VALUES' })
                }
                toggleCalculator={() =>
                  uiDispatch({ type: 'TOGGLE_CALCULATOR' })
                }
                toggleNotes={() => uiDispatch({ type: 'TOGGLE_NOTES' })}
                blockCount={blockCount}
                setQuestionsMenuIsOpen={(isOpen) =>
                  uiDispatch({
                    type: 'SET_QUESTIONS_MENU_OPEN',
                    payload: isOpen,
                  })
                }
                isFlagged={activeBlock.flags?.[activeVignette?.id] ?? false} // Handle potential undefined flags
                labValuesAreOpen={uiState.showLabValues}
                calculatorIsOpen={uiState.showCalculator}
                notesAreOpen={uiState.showNotes}
                pauseBlock={pauseBlock}
                setConfirmEndIsOpen={(isOpen) =>
                  uiDispatch({
                    type: 'SET_CONFIRM_END_MODAL_OPEN',
                    payload: isOpen,
                  })
                }
                isDemo={isDemo}
                setIsQBankTourRunning={setIsQBankTourRunning}
              />
              <div
                className={`content-area${uiState.showLabValues ? ' lab-values-open' : ''}${shouldRenderAnswerAndExplanation() ? ' showing-answer' : ''}`}
              >
                {vignettes.length && (
                  <section className="content-area_main">
                    <PanelGroup
                      id="panel-group"
                      direction="horizontal"
                      className="panel-group"
                    >
                      <Panel id="left-panel" className="panel" minSize={33}>
                        <div className="left" ref={leftColumnRef}>
                          {currentUser?.role === 'admin' && (
                            <div className="admin-toolbar">
                              <div className="qa-status">
                                {activeVignette?.isQAed ? (
                                  <div className="is-qaed">Is QAed</div>
                                ) : (
                                  <div className="is-not-qaed">Is Not QAed</div>
                                )}
                              </div>
                              <div className="spacer">|</div>
                              <button
                                className="uuid-display"
                                onClick={() =>
                                  copyToClipboard(
                                    activeVignetteContent?.id || ''
                                  )
                                }
                              >
                                <Copy />
                                {activeVignetteContent?.id}
                              </button>
                            </div>
                          )}
                          <div ref={highlightTarget}></div>
                          {!shouldRenderAnswerAndExplanation() &&
                            parsedExhibit &&
                            parsedExhibit.length > 0 && (
                              <div className="exhibit not-in-review m-t-1-50 exhibit--mobile">
                                {parsedExhibit}
                              </div>
                            )}
                          <div
                            className="answer-block"
                            ref={nonAuthTourStep2Ref}
                          >
                            <AnswerOptions
                              vignetteContent={activeVignetteContent}
                              vignetteId={activeVignette?.id}
                              userAnswer={storedAnswer(
                                activeVignette?.id || ''
                              )}
                              crossouts={
                                activeBlock?.answerCrossouts?.[
                                  activeVignette?.id || ''
                                ]
                              }
                              onSetAnswer={(answerChoice, submit) =>
                                setAnswer(activeVignette!, answerChoice, submit)
                              }
                              isBlockSubmitted={activeBlock.isSubmitted}
                              shouldShowFeedback={shouldRenderAnswerAndExplanation()}
                              blockType={activeBlockType}
                            />
                          </div>
                          <div
                            className="proceed"
                            style={
                              activeQuestionIndex === vignettes.length - 1 &&
                              activeBlockType === BlockType.tutor &&
                              !shouldRenderAnswerAndExplanation()
                                ? { padding: 0 }
                                : {}
                            }
                          >
                            {activeBlockType === BlockType.tutor &&
                              !shouldRenderAnswerAndExplanation() &&
                              todaySession.phase !== StudyPhase.qbankReview && (
                                <button
                                  className="button button--submit"
                                  onClick={() => {
                                    hapticsImpactLight();
                                    if (isDemo && activeQuestionIndex === 0) {
                                      signalDemoFirstAnswerSubmitted();
                                    }
                                    setAnswer(activeVignette!, null, true);
                                  }}
                                  disabled={!storedAnswer(activeVignette?.id)}
                                  style={
                                    activeQuestionIndex === vignettes.length - 1
                                      ? { margin: '1.5rem 0' }
                                      : {}
                                  }
                                >
                                  Submit
                                </button>
                              )}
                            {((activeBlockType === BlockType.timed &&
                              activeQuestionIndex !== vignettes.length - 1) ||
                              (activeBlockType === BlockType.tutor &&
                                shouldRenderAnswerAndExplanation() &&
                                activeQuestionIndex !==
                                  vignettes.length - 1)) && (
                              <button
                                className="button"
                                onClick={() => {
                                  nextQuestion();
                                  hapticsImpactLight();
                                }}
                              >
                                Proceed to Next Item
                              </button>
                            )}
                          </div>
                        </div>
                      </Panel>
                      {shouldRenderAnswerAndExplanation() && (
                        <PanelResizeHandle className="panel-resize">
                          <Drag />
                        </PanelResizeHandle>
                      )}
                      <Panel
                        id="right-panel"
                        className="panel"
                        onResize={handlePanelResize}
                        minSize={40}
                      >
                        <div className="right" ref={rightColumnRef}>
                          {!shouldRenderAnswerAndExplanation() &&
                            parsedExhibit &&
                            parsedExhibit.length > 0 && (
                              <div className="exhibit not-in-review m-t-1-50 exhibit--desktop">
                                {parsedExhibit}
                              </div>
                            )}
                          {shouldRenderAnswerAndExplanation() && (
                            <StudyPanel
                              panelType={panelType}
                              setPanelType={setPanelType}
                              phase={StudyPhase.qbank}
                              targetArticleId={
                                activeVignette?.topics
                                  ? activeVignette?.topics[0]?.articleId
                                  : ''
                              }
                              activeVignette={activeVignette}
                              activeVignetteContent={activeVignetteContent}
                              linkedFlashcards={linkedFlashcards}
                              getLinkedFlashcards={getLinkedFlashcards}
                              isDemo={isDemo}
                              reportValue={reportValue}
                              setReportValue={setReportValue}
                            >
                              {panelType === PanelType.explanation && (
                                <ExplanationDisplay
                                  parsedExhibit={parsedExhibit}
                                  parsedExplanation={parsedExplanation}
                                  activeVignetteContent={activeVignetteContent}
                                />
                              )}
                            </StudyPanel>
                          )}
                        </div>
                      </Panel>
                    </PanelGroup>
                  </section>
                )}
                <section className="content-area_lab-values-container">
                  <LabValues labValuesRef={labValuesRef} />
                </section>
                {uiState.showCalculator && (
                  <Calculator
                    closeCalculator={() =>
                      uiDispatch({ type: 'TOGGLE_CALCULATOR' })
                    }
                  />
                )}
                {uiState.showNotes && (
                  <Notes
                    vignetteNotes={vignetteNotes}
                    setVignetteNotes={setVignetteNotes}
                    updateVignetteNotes={updateVignetteNotes}
                    clearVignetteNotes={clearVignetteNotes}
                    isReview={todaySession.phase === StudyPhase.qbankReview}
                    activeBlockType={activeBlockType}
                    isPastSessionReview={todaySession.isReview}
                    toggleNotes={() => uiDispatch({ type: 'TOGGLE_NOTES' })}
                  />
                )}
                {renderFunctionMenu()}
              </div>
              <Footer
                activeBlockId={activeBlockId}
                blockType={activeBlockType}
                isSubmitted={activeBlock.isSubmitted}
                changePhase={changePhase}
                continueExam={continueExam}
                timeRemaining={timeRemaining}
                activeVignetteId={activeVignette?.id}
                fadeInFlashLoader={fadeInFlashLoader}
                pauseBlock={pauseBlock}
                setConfirmEndIsOpen={(isOpen) =>
                  uiDispatch({
                    type: 'SET_CONFIRM_END_MODAL_OPEN',
                    payload: isOpen,
                  })
                }
                nextQuestion={nextQuestion}
                prevQuestion={prevQuestion}
                currentQuestion={activeQuestionIndex}
                totalQuestions={vignettes.length}
                isDemo={isDemo}
                allDemoQuestionsSubmitted={allDemoQuestionsSubmitted}
                createAccountModalIsOpen={createAccountModalIsOpen}
                functionMenuIsOpen={uiState.functionMenuIsOpen}
                setFunctionMenuIsOpen={(isOpen) =>
                  uiDispatch({
                    type: 'SET_FUNCTION_MENU_OPEN',
                    payload: isOpen,
                  })
                }
                refreshFlashcards={refreshFlashcards}
                renderSwitchPhaseButton={renderSwitchPhaseButton}
              />
            </div>
            <Popover
              open={uiState.vignetteMenuIsOpen}
              onClose={() =>
                uiDispatch({ type: 'SET_VIGNETTE_MENU_OPEN', payload: false })
              }
              anchorEl={contextMenuAnchor.current}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              disableRestoreFocus
            >
              <div className="modal modal--context-menu">
                <button
                  onClick={() => {
                    uiDispatch({
                      type: 'SET_REPORT_CARD_CLASS_ACTIVE',
                      payload: true,
                    });
                    uiDispatch({
                      type: 'SET_VIGNETTE_MENU_OPEN',
                      payload: false,
                    });
                    hapticsImpactLight();
                  }}
                >
                  Report Question
                </button>
              </div>
            </Popover>
            <ImagePopover parsedExplanation={parsedExplanation} />
            <ConfirmEndModal
              isOpen={uiState.confirmEndIsOpen}
              onClose={() =>
                uiDispatch({
                  type: 'SET_CONFIRM_END_MODAL_OPEN',
                  payload: false,
                })
              }
              onConfirmEnd={() => {
                endBlock();
                uiDispatch({
                  type: 'SET_CONFIRM_END_MODAL_OPEN',
                  payload: false,
                });
              }}
              vignettesRemainingCount={vignettesRemaining()}
            />
            {!isDemo && showQBankTour && (
              <AuthFeatureTour
                stepTargets={[...qbankStepSelectors]}
                run={isQBankTourRunning}
                stepIndex={currentQBankTourStepIndex}
                onStepChange={(newIndex) => handleStepChange('qbank', newIndex)}
                onTourEnd={() => handleTourEnd('qbank')}
                handleSeenQBankTour={handleSeenQBankTour}
                qBankDemoQuestionCorrect={qBankDemoQuestionCorrect}
              />
            )}
            {isDemo && (
              <NonAuthFeatureTour
                answeredCorrectly={
                  activeBlock?.answers?.[activeVignette?.id]?.answer ===
                  activeBlock?.answers?.[activeVignette?.id]?.correctAnswer
                } // Handle potential undefined
                stepTargets={nonAuthStepTargets}
                run={isNonAuthTourRunning}
                stepIndex={currentNonAuthStepIndex}
                onStepChange={(newIndex) =>
                  handleStepChange('non-auth', newIndex)
                }
                onTourEnd={() => handleTourEnd('non-auth')}
                createAccountModalIsOpen={createAccountModalIsOpen}
                setCreateAccountModalIsOpen={setCreateAccountModalIsOpen}
              />
            )}
          </>
        )}
    </div>
  );
};

export default Exam;
