import React, { useCallback, useState, useEffect } from 'react';
import Page from '../../components/Page';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import MSHeader from '../../components/NonAuth/MSHeader';
import MSFooter from '../../components/NonAuth/MSFooter';
import NonAuthPage from '../../components/NonAuth/NonAuthPage';
import { hapticsImpactLight } from '../../utils/haptics';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { TriangleDown } from '../../assets/svgs/TriangleDown';
import HelpMain from '../../components/Help/HelpMain';
import HelpArticlePage from '../../components/Help/HelpArticlePage';
import { ArrowRight, Map } from 'react-feather';
import { useNavigate, useLocation } from 'react-router-dom';
import { HelpArticleTreeNode } from '../../types/Help';
import { Link } from 'react-router-dom';
// import { getArticleTree } from '../../services/help';

const Help: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [activeHelpArticle, setActiveHelpArticle] =
    useState<HelpArticleTreeNode | null>(null);
  const [helpArticlesTree, setHelpArticlesTree] = useState<
    HelpArticleTreeNode[]
  >([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchArticleTree();
  }, []);

  const findArticleById = (
    tree: HelpArticleTreeNode[],
    articleId: string
  ): HelpArticleTreeNode | null => {
    for (const node of tree) {
      if (node.id === articleId) {
        return node;
      }
      if (node.children) {
        const found = findArticleById(node.children, articleId);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const findArticleByNotionId = (
    tree: HelpArticleTreeNode[],
    notionId: string
  ): HelpArticleTreeNode | null => {
    for (const node of tree) {
      if (node.notionId?.replace(/-/g, '') === notionId) {
        return node;
      }
      if (node.children) {
        const found = findArticleByNotionId(node.children, notionId);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const articleId = location.pathname.split('/').pop();
    if (articleId) {
      const article = findArticleById(helpArticlesTree, articleId);
      if (article) {
        const pageContent = document.querySelector('.page_content');
        if (pageContent) {
          pageContent.scrollTo(0, 0);
        }
        setActiveHelpArticle(article);
      } else {
        const articleByNotion = findArticleByNotionId(
          helpArticlesTree,
          articleId
        );
        if (articleByNotion) {
          setActiveHelpArticle(articleByNotion);
        } else {
          setActiveHelpArticle(null);
        }
      }
    } else {
      setActiveHelpArticle(null);
    }
  }, [location.pathname]);

  const fetchArticleTree = async () => {
    // const tree = await getArticleTree();
    const tree = [
      {
        id: 'getting-started',
        notionId: '1742f131-2554-8083-b244-e675672b0195',
        title: 'Getting Started',
        description: '',
        children: [
          {
            id: 'the-basics',
            notionId: '1742f131-2554-805c-a2c7-c66c183bd1a2',
            title: 'The Basics',
            description: 'The basics of Ora',
            children: [],
          },
          {
            id: 'your-daily-session',
            notionId: '16c2f131-2554-80b1-bffb-d4af845a833f',
            title: 'Your Daily Session',
            description: 'Understanding your daily session',
            children: [],
          },
          {
            id: 'exam-settings',
            notionId: '16d2f131-2554-80c2-ab10-c7d38547680e',
            title: 'Exam Settings',
            description: 'Customizing the Ora exam settings',
            children: [],
          },
        ],
      },
      {
        id: 'learning-on-ora',
        notionId: '1742f131-2554-800b-b882-fdfd10dd1a47',
        title: 'Learning on Ora',
        description: '',
        children: [
          {
            id: 'question-bank',
            notionId: '16c2f131-2554-80ed-8bcb-f3b3e20676c9',
            title: 'Question Bank',
            description: 'The best way to practice for your exams',
            children: [],
          },
          {
            id: 'flashcards',
            notionId: '16c2f131-2554-80c9-b891-f0e7feb78b27',
            title: 'Flashcards',
            description: 'Retain medical knowledge indefinitely',
            children: [],
          },
        ],
      },
      {
        id: 'key-concepts',
        notionId: '1742f131-2554-8035-9cf3-c9145c569352',
        title: 'Key Concepts',
        description: '',
        children: [
          {
            id: 'linked-flashcards',
            notionId: '0ff2f131-2554-8092-8bf9-f5df6f54718e',
            title: 'Linked Flashcards',
            description: 'Flashcards assigned from QBank questions',
            children: [],
          },
        ],
      },
      {
        id: 'other',
        notionId: '1742f131-2554-803b-959c-fe790e06602f',
        title: 'Other',
        description: '',
        children: [
          {
            id: 'controller-support',
            notionId: '16c2f131-2554-80bd-96ed-e1478fe5d72a',
            title: 'Controller Support',
            description: 'Use game controllers with flashcards',
            children: [],
          },
        ],
      },
    ];
    setHelpArticlesTree(tree);
  };

  const renderHelpArticleTree = useCallback(
    (helpArticles: HelpArticleTreeNode[], level: number) => {
      if (!helpArticles || !helpArticles.length) return null;

      return helpArticles.map((t, i) => {
        const panelId = `p${level}${i}-${t.title.replace(/\s+/g, '').toLowerCase()}`;

        if (t.children && t.children.length > 0) {
          return (
            <Accordion
              key={i}
              expanded={true}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                sx={{
                  padding: 0,
                }}
                aria-controls={`${panelId}-content`}
                id={`${panelId}-header`}
              >
                {t.children && t.children.length > 0 && (
                  <TriangleDown classes="expand-icon" />
                )}
                <div className="row">
                  <div>{t.title}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={`level-${level}`}>
                  {renderHelpArticleTree(t.children, level + 1)}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        } else {
          return (
            <div
              className={`article-row ${t.id === activeHelpArticle?.id ? 'is-active' : ''}`}
              key={i}
              id={`${panelId}-header`}
            >
              <Link
                to={`/help/${t.id}`}
                onClick={() => {
                  const contentWindow = document.querySelector('.page_content');
                  if (contentWindow) {
                    contentWindow.scrollTo(0, 0);
                  }
                }}
              >
                {t.title}
              </Link>
            </div>
          );
        }
      });
    },
    [activeHelpArticle]
  );

  const helpArticleTree = renderHelpArticleTree(helpArticlesTree, 0);

  const renderHelp = () => {
    return (
      <div className="help">
        <div className="help_left">
          <div className="article-accordion_wrapper">
            <div className="article-accordion_wrapper_header">
              <Link
                to="/help"
                onClick={() => {
                  hapticsImpactLight();
                }}
              >
                Help Center
              </Link>
            </div>
            <div className="article-accordion">{helpArticleTree}</div>
          </div>
          <div className="roadmap-tile">
            <h4>
              <Map />
              Roadmap
            </h4>
            <p>Want to see what we're working on?</p>
            <button
              className="button button--link"
              onClick={() => {
                hapticsImpactLight();
                navigate('/roadmap');
              }}
            >
              View the Ora Roadmap
              <ArrowRight />
            </button>
          </div>
        </div>
        <div className="help_main">
          {!activeHelpArticle && <HelpMain helpArticles={helpArticlesTree} />}
          {activeHelpArticle && activeHelpArticle?.notionId && (
            <HelpArticlePage
              title={activeHelpArticle.title}
              helpArticleNotionId={activeHelpArticle.notionId}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {currentUser && <Page type="help">{renderHelp()}</Page>}
      {!currentUser && (
        <>
          <MSHeader />
          <NonAuthPage classes="p-0">{renderHelp()}</NonAuthPage>
          <MSFooter />
        </>
      )}
    </>
  );
};

export default Help;
