import { Bookmark } from './Bookmark';
import { BlockTimeSetting, BlockType, StudyPhase } from './Study';
import { SubscriptionPlan } from './Subscription';

export type ResponseOption = 'two' | 'four';

export enum UserRole {
  default = 'default',
  admin = 'admin',
}

export type ThemeMode = 'system' | 'light' | 'dark';
export type ScreensaverOption =
  | 'none'
  | 'thirtySeconds'
  | 'oneMinute'
  | 'twoMinutes'
  | 'fiveMinutes'
  | 'tenMinutes';

export interface UserExam {
  id: string;
  name: string;
  examDate: Date;
  primary: boolean;
  selectedFlashcardCount: number;
  selectedVignetteCount: number;
  isDefaultDate: boolean;
  isUsingFlashcards: boolean;
  isUsingQbank: boolean;
  hasCompletedRealExam: boolean;
  realExamScore: number | null;
  ignoredExamDate: boolean;
}

export interface Exam {
  id: string;
  name: string;
  flashcardCount: number;
  vignetteCount: number;
}

export interface User {
  id: string;
  name?: string | null;
  email: string | null;
  createdAt: Date;
  oauth?: boolean | null;
  oauthStrategy?: string | null;
  role: UserRole;
  timezone: string;
  isOnboarded: boolean;
  onboardingTips: { [key: string]: boolean };
  theme: ThemeMode;
  demoMode: boolean;
  screensaverPreference: ScreensaverOption;
  hasWatchedWelcomeVideo: boolean;
  responseOptions: ResponseOption;
  showIntervals: boolean;
  allowedBetaUser: boolean;
  exams: UserExam[];
  preferredStartPhase: StudyPhase;
  preferredBlockType: BlockType;
  preferredBlockTimeSetting: BlockTimeSetting;
  isAnon?: boolean;
  bookmarks: Bookmark[];
  hasIOSApp: boolean;
  hasAndroidApp: boolean;
  flashcardTextSize: string;
  vignetteTextSize: string;
  vignetteMobileTextSize: string;
  studyReminders: string;
  subscribedToEmails: boolean;
  isApprovedReferrer: boolean;
  referralId?: string;
  buyIntent: SubscriptionPlan | null;
  ambassador: boolean;
  ambassadorStartDate: Date | null;
  profilePictureUrl?: string;
}

export interface UpdateUserInfoRequest {
  name?: string | null;
  email?: string | null;
  timezone?: string;
  isOnboarded?: boolean;
  theme?: ThemeMode;
  demoMode?: boolean;
  screensaverPreference?: ScreensaverOption;
  preferredBlockTimeSetting?: BlockTimeSetting;
  hasWatchedWelcomeVideo?: boolean;
  responseOptions?: ResponseOption;
  showIntervals?: boolean;
  onboardingTips?: { [key: string]: boolean };
  preferredStartPhase?: StudyPhase;
  preferredBlockType?: string;
  hasIOSApp?: boolean;
  hasAndroidApp?: boolean;
  flashcardTextSize?: string;
  vignetteTextSize?: string;
  vignetteMobileTextSize?: string;
  studyReminders?: string;
  subscribedToEmails?: boolean;
  ambassador?: boolean;
  ambassadorStartDate?: Date | null;
  buyIntent?: SubscriptionPlan | null;
  profilePictureUrl?: string;
}
