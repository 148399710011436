import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import LinearProgress from '@mui/material/LinearProgress';

const TrialDisplay: React.FC = () => {
  const subscription = useSelector(
    (state: RootState) => state.subscription.subscription
  );

  const daysRemaining = Math.max(
    0,
    moment(subscription?.trialEnd)
      .startOf('day')
      .diff(moment().startOf('day'), 'days')
  );

  const trialProgress = Math.min(100, ((5 - daysRemaining) / 5) * 100);

  return (
    <div className="trial-display">
      <div className="trial-display_progress">
        <LinearProgress
          sx={{ width: '100%' }}
          variant="determinate"
          color="success"
          value={trialProgress === 0 ? 5 : trialProgress}
        />
        <div className="days-remaining">{daysRemaining} days remaining</div>
      </div>
      <div className="m-t-0-50">
        {daysRemaining === 0 ? 'Free trial ended on' : 'Free trial ends on'}{' '}
        {moment(subscription?.trialEnd).format('MMMM D, YYYY')}
      </div>
    </div>
  );
};

export default TrialDisplay;
