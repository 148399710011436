import { useSnackbar } from 'notistack';
import OraSwitch from '../../CustomMUI/Switch';
import GoalSelection from '../GoalSelection';

interface ContentSelectionProps {
  contentSelectionOption: 'High Yield' | 'Standard' | 'Comprehensive' | '';
  primaryExamName: string;
  primaryExamDate: string;
  isUsingFlashcards: boolean;
  setIsUsingFlashcards: (value: boolean) => void;
  totalUserSelectedFlashcards: number;
  setTotalUserSelectedFlashcards: (value: number) => void;
  isUsingQbank: boolean;
  setIsUsingQbank: (value: boolean) => void;
  totalUserSelectedVignettes: number;
  setTotalUserSelectedVignettes: (value: number) => void;
  setWorkloadOption: (value: string) => void;
}

const ContentSelection: React.FC<ContentSelectionProps> = ({
  contentSelectionOption,
  primaryExamName,
  isUsingFlashcards,
  setIsUsingFlashcards,
  isUsingQbank,
  setIsUsingQbank,
  setWorkloadOption,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className="content-selection">
      <div className="content-selection_switch-container">
        <div className="content-selection_switch-container_switch">
          <OraSwitch
            checked={isUsingQbank}
            onChange={(e) => {
              setIsUsingQbank(e.target.checked);
              if (!isUsingFlashcards) {
                setIsUsingFlashcards(true);
                enqueueSnackbar(
                  'You must have at least one content type enabled.',
                  {
                    autoHideDuration: 3000,
                    style: { width: 'fit-content' },
                  }
                );
              }
            }}
          />
          QBank
        </div>
        <div className="content-selection_switch-container_switch">
          <OraSwitch
            checked={isUsingFlashcards}
            onChange={(e) => {
              setIsUsingFlashcards(e.target.checked);
              if (!isUsingQbank) {
                setIsUsingQbank(true);
                enqueueSnackbar(
                  'You must have at least one content type enabled.',
                  {
                    autoHideDuration: 3000,
                    style: { width: 'fit-content' },
                  }
                );
              }
            }}
          />
          Flashcards
        </div>
      </div>
      <div className="content-selection_options">
        <GoalSelection
          selectedGoal={contentSelectionOption}
          setSelectedGoal={setWorkloadOption}
          isUsingQbank={isUsingQbank}
          isUsingFlashcards={isUsingFlashcards}
          primaryExamName={primaryExamName}
        />
      </div>
    </div>
  );
};

export default ContentSelection;
