// hooks/useParsedVignetteContent.ts
import React, { useState, useEffect } from 'react';
import ReactHtmlParser, { HTMLReactParserOptions } from 'html-react-parser';
import CustomImage from '../CustomImage';
import { VignetteContent } from '../../../../types/Vignette';

interface UseParsedVignetteContentProps {
  activeVignetteContent: VignetteContent | undefined;
  userAnswer: string | null;
  shouldRenderAnswerAndExplanation: () => boolean;
}

export function useParsedVignetteContent({
  activeVignetteContent,
  userAnswer,
  shouldRenderAnswerAndExplanation,
}: UseParsedVignetteContentProps) {
  const [parsedExhibit, setParsedExhibit] = useState<React.ReactNode[]>([]);
  const [parsedExplanation, setParsedExplanation] = useState<React.ReactNode[]>(
    []
  );

  // Render the Vignette, Set Vignette Notes, Set Vignette Exhibit. Add Event Listeners for Crossouts if Not Submitted
  useEffect(() => {
    setParsedExhibit([]);
    setParsedExplanation([]);
    if (activeVignetteContent) {
      if (activeVignetteContent.exhibit) {
        parseExhibit(activeVignetteContent.exhibit);
      }
      if (activeVignetteContent.explanation) {
        parseExplanation(
          activeVignetteContent.explanation,
          userAnswer,
          setParsedExplanation
        );
      }
    }
  }, [activeVignetteContent]);

  const parseExplanation = (
    explanationText: string,
    userAnswer: string | null,
    setExplanation: React.Dispatch<React.SetStateAction<React.ReactNode[]>>
  ) => {
    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        if (
          domNode.type === 'tag' &&
          domNode.name === 'img' &&
          domNode.attribs &&
          domNode.attribs['data-image-caption']
        ) {
          const {
            src,
            'data-image-caption': caption,
            'data-image-credit': credit,
            'data-image-src-annotated': annotatedSrc,
          } = domNode.attribs;

          return (
            <CustomImage
              src={src}
              caption={caption}
              credit={credit}
              annotatedSrc={annotatedSrc}
            />
          );
        }
        if (
          domNode.type === 'tag' &&
          domNode.name === 'div' &&
          domNode.attribs &&
          domNode.attribs['class'] === 'answer-explanation' &&
          domNode.attribs['data-explanation-target'] ===
            userAnswer?.toLowerCase()
        ) {
          domNode.attribs['class'] = 'answer-explanation user-answer';
        }
      },
    };

    const explanation = ReactHtmlParser(explanationText, options);
    setExplanation(Array.isArray(explanation) ? explanation : [explanation]);
  };

  const parseExhibit = (exhibitText: string) => {
    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        if (
          domNode.type === 'tag' &&
          domNode.name === 'img' &&
          domNode.attribs &&
          domNode.attribs['data-image-caption']
        ) {
          const {
            src,
            'data-image-caption': caption,
            'data-image-credit': credit,
            'data-image-src-annotated': annotatedSrc,
          } = domNode.attribs;

          return (
            <CustomImage
              src={shouldRenderAnswerAndExplanation() ? annotatedSrc : src}
              caption={caption}
              credit={credit}
              annotatedSrc={annotatedSrc}
            />
          );
        }
      },
    };

    const explanation = ReactHtmlParser(exhibitText, options);
    setParsedExhibit(Array.isArray(explanation) ? explanation : [explanation]);
  };

  return { parsedExhibit, parsedExplanation };
}
