import axiosInstance from './axiosCreator';
import { User } from '../types/User';
import { StudySession } from '../types/Study';
import axios from 'axios';

// Add exam to user - Improved with object parameter
export const addOrUpdateUserExam = async ({
  userId,
  examId,
  examDate,
  selectedFlashcardCount,
  selectedVignetteCount,
  isUsingFlashcards,
  isUsingQbank,
  hasCompletedRealExam,
  realExamScore,
  ignoredExamDate,
  clearTodaySession,
}: {
  userId: string;
  examId: string;
  examDate: string | null;
  selectedFlashcardCount: number;
  selectedVignetteCount: number;
  isUsingFlashcards: boolean;
  isUsingQbank: boolean;
  hasCompletedRealExam: boolean;
  realExamScore: number | null;
  ignoredExamDate: boolean;
  clearTodaySession?: boolean;
}) => {
  try {
    const response = await axiosInstance.post(`/users/${userId}/exams`, {
      examId,
      examDate,
      selectedFlashcardCount,
      selectedVignetteCount,
      isUsingFlashcards,
      isUsingQbank,
      hasCompletedRealExam,
      realExamScore,
      ignoredExamDate,
      clearTodaySession,
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

export const changePrimaryExam = async (userId: string, examId: string) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/changePrimaryUserExam`,
      {
        examId,
      }
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Get all users
export const getAllUsers = async () => {
  try {
    const response = await axiosInstance.get('/users');
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Get batched users
export const getBatchedUsers = async (
  skip: number,
  take: number,
  hideSynaptiq: boolean,
  email: string = ''
) => {
  try {
    const response = await axiosInstance.get(
      `/users/paginated?skip=${skip}&take=${take}&hideSynaptiq=${hideSynaptiq}&email=${email}`
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Get a single user by ID
export const getUserById = async (id: string) => {
  try {
    const response = await axiosInstance.get(`/users/${id}`);
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Update a user
export const updateUser = async (id: string, userData: Partial<User>) => {
  try {
    const response = await axiosInstance.put(`/users/${id}`, userData);
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Update user referralId
export const updateUserReferralId = async (id: string, referralId: string) => {
  try {
    const response = await axiosInstance.put(`/users/${id}/referralId`, {
      referralId,
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Get last study session
export const getLastSession = async (id: string) => {
  try {
    const response = await axiosInstance.get(`/users/${id}/last-session`);
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Create a new study session
export const createNewSession = async (
  userId: string,
  sessionData: StudySession
) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/create-session`,
      sessionData
    );
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Delete a user
export const deleteUser = async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/users/${id}`);
    return response;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Get Mobile App Info
export const getMobileAppInfo = async () => {
  try {
    const response = await axiosInstance.get('/users/mobileAppInfo');
    return response.data;
  } catch (error) {
    // Handle error
    console.error(error);
  }
};

// Get Ambassadors
export const getAmbassadors = async () => {
  try {
    const response = await axiosInstance.get('/users/ambassadors');
    return response.data;
  } catch (error) {
    console.error('Error getting ambassadors:', error);
  }
};

/**
 * Updates a user's ambassador status and related fields
 * @param userId The ID of the user to update
 * @param ambassador Boolean indicating whether the user is an ambassador
 * @param ambassadorStartDate Optional ISO date string for when the user became an ambassador
 * @returns The updated user object or null if the request failed
 */
export const updateUserAmbassadorStatus = async (
  userId: string,
  ambassador: boolean,
  ambassadorStartDate?: string
): Promise<User | null> => {
  try {
    const response = await axiosInstance.put(`/users/${userId}/ambassador`, {
      ambassador,
      ...(ambassadorStartDate && { ambassadorStartDate }),
    });

    return response.data;
  } catch (error) {
    console.error('Error updating user ambassador status:', error);
    return null;
  }
};

export const updateUserSelfAssessment = async (
  userId: string,
  strengths: string,
  weaknesses: string
) => {
  try {
    const response = await axiosInstance.put(
      `/users/${userId}/selfAssessment`,
      {
        strengths,
        weaknesses,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating user strengths and weaknesses:', error);
  }
};

export const fetchPresignedUrl = async (
  bucket: string,
  dirName: string,
  filename: string
): Promise<{ url: string | null }> => {
  try {
    const response = await axiosInstance.get(
      `/aws/presigned?bucket=${bucket}&dir_name=${dirName}&filename=${filename}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching presigned URL:', error);
    return { url: null };
  }
};

export const uploadProfilePictureToS3 = async (
  file: File,
  userId: string
): Promise<string> => {
  const bucket = 'ora-imgs';
  const dirName = `avatars/${userId}`;
  const data = await fetchPresignedUrl(bucket, dirName, file.name);

  if (!userId) return '';

  if (data.url) {
    try {
      await axios.put(data.url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      return `https://ora-imgs.s3.us-west-1.amazonaws.com/${dirName}/${file.name}`;
    } catch (error) {
      console.error('Upload error:', error);
      throw new Error(
        `Upload failed: ${error instanceof Error ? error.message : 'Unknown error'}`
      );
    }
  }

  throw new Error('Failed to get presigned URL');
};

/**
 * Deletes a profile picture from S3 using the profile picture URL
 * @param profilePictureUrl The full URL to the profile picture to delete
 * @returns The response from the delete API call or null if extraction failed
 */
export const deleteProfilePictureFromS3 = async (profilePictureUrl: string) => {
  if (!profilePictureUrl || !profilePictureUrl.includes('ora-imgs')) {
    return null;
  }

  try {
    // Extract the profile picture information from the URL
    const pictureInfo = extractProfilePictureInfo(profilePictureUrl);

    if (!pictureInfo) {
      throw new Error('Could not extract profile picture information from URL');
    }

    // Call the profile-picture delete endpoint with extracted parameters
    const response = await axiosInstance.delete(
      `/aws/profile-picture?bucket=${pictureInfo.bucket}&directory=${pictureInfo.directory}&filename=${pictureInfo.filename}`
    );

    return response.data;
  } catch (error) {
    console.error('Error deleting profile picture:', error);
    throw new Error(
      `Delete failed: ${error instanceof Error ? error.message : 'Unknown error'}`
    );
  }
};

/**
 * @private Internal interface for profile picture parameters
 */
interface ProfilePictureParams {
  bucket: string;
  directory: string;
  filename: string;
}

/**
 * @private Extracts profile picture information from a user's profile picture URL
 * @param profilePictureUrl The full URL to the profile picture
 * @returns Object containing bucket, directory, and filename
 */
const extractProfilePictureInfo = (
  profilePictureUrl: string
): ProfilePictureParams | null => {
  try {
    // Expected format: https://ora-imgs.s3.us-west-1.amazonaws.com/avatars/userId/filename.jpg
    const bucket = 'ora-imgs';

    // Extract the path after the domain
    const urlObj = new URL(profilePictureUrl);
    const pathParts = urlObj.pathname.split('/').filter(Boolean);

    // The first part should be 'avatars'
    if (pathParts.length < 2 || pathParts[0] !== 'avatars') {
      return null;
    }

    // The last part is the filename
    const filename = pathParts[pathParts.length - 1];

    // The directory is everything between the bucket and filename
    const directory = pathParts.slice(0, pathParts.length - 1).join('/');

    return {
      bucket,
      directory,
      filename,
    };
  } catch (error) {
    console.error('Error extracting profile picture info:', error);
    return null;
  }
};
