// src/components/Study/Exam/AnswerOptions.tsx
import React from 'react';
import parse from 'html-react-parser';
import { Check, X } from 'react-feather';
import { VignetteContent } from '../../../../types/Vignette';
import { BlockType } from '../../../../types/Study';
import { hapticsImpactLight } from '../../../../utils/haptics';

interface AnswerOptionsProps {
  vignetteContent: VignetteContent | undefined;
  vignetteId: string | undefined;
  userAnswer: string | null | undefined;
  crossouts: boolean[] | undefined;
  onSetAnswer: (answerChoice: string, submit: boolean) => void;
  isBlockSubmitted: boolean;
  shouldShowFeedback: boolean;
  blockType: BlockType | undefined;
}

const AnswerOptions: React.FC<AnswerOptionsProps> = ({
  vignetteContent,
  vignetteId,
  userAnswer,
  crossouts = [],
  onSetAnswer,
  isBlockSubmitted,
  shouldShowFeedback,
}) => {
  if (!vignetteContent || !vignetteId) {
    return null;
  }

  const { answerOptions, correctAnswer } = vignetteContent;

  const isDisabled = isBlockSubmitted || shouldShowFeedback;

  return (
    <form>
      {answerOptions.map((answerOption, i) => {
        const optionLetter = answerOption.optionLetter;
        const optionId = String.fromCharCode(65 + i);

        const isCrossedOut = crossouts[i] === true;
        const isSelected = optionLetter === userAnswer;
        const isCorrectChoice =
          shouldShowFeedback && optionLetter === correctAnswer;
        const isIncorrectChoice =
          shouldShowFeedback && isSelected && optionLetter !== correctAnswer;

        return (
          <label
            key={`${vignetteId}-answer-${i}`}
            htmlFor={optionId}
            data-index={i}
            className={`${isCrossedOut ? 'crossed-out' : ''}${
              isCorrectChoice ? ' correct-answer' : ''
            }${isIncorrectChoice ? ' incorrect-answer' : ''}`}
          >
            {isCorrectChoice && <Check className="correct" />}
            {isIncorrectChoice && <X className="incorrect" />}

            <input
              id={optionId}
              type="radio"
              name={`answer-choices-${vignetteId}`}
              value={optionLetter}
              disabled={isDisabled}
              checked={isSelected}
              onChange={() => {
                if (!isDisabled) {
                  onSetAnswer(optionLetter, false);
                }
              }}
              onClick={() => {
                hapticsImpactLight();
              }}
            />
            <div className="input-ring"></div>
            <span>
              {optionId}. {parse(answerOption.optionText)}
            </span>
          </label>
        );
      })}
    </form>
  );
};

export default AnswerOptions;
