export const HelpActive = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2679 5.78067L10.2679 5.78065L10.2624 5.78372C9.615 6.14601 9.11059 6.66075 8.76117 7.31537C8.41429 7.96524 8.25 8.69032 8.25 9.47191V10.2219H9H10.6756H11.4256V9.47191C11.4256 9.02913 11.5445 8.78502 11.6932 8.63527C11.8453 8.48216 12.0798 8.36798 12.4922 8.36798C12.898 8.36798 13.1179 8.48319 13.2582 8.63411L13.2581 8.63417L13.2666 8.64303C13.4221 8.80501 13.5431 9.06519 13.5431 9.52247C13.5431 10.172 13.3427 10.4125 13.1233 10.5306L13.1233 10.5305L13.118 10.5335C12.7468 10.7371 12.122 10.8792 11.1611 10.8792H10.6443H9.87177L9.89462 11.6514L9.97292 14.2975L9.99446 15.0253H10.5585C10.3957 15.118 10.2455 15.2374 10.1105 15.3827C9.75135 15.7693 9.5811 16.2488 9.5811 16.7697C9.5811 17.2905 9.75135 17.77 10.1105 18.1566C10.4724 18.5461 10.9433 18.75 11.4743 18.75C12.0025 18.75 12.4658 18.5403 12.8224 18.1566C13.1815 17.77 13.3518 17.2905 13.3518 16.7697C13.3518 16.2488 13.1815 15.7693 12.8224 15.3827C12.6881 15.2383 12.5388 15.1185 12.3766 15.0253H12.9885L13.0221 14.3105L13.0529 13.6551C13.9914 13.5501 14.8157 13.2285 15.486 12.6536C16.3657 11.8991 16.75 10.8083 16.75 9.52247C16.75 8.27615 16.3745 7.20692 15.554 6.40812C14.7509 5.6159 13.6976 5.25 12.4765 5.25C11.6702 5.25 10.9267 5.41921 10.2679 5.78067ZM22.25 12C22.25 17.6609 17.6609 22.25 12 22.25C6.33908 22.25 1.75 17.6609 1.75 12C1.75 6.33908 6.33908 1.75 12 1.75C17.6609 1.75 22.25 6.33908 22.25 12Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
