import React, { useState, useCallback, useRef } from 'react';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop';
import { Box, Typography, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  deleteProfilePictureFromS3,
  uploadProfilePictureToS3,
} from '../../../services/users';
import { setUserInfo } from '../../../slices/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUserInfo } from '../../../services/auth';
import CustomModal from '../../Global/CustomModal';
import { getCroppedImg } from '../../../utils/imageUtils';
import { useSnackbar } from 'notistack';
import { RootState } from '../../../store/store';
import { User } from '../../../assets/svgs/User';
import { Edit, Plus, Trash } from 'react-feather';

const StyledCropContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: 300,
  backgroundColor: '#333',
});

const ProfilePictureUploader: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const [image, setImage] = useState<string>(
    currentUser?.profilePictureUrl || ''
  );
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      // Check file type
      const allowedTypes = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/heic',
      ];
      if (!allowedTypes.includes(file.type.toLowerCase())) {
        enqueueSnackbar('Please upload only PNG, JPG, JPEG, or HEIC files', {
          autoHideDuration: 3000,
        });
        return;
      }

      // Check file size (5MB = 5 * 1024 * 1024 bytes)
      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        enqueueSnackbar('File size must be less than 5MB', {
          autoHideDuration: 3000,
        });
        return;
      }

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (typeof reader.result === 'string') {
          setImage(reader.result);
          setIsDialogOpen(true);
        }
      });
      reader.readAsDataURL(file);
    }
  };

  const generateUniqueFilename = (userId: string): string => {
    const timestamp = Date.now();

    // Create a hash using userId, timestamp and a random number
    let hashStr = '';
    const str = userId + timestamp + Math.random().toString();
    for (let i = 0; i < str.length; i++) {
      hashStr += str.charCodeAt(i).toString(16);
    }

    // Take first 12 chars of the hash
    const hash = hashStr.substring(0, 12);

    return `${timestamp}_${hash}.jpg`;
  };

  const handleCropImage = async () => {
    if (!currentUser?.id) {
      return;
    }
    if (image && croppedAreaPixels) {
      try {
        const croppedBlob = await getCroppedImg(image, croppedAreaPixels);
        const uniqueFilename = generateUniqueFilename(currentUser.id);
        const croppedFile = new File([croppedBlob], uniqueFilename, {
          type: 'image/jpeg',
        });
        const avatarUrl = await uploadProfilePictureToS3(
          croppedFile,
          currentUser.id
        );
        updateUserAvatar(avatarUrl);
        setIsDialogOpen(false);
      } catch (error) {
        console.error('Error cropping image:', error);
      }
    }
  };

  const updateUserAvatar = async (avatarUrl: string) => {
    try {
      const updatedUser = await updateCurrentUserInfo({
        profilePictureUrl: avatarUrl,
      });
      if (updatedUser) {
        dispatch(setUserInfo(updatedUser));
        enqueueSnackbar('Profile picture updated successfully', {
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error updating profile picture:', error);
      enqueueSnackbar('Failed to update profile picture', {
        autoHideDuration: 3000,
      });
    }
  };

  const handleDeleteAvatar = async () => {
    if (process.env.NODE_ENV !== 'production') {
      enqueueSnackbar('This action is restricted locally.', {
        autoHideDuration: 3000,
      });
      return;
    }
    if (!currentUser?.id || !currentUser?.profilePictureUrl) {
      return;
    }
    try {
      // Call the delete profile picture function with the URL
      await deleteProfilePictureFromS3(currentUser.profilePictureUrl);

      // Update user profile with empty picture URL
      const updatedUser = await updateCurrentUserInfo({
        profilePictureUrl: '',
      });

      if (updatedUser) {
        dispatch(setUserInfo(updatedUser));
        enqueueSnackbar('Profile picture deleted successfully', {
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error deleting profile picture:', error);
      enqueueSnackbar('Failed to delete profile picture', {
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div className="profile-picture-uploader">
      <div className="profile-picture-uploader_avatar">
        {currentUser?.profilePictureUrl ? (
          <>
            <img
              src={currentUser?.profilePictureUrl}
              alt={currentUser?.name || 'Profile Picture'}
            />
            <div className="edit-icon">
              <Edit />
            </div>
          </>
        ) : (
          <div className="template-avatar">
            <div className="template-avatar_icon">
              <User />
            </div>
            <div className="template-avatar_hover">
              <Plus />
            </div>
          </div>
        )}
        <input
          type="file"
          accept=".png,.jpg,.jpeg,.heic"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
      </div>
      {currentUser?.profilePictureUrl && (
        <button
          className="button button--icon-only"
          onClick={handleDeleteAvatar}
        >
          <Trash />
        </button>
      )}
      <CustomModal
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        name={'profile-picture-uploader'}
        glass={true}
        blurs={true}
      >
        <div className="modal_header">
          <h2>Crop Profile Picture</h2>
        </div>
        <div className="modal_content">
          {image && (
            <>
              <StyledCropContainer>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape="round"
                />
              </StyledCropContainer>
              <div>
                <Typography>Zoom</Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(_, value) => setZoom(value as number)}
                />
              </div>
            </>
          )}
        </div>
        <div className="modal_actions">
          <button
            className="button button--glass"
            onClick={() => setIsDialogOpen(false)}
          >
            Cancel
          </button>
          <button className="button button--glass" onClick={handleCropImage}>
            Save
          </button>
        </div>
      </CustomModal>
    </div>
  );
};

export default ProfilePictureUploader;
