import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { changePassword, updateCurrentUserInfo } from '../../services/auth';
import { useSnackbar } from 'notistack';
import {
  logout,
  setUserInfo,
  updateAuthToken,
  updateRefreshToken,
} from '../../slices/auth/authSlice';
import { deleteUser } from '../../services/users';
import {
  AlertTriangle,
  ArrowRight,
  CreditCard,
  ExternalLink,
  HelpCircle,
  LogOut,
  Settings,
  Sliders,
  User,
} from 'react-feather';
import GoogleIcon from '../../assets/google-g.svg';
import AppleIcon from '../../assets/apple.svg';
import { hapticsImpactLight } from '../../utils/haptics';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import OraSwitch from '../../components/CustomMUI/Switch';
import { TriangleRight } from '../../assets/svgs/TriangleRight';
import { TriangleDown } from '../../assets/svgs/TriangleDown';
import Page from '../../components/Page';
import { useHotkeys } from 'react-hotkeys-hook';
import { useUpdateTheme } from '../../hooks/useUpdateTheme';
import CustomModal from '../../components/Global/CustomModal';
import posthog from 'posthog-js';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useHandleResetProgress } from '../../utils/resetProgress';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment-timezone';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { ScreensaverOption, ThemeMode } from '../../types/User';
import useWindowWidth from '../../hooks/useWindowWidth';
import Billing from '../../components/Account/Billing';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { BlockTimeSetting } from '../../types/Study';
import FlashcardSettings from '../../components/Study/Flashcards/FlashcardSettings';
import ProfilePictureUploader from '../../components/Account/ProfilePictureUploader';

function decodeHTMLEntities(str: string) {
  const txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value;
}

enum AccountPage {
  Profile = 'profile',
  StudySettings = 'study-settings',
  Preferences = 'preferences',
  Billing = 'billing',
}

const Account: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const currentAuth = useSelector((state: RootState) => state.auth);

  const [activePage, setActivePage] = useState(AccountPage.Profile);
  const [showAccountMenu, setShowAccountMenu] = useState(true);

  const [dangerZoneModalIsOpen, setDangerZoneModalIsOpen] = useState(false);
  const [dangerZoneType, setDangerZoneType] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [userTheme, setUserTheme] = useState(currentUser?.theme);
  const [demoMode, setDemoMode] = useState(currentUser?.demoMode || false);
  const [screensaverPreference, setScreensaverPreference] = useState(
    currentUser?.screensaverPreference || 'none'
  );
  const [blockTimeSettingPreference, setBlockTimeSettingPreference] = useState(
    currentUser?.preferredBlockTimeSetting || 'default'
  );
  const [isSubscribedToEmails, setIsSubscribedToEmails] = useState(
    currentUser?.subscribedToEmails || false
  );
  const [hasRemindersEnabled, setHasRemindersEnabled] = useState(
    currentUser?.studyReminders !== 'never'
  );
  const [studyReminderPreference, setStudyReminderPreference] = useState(
    currentUser?.studyReminders === 'never'
      ? 'occasional'
      : currentUser?.studyReminders
  );
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordChangeError, setPasswordChangeError] = useState('');
  const [editName, setEditName] = useState(currentUser?.name || '');
  const [selectedTimezone, setSelectedTimezone] = useState<string | null>(
    currentUser?.timezone || null
  );

  const handleResetProgress = useHandleResetProgress();
  const windowWidth = useWindowWidth();

  const emailPreferencesRef = useRef<HTMLDivElement>(null);
  const hasMounted = useRef(false);

  useHotkeys(
    'esc',
    () => {
      if (!dangerZoneModalIsOpen) {
        navigate('/');
      }
    },
    {},
    [dangerZoneModalIsOpen]
  );

  const { updateTheme } = useUpdateTheme();

  useEffect(() => {
    if (hasMounted.current) {
      if (selectedTimezone) {
        handleUpdateTimezone();
      }
    } else {
      hasMounted.current = true;
    }
  }, [selectedTimezone]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const subpageKey = urlParams.get('sub');
    const openMenu = urlParams.get('open-menu');
    if (openMenu) {
      setShowAccountMenu(true);
      window.history.replaceState({}, '', window.location.pathname);
    } else if (
      subpageKey &&
      Object.values(AccountPage).includes(subpageKey as AccountPage)
    ) {
      setActivePage(subpageKey as AccountPage);
    }
  }, [navigate, window.location.search]);

  const handleSaveName = async () => {
    const updateUser = await updateCurrentUserInfo({ name: editName });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
      enqueueSnackbar('Name updated.', { autoHideDuration: 3000 });
      posthog?.capture('user_updated_name');
    } else {
      enqueueSnackbar(
        'Error updating name. Please reload the page and try again.'
      );
    }
  };

  const handleUpdateTimezone = async () => {
    if (selectedTimezone) {
      const updateUser = await updateCurrentUserInfo({
        timezone: selectedTimezone,
      });
      if (updateUser) {
        dispatch(setUserInfo(updateUser));
        enqueueSnackbar('Timezone updated.', {
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(
          'Error updating timezone. Please reload the page and try again.'
        );
      }
    }
  };

  const handleDangerZoneButton = (type: string) => {
    setDangerZoneModalIsOpen(true);
    setDangerZoneType(type);
  };

  const handleDangerZoneAction = async (type: string) => {
    if (type === 'delete' && dangerZoneType === 'delete') {
      if (currentUser?.id) {
        const response = await deleteUser(currentUser?.id);
        if (response?.status === 204) {
          posthog?.capture('user_deleted_account');
          handleSignOut();
        } else {
          enqueueSnackbar(
            'Error deleting account. Please reload the page and try again.'
          );
        }
      }
    } else if (type === 'reset' && dangerZoneType === 'reset') {
      handleResetProgress();
    }
    setDangerZoneModalIsOpen(false);
  };

  const determineChangePasswordValid = () => {
    if (
      newPassword === confirmNewPassword &&
      newPassword.length >= 8 &&
      oldPassword !== newPassword
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleChangePassword = async () => {
    const response = await changePassword(
      currentAuth.authToken as string,
      oldPassword,
      newPassword
    );
    if (response && response.newToken && response.refreshToken) {
      handleChangePasswordSuccess(response.newToken, response.refreshToken);
    } else {
      if (response.error) {
        setPasswordChangeError(response.error);
      }
    }
  };

  const handleChangePasswordSuccess = (
    authToken: string,
    refreshToken: string | null = null
  ) => {
    enqueueSnackbar('Password updated.', {
      autoHideDuration: 3000,
    });
    setIsChangingPassword(false);
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    dispatch(updateAuthToken(authToken));
    if (refreshToken) dispatch(updateRefreshToken(refreshToken));
  };

  const updateDemoMode = async (demoMode: boolean) => {
    const updateUser = await updateCurrentUserInfo({ demoMode: demoMode });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const handleSignOut = () => {
    dispatch(logout());
    navigate('/auth');
  };

  const handleThemeChange = (theme: ThemeMode) => {
    if (theme !== null) {
      setUserTheme(theme);
      posthog?.capture('user_changed_theme', { theme: theme });
      updateTheme(theme);
    }
  };

  const handleDemoModeChange = (demoMode: boolean) => {
    setDemoMode(demoMode);
    updateDemoMode(demoMode);
  };

  const handleToggleReminders = async (checked: boolean) => {
    setHasRemindersEnabled(checked);
    const newValue = checked ? studyReminderPreference : 'never';
    const updateUser = await updateCurrentUserInfo({
      studyReminders: newValue,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
      enqueueSnackbar('Reminder preferences updated.', {
        autoHideDuration: 3000,
      });
    }
  };

  const handleEmailPreferencesChange = async (
    studyRemindersPreference: string
  ) => {
    setStudyReminderPreference(studyRemindersPreference);
    const updateUser = await updateCurrentUserInfo({
      studyReminders: studyRemindersPreference,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
      enqueueSnackbar('Reminder preferences updated.', {
        autoHideDuration: 3000,
      });
    }
  };

  const handleToggleEmails = async (checked: boolean) => {
    setIsSubscribedToEmails(checked);
    const updateUser = await updateCurrentUserInfo({
      subscribedToEmails: checked,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
      enqueueSnackbar(checked ? 'Emails enabled.' : 'Emails disabled.', {
        autoHideDuration: 3000,
      });
    }
  };

  const handleScreensaverPreferenceChange = async (
    screensaverPreference: ScreensaverOption
  ) => {
    setScreensaverPreference(screensaverPreference);
    const updateUser = await updateCurrentUserInfo({
      screensaverPreference: screensaverPreference,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
      enqueueSnackbar('Screensaver preference updated.', {
        autoHideDuration: 3000,
      });
    }
  };

  const timezoneOptions = moment.tz.names().map((zone) => {
    let offset = moment.tz(zone).format('Z');
    offset = offset.replace('+', '&#xff0b;').replace('-', '&#xff0d;');
    const parts = zone.split('/');
    const region = parts[0];
    const locationParts = parts.slice(1).map((p) => p.replace('_', ' '));
    const locationStr = locationParts.join(' - ');

    return {
      label: `GMT${offset} - ${region}${locationStr ? ` - ${locationStr}` : ''}`,
      value: zone,
      monospacedOffset: offset,
      region,
      city: locationStr,
    };
  });

  const handleBlockTimeSettingChange = async (
    blockTimeSetting: BlockTimeSetting
  ) => {
    setBlockTimeSettingPreference(blockTimeSetting);
    const updateUser = await updateCurrentUserInfo({
      preferredBlockTimeSetting: blockTimeSetting,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
      enqueueSnackbar('QBank time accommodation updated.', {
        autoHideDuration: 3000,
      });
    }
  };

  const handleAccountMenuClick = (page: AccountPage) => {
    hapticsImpactLight();
    setActivePage(page);
    if (windowWidth < 900) {
      setShowAccountMenu(false);
    }
  };

  return (
    <Page type="account">
      <div className="account">
        <div className="account_main">
          <div className="account_main_menu">
            <div className={`account-menu ${showAccountMenu ? 'is-open' : ''}`}>
              <div className="account-menu_header">
                <h2>Account</h2>
              </div>
              <button
                className={`button button--glass ${
                  activePage === AccountPage.Profile ? 'is-active' : ''
                }`}
                onClick={() => handleAccountMenuClick(AccountPage.Profile)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <User />
                  <span>Profile</span>
                </div>
                <ArrowRight />
              </button>
              <button
                className={`button button--glass ${
                  activePage === AccountPage.StudySettings ? 'is-active' : ''
                }`}
                onClick={() =>
                  handleAccountMenuClick(AccountPage.StudySettings)
                }
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <Settings />
                  <span>Study Settings</span>
                </div>
                <ArrowRight />
              </button>
              <button
                className={`button button--glass ${
                  activePage === AccountPage.Preferences ? 'is-active' : ''
                }`}
                onClick={() => handleAccountMenuClick(AccountPage.Preferences)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <Sliders />
                  <span>Preferences</span>
                </div>
                <ArrowRight />
              </button>
              <button
                className={`button button--glass ${
                  activePage === AccountPage.Billing ? 'is-active' : ''
                }`}
                onClick={() => handleAccountMenuClick(AccountPage.Billing)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <CreditCard />
                  <span>Billing</span>
                </div>
                <ArrowRight />
              </button>
              <button
                className={`button button--glass`}
                onClick={() => {
                  navigate('/help');
                  hapticsImpactLight();
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <HelpCircle />
                  <span>Help</span>
                </div>
                <ArrowRight />
              </button>
              <button
                className={`button button--glass`}
                onClick={() => {
                  hapticsImpactLight();
                  handleSignOut();
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <LogOut />
                  <span>Log out</span>
                </div>
              </button>
            </div>
          </div>
          <div className="account_main_page">
            {activePage === AccountPage.Profile && (
              <>
                <h1>Profile</h1>
                <div className="block block--settings block--settings--personal-info">
                  <div className="block_body">
                    <h3>Personal Info</h3>
                    <div className="personal-info-container">
                      <ProfilePictureUploader />
                      <div className="field">
                        <InputLabel id="name" className="m-b-0-50">
                          Display Name
                        </InputLabel>
                        <TextField
                          id="name"
                          variant="outlined"
                          value={editName}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setEditName(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="block_footer">
                    <div className="helper">5MB image limit.</div>
                    <button
                      className="button button--glass"
                      onClick={() => {
                        handleSaveName();
                        hapticsImpactLight();
                      }}
                      disabled={editName === currentUser?.name}
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div className="block block--settings">
                  <div className="block_body">
                    <h3>Email</h3>
                    <div className="field">
                      <div className="value">{currentUser?.email}</div>
                    </div>
                  </div>
                  <div className="block_footer">
                    <div
                      className="helper"
                      style={{ marginRight: 'auto', marginLeft: '0' }}
                    >
                      This cannot be changed.
                    </div>
                  </div>
                </div>
                <div className="block block--settings">
                  <div className="block_body">
                    <h3>Password</h3>
                    {!isChangingPassword && (
                      <>
                        {currentUser?.oauth && (
                          <p>
                            Your password is managed by{' '}
                            {currentUser?.oauthStrategy}.
                          </p>
                        )}
                        {!currentUser?.oauth && <div>···········</div>}
                      </>
                    )}
                    {isChangingPassword && (
                      <div>
                        {passwordChangeError && (
                          <p style={{ color: 'var(--error)' }}>
                            {passwordChangeError}
                          </p>
                        )}
                        <p>Change your password</p>
                        <TextField
                          className="m-y-0-75"
                          id="oldPassword"
                          label="Current Password"
                          variant="outlined"
                          value={oldPassword}
                          type="password"
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setOldPassword(event.target.value);
                          }}
                        />
                        <TextField
                          className="m-y-0-75"
                          id="newPassword"
                          label="New Password"
                          variant="outlined"
                          value={newPassword}
                          type="password"
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setNewPassword(event.target.value);
                          }}
                        />
                        <TextField
                          className="m-y-0-75"
                          id="confirmNewPassword"
                          label="Confirm Password"
                          variant="outlined"
                          value={confirmNewPassword}
                          type="password"
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setConfirmNewPassword(event.target.value);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="block_footer">
                    {!currentUser?.oauth && (
                      <>
                        {!isChangingPassword && (
                          <button
                            className="button button--glass"
                            onClick={() => {
                              setIsChangingPassword(true);
                              hapticsImpactLight();
                            }}
                          >
                            Update
                          </button>
                        )}
                        {isChangingPassword && (
                          <button
                            className="button button--glass"
                            onClick={() => {
                              setIsChangingPassword(false);
                              hapticsImpactLight();
                            }}
                          >
                            Cancel
                          </button>
                        )}
                        {isChangingPassword && (
                          <button
                            className="button button--glass"
                            onClick={() => {
                              handleChangePassword();
                              hapticsImpactLight();
                            }}
                            disabled={determineChangePasswordValid()}
                          >
                            Update
                          </button>
                        )}
                      </>
                    )}
                    {currentUser?.oauth && (
                      <>
                        {currentUser?.oauthStrategy === 'Google' && (
                          <a
                            className="button button--glass"
                            style={{ width: 'fit-content' }}
                            href="https://myaccount.google.com/signinoptions/password"
                            target="_blank"
                            onClick={hapticsImpactLight}
                          >
                            <img src={GoogleIcon} alt="Google Logo" />
                            Manage Password
                            <ExternalLink
                              style={{
                                height: '1rem',
                                marginLeft: '.125rem',
                              }}
                            />
                          </a>
                        )}
                        {currentUser?.oauthStrategy === 'Apple' && (
                          <a
                            className="button button--glass"
                            style={{ width: 'fit-content' }}
                            href="https://support.apple.com/en-us/101567#:~:text=Change%20your%20Apple%20ID%20password%20on%20your%20iPhone%2C%20iPad%2C%20Apple,and%20confirm%20the%20new%20password."
                            target="_blank"
                            onClick={hapticsImpactLight}
                          >
                            <img src={AppleIcon} alt="Apple Logo" />
                            Manage Password
                            <ExternalLink
                              style={{
                                height: '1rem',
                                marginLeft: '.125rem',
                              }}
                            />
                          </a>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <Accordion>
                  <AccordionSummary
                    aria-controls="adv-study-content"
                    id="adv-study-header"
                    sx={{ padding: 0 }}
                  >
                    <div className="row">
                      <div className="icon">
                        <TriangleRight classes="expand-icon expand-icon--closed" />
                        <TriangleDown classes="expand-icon expand-icon--open" />
                      </div>
                      <span className="button button--link--glass p-l-0">
                        Advanced Account Settings
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {currentUser?.role === 'admin' && (
                      <div className="block block--settings block--danger">
                        <div className="block_body">
                          <h3>Reset Scheduling</h3>
                          <p className="m-b-1-50">
                            This action wipes all your scheduling data and
                            resets your study progress to zero. This cannot be
                            undone.
                          </p>
                          <button
                            className="button button--error"
                            onClick={() => handleDangerZoneButton('reset')}
                          >
                            Reset Scheduling
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="block block--settings block--danger">
                      <div className="block_body">
                        <h3>Delete Account</h3>
                        <p className="m-b-1-50">
                          This action fully deletes your account including your
                          scheduling data. You will be logged out. This cannot
                          be undone.
                        </p>
                        <button
                          className="button button--error"
                          onClick={() => handleDangerZoneButton('delete')}
                        >
                          Delete Account
                        </button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            {activePage === AccountPage.StudySettings && (
              <>
                <h1>Study Settings</h1>
                <div className="block block--settings">
                  <div className="block_body">
                    <h3>QBank Settings</h3>
                    <InputLabel
                      id="block-time-setting-preference"
                      className="m-b-0-50"
                    >
                      QBank Time Accommodation
                    </InputLabel>
                    <Select
                      labelId="block-time-setting-preference"
                      id="block-time-setting-preference"
                      value={blockTimeSettingPreference}
                      onChange={(e) => {
                        handleBlockTimeSettingChange(
                          e.target.value as BlockTimeSetting
                        );
                      }}
                      style={
                        windowWidth > 560
                          ? { width: '50%', marginTop: '0' }
                          : { width: '100%', marginTop: '0' }
                      }
                    >
                      <MenuItem value={BlockTimeSetting.default}>
                        <TriangleRight />
                        Default
                      </MenuItem>
                      <MenuItem value={BlockTimeSetting.quarter}>
                        <TriangleRight />
                        25% Additional Testing Time (Time and 1/4)
                      </MenuItem>
                      <MenuItem value={BlockTimeSetting.half}>
                        <TriangleRight />
                        50% Additional Testing Time (Time and 1/2)
                      </MenuItem>
                      <MenuItem value={BlockTimeSetting.double}>
                        <TriangleRight />
                        100% Additional Testing Time (Double Time)
                      </MenuItem>
                    </Select>
                  </div>
                  <div className="block_footer">
                    <div
                      className="helper"
                      style={{ marginRight: 'auto', marginLeft: '0' }}
                    >
                      Only affects timed QBank blocks.
                    </div>
                  </div>
                </div>
                <div className="block block--settings block--settings--flashcards">
                  <div className="block_body">
                    <h3>Flashcard Settings</h3>
                    <FlashcardSettings />
                  </div>
                </div>
              </>
            )}
            {activePage === AccountPage.Preferences && (
              <>
                <h1>Preferences</h1>
                <div className="block block--settings">
                  <div className="block_body">
                    <h3>Timezone</h3>
                    <Autocomplete
                      options={timezoneOptions}
                      getOptionLabel={(option) =>
                        decodeHTMLEntities(option.label)
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <span
                            style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                            dangerouslySetInnerHTML={{
                              __html: `GMT${option.monospacedOffset}`,
                            }}
                          />
                          &nbsp;&nbsp;
                          {option.region}
                          {option.city ? ` - ${option.city}` : ''}
                        </li>
                      )}
                      value={
                        timezoneOptions.find(
                          (o) => o.value === selectedTimezone
                        ) || undefined
                      }
                      onChange={(_event, newValue) => {
                        setSelectedTimezone(newValue?.value || null);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" fullWidth />
                      )}
                      disableClearable
                    />
                  </div>
                  <div className="block_footer">
                    <div
                      className="helper"
                      style={{ marginRight: 'auto', marginLeft: '0' }}
                    >
                      Determines when your daily session resets.
                    </div>
                  </div>
                </div>
                <div className="block block--settings">
                  <div className="block_body">
                    <h3
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      ref={emailPreferencesRef}
                    >
                      <span>Email &amp; Reminders</span>
                      <OraSwitch
                        checked={isSubscribedToEmails}
                        onChange={(e) => handleToggleEmails(e.target.checked)}
                        onClick={() => {
                          hapticsImpactLight();
                        }}
                      />
                    </h3>
                    <div
                      className="study-reminder-preferences"
                      onClick={() => {
                        if (!isSubscribedToEmails) {
                          if (emailPreferencesRef.current) {
                            emailPreferencesRef.current.classList.add('shake');
                          }
                          setTimeout(() => {
                            if (emailPreferencesRef.current) {
                              emailPreferencesRef.current.classList.remove(
                                'shake'
                              );
                            }
                          }, 500);
                        }
                      }}
                    >
                      <div
                        className={`study-reminder-preferences_inner ${!isSubscribedToEmails ? 'disabled' : ''}`}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <InputLabel id="email-preference">
                            Study Reminders
                          </InputLabel>
                          <OraSwitch
                            checked={hasRemindersEnabled}
                            onChange={(e) =>
                              handleToggleReminders(e.target.checked)
                            }
                            onClick={() => {
                              hapticsImpactLight();
                            }}
                          />
                        </div>
                        <Select
                          labelId="email-preferences"
                          id="email-preferences"
                          value={studyReminderPreference}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            handleEmailPreferencesChange(newValue);
                          }}
                          style={
                            windowWidth > 560
                              ? { width: '50%', marginTop: '.5rem' }
                              : { width: '100%', marginTop: '.5rem' }
                          }
                          onClick={hapticsImpactLight}
                          disabled={!hasRemindersEnabled}
                        >
                          <MenuItem value="daily">
                            <TriangleRight />
                            Daily
                          </MenuItem>
                          <MenuItem value="occasional">
                            <TriangleRight />
                            Occasional
                          </MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block block--settings">
                  <div className="block_body">
                    <h3>Theme</h3>
                    <div
                      className="field"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        justifyContent: 'space-between',
                      }}
                    >
                      <ToggleButtonGroup
                        value={userTheme}
                        exclusive
                        onChange={(_e, newValue) => handleThemeChange(newValue)}
                        aria-label="Theme Options"
                      >
                        <ToggleButton value="system" aria-label="System">
                          System
                        </ToggleButton>
                        <ToggleButton value="light" aria-label="Light">
                          Light
                        </ToggleButton>
                        <ToggleButton value="dark" aria-label="Dark">
                          Dark
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                    {currentUser?.role === 'admin' && (
                      <div
                        className="field m-t-1"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                          justifyContent: 'space-between',
                        }}
                      >
                        <InputLabel id="timezone-label">Demo Mode</InputLabel>
                        <OraSwitch
                          checked={demoMode}
                          onChange={(e) =>
                            handleDemoModeChange(e.target.checked)
                          }
                          onClick={() => {
                            hapticsImpactLight();
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {windowWidth > 900 && (
                  <div className="block block--settings">
                    <div className="block_body">
                      <h3>Screensaver</h3>
                      <Select
                        labelId="screensaver-preference"
                        id="screensaver-preference"
                        value={screensaverPreference}
                        onChange={(e) => {
                          handleScreensaverPreferenceChange(
                            e.target.value as ScreensaverOption
                          );
                        }}
                        style={
                          windowWidth > 560
                            ? { width: '50%', marginTop: '0' }
                            : { width: '100%', marginTop: '0' }
                        }
                      >
                        <MenuItem value="none">
                          <TriangleRight />
                          Off
                        </MenuItem>
                        <MenuItem value="thirtySeconds">
                          <TriangleRight />
                          30 Seconds
                        </MenuItem>
                        <MenuItem value="oneMinute">
                          <TriangleRight />
                          One Minute
                        </MenuItem>
                        <MenuItem value="twoMinutes">
                          <TriangleRight />
                          Two Minutes
                        </MenuItem>
                        <MenuItem value="fiveMinutes">
                          <TriangleRight />
                          Five Minutes
                        </MenuItem>
                        <MenuItem value="tenMinutes">
                          <TriangleRight />
                          10 Minutes
                        </MenuItem>
                      </Select>
                    </div>
                    <div className="block_footer">
                      <div
                        className="helper"
                        style={{ marginRight: 'auto', marginLeft: '0' }}
                      >
                        Shows Learning Objectives from today's session.
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {activePage === AccountPage.Billing && (
              <>
                <h1>Billing</h1>
                <Billing />
              </>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        open={dangerZoneModalIsOpen}
        onClose={() => setDangerZoneModalIsOpen(false)}
        name="danger-zone"
        glass={true}
      >
        <div className="modal_header">
          {dangerZoneType === 'reset' && <h2>Reset Scheduling</h2>}
          {dangerZoneType === 'delete' && <h2>Delete Account</h2>}
        </div>
        <div className="modal_content">
          {dangerZoneType === 'reset' && (
            <p>
              <AlertTriangle
                style={{
                  color: 'var(--error',
                  height: '1rem',
                  transform: 'translateY(2px)',
                }}
              />
              <strong style={{ color: 'var(--error' }}>
                PROCEED WITH CAUTION
              </strong>
              <br />
              <br />
              This action wipes all your scheduling data. This resets your study
              progress to zero. This cannot be undone.
            </p>
          )}
          {dangerZoneType === 'delete' && (
            <p>
              <AlertTriangle
                style={{
                  color: 'var(--error',
                  height: '1rem',
                  transform: 'translateY(2px)',
                }}
              />
              <strong style={{ color: 'var(--error' }}>
                PROCEED WITH CAUTION
              </strong>
              <br />
              <br />
              This action will permanently delete your account, including all
              scheduling data. Any active subscription will be canceled, and you
              will be logged out. This cannot be undone.
            </p>
          )}
        </div>
        <div className="modal_actions">
          <button
            className="button button--secondary"
            onClick={() => {
              setDangerZoneModalIsOpen(false);
              hapticsImpactLight();
            }}
          >
            Cancel
          </button>
          <button
            className="button button--error"
            onClick={() => {
              handleDangerZoneAction(dangerZoneType);
              hapticsImpactLight();
            }}
          >
            {dangerZoneType === 'reset' ? 'Reset Scheduling' : 'Delete Account'}
          </button>
        </div>
      </CustomModal>
    </Page>
  );
};

export default Account;
