import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import authReducer from '../slices/auth/authSlice';
import blocksSlice from '../slices/blocks/blocksSlice';
import todaySessionSlice from '../slices/todaySession/todaySessionSlice';
import flashcardsReducer from '../slices/flashcards/flashcardsSlice';
import activeTopicsSlice from '../slices/activeTopics/activeTopicsSlice';
import pastFlashcardsReducer from '../slices/pastFlashcards/pastFlashcardsSlice';
import vignettesReducer from '../slices/vignettes/vignettesSlice';
import statsSlice from '../slices/stats/statsSlice';
import subscriptionsReducer from '../slices/subscriptions/subscriptionsSlice';
import { authApi } from '../services/auth';

const appReducers = combineReducers({
  activeTopics: activeTopicsSlice,
  auth: authReducer,
  blocks: blocksSlice,
  todaySession: todaySessionSlice,
  flashcards: flashcardsReducer,
  pastFlashcards: pastFlashcardsReducer,
  vignettes: vignettesReducer,
  stats: statsSlice,
  subscription: subscriptionsReducer,
  [authApi.reducerPath]: authApi.reducer,
});

// @ts-expect-error TODO: Define types
const rootReducer = (state, action) => {
  if (action.type === 'user/logout') {
    state = undefined;
  }
  return appReducers(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(authApi.middleware);

    if (process.env.NODE_ENV === 'development') {
      return defaultMiddleware.concat(logger);
    }

    return defaultMiddleware;
  },
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
