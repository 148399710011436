import TopicRadar from '../../../components/Onboarding/OnboardingSummary/TopicRadar';
import { useEffect, useState } from 'react';
import { formatTopicStats } from '../../../utils/formatTopicStats';
import TopicAccordion from './TopicAccordion';
import { TopicStat } from '../../../types/Dashboard';
import { fetchTopicTreeStats } from '../../../services/stats';

const TopicPerformance: React.FC = () => {
  const [topicPerformance, setTopicPerformance] = useState<TopicStat[]>([]);
  const [levelOneTopics, setLevelOneTopics] = useState<{
    [topicName: string]: {
      correct: number;
      total: number;
      percentage: number;
    };
  } | null>();

  useEffect(() => {
    getTopicTreeStats();
  }, []);

  const getTopicTreeStats = async () => {
    const res = await fetchTopicTreeStats();
    if (res) {
      setTopicPerformance(res);
      const levelOne = formatTopicStats(res);
      setLevelOneTopics(levelOne);
    }
  };

  return (
    <div className="topic-performance">
      <div className="topic-performance_breakdown">
        {topicPerformance.map((topic) => (
          <TopicAccordion topic={topic} />
        ))}
      </div>
      <div className="topic-performance_radar-container">
        <TopicRadar isSummary={false} topicProgression={levelOneTopics} />
      </div>
    </div>
  );
};

export default TopicPerformance;
