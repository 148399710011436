import CustomModal from '../../Global/CustomModal';

interface LegacyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LegacyModal: React.FC<LegacyModalProps> = ({ isOpen, onClose }) => {
  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      name="legacy"
      blurs={true}
      glass={true}
      disableBackdropClick={true}
    >
      <div className="modal_content">
        <h2 className="m-t-0">Free 12 Month Access</h2>
        <p className="m-b-0-25">
          To keep Ora sustainable, we've recently transitioned to a subscription
          model. As a thank you for being with us since the beginning, you will
          receive <strong>free access</strong> for a full year. We really
          appreciate your help in shaping Ora into the product it is today.
          We're excited to usher in a new era of Step and Shelf prep.
        </p>
      </div>
      <div className="modal_actions">
        <button className="button button--glass" onClick={onClose}>
          Accept
        </button>
      </div>
    </CustomModal>
  );
};

export default LegacyModal;
