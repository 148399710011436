import { Accordion, AccordionSummary, Tooltip } from '@mui/material';
import { TopicStat } from '../../../../types/Dashboard';
import { TriangleRight } from '../../../../assets/svgs/TriangleRight';
import { TriangleDown } from '../../../../assets/svgs/TriangleDown';
import { useState } from 'react';

interface TopicAccordionProps {
  topic: TopicStat;
}

// Separate component for recursive rendering
const TopicAccordion: React.FC<TopicAccordionProps> = ({ topic }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const percentage = Math.round(
    (topic.stats.answeredCorrectlyCount / topic.stats.timesIntroduced) * 100
  );

  return (
    <Accordion
      key={topic.id}
      className="topic-performance-accordion"
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionSummary>
        <div className="topic-row">
          <div className="topic-row_name">
            {!isExpanded && <TriangleRight classes="expand-icon" />}
            {isExpanded && <TriangleDown classes="expand-icon" />}
            <span>{topic.name}</span>
          </div>
          <div className="topic-row_percentage">
            {topic.stats.timesIntroduced ? (
              percentage + '%'
            ) : (
              <Tooltip title={"You haven't seen this topic yet."}>
                <span>-</span>
              </Tooltip>
            )}
          </div>
        </div>
      </AccordionSummary>
      {isExpanded && (
        <div className="topic-stats">
          <div className="stat">
            <div className="stat_value">{topic.stats.timesIntroduced}</div>
            <div className="stat_label">Times Seen</div>
          </div>
          <div className="stat">
            <div className="stat_value">
              {topic.stats.answeredCorrectlyCount}
            </div>
            <div className="stat_label">Correct Answers</div>
          </div>
          <div className="stat">
            <div className="stat_value">
              {topic.stats.answeredIncorrectlyCount}
            </div>
            <div className="stat_label">Incorrect Answers</div>
          </div>
          <div className="stat">
            <div className="stat_value">
              {topic.stats.timesIntroduced
                ? Math.round(
                    (topic.stats.answeredCorrectlyCount /
                      topic.stats.timesIntroduced) *
                      100
                  )
                : 0}
              <span>%</span>
            </div>
            <div className="stat_label">Overall Score</div>
          </div>
        </div>
      )}
      {isExpanded && topic.children && topic.children.length > 0 && (
        <div className="topic-children" style={{ paddingLeft: '1.5rem' }}>
          <h3>Subtopics</h3>
          {topic.children.map((child) => (
            <TopicAccordion key={child.id} topic={child} />
          ))}
        </div>
      )}
    </Accordion>
  );
};

export default TopicAccordion;
