import axiosInstance from './axiosCreator';

export interface GroupMember {
  user: {
    id: string;
    name: string;
    email: string;
  };
}

export interface Group {
  id: string;
  expiresAt: string;
  status: 'ACTIVE' | 'COMPLETE';
  members: GroupMember[];
}

export interface CreateGroupResponse {
  id: string;
  expiresAt: string;
  status: 'ACTIVE';
  members: GroupMember[];
}

export interface JoinGroupResponse {
  id: string;
  expiresAt: string;
  status: 'ACTIVE' | 'COMPLETE';
  members: GroupMember[];
}

export interface GroupErrorResponse {
  error: string;
  groupId?: string;
}

/**
 * Creates a new group and adds the user as the first member.
 * @param userId - ID of the user creating the group
 * @returns The newly created group or error information
 */
export const createGroup = async (
  userId: string
): Promise<CreateGroupResponse | void> => {
  try {
    const response = await axiosInstance.post('/groups', { userId });
    return response.data;
  } catch (error) {
    console.error('Error creating group:', error);
  }
};

/**
 * Adds a user to an existing group.
 * @param groupId - ID of the group to join
 * @param userId - ID of the user joining the group
 * @returns The updated group information or error
 */
export const joinGroup = async (
  groupId: string,
  userId: string
): Promise<JoinGroupResponse | void> => {
  try {
    const response = await axiosInstance.post(`/groups/${groupId}/join`, {
      userId,
    });
    return response.data;
  } catch (error) {
    console.error('Error joining group:', error);
  }
};

/**
 * Retrieves detailed information about a specific group.
 * @param groupId - ID of the group to retrieve
 * @returns The group information or undefined if not found
 */
export const getGroupById = async (groupId: string): Promise<Group | void> => {
  try {
    const response = await axiosInstance.get(`/groups/${groupId}`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving group:', error);
  }
};
