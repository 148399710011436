import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from '../ErrorPage';
import Home from '../pages/Home';
import Admin from '../pages/Admin';
import Auth from '../pages/Auth';
import ProtectedLayout from './ProtectedLayout';
import Study from '../pages/Study';
import ResetPassword from '../pages/Auth/ResetPassword';
import Onboarding from '../pages/Onboarding';
import Library from '../pages/Library';
import EmailPreferences from '../pages/EmailPreferences';
import Dashboard from '../pages/Dashboard';
import PastBlocks from '../pages/Dashboard/PastBlocks';
import Account from '../pages/Account';
import Roadmap from '../pages/Roadmap';
import Demo from '../pages/Demo';
import Help from '../pages/Help';
import Pricing from '../pages/Pricing';
import Guarantee from '../pages/Pricing/Guarantee';
import Group from '../pages/Group';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <ProtectedLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: 'onboarding', element: <Onboarding /> },
      { path: 'admin', element: <Admin /> },
      { path: 'study', element: <Study /> },
      {
        path: 'account',
        element: <Account />,
      },
      { path: 'performance', element: <Dashboard /> },
      { path: 'past-blocks', element: <PastBlocks /> },
      { path: 'library', element: <Library /> },
      { path: 'library/:articleId', element: <Library /> },
      { path: 'help', element: <Help /> },
      { path: 'help/:helpArticleId', element: <Help /> },
    ],
  },
  {
    path: 'roadmap',
    element: <Roadmap />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'email-preferences',
    element: <EmailPreferences />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'pricing',
    element: <Pricing />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'group',
    element: <Group />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'group/:groupId',
    element: <Group />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'guarantee',
    element: <Guarantee />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'auth',
    element: <Auth />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'reset',
    element: <ResetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'demo',
    element: <Demo />,
    errorElement: <ErrorPage />,
  },
]);
