export const User = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="10.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M6 20C6 20 7.41176 16 12 16C16.5882 16 18 20 18 20"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <circle
        cx="12"
        cy="10"
        r="3.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
