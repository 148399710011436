// src/components/Study/Exam/ExplanationDisplay.tsx
import React from 'react';
import parse from 'html-react-parser';
import { VignetteContent } from '../../../../types/Vignette'; // Adjust path as needed
import ImageDisplay from '../../../Misc/ImageDisplay'; // Adjust path as needed

interface ExplanationDisplayProps {
  parsedExhibit: React.ReactNode | null;
  parsedExplanation: React.ReactNode | null;
  activeVignetteContent: VignetteContent | undefined;
}

const ExplanationDisplay: React.FC<ExplanationDisplayProps> = ({
  parsedExhibit,
  parsedExplanation,
  activeVignetteContent,
}) => {
  if (!activeVignetteContent) {
    return null;
  }

  const {
    Image,
    learningObjective,
    references,
    id: contentId,
  } = activeVignetteContent;

  return (
    <div className="explanation">
      {/* Exhibit within the explanation panel */}
      {parsedExhibit && <div className="exhibit">{parsedExhibit}</div>}

      {/* Images */}
      {Image && Image.length > 0 && (
        <ImageDisplay
          images={Image}
          displayMainImage={true}
          activeContentId={contentId || ''} // Pass content ID for context if needed by ImageDisplay
        />
      )}

      {/* Main Explanation Body */}
      {parsedExplanation}

      {/* Learning Objective */}
      {learningObjective && (
        <div className="block block--lo">
          <strong>Learning Objective: </strong>
          {parse(learningObjective)}
        </div>
      )}

      {/* References */}
      {references && references.length > 0 && (
        <div className="references">
          <ul className={references.length === 1 ? 'one-ref' : ''}>
            {references.map((reference, index) => (
              <li key={`${contentId}-ref-${index}`}>{parse(reference)}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ExplanationDisplay;
