import { useEffect, useState } from 'react';
import ExamDateSelect from '../../Global/Header/ExamDateSelect';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { UserExam } from '../../../types/User';
import { fetchFirstStudySession } from '../../../services/studySession';
import moment from 'moment';
import { fetchVignetteCompletion } from '../../../services/vignettes';
import { fetchFlashcardCompletion } from '../../../services/flashcards';
import PriorityDisplay from '../PriorityDisplay';
import { Capacitor } from '@capacitor/core';
import useWindowWidth from '../../../hooks/useWindowWidth';

interface TimelineProps {
  showTopicPriorities: boolean;
  setShowTopicPriorities: (show: boolean) => void;
  setIsLoadingStudySessionAndContent: (isLoading: boolean) => void;
  setIsLoadedStudySessionAndContent: (isLoaded: boolean) => void;
  refreshStudySessionAndContent: () => void;
}

const Timeline: React.FC<TimelineProps> = ({
  setIsLoadingStudySessionAndContent,
  setIsLoadedStudySessionAndContent,
  refreshStudySessionAndContent,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const [completionProgress, setCompletionProgress] = useState(0);
  const [timeRemainingProgress, setTimeRemainingProgress] = useState(0);
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [isTopicFlyoutVisible, setIsTopicFlyoutVisible] = useState(false);

  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (primaryExam) {
      calculateStudyProgress(primaryExam);
      calculateContentCompletion(primaryExam);
    }
  }, [primaryExam, primaryExam?.examDate]);

  const calculateStudyProgress = async (primaryExam: UserExam) => {
    try {
      const response = await fetchFirstStudySession(primaryExam.id);
      const endDate = moment.utc(primaryExam.examDate);
      const startDate = response?.date
        ? moment.utc(response.date)
        : moment.utc();
      const currentDate = moment.utc();
      const tDays = endDate
        .startOf('day')
        .diff(startDate.startOf('day'), 'days');
      const dRemaining = endDate
        .startOf('day')
        .diff(currentDate.startOf('day'), 'days');
      const progress = 100 - (dRemaining / tDays) * 100;
      setTotalDays(tDays);
      setDaysRemaining(dRemaining);
      setTimeRemainingProgress(progress);
    } catch (error) {
      console.error('Error calculating study progress:', error);
    }
  };

  const calculateContentCompletion = async (primaryExam: UserExam) => {
    let numerator = 0,
      denominator = 0;
    if (currentUser && primaryExam) {
      if (primaryExam.isUsingQbank) {
        await fetchVignetteCompletion(currentUser.id, primaryExam.id).then(
          (response) => {
            if (response) {
              numerator += response.correctVignetteCount;
              denominator += response.selectedVignetteCount;
            }
          }
        );
      }
      if (primaryExam.isUsingFlashcards) {
        await fetchFlashcardCompletion(currentUser.id, primaryExam.id).then(
          (response) => {
            if (response) {
              numerator += response.correctFlashcardCount;
              denominator += response.selectedFlashcardCount;
            }
          }
        );
      }
    }
    setCompletionProgress(Math.round((numerator / denominator) * 100));
  };

  return (
    <div
      className={`timeline${primaryExam?.isUsingQbank && windowWidth > 560 && !Capacitor.isNativePlatform() ? ' should-show-roadmap' : ''}${isTopicFlyoutVisible && windowWidth > 560 && !Capacitor.isNativePlatform() ? ' show-roadmap' : ''}${totalDays < 75 ? ' short-timeline' : ''}`}
    >
      <div className="timeline_main">
        <div className="timeline_main_progress">
          <div className="timeline_main_progress_end"></div>
          <div className="timeline_main_progress_bar">
            <div
              className="timeline_main_progress_bar_fill timeline_main_progress_bar_fill--completion"
              style={{ width: `${completionProgress}%` }}
            >
              <label>
                <div className="main">{completionProgress}%</div>
                <div className="helper">Completion</div>
                <div className="triangle"></div>
              </label>
            </div>
            <div
              className="timeline_main_progress_bar_fill timeline_main_progress_bar_fill--time"
              style={{ width: `${timeRemainingProgress}%` }}
            >
              <label>
                <div className="triangle"></div>
                <div className="main">Today</div>
                <div className="helper">
                  {daysRemaining === 1
                    ? `${daysRemaining} day left`
                    : `${daysRemaining} days left`}
                </div>
              </label>
            </div>
          </div>
          <div className="timeline_main_progress_end timeline_main_progress_end--exam"></div>
          {windowWidth > 560 && !Capacitor.isNativePlatform() && (
            <div className="timeline_roadmap">
              <PriorityDisplay
                todayPosition={timeRemainingProgress}
                isTopicFlyoutVisible={isTopicFlyoutVisible}
                setIsTopicFlyoutVisible={setIsTopicFlyoutVisible}
              />
            </div>
          )}
        </div>
        <ExamDateSelect
          setIsLoadingStudySessionAndContent={
            setIsLoadingStudySessionAndContent
          }
          setIsLoadedStudySessionAndContent={setIsLoadedStudySessionAndContent}
          refreshStudySessionAndContent={refreshStudySessionAndContent}
        />
      </div>
    </div>
  );
};

export default Timeline;
