import { useState } from 'react';
import { Block, BlockTimeSetting, BlockType } from '../../../../types/Study';
import { convertSecondsToDisplayTime } from '../../../../utils/convertSecondsToDisplayTime';
import {
  hapticsImpactLight,
  hapticsImpactMedium,
} from '../../../../utils/haptics';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { ArrowRight, BookOpen, Clock, Info } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import IntercomButton from '../../../Global/IntercomButton';
import { UserExam } from '../../../../types/User';
import PastQBankBlock from '../../../Global/PastQBankBlock';
import { updateCurrentUserInfo } from '../../../../services/auth';
import { setUserInfo } from '../../../../slices/auth/authSlice';
import { formatAccommodation } from '../../../../utils/accommodationUtils';

interface StartScreenProps {
  activeBlock: Block | null;
  currentBlockNumber: number;
  timeRemaining: number;
  startBlock: (type: BlockType) => void;
  resumeBlock: () => void;
  primaryExam: UserExam | undefined;
  fadeInFlashLoader: () => void;
  reviewQbankBlock?: (blockId: string) => void;
}

const StartScreen: React.FC<StartScreenProps> = ({
  activeBlock,
  currentBlockNumber,
  timeRemaining,
  startBlock,
  resumeBlock,
  primaryExam,
  fadeInFlashLoader,
  reviewQbankBlock,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const blockCount = useSelector((state: RootState) => state.blocks.length);
  const previousBlocks = useSelector((state: RootState) =>
    state.blocks.filter((b) => b.isSubmitted)
  );
  const [isChangingBlockType, setIsChangingBlockType] = useState(false);

  const dispatch = useDispatch();

  const renderPreviousBlocks = () => {
    if (previousBlocks.length === 0) return;
    return (
      <>
        <h2>Previous Blocks</h2>
        {[...previousBlocks]
          .sort((a, b) => (a.ord ?? 0) - (b.ord ?? 0))
          .map((block, i) => {
            return (
              <PastQBankBlock
                key={block.id}
                title={`Block ${i + 1}`}
                blockType={
                  block.type
                    ? block.type.charAt(0).toUpperCase() + block.type.slice(1)
                    : 'Loading...'
                }
                questionCount={block.vignetteCount}
                correctCount={block.correctCount}
                incorrectCount={block.incorrectCount}
                omittedCount={block.omittedCount}
                reviewBlockFunction={() => {
                  fadeInFlashLoader();
                  console.log('Loading a Past Today Block for Review');
                  setTimeout(() => {
                    if (reviewQbankBlock) reviewQbankBlock(block.id);
                  }, 250);
                }}
              />
            );
          })}
      </>
    );
  };

  const updateUserBlockPreference = async (type: BlockType) => {
    const updateUser = await updateCurrentUserInfo({
      preferredBlockType: type,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const renderAccommodations = () => {
    if (activeBlock?.isStarted) {
      if (activeBlock?.timeSetting !== BlockTimeSetting.default) {
        return ` | ${formatAccommodation(activeBlock?.timeSetting as BlockTimeSetting, true)} Time`;
      }
    } else {
      if (currentUser?.preferredBlockTimeSetting !== BlockTimeSetting.default) {
        return ` | ${formatAccommodation(
          currentUser?.preferredBlockTimeSetting,
          true
        )} Time`;
      }
    }
  };

  const calculateAccommodatedTime = (vignetteCount: number) => {
    let multiplier = 1;
    if (currentUser?.preferredBlockTimeSetting === BlockTimeSetting.quarter) {
      multiplier = 1.25;
    } else if (
      currentUser?.preferredBlockTimeSetting === BlockTimeSetting.half
    ) {
      multiplier = 1.5;
    } else if (
      currentUser?.preferredBlockTimeSetting === BlockTimeSetting.double
    ) {
      multiplier = 2;
    }
    return convertSecondsToDisplayTime(vignetteCount * multiplier * 90);
  };

  return (
    <div
      className={`exam-start${currentBlockNumber === blockCount && activeBlock?.isSubmitted ? ' all-submitted' : ''}${isChangingBlockType ? ' is-changing-type' : ''}`}
    >
      <div className="exam-start_wrapper">
        <div className="exam-start_wrapper_header">
          <div className="title">
            {primaryExam && <div>{primaryExam.name}</div>}
            <h1>Question Bank</h1>
          </div>
        </div>
        <div className="exam-start_wrapper_main">
          <div className="exam-start_wrapper_main_start">
            <h2>
              Block {currentBlockNumber} <span>of {blockCount}</span>
            </h2>
            <div className="exam-start_wrapper_main_start_container">
              {((activeBlock?.isStarted &&
                activeBlock.type === BlockType.timed) ||
                isChangingBlockType ||
                (currentUser?.preferredBlockType === BlockType.timed &&
                  !isChangingBlockType &&
                  !activeBlock?.isStarted)) && (
                <div className="exam-start_wrapper_main_start_container_timed">
                  <button
                    className="panel"
                    onClick={() => {
                      fadeInFlashLoader();
                      hapticsImpactMedium();
                      localStorage.removeItem('ora-today-block-id');
                      setTimeout(() => {
                        if (!activeBlock?.isStarted) {
                          startBlock(BlockType.timed);
                        } else {
                          resumeBlock();
                        }
                      }, 250);
                    }}
                  >
                    <div className="panel_main">
                      <Clock />
                    </div>
                    <div className="panel_footer">
                      <h3>
                        Timed Mode
                        <Tooltip
                          title={
                            'In Timed Mode, you will have a set amount of time to complete all of the block questions. Review will be available after the block is completed.'
                          }
                          enterTouchDelay={0}
                        >
                          <Info
                            onClick={(
                              e: React.MouseEvent<HTMLOrSVGElement>
                            ) => {
                              e.preventDefault();
                              e.stopPropagation();
                              hapticsImpactLight();
                            }}
                          />
                        </Tooltip>
                      </h3>
                      <p>
                        {activeBlock?.vignetteCount} Questions |{' '}
                        {activeBlock?.isStarted
                          ? convertSecondsToDisplayTime(timeRemaining)
                          : calculateAccommodatedTime(
                              activeBlock?.vignetteCount ?? 0
                            )}
                        {renderAccommodations()}
                      </p>
                      <div className="button button--glass">
                        {!activeBlock?.isStarted
                          ? 'Start Timed Block'
                          : 'Resume Block'}
                      </div>
                    </div>
                  </button>
                </div>
              )}
              {((activeBlock?.isStarted &&
                activeBlock.type === BlockType.tutor) ||
                isChangingBlockType ||
                (currentUser?.preferredBlockType === BlockType.tutor &&
                  !isChangingBlockType &&
                  !activeBlock?.isStarted)) && (
                <div className="exam-start_wrapper_main_start_container_tutor">
                  <button
                    className="panel"
                    onClick={() => {
                      fadeInFlashLoader();
                      hapticsImpactMedium();
                      localStorage.removeItem('ora-today-block-id');
                      updateUserBlockPreference(BlockType.tutor);
                      setTimeout(() => {
                        startBlock(BlockType.tutor);
                      }, 250);
                    }}
                  >
                    <div className="panel_main">
                      <BookOpen />
                    </div>
                    <div className="panel_footer">
                      <h3>
                        Untimed Mode
                        <Tooltip
                          title={
                            'In Untimed Mode, you will see questions one at a time with review following each question.'
                          }
                          enterTouchDelay={0}
                        >
                          <Info
                            onClick={(
                              e: React.MouseEvent<HTMLOrSVGElement>
                            ) => {
                              e.preventDefault();
                              e.stopPropagation();
                              hapticsImpactLight();
                            }}
                          />
                        </Tooltip>
                      </h3>
                      <p>{activeBlock?.vignetteCount} Questions | Untimed</p>
                      <div className="button button--glass">
                        {!activeBlock?.isStarted
                          ? 'Start Untimed Block'
                          : 'Resume Block'}
                      </div>
                    </div>
                  </button>
                </div>
              )}
              {!isChangingBlockType && !activeBlock?.isStarted && (
                <button
                  className="button button--link"
                  onClick={() => {
                    setIsChangingBlockType(!isChangingBlockType);
                    hapticsImpactLight();
                  }}
                >
                  Change Block Type
                  <ArrowRight />
                </button>
              )}
            </div>
          </div>
          {previousBlocks.length > 0 && (
            <div className="exam-start_wrapper_main_previous">
              {renderPreviousBlocks()}
            </div>
          )}
        </div>
        <IntercomButton />
      </div>
    </div>
  );
};

export default StartScreen;
