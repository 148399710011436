import { Check } from 'react-feather';
import {
  getComprehensiveFlashcards,
  getComprehensiveQbank,
  getHighYieldFlashcards,
  getHighYieldQbank,
  getStandardFlashcards,
  getStandardQbank,
} from '../../../utils/contentQuantityUtils';
import { hapticsImpactLight } from '../../../utils/haptics';
import { workloadOptionExamplesMap } from '../../../utils/workloadOptionExamples';

interface GoalSelectionProps {
  selectedGoal: 'High Yield' | 'Standard' | 'Comprehensive' | '';
  setSelectedGoal: (
    goal: 'High Yield' | 'Standard' | 'Comprehensive' | ''
  ) => void;
  isUsingQbank: boolean;
  isUsingFlashcards: boolean;
  primaryExamName: string | undefined;
  showCheckmark?: boolean;
}

const GoalSelection: React.FC<GoalSelectionProps> = ({
  selectedGoal,
  setSelectedGoal,
  isUsingQbank,
  isUsingFlashcards,
  primaryExamName,
  showCheckmark = false,
}) => {
  if (!primaryExamName) return;
  return (
    <>
      <button
        className={`button button--workload-option ${selectedGoal === 'High Yield' ? 'is-selected' : ''}`}
        onClick={() => {
          hapticsImpactLight();
          setSelectedGoal('High Yield');
        }}
      >
        <div className="button--workload-option_name">High Yield</div>
        <div className="button--workload-option_quantity">
          {isUsingQbank &&
            `${getHighYieldQbank(primaryExamName)} QBank questions`}
          {isUsingQbank && isUsingFlashcards && ' | '}
          {isUsingFlashcards &&
            `${getHighYieldFlashcards(primaryExamName)} flashcards`}
        </div>
        <div className="button--workload-option_description">
          Topics for a safe passing score (e.g.{' '}
          {
            workloadOptionExamplesMap[
              primaryExamName as keyof typeof workloadOptionExamplesMap
            ]['High Yield']
          }
          )
        </div>
        {showCheckmark && <Check className="checkmark" />}
      </button>
      <button
        className={`button button--workload-option ${selectedGoal === 'Standard' ? 'is-selected' : ''}`}
        onClick={() => {
          hapticsImpactLight();
          setSelectedGoal('Standard');
        }}
      >
        <div className="button--workload-option_name">Standard</div>
        <div className="button--workload-option_quantity">
          {isUsingQbank &&
            `${getStandardQbank(primaryExamName)} QBank questions`}
          {isUsingQbank && isUsingFlashcards && ' | '}
          {isUsingFlashcards &&
            `${getStandardFlashcards(primaryExamName)} flashcards`}
        </div>
        <div className="button--workload-option_description">
          Topics for an above-average score (e.g.{' '}
          {
            workloadOptionExamplesMap[
              primaryExamName as keyof typeof workloadOptionExamplesMap
            ]['Standard']
          }
          )
        </div>
        {showCheckmark && <Check className="checkmark" />}
      </button>
      <button
        className={`button button--workload-option ${selectedGoal === 'Comprehensive' ? 'is-selected' : ''}`}
        onClick={() => {
          hapticsImpactLight();
          setSelectedGoal('Comprehensive');
        }}
      >
        <div className="button--workload-option_name">Comprehensive</div>
        <div className="button--workload-option_quantity">
          {isUsingQbank &&
            `${getComprehensiveQbank(primaryExamName)} QBank questions`}
          {isUsingQbank && isUsingFlashcards && ' | '}
          {isUsingFlashcards &&
            `${getComprehensiveFlashcards(primaryExamName)} flashcards`}
        </div>
        <div className="button--workload-option_description">
          Topics for a peak score (e.g.{' '}
          {
            workloadOptionExamplesMap[
              primaryExamName as keyof typeof workloadOptionExamplesMap
            ]['Comprehensive']
          }
          )
        </div>
        {showCheckmark && <Check className="checkmark" />}
      </button>
    </>
  );
};

export default GoalSelection;
