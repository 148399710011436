import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import {
  Group as GroupType,
  getGroupById,
  joinGroup,
  createGroup,
} from '../../services/groups';
import { getSubscriptionStatus } from '../../services/subscriptions';
import { setSubscription } from '../../slices/subscriptions/subscriptionsSlice';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  createSetupIntent,
  setDefaultPaymentMethod,
} from '../../services/subscriptions';
import { useSnackbar } from 'notistack';
import CustomModal from '../../components/Global/CustomModal';
import { RootState } from '../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import NonAuthPage from '../../components/NonAuth/NonAuthPage';
import MSHeader from '../../components/NonAuth/MSHeader';
import MSFooter from '../../components/NonAuth/MSFooter';
import { ArrowRight, Link } from 'react-feather';
import { hapticsImpactLight } from '../../utils/haptics';
import CircularProgress from '@mui/material/CircularProgress';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({
  onSuccess,
  onCancel,
  groupId,
  currentUser,
}: {
  onSuccess: (newGroupId?: string) => void;
  onCancel: () => void;
  groupId?: string;
  currentUser?: { id: string; theme: string };
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Fetch the setup intent when component mounts
    const getSetupIntent = async () => {
      try {
        const setupIntent = await createSetupIntent();
        if (setupIntent) {
          setClientSecret(setupIntent.clientSecret);
        } else {
          throw new Error('Failed to create setup intent');
        }
      } catch (error) {
        console.error('Error getting setup intent:', error);
        enqueueSnackbar('Failed to initialize payment form.', {
          autoHideDuration: 3000,
        });
      }
    };

    getSetupIntent();
  }, [enqueueSnackbar]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements || !clientSecret) {
      return;
    }
    setIsProcessing(true);
    try {
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        throw new Error('Card element not found');
      }

      let setupIntent;

      try {
        // Try to confirm the setup intent
        const result = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        });

        if (result.error) {
          throw result.error;
        }

        setupIntent = result.setupIntent;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (setupError: any) {
        // Check if the error is because the intent is already succeeded
        if (
          setupError.code === 'setup_intent_unexpected_state' &&
          setupError.setup_intent &&
          setupError.setup_intent.status === 'succeeded'
        ) {
          // Use the setup intent from the error object
          setupIntent = setupError.setup_intent;
        } else {
          // Re-throw if it's a different error
          throw setupError;
        }
      }

      if (setupIntent.status === 'succeeded') {
        // Make sure we have a user ID
        if (!currentUser?.id) {
          throw new Error('User ID is required');
        }

        // Set the payment method as default for the customer
        if (setupIntent.payment_method) {
          try {
            await setDefaultPaymentMethod(setupIntent.payment_method);
          } catch (error) {
            console.error('Error setting default payment method:', error);
            // Continue with the group action even if setting default payment method fails
          }
        }

        let result;

        // If groupId exists, join the group; otherwise, create a new group
        if (groupId) {
          result = await joinGroup(groupId, currentUser.id);
        } else {
          result = await createGroup(currentUser.id);
        }

        if (result) {
          // Pass the group ID to onSuccess
          onSuccess(result.id);
        } else {
          throw new Error(
            groupId ? 'Failed to join the group' : 'Failed to create a group'
          );
        }
      } else {
        throw new Error('Setup intent was not successful');
      }
    } catch (error) {
      console.error('Error processing payment and group action:', error);
      enqueueSnackbar('Failed to process your request. Please try again.', {
        autoHideDuration: 3000,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <div className="modal_header">
        <h2>Claim Your Spot</h2>
      </div>
      <div className="modal_content">
        <p className="m-t-0">
          Your card will not be charged until the group is complete.
        </p>
        <div className="stripe-form">
          <form>
            <div>
              <CardElement
                options={{
                  style: {
                    base: {
                      color: 'rgb(48, 49, 61)',
                      fontSize: '16px',
                    },
                    invalid: {
                      color: 'var(--error)',
                    },
                  },
                }}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="modal_actions">
        <button className="button button--glass" onClick={onCancel}>
          Cancel
        </button>
        <button
          className="button button--glass"
          onClick={handleSubmit}
          style={{
            opacity: !stripe || isProcessing ? 0.7 : 1,
          }}
          disabled={!stripe || isProcessing}
        >
          {isProcessing ? (
            <CircularProgress
              sx={{
                color: 'var(--type-body-inverse)',
                height: '1rem !important',
                width: '1rem !important',
              }}
            />
          ) : (
            'Claim Spot'
          )}
        </button>
      </div>
    </>
  );
};

const Group: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const subscription = useSelector(
    (state: RootState) => state.subscription.subscription
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { groupId } = useParams<{ groupId?: string }>();
  const [group, setGroup] = useState<GroupType | null>(null);
  const [, setLoading] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showHowItWorksModal, setShowHowItWorksModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isCurrentUserMember, setIsCurrentUserMember] = useState(false);

  // Get URL search params to check for claim=true
  const location = window.location;
  const searchParams = new URLSearchParams(location.search);
  const shouldClaimSpot = searchParams.get('claim') === 'true';

  useEffect(() => {
    if (currentUser) {
      document.body.classList.add('group-page-authed');
    } else {
      document.body.classList.add('group-page');
    }
    return () => {
      document.body.classList.remove('group-page');
      document.body.classList.remove('group-page-authed');
    };
  }, [currentUser]);

  const fetchGroup = async () => {
    if (!groupId) return;

    setLoading(true);
    try {
      const fetchedGroup = await getGroupById(groupId);
      if (fetchedGroup) {
        setGroup(fetchedGroup as GroupType);

        // Check if current user is already a member of this group
        if (currentUser) {
          const isMember = fetchedGroup.members.some(
            (member) => member.user.id === currentUser.id
          );
          setIsCurrentUserMember(isMember);
        }
      }
    } catch (error) {
      console.error('Failed to fetch group:', error);
      enqueueSnackbar('Failed to refresh group data.', {
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroup();
  }, [groupId, currentUser]);

  // Effect to automatically open payment modal if claim=true in URL
  useEffect(() => {
    if (shouldClaimSpot && currentUser && !isCurrentUserMember) {
      setShowPaymentModal(true);

      // Optionally clean up the URL to remove the claim parameter
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('claim');
      window.history.replaceState({}, '', newUrl.toString());
    } else if (shouldClaimSpot && !currentUser) {
      // If user is not logged in, redirect to auth page
      navigate('/auth?signup=true&plan=GROUP');
    }
  }, [shouldClaimSpot, currentUser, navigate, isCurrentUserMember]);

  // Calculate available spots (assuming max 5 members per group)
  const maxMembers = parseInt(import.meta.env.VITE_GROUP_DISCOUNT_SIZE || '5');
  const currentMembers = group?.members?.length || 0;
  const availableSpots = maxMembers - currentMembers;

  const handleClaimSpot = () => {
    if (!currentUser) {
      // Store the groupId in sessionStorage before redirecting
      if (groupId) {
        sessionStorage.setItem('ora-pending-group-id', groupId);
      }
      navigate('/auth?signup=true&plan=GROUP');
      return;
    }
    setShowPaymentModal(true);
  };

  const handlePaymentSuccess = async (newGroupId?: string) => {
    setShowPaymentModal(false);
    enqueueSnackbar('You have successfully claimed your spot.', {
      autoHideDuration: 3000,
    });

    // Refresh subscription data in Redux
    try {
      const subscriptionData = await getSubscriptionStatus();
      if (subscriptionData) {
        dispatch(setSubscription(subscriptionData));
      }
    } catch (error) {
      console.error('Failed to refresh subscription data:', error);
    }

    if (groupId) {
      await fetchGroup();
    } else if (newGroupId) {
      navigate(`/group/${newGroupId}`);
    }
  };

  // Function to handle link sharing
  const handleShareLink = () => {
    // Create a clean URL without any existing parameters
    const url = new URL(window.location.href);
    const groupLink = `${url.origin}${url.pathname}`;

    hapticsImpactLight();

    navigator.clipboard
      .writeText(groupLink)
      .then(() => {
        enqueueSnackbar('Copied to clipboard.', {
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        console.error('Failed to copy link: ', err);
        enqueueSnackbar('Failed to copy link.', {
          autoHideDuration: 3000,
        });
      });
  };

  // Render different button based on user membership status
  const renderActionButton = () => {
    if (isCurrentUserMember) {
      return null;
    } else if (availableSpots > 0) {
      return (
        <button
          className="button button--light-gradient"
          onClick={handleClaimSpot}
        >
          Claim Your Spot
        </button>
      );
    } else {
      return (
        <button className="button button--glass" disabled>
          Group Full
        </button>
      );
    }
  };

  const getHeaderTexts = () => {
    if (!groupId) {
      return {
        header: 'Create a group, everyone saves 75%',
        subheader: "You won't be charged unless the group fills.",
      };
    } else if (isCurrentUserMember) {
      const isFilled = group && group.members.length >= maxMembers;
      return {
        header: isFilled ? 'This Group is Filled' : 'Share Your Group',
        subheader: isFilled
          ? 'Everyone has received their discount. Happy studying!'
          : 'Everyone gets their discount when the group fills.',
      };
    } else {
      return {
        header: 'Join this group to save 75%',
        subheader: `${availableSpots} spots left. You won't be charged unless the group fills.`,
      };
    }
  };

  // Helper functions to determine user state
  const hasActiveSubscription = () => {
    return (
      currentUser &&
      (subscription?.status === 'ACTIVE' ||
        subscription?.status === 'GRANDFATHERED')
    );
  };

  const isViewingOwnGroup = () => {
    return groupId && subscription?.groupId === groupId;
  };

  const belongsToPendingGroup = () => {
    return (
      currentUser &&
      subscription?.groupStatus === 'PENDING' &&
      subscription?.groupId
    );
  };

  // Render different group states
  const renderActiveSubscriptionMessage = () => {
    const headerText =
      subscription?.status === 'GRANDFATHERED'
        ? 'You Have Legacy Access'
        : 'You Have an Active Subscription';

    const subText =
      subscription?.status === 'GRANDFATHERED'
        ? 'You already have Legacy access to Ora. Group discounts are for new subscribers only.'
        : 'You already have an active subscription plan. Group discounts are for new subscribers only.';

    return (
      <div className="group">
        <div className="group_header">
          <h1>{headerText}</h1>
          <p>{subText}</p>
        </div>
        <div className="group_action" style={{ marginTop: '2rem' }}>
          <button
            className="button button--light-gradient"
            onClick={() => navigate('/')}
          >
            Return Home
          </button>
        </div>
      </div>
    );
  };

  const renderAlreadyInGroupMessage = (isViewingDifferentGroup = false) => {
    return (
      <div className="group">
        <div className="group_header">
          <h1>You Already Belong to a Group</h1>
          <p>
            {isViewingDifferentGroup
              ? 'You are already a member of a different group. You can only be part of one group at a time.'
              : 'You are already a member of a group. You can view your group details below.'}
          </p>
        </div>
        <div className="group_action" style={{ marginTop: '2rem' }}>
          <button
            className="button button--light-gradient"
            onClick={() => navigate(`/group/${subscription?.groupId}`)}
          >
            View Your Group
          </button>
        </div>
      </div>
    );
  };

  const renderGroupDetails = () => {
    return (
      <>
        <div className="group">
          <div className="group_header">
            <h1>{getHeaderTexts().header}</h1>
            <p className="m-b-1-50">{getHeaderTexts().subheader}</p>
            <button
              className="button button--glass"
              onClick={() => setShowHowItWorksModal(true)}
            >
              How it Works
              <ArrowRight />
            </button>
          </div>
          {isCurrentUserMember && availableSpots > 0 && (
            <div className="group_share-container">
              <h3>Share with friends to fill your group!</h3>
              <div className="group_share-container_link">
                <div className="group_share-container_link_url">
                  {window.location.origin}
                  {window.location.pathname}
                </div>
                <button
                  className="button button--glass"
                  onClick={handleShareLink}
                >
                  <Link />
                  <span>Copy Link</span>
                </button>
              </div>
            </div>
          )}
          <div className="group_members">
            <h2 className="m-t-0">Group Members</h2>
            {group?.members.map((member, index) => (
              <div key={index} className="group-member-row reserved">
                <div className="group-member-row_circle">
                  <div>{member.user.email.slice(0, 1).toUpperCase()}</div>
                </div>
                <div className="group-member-row_info">
                  <div className="group-member-row_info_email">
                    {member.user.email === currentUser?.email
                      ? 'You'
                      : member.user.email}
                  </div>
                  <div className="group-member-row_info_joined">
                    Joined{' '}
                    {new Date().toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                    })}
                  </div>
                </div>
              </div>
            ))}
            {Array.from({ length: availableSpots }).map((_, index) => (
              <div key={`empty-${index}`} className="group-member-row">
                <div className="group-member-row_circle">
                  <div>?</div>
                </div>
                <div>Available Spot</div>
              </div>
            ))}
          </div>
          <div className="group_action">{renderActionButton()}</div>
        </div>
        {currentUser && (
          <>
            <CustomModal
              open={showPaymentModal}
              onClose={() => setShowPaymentModal(false)}
              name="group-payment"
              glass={true}
              blurs={true}
              disableBackdropClick={true}
            >
              <Elements stripe={stripePromise}>
                <PaymentForm
                  onSuccess={handlePaymentSuccess}
                  onCancel={() => setShowPaymentModal(false)}
                  groupId={groupId}
                  currentUser={currentUser}
                />
              </Elements>
            </CustomModal>
          </>
        )}
        <CustomModal
          open={showHowItWorksModal}
          onClose={() => setShowHowItWorksModal(false)}
          name={'group-discount'}
          glass={true}
          blurs={true}
        >
          <div className="modal_header">
            <h2>How Group Discounts Work</h2>
          </div>
          <div className="modal_content">
            <ol>
              <li>
                <strong>Create or Join a Group</strong>
                <p>Start a new group or join an existing one.</p>
              </li>
              <li>
                <strong>Add Payment Method</strong>
                <p>
                  Confirm your spot by adding your payment details. You won't be
                  charged until the group is complete.
                </p>
              </li>
              <li>
                <strong>Invite Friends</strong>
                <p>Share the group link to fill all spots.</p>
              </li>
              <li>
                <strong>Complete the Group</strong>
                <p>
                  Once all {maxMembers} spots are filled, you will be charged
                  and everyone's subscriptions will be activated.
                </p>
              </li>
              <li>
                <strong>Enjoy the Savings</strong>
                <p>
                  All members receive 75% off the first year — just $25/month
                  (annual commitment, billed monthly). After one year, the plan
                  renews at the regular annual price ($50/month, annual
                  commitment).
                </p>
              </li>
            </ol>
            <p className="note">
              <strong>Note:</strong> If the group doesn't fill within 14 days,
              the group expires and no group members will be charged.
            </p>
          </div>
          <div className="modal_actions">
            <button
              className="button button--glass"
              onClick={() => setShowHowItWorksModal(false)}
            >
              Close
            </button>
          </div>
        </CustomModal>
      </>
    );
  };

  const renderGroup = () => {
    // Case 1: User has an active subscription (not viewing their own group)
    if (hasActiveSubscription() && (!groupId || !isViewingOwnGroup())) {
      return renderActiveSubscriptionMessage();
    }

    // Case 2: User belongs to a pending group and is on the generic group page
    if (belongsToPendingGroup() && !groupId) {
      return renderAlreadyInGroupMessage();
    }

    // Case 3: User belongs to a pending group and is trying to view a different group
    if (
      belongsToPendingGroup() &&
      groupId &&
      subscription?.groupId !== groupId
    ) {
      return renderAlreadyInGroupMessage(true);
    }

    // Default case: Show group details
    return renderGroupDetails();
  };

  return (
    <>
      {currentUser && <Page>{renderGroup()}</Page>}
      {!currentUser && (
        <>
          <MSHeader />
          <NonAuthPage>{renderGroup()}</NonAuthPage>
          <MSFooter />
        </>
      )}
      <div className="green-blur"></div>
    </>
  );
};

export default Group;
