import { createTheme } from '@mui/material/styles';
import { OraMuiMenuItem } from './components/OraMuiMenuItem';
import { OraMuiTextField } from './components/OraMuiTextField';
import { OraTypography } from './components/OraTypography';
import { OraMuiToggleButton } from './components/OraMuiToggleButton';
import { OraMuiCircularProgress } from './components/OraMuiCircularProgress';
import { OraMuiAutocomplete } from './components/OraMuiAutocomplete';
import { OraMuiFormLabel } from './components/OraMuiFormLabel';
import {
  OraMuiSpeedDial,
  OraMuiSpeedDialAction,
} from './components/OraMuiSpeedDial';
import { OraMuiSelect } from './components/OraMuiSelect';
import { OraMuiSlider } from './components/OraMuiSlider';
import { OraMuiMenu } from './components/OraMuiMenu';
import { OraMuiSwitch } from './components/OraMuiSwitch';
import { OraMuiTooltip } from './components/OraMuiTooltip';
export const theme = createTheme({
  typography: OraTypography,
  palette: {
    primary: {
      main: '#111215',
    },
    text: {
      primary: '#545968',
      secondary: '#545968',
    },
    success: {
      main: '#6CC2B2',
    },
    error: {
      main: '#FA3E56',
    },
  },
  components: {
    MuiToggleButton: OraMuiToggleButton,
    MuiTextField: OraMuiTextField,
    MuiCircularProgress: OraMuiCircularProgress,
    MuiAutocomplete: OraMuiAutocomplete,
    MuiMenuItem: OraMuiMenuItem,
    MuiFormLabel: OraMuiFormLabel,
    MuiSpeedDial: OraMuiSpeedDial('#111215'),
    MuiSelect: OraMuiSelect,
    MuiSpeedDialAction: OraMuiSpeedDialAction('#111215'),
    MuiSlider: OraMuiSlider,
    MuiMenu: OraMuiMenu,
    MuiSwitch: OraMuiSwitch,
    MuiTooltip: OraMuiTooltip,
  },
});

export const darkTheme = createTheme({
  typography: OraTypography,
  palette: {
    primary: {
      main: '#111215',
    },
    text: {
      primary: '#FEFEFE',
      secondary: '#FEFEFE',
    },
    success: {
      main: '#6CC2B2',
    },
    error: {
      main: '#FA3E56',
    },
  },
  components: {
    MuiToggleButton: OraMuiToggleButton,
    MuiTextField: OraMuiTextField,
    MuiCircularProgress: OraMuiCircularProgress,
    MuiAutocomplete: OraMuiAutocomplete,
    MuiMenuItem: OraMuiMenuItem,
    MuiFormLabel: OraMuiFormLabel,
    MuiSpeedDial: OraMuiSpeedDial('#FEFEFE'),
    MuiSelect: OraMuiSelect,
    MuiSpeedDialAction: OraMuiSpeedDialAction('#FEFEFE'),
    MuiSlider: OraMuiSlider,
    MuiMenu: OraMuiMenu,
    MuiSwitch: OraMuiSwitch,
    MuiTooltip: OraMuiTooltip,
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#FEFEFE',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#2A333B',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        backdrop: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      },
    },
    // @ts-expect-error - unknown type
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-disabled:not(.Mui-selected)': {
            color: 'rgba(255,255,255,.38)',
          },
        },
      },
    },
  },
});
