import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import { useNavigate } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import moment from 'moment';
import {
  cancelSubscription,
  reactivateSubscription,
  getSubscriptionStatus,
  getBillingInformation,
  updateBillingDetails,
  addPaymentMethod,
  removePaymentMethod,
  setDefaultPaymentMethod,
  getInvoices,
  getInvoiceById,
  Invoice,
} from '../../../services/subscriptions';
import TrialDisplay from '../../Global/TrialDisplay';
import { hapticsImpactLight } from '../../../utils/haptics';
import { useState, useEffect } from 'react';
import CustomModal from '../../Global/CustomModal';
import { setSubscription } from '../../../slices/subscriptions/subscriptionsSlice';
import { useSnackbar } from 'notistack';
import { loadStripe, PaymentMethod } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  AddressElement,
} from '@stripe/react-stripe-js';
import { BillingInfo } from '../../../types/Subscription';
import { ArrowRight, Trash } from 'react-feather';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { useSystemTheme } from '../../../hooks/useSystemTheme';
import CircularProgress from '@mui/material/CircularProgress';

// Initialize Stripe (you'll need to replace with your publishable key)
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const Billing: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const subscription = useSelector(
    (state: RootState) => state.subscription.subscription
  );
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState<'cancel' | 'renew'>(
    'cancel'
  );

  // New state variables for billing functionality
  const [billingInfo, setBillingInfo] = useState<BillingInfo | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isEditingBilling, setIsEditingBilling] = useState(false);
  const [isAddingPayment, setIsAddingPayment] = useState(false);
  const [billingForm, setBillingForm] = useState({
    name: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const systemTheme = useSystemTheme();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    refreshSubscriptionStatus();
  }, []);

  // Fetch billing information on component mount
  useEffect(() => {
    if (
      subscription &&
      subscription.status !== 'GRANDFATHERED' &&
      subscription.status !== 'TRIAL'
    ) {
      fetchBillingInfo();
      fetchInvoices();
    }
  }, [subscription]);

  const fetchBillingInfo = async () => {
    setIsLoading(true);
    try {
      const data = await getBillingInformation();
      if (data) {
        setBillingInfo(data.customer);
        setPaymentMethods(data.paymentMethods || []);
        setBillingForm({
          name: data.customer?.name || '',
          address: {
            line1: data.customer?.address?.line1 || '',
            line2: data.customer?.address?.line2 || '',
            city: data.customer?.address?.city || '',
            state: data.customer?.address?.state || '',
            postal_code: data.customer?.address?.postal_code || '',
            country: data.customer?.address?.country || '',
          },
        });
      }
    } catch (error) {
      enqueueSnackbar('Failed to load billing information.', {
        autoHideDuration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInvoices = async () => {
    try {
      const data = await getInvoices();
      if (data && data.invoices) {
        setInvoices(data.invoices);
      }
    } catch (error) {
      enqueueSnackbar('Failed to load invoices.', { autoHideDuration: 3000 });
    }
  };

  const handleViewInvoice = async (invoiceId: string) => {
    try {
      const data = await getInvoiceById(invoiceId);
      if (data && data.invoice) {
        setSelectedInvoice(data.invoice);
      }
    } catch (error) {
      enqueueSnackbar('Failed to load invoice details.', {
        autoHideDuration: 3000,
      });
    }
  };

  const handleUpdateBillingDetails = async () => {
    setIsLoading(true);
    try {
      await updateBillingDetails(billingForm);
      await fetchBillingInfo();
      setIsEditingBilling(false);
      enqueueSnackbar('Billing information updated successfully.', {
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar('Failed to update billing information.', {
        autoHideDuration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemovePaymentMethod = async (paymentMethodId: string) => {
    if (paymentMethods.length <= 1) {
      enqueueSnackbar('You need at least one payment method.', {
        autoHideDuration: 3000,
      });
      return;
    }

    // Set next payment method as default
    const nextPaymentMethod = paymentMethods.find(
      (method) => method.id !== paymentMethodId
    );
    if (nextPaymentMethod) {
      await setDefaultPaymentMethod(nextPaymentMethod.id);
    }

    try {
      await removePaymentMethod(paymentMethodId);
      await fetchBillingInfo();
      enqueueSnackbar('Payment method removed.', { autoHideDuration: 3000 });
    } catch (error) {
      enqueueSnackbar('Failed to remove payment method.', {
        autoHideDuration: 3000,
      });
    }
  };

  const handleSetDefaultPaymentMethod = async (paymentMethodId: string) => {
    try {
      await setDefaultPaymentMethod(paymentMethodId);
      await fetchBillingInfo();
      enqueueSnackbar('Default payment method updated.', {
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar('Failed to update default payment method.', {
        autoHideDuration: 3000,
      });
    }
  };

  const capitalizeWord = (word: string | undefined) => {
    if (!word) return '';
    if (word === 'GRANDFATHERED') return 'Legacy';
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  const formatPrice = (
    price: string | undefined,
    plan: string | undefined,
    percentOff?: number,
    discountEnd?: string
  ) => {
    if (plan === 'GRANDFATHERED') return 'Free';
    if (!price || !plan) return '';
    const numericPrice = parseFloat(price);
    const isAnnual = plan.toLowerCase().includes('annual');

    if (isAnnual) {
      if (percentOff && discountEnd && moment(discountEnd).isAfter(moment())) {
        return (
          <div className="discounted-group-price">
            <div className="strike">$600/year</div>
            <div>$300/year (Billed $25/month)</div>
          </div>
        );
      }
      return `$${numericPrice * 12}/year (Billed $${numericPrice}/month)`;
    }
    return `$${numericPrice}/month`;
  };

  const renderSubscription = () => {
    return (
      <div className="subscription-listing">
        <div className="subscription-listing_cell">
          <div className="subscription-listing_cell_header">Plan</div>
          <div className="subscription-listing_cell_data">
            {capitalizeWord(subscription?.plan)}
            {subscription?.cancelAt &&
              subscription?.status !== 'GRANDFATHERED' && (
                <div className="badge badge--error">Canceled</div>
              )}
          </div>
        </div>
        <div className="subscription-listing_cell">
          <div className="subscription-listing_cell_header">Price</div>
          <div className="subscription-listing_cell_data">
            {formatPrice(
              subscription?.price,
              subscription?.plan,
              subscription?.percentOff,
              subscription?.discountEnd
            )}
          </div>
        </div>
        <div className="subscription-listing_cell">
          <div className="subscription-listing_cell_header">
            {subscription?.cancelAt
              ? 'Cancels'
              : subscription?.percentOff &&
                  subscription?.discountEnd &&
                  moment(subscription.discountEnd).isAfter(moment())
                ? 'Renews at Full Price'
                : 'Renews'}
          </div>
          <div className="subscription-listing_cell_data">
            {moment(subscription?.cancelAt || subscription?.renewsAt).format(
              'MMMM D, YYYY'
            )}
          </div>
        </div>
      </div>
    );
  };

  const refreshSubscriptionStatus = async () => {
    const subscriptionData = await getSubscriptionStatus();
    if (subscriptionData) {
      dispatch(setSubscription(subscriptionData));
    }
  };

  const cancelSubscriptionHandler = async () => {
    try {
      await cancelSubscription();
      await refreshSubscriptionStatus();
      enqueueSnackbar('Subscription canceled.', {
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error('Error canceling subscription:', error);
    }
  };

  const reactivateSubscriptionHandler = async () => {
    try {
      await reactivateSubscription();
      await refreshSubscriptionStatus();
      enqueueSnackbar('Subscription renewed.', {
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error('Error reactivating subscription:', error);
      enqueueSnackbar('Error reactivating subscription.', {
        autoHideDuration: 3000,
      });
    }
  };

  const handleConfirmAction = async () => {
    if (confirmModalType === 'cancel') {
      await cancelSubscriptionHandler();
    } else {
      await reactivateSubscriptionHandler();
    }
    setConfirmModalIsOpen(false);
  };

  const handleActionClick = (type: 'cancel' | 'renew') => {
    setConfirmModalType(type);
    setConfirmModalIsOpen(true);
  };

  // New component for adding payment method
  const AddPaymentMethodForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();

      if (!stripe || !elements) {
        return;
      }
      setIsProcessing(true);
      try {
        const cardElement = elements.getElement(CardElement);

        if (!cardElement) {
          throw new Error('Card element not found');
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
        if (error) {
          throw error;
        }
        await addPaymentMethod(paymentMethod.id);
        await fetchBillingInfo();
        setIsAddingPayment(false);
        enqueueSnackbar('Payment method added successfully.', {
          autoHideDuration: 3000,
        });
      } catch (error) {
        console.error('Error adding payment method:', error);
        enqueueSnackbar('Failed to add payment method.', {
          autoHideDuration: 3000,
        });
      } finally {
        setIsProcessing(false);
      }
    };
    return (
      <>
        <CustomModal
          open={isAddingPayment}
          onClose={() => setIsAddingPayment(false)}
          name="add-payment-method"
          glass={true}
          blurs={true}
          disableBackdropClick={true}
        >
          <div className="modal_header">
            <h2>Add Payment Method</h2>
          </div>
          <div className="modal_content">
            <div className="stripe-form">
              <form>
                <CardElement
                  options={{
                    style: {
                      base: {
                        color: 'rgb(48, 49, 61)',
                        fontSize: '16px',
                      },
                      invalid: {
                        color: 'var(--error)',
                      },
                    },
                  }}
                />
              </form>
            </div>
          </div>
          <div className="modal_actions">
            <button
              type="button"
              className="button button--glass"
              onClick={() => setIsAddingPayment(false)}
              disabled={isProcessing}
            >
              Cancel
            </button>
            <button
              className="button button--glass"
              disabled={!stripe || isProcessing}
              onClick={handleSubmit}
            >
              {isProcessing ? (
                <CircularProgress
                  sx={{
                    color: 'var(--type-body-inverse)',
                    height: '1rem !important',
                    width: '1rem !important',
                  }}
                />
              ) : (
                'Add Method'
              )}
            </button>
          </div>
        </CustomModal>
      </>
    );
  };

  // Render billing details form
  const renderBillingDetailsForm = () => {
    return (
      <>
        <div className="block_body">
          <div className="stripe-form">
            <Elements
              stripe={stripePromise}
              options={{
                appearance: {
                  theme: 'stripe',
                },
              }}
            >
              <AddressElement
                options={{
                  mode: 'billing',
                  autocomplete: {
                    mode: 'google_maps_api',
                    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
                  },
                  defaultValues: {
                    name: billingForm.name,
                    address: {
                      line1: billingForm.address.line1,
                      line2: billingForm.address.line2,
                      city: billingForm.address.city,
                      state: billingForm.address.state,
                      postal_code: billingForm.address.postal_code,
                      country: '',
                    },
                  },
                }}
                onChange={(event) => {
                  const address = event.value.address;
                  setBillingForm({
                    ...billingForm,
                    name: event.value.name || billingForm.name,
                    address: {
                      line1: address?.line1 || '',
                      line2: address?.line2 || '',
                      city: address?.city || '',
                      state: address?.state || '',
                      postal_code: address?.postal_code || '',
                      country: address?.country || '',
                    },
                  });
                }}
              />
            </Elements>
          </div>
        </div>
        <div className="block_footer">
          <button
            className="button button--glass"
            onClick={() => setIsEditingBilling(false)}
          >
            Cancel
          </button>
          <button
            className="button button--glass"
            onClick={handleUpdateBillingDetails}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress
                sx={{
                  color: 'var(--type-body-inverse)',
                  height: '1rem !important',
                  width: '1rem !important',
                }}
              />
            ) : (
              'Update Billing Address'
            )}
          </button>
        </div>
      </>
    );
  };

  // Render billing details view
  const renderBillingDetails = () => {
    if (isEditingBilling) {
      return renderBillingDetailsForm();
    }
    return (
      <>
        <div className="block_body">
          <h2>Billing Address</h2>
          {billingInfo?.address?.line1 ? (
            <div className="billing-details">
              <div className="billing-details_detail">
                <div className="billing-details_detail_label">Full Name</div>
                <div className="billing-details_detail_value">
                  {billingInfo?.name}
                </div>
              </div>
              <div className="billing-details_detail">
                <div className="billing-details_detail_label">
                  Address Line 1
                </div>
                <div className="billing-details_detail_value">
                  {billingInfo?.address?.line1}
                </div>
              </div>
              {billingInfo?.address?.line2 && (
                <div className="billing-details_detail">
                  <div className="billing-details_detail_label">
                    Address Line 2
                  </div>
                  <div className="billing-details_detail_value">
                    {billingInfo?.address?.line2}
                  </div>
                </div>
              )}
              <div className="billing-details_detail">
                <div className="billing-details_detail_label">City</div>
                <div className="billing-details_detail_value">
                  {billingInfo?.address?.city}
                </div>
              </div>
              <div className="billing-details_detail">
                <div className="billing-details_detail_label">State</div>
                <div className="billing-details_detail_value">
                  {billingInfo?.address?.state}
                </div>
              </div>
              <div className="billing-details_detail">
                <div className="billing-details_detail_label">Postal Code</div>
                <div className="billing-details_detail_value">
                  {billingInfo?.address?.postal_code}
                </div>
              </div>
              <div className="billing-details_detail">
                <div className="billing-details_detail_label">Country</div>
                <div className="billing-details_detail_value">
                  {billingInfo?.address?.country}
                </div>
              </div>
            </div>
          ) : (
            <p className="m-y-0">No saved billing address.</p>
          )}
        </div>
        <div className="block_footer">
          <button
            className="button button--glass"
            onClick={() => setIsEditingBilling(true)}
          >
            Edit Billing Address
          </button>
        </div>
      </>
    );
  };

  // Render payment methods
  const renderPaymentMethods = () => {
    if (isAddingPayment) {
      return (
        <Elements
          stripe={stripePromise}
          options={{
            appearance: {
              theme:
                (currentUser && currentUser.theme === 'dark') ||
                (currentUser &&
                  currentUser.theme === 'system' &&
                  systemTheme === 'dark')
                  ? 'night'
                  : 'stripe',
            },
          }}
        >
          <AddPaymentMethodForm />
        </Elements>
      );
    }
    const sortedPaymentMethods = [...paymentMethods].sort((a, b) => {
      if (a.id === billingInfo?.default_payment_method?.id) return -1;
      if (b.id === billingInfo?.default_payment_method?.id) return 1;
      return 0;
    });
    return (
      <>
        <div className="block_body">
          <h2>Payment Methods</h2>
          <div className="payment-methods">
            {paymentMethods.length === 0 ? (
              <p className="m-y-0">No saved payment methods.</p>
            ) : (
              <div className="payment-methods-listing">
                {sortedPaymentMethods.map((method) => (
                  <div
                    key={method.id}
                    className="payment-methods-listing_method"
                  >
                    <div className="payment-method-info">
                      <div className="card-brand">
                        {method.card?.brand?.toUpperCase()} ••••{' '}
                        {method.card?.last4}
                      </div>
                      <div className="card-expiry">
                        Expires {method.card?.exp_month}/{method.card?.exp_year}
                      </div>
                    </div>
                    <div className="payment-method-actions">
                      {method.id ===
                        billingInfo?.default_payment_method?.id && (
                        <div className="badge badge--success">Default</div>
                      )}
                      {method.id !==
                        billingInfo?.default_payment_method?.id && (
                        <button
                          className="button button--set-default button--link"
                          onClick={() =>
                            handleSetDefaultPaymentMethod(method.id)
                          }
                        >
                          Set as Default
                        </button>
                      )}
                      <Tooltip
                        title={
                          paymentMethods.length === 1 &&
                          subscription?.status === 'ACTIVE'
                            ? "You can't delete this payment method because you have an active plan that uses it."
                            : ''
                        }
                      >
                        <span>
                          <button
                            className="button button--icon-only"
                            onClick={() => handleRemovePaymentMethod(method.id)}
                            disabled={
                              paymentMethods.length === 1 &&
                              subscription?.status === 'ACTIVE'
                            }
                          >
                            <Trash />
                          </button>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="block_footer">
          <button
            className="button button--glass"
            onClick={() => setIsAddingPayment(true)}
          >
            Add Payment Method
          </button>
        </div>
      </>
    );
  };

  // Render invoices
  const renderInvoices = () => {
    return (
      <>
        <div className="block_body">
          <h2>Invoices</h2>
          <div className="invoices-listing">
            {invoices.length === 0 ? (
              <p className="m-y-0">No invoices yet.</p>
            ) : (
              <div className="invoices-listing_table">
                {invoices.map((invoice) => (
                  <div
                    key={invoice.id}
                    className="invoices-listing_table_row"
                    onClick={() => handleViewInvoice(invoice.id)}
                  >
                    <div className="invoice-date">
                      {moment.unix(invoice.created).format('MMMM D, YYYY')}
                      <ArrowRight />
                    </div>
                    <div className="invoice-details">
                      <div className="invoice-number">#{invoice.number}</div>
                      <div className="invoice-amount">
                        ${(invoice.amount_due / 100).toFixed(2)}
                      </div>
                      <div className="invoice-status">
                        <div
                          className={`badge badge--${invoice.status === 'paid' ? 'success' : 'warning'}`}
                        >
                          {invoice.status.toUpperCase()}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {invoices.length === 0 && (
          <div className="block_footer">
            <div
              className="helper"
              style={{ marginRight: 'auto', marginLeft: '0' }}
            >
              Invoices will appear here.
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="billing">
      <div className="block block--settings block--settings--visible">
        <div className="block_body">
          <h2>Current Subscription</h2>
          {subscription?.status === 'TRIAL' ? (
            <TrialDisplay />
          ) : subscription?.status === 'CANCELED' ? (
            <div className="helper">Your subscription has been canceled.</div>
          ) : (
            renderSubscription()
          )}
        </div>
        <div className="block_footer">
          {!Capacitor.isNativePlatform() && (
            <>
              {(subscription?.status === 'TRIAL' ||
                subscription?.status === 'CANCELED') && (
                <button
                  className="button button--glass"
                  onClick={() => {
                    hapticsImpactLight();
                    navigate('/pricing');
                  }}
                >
                  Upgrade Now
                </button>
              )}
              {subscription?.status === 'ACTIVE' && (
                <div className="button-group">
                  {subscription.cancelAt ? (
                    <button
                      className="button button--glass"
                      onClick={() => {
                        hapticsImpactLight();
                        handleActionClick('renew');
                      }}
                    >
                      Renew Subscription
                    </button>
                  ) : (
                    <button
                      className="button button--glass"
                      onClick={() => {
                        hapticsImpactLight();
                        handleActionClick('cancel');
                      }}
                    >
                      Cancel Subscription
                    </button>
                  )}
                </div>
              )}
            </>
          )}
          {subscription?.status === 'GRANDFATHERED' &&
            !Capacitor.isNativePlatform() && (
              <div
                className="helper"
                style={{ marginRight: 'auto', marginLeft: '0' }}
              >
                Thank you for being a part of Ora. You have free access until
                February 23, 2026.
              </div>
            )}
        </div>
      </div>
      {!Capacitor.isNativePlatform() &&
        subscription?.status !== 'TRIAL' &&
        subscription?.status !== 'GRANDFATHERED' && (
          <div className="block block--settings block--settings--visible">
            {isLoading && !billingInfo ? (
              <Skeleton
                sx={{
                  bgcolor: 'rgba(255,255,255,.05)',
                  borderRadius: '1rem',
                }}
                variant="rectangular"
                animation="wave"
                width={'100%'}
                height={200}
              />
            ) : (
              renderBillingDetails()
            )}
          </div>
        )}
      {!Capacitor.isNativePlatform() &&
        subscription?.status !== 'TRIAL' &&
        subscription?.status !== 'GRANDFATHERED' && (
          <div className="block block--settings block--settings--visible">
            {isLoading && !billingInfo ? (
              <Skeleton
                sx={{
                  bgcolor: 'rgba(255,255,255,.05)',
                  borderRadius: '1rem',
                }}
                variant="rectangular"
                animation="wave"
                width={'100%'}
                height={200}
              />
            ) : (
              renderPaymentMethods()
            )}
          </div>
        )}
      {!Capacitor.isNativePlatform() &&
        subscription?.status !== 'TRIAL' &&
        subscription?.status !== 'GRANDFATHERED' && (
          <div className="block block--settings block--settings--visible">
            {isLoading && !billingInfo ? (
              <Skeleton
                sx={{
                  bgcolor: 'rgba(255,255,255,.05)',
                  borderRadius: '1rem',
                }}
                variant="rectangular"
                animation="wave"
                width={'100%'}
                height={200}
              />
            ) : (
              <>
                {renderInvoices()}
                {selectedInvoice && (
                  <CustomModal
                    open={!!selectedInvoice}
                    onClose={() => setSelectedInvoice(null)}
                    name="invoice-details"
                    glass={true}
                    blurs={true}
                  >
                    <div className="modal_header">
                      <h2>Invoice #{selectedInvoice.number}</h2>
                    </div>
                    <div className="modal_content">
                      <div className="invoice-detail">
                        <div className="invoice-detail_label">Status</div>
                        <div className="invoice-detail_value">
                          <div
                            className={`badge badge--${selectedInvoice.status === 'paid' ? 'success' : 'warning'}`}
                          >
                            {selectedInvoice.status.toUpperCase()}
                          </div>
                        </div>
                      </div>
                      <div className="invoice-detail">
                        <div className="invoice-detail_label">Date</div>
                        <div className="invoice-detail_value">
                          {moment
                            .unix(selectedInvoice.created)
                            .format('MMMM D, YYYY')}
                        </div>
                      </div>
                      <div className="invoice-detail">
                        <div className="invoice-detail_label">Period</div>
                        <div className="invoice-detail_value">
                          {moment
                            .unix(selectedInvoice.period_start)
                            .format('MMM D')}{' '}
                          -{' '}
                          {moment
                            .unix(selectedInvoice.period_end)
                            .format('MMM D, YYYY')}
                        </div>
                      </div>
                      <div className="invoice-detail">
                        <div className="invoice-detail_label">Amount</div>
                        <div className="invoice-detail_value">
                          ${(selectedInvoice.amount_due / 100).toFixed(2)}
                        </div>
                      </div>
                      <hr />
                      <h4>Items</h4>
                      <div className="invoice-items">
                        {selectedInvoice.lines?.data?.map(
                          (item, index: number) => (
                            <div key={index} className="invoice-item">
                              <div className="invoice-item-description">
                                {item.description}
                              </div>
                              <div className="invoice-item-amount">
                                ${(item.amount / 100).toFixed(2)}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="modal_actions">
                      {selectedInvoice.hosted_invoice_url && (
                        <a
                          href={selectedInvoice.hosted_invoice_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="button button--glass"
                        >
                          View on Stripe
                        </a>
                      )}
                      {selectedInvoice.invoice_pdf && (
                        <a
                          href={selectedInvoice.invoice_pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="button button--glass"
                        >
                          Download PDF
                        </a>
                      )}
                    </div>
                  </CustomModal>
                )}
              </>
            )}
          </div>
        )}
      <CustomModal
        open={confirmModalIsOpen}
        onClose={() => setConfirmModalIsOpen(false)}
        name="subscription-action"
        glass={true}
      >
        <div className="modal_header">
          <h2>
            {confirmModalType === 'cancel'
              ? 'Cancel Subscription'
              : 'Renew Subscription'}
          </h2>
        </div>
        <div className="modal_content">
          {confirmModalType === 'cancel' ? (
            <p className="m-0">
              Your subscription will be canceled, but is still available until
              the end of your billing period on{' '}
              {moment(subscription?.renewsAt).format('MMMM D, YYYY')}.
              <br />
              <br />
              If you change your mind, you can renew your subscription.
            </p>
          ) : (
            <p className="m-0">
              Your subscription will be renewed and billing will resume on{' '}
              {moment(subscription?.cancelAt).format('MMMM D, YYYY')}.
            </p>
          )}
        </div>
        <div className="modal_actions">
          <button
            className="button button--secondary"
            onClick={() => {
              setConfirmModalIsOpen(false);
              hapticsImpactLight();
            }}
          >
            Cancel
          </button>
          <button
            className={`button ${
              confirmModalType === 'cancel' ? 'button--error' : 'button--glass'
            }`}
            onClick={() => {
              handleConfirmAction();
              hapticsImpactLight();
            }}
          >
            {confirmModalType === 'cancel'
              ? 'Cancel Subscription'
              : 'Renew Subscription'}
          </button>
        </div>
      </CustomModal>
    </div>
  );
};

export default Billing;
