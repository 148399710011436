import React, { useEffect, useRef, useState } from 'react';
import { Copy, Link } from 'react-feather';
import { Flashcard as FlashcardType } from '../../../../types/Flashcard';
import { CircularProgress, Tooltip } from '@mui/material';
import parse from 'html-react-parser';
import { useHotkeys } from 'react-hotkeys-hook';
import { hapticsImpactLight } from '../../../../utils/haptics';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { enqueueSnackbar } from 'notistack';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { suspendFlashcard } from '../../../../services/flashcards';
import {
  removeFromNew,
  removeFromDue,
} from '../../../../slices/flashcards/flashcardsSlice';
import StudyPanel from '../../StudyPanel';
import { PanelType, StudyPhase } from '../../../../types/Study';
import ReportCard from '../../ReportCard';
import { removeFromPast } from '../../../../slices/pastFlashcards/pastFlashcardsSlice';
import { incrementCompletedFlashcards } from '../../../../slices/todaySession/todaySessionSlice';
// import EditFlashcard from '../../EditFlashcard';
import { copyToClipboard } from '../../../../utils/copyToClipboard';

import { useGamepadContext } from '../../../../context/useGamepadContext';
import CustomModal from '../../../Global/CustomModal';
import { GamepadButtonIcon } from '../../../Gamepad/GamepadButtonIcon';
import { GamepadButtonEventWithLogical } from '../../../../context/GamepadContext';
import FlashcardMenu from '../FlashcardMenu';
import ImageDisplay from '../../../Misc/ImageDisplay';
import FlashcardSettings from '../FlashcardSettings';
import Hammer from 'hammerjs';
import { Capacitor } from '@capacitor/core';

interface FlashcardProps {
  flashcard: FlashcardType | null;
  isFlipped: boolean;
  flipCard: (isFlipped: boolean) => void;
  handleResponse: (response: number) => void;
  newScheduling: { [key: number]: string };
  flashcardMenuIsOpen: boolean;
  setFlashcardMenuIsOpen: (isOpen: boolean) => void;
  prevFlashcard: boolean;
  undoResponse: () => void;
  shortcutMenuIsOpen: boolean;
  setShortcutMenuIsOpen: (isOpen: boolean) => void;
  keybindsDisabled: boolean;
  setKeybindsDisabled: (isDisabled: boolean) => void;
  panelType: PanelType;
  setPanelType: (panelType: PanelType) => void;
  fetchSuspendedFlashcards: () => void;
  setShowSuspendedCards: (showSuspendedCards: boolean) => void;
  suspendedCardCount: number;
}

const Flashcard: React.FC<FlashcardProps> = ({
  flashcard,
  isFlipped,
  flipCard,
  handleResponse,
  newScheduling,
  prevFlashcard,
  undoResponse,
  keybindsDisabled,
  setKeybindsDisabled,
  panelType,
  setPanelType,
  fetchSuspendedFlashcards,
  setShowSuspendedCards,
  suspendedCardCount,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const responseOptions = useSelector(
    (state: RootState) => state.auth.userInfo?.responseOptions
  );
  const flashcardTextSize = useSelector(
    (state: RootState) => state.auth.userInfo?.flashcardTextSize
  );
  const showIntervals = useSelector(
    (state: RootState) => state.auth.userInfo?.showIntervals
  );
  const [activeFlashcardVariant, setActiveFlashcardVariant] = useState(
    flashcard?.contents[flashcard.scheduling.difficultyIndex]
  );
  const [flashcardSettingsIsOpen, setFlashcardSettingsIsOpen] = useState(false);
  const [reportIsOpen, setReportIsOpen] = useState(false);
  const [speedDialIsOpen, setSpeedDialIsOpen] = useState(false);
  const [reportValue, setReportValue] = useState('');

  const flashcardRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();

  useHotkeys('1', () => keybinds('1'), { keyup: true });
  useHotkeys('2', () => keybinds('2'), { keyup: true });
  useHotkeys('3', () => keybinds('3'), { keyup: true });
  useHotkeys('4', () => keybinds('4'), { keyup: true });
  useHotkeys('r', () => keybinds('r'), { keyup: true });
  useHotkeys('l', () => keybinds('l'), { keyup: true });
  useHotkeys('e', () => keybinds('e'), { keyup: true });
  useHotkeys('comma', () => keybinds('comma'), { keyup: true });
  useHotkeys('period', () => keybinds('period'), { keyup: true });
  useHotkeys('escape', () => keybinds('esc'), { keyup: true });
  useHotkeys('backspace', () => keybinds('backspace'), { keyup: true });
  useHotkeys('delete', () => keybinds('backspace'), { keyup: true });

  // Gamepad
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const {
    lastButtonEvent,
    gamepadType,
    setScrollTarget,
    setIsSelectGamepadModalOpen,
  } = useGamepadContext();
  const lastHandledButtonEventRef =
    useRef<GamepadButtonEventWithLogical | null>(null);
  useEffect(() => {
    if (
      lastButtonEvent &&
      lastButtonEvent.pressed &&
      lastButtonEvent !== lastHandledButtonEventRef.current
    ) {
      lastHandledButtonEventRef.current = lastButtonEvent;

      const { universalButton } = lastButtonEvent;
      if (!universalButton) {
        return;
      }
      switch (universalButton) {
        case 'A':
          if (isFlipped) {
            handleResponse(2);
          } else {
            flipCard(true);
          }
          break;
        case 'B':
          if (responseOptions === 'four' && isFlipped) {
            handleResponse(3);
          }
          break;
        case 'X':
          if (isFlipped) {
            handleResponse(1);
          }
          break;
        case 'Y':
          if (responseOptions === 'four' && isFlipped) {
            handleResponse(0);
          }
          break;
        case 'RS':
          if (isFlipped) {
            flipCard(false);
          } else {
            flipCard(true);
          }
          break;
        case 'RB':
          setPanelType(PanelType.library);
          break;
        case 'LB':
          setPanelType(PanelType.explanation);
          break;
        default:
          break;
      }
    }
  }, [lastButtonEvent]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Don't prevent spacebar if focused on input or textarea
      if (
        (event.target as HTMLElement).tagName === 'TEXTAREA' ||
        (event.target as HTMLElement).tagName === 'INPUT'
      ) {
        return;
      }

      if (event.key === ' ' || event.code === 'Space') {
        event.preventDefault();
        scrollContainerRef.current?.scrollTo({ top: 0 });
      }
    };

    window.addEventListener('keydown', handleKeyDown, { passive: false });
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [scrollContainerRef]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const hammer = new Hammer(scrollContainerRef.current);
      hammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });

      hammer.on('swiperight', () => {
        undoResponse();
        hapticsImpactLight();
      });

      return () => {
        hammer.destroy();
      };
    }
  }, [scrollContainerRef.current, undoResponse]);

  useEffect(() => {
    setScrollTarget(scrollContainerRef.current);

    return () => {
      setScrollTarget(null);
    };
  }, [setScrollTarget]);

  useEffect(() => {
    setActiveFlashcardVariant(
      flashcard?.contents[flashcard.scheduling.difficultyIndex]
    );
    setReportIsOpen(false);
  }, [flashcard]);

  // Reset Report Value on Flashcard Change
  useEffect(() => {
    setReportValue('');
  }, [flashcard?.id]);

  // Close the Flashcard Menu on Flip
  useEffect(() => {
    setSpeedDialIsOpen(false);
  }, [isFlipped]);

  useEffect(() => {
    if (panelType !== PanelType.report) {
      setKeybindsDisabled(false);
    }
  }, [panelType]);

  if (!flashcard || !activeFlashcardVariant) {
    return null;
  }

  const keybinds = (key: string) => {
    if (key === 'esc') {
      setKeybindsDisabled(false);
      setReportIsOpen(false);
      setPanelType(PanelType.explanation);
    }
    if (keybindsDisabled) return;
    if (isFlipped && key === '1') {
      if (currentUser?.responseOptions === 'four') {
        handleResponse(0);
      } else {
        handleResponse(1);
      }
    }
    if (isFlipped && key === '2') {
      if (currentUser?.responseOptions === 'four') {
        handleResponse(1);
      } else {
        handleResponse(2);
      }
    }
    if (isFlipped && key === '3') {
      if (currentUser?.responseOptions === 'four') {
        handleResponse(2);
      }
    }
    if (isFlipped && key === '4') {
      if (currentUser?.responseOptions === 'four') {
        handleResponse(3);
      }
    }
    if (
      !isFlipped &&
      (key === '1' || key === '2' || key === '3' || key === '4')
    ) {
      flipCard(true);
    }
    if (key === 'r') {
      if (!isFlipped) {
        setReportIsOpen(true);
      } else {
        setPanelType(PanelType.report);
      }
    }
    if (key === 'e') {
      setPanelType(PanelType.explanation);
    }
    // if (key === 'comma' && currentUser?.role === 'admin') {
    //   setIsEditing(true);
    //   setKeybindsDisabled(true);
    // }
    if (key === 'backspace') {
      suspendCard();
    }
    if (key === 'l') {
      setPanelType(PanelType.library);
    }
  };

  // Mobile Shortcut Clicks
  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!Capacitor.isNativePlatform() || reportIsOpen) return;
    if (speedDialIsOpen) {
      setSpeedDialIsOpen(false);
      return;
    }

    // Ignore if desktop
    if (windowWidth > 560) return;

    if (isFlipped) {
      // Check if clicked element is a button or link
      const target = event.target as HTMLElement;
      if (
        target.tagName === 'BUTTON' ||
        target.tagName === 'A' ||
        target.closest('button') ||
        target.closest('a') ||
        target.closest('.article')
      ) {
        return;
      }

      const { clientX, currentTarget } = event;
      const { offsetWidth, offsetLeft } = currentTarget;

      // Left half = Wrong, Right half = Right
      if (currentUser?.responseOptions === 'two') {
        if (clientX - offsetLeft < offsetWidth / 2) {
          handleResponse(1); // Wrong
        } else {
          handleResponse(2); // Right
        }
        hapticsImpactLight();
      }
    } else {
      flipCard(true);
    }
  };

  const suspendCard = async () => {
    const response = await suspendFlashcard(flashcard.id);
    if (response) {
      dispatch(removeFromNew(flashcard.id));
      dispatch(removeFromDue(flashcard.id));
      dispatch(removeFromPast(flashcard.id));
      dispatch(incrementCompletedFlashcards());
      enqueueSnackbar('Flashcard suspended.', {
        autoHideDuration: 3000,
      });
      fetchSuspendedFlashcards();
    }
  };

  const isLinkedToday = (linkedAt: string | Date) => {
    if (typeof linkedAt === 'string') {
      linkedAt = new Date(linkedAt);
    }
    const today = new Date();
    return linkedAt.toDateString() === today.toDateString();
  };

  const renderLinkedTodayIcon = () => {
    if (flashcard.linkedAt && isLinkedToday(flashcard.linkedAt)) {
      return (
        <Tooltip
          title="This flashcard was assigned to reinforce a topic from your QBank session today."
          enterTouchDelay={0}
        >
          <div className="linked-today">
            <Link />
          </div>
        </Tooltip>
      );
    }
  };

  return (
    <>
      <div
        className={`flashcard${flashcardTextSize === 'smaller' ? ' text-size-smaller' : ''}${flashcardTextSize === 'larger' ? ' text-size-larger' : ''}`}
      >
        <div className="flashcard_main">
          <div className="flashcard_main_card" ref={flashcardRef}>
            <div
              className="flashcard_main_card_container"
              ref={scrollContainerRef}
              onClick={handleCardClick}
            >
              <div className="flashcard_main_card_container_content">
                {isFlipped
                  ? parse(activeFlashcardVariant.back)
                  : parse(activeFlashcardVariant.front)}
              </div>
              {!isFlipped && reportIsOpen && (
                <div className="flashcard_main_card_container_explanation p-t-0">
                  <div className="study-panel">
                    <div className="study-panel_body">
                      <ReportCard
                        setReportIsOpen={setReportIsOpen}
                        setPanelType={setPanelType}
                        flashcard={flashcard}
                        flashcardVariant={activeFlashcardVariant}
                        reportValue={reportValue}
                        setReportValue={setReportValue}
                      />
                    </div>
                  </div>
                </div>
              )}
              {isFlipped && (
                <div className="flashcard_main_card_container_explanation">
                  <StudyPanel
                    panelType={panelType}
                    setReportIsOpen={setReportIsOpen}
                    setPanelType={setPanelType}
                    phase={StudyPhase.flashcard}
                    targetArticleId={flashcard?.topics[0]?.articleId}
                    flashcard={flashcard}
                    flashcardVariant={activeFlashcardVariant}
                    reportValue={reportValue}
                    setReportValue={setReportValue}
                  >
                    <div>
                      {activeFlashcardVariant.explanation && (
                        <div className="explanation">
                          {activeFlashcardVariant.Image &&
                            activeFlashcardVariant.Image.length > 0 && (
                              <ImageDisplay
                                images={activeFlashcardVariant.Image}
                                displayMainImage={true}
                                activeContentId={
                                  activeFlashcardVariant.flashcardId
                                }
                              />
                            )}
                          {parse(activeFlashcardVariant.explanation || '')}
                        </div>
                      )}
                      {Array.isArray(activeFlashcardVariant?.references) &&
                        activeFlashcardVariant?.references.length > 0 &&
                        activeFlashcardVariant?.references[0] && (
                          <div className="references">
                            <ul
                              className={
                                activeFlashcardVariant?.references.length === 1
                                  ? 'one-ref'
                                  : ''
                              }
                            >
                              {activeFlashcardVariant.references.map(
                                (reference, index) => (
                                  <li key={index}>{parse(reference)}</li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                    </div>
                  </StudyPanel>
                </div>
              )}
              {currentUser?.role === 'admin' && (
                <div className="admin-toolbar">
                  <div className="qa-status">
                    {flashcard.isQAed ? (
                      <div className="is-qaed">Is QAed</div>
                    ) : (
                      <div className="is-not-qaed">Is Not QAed</div>
                    )}
                  </div>
                  <div className="spacer">|</div>
                  <button
                    className="uuid-display"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      copyToClipboard(activeFlashcardVariant.flashcardId);
                    }}
                  >
                    <Copy />
                    {activeFlashcardVariant?.flashcardId}
                  </button>
                </div>
              )}
              {renderLinkedTodayIcon()}
            </div>
            <FlashcardMenu
              speedDialIsOpen={speedDialIsOpen}
              setSpeedDialIsOpen={setSpeedDialIsOpen}
              undoResponse={undoResponse}
              setIsSelectGamepadModalOpen={setIsSelectGamepadModalOpen}
              setReportIsOpen={setReportIsOpen}
              setPanelType={setPanelType}
              isFlipped={isFlipped}
              suspendedCardCount={suspendedCardCount}
              setShowSuspendedCards={setShowSuspendedCards}
              setFlashcardSettingsIsOpen={setFlashcardSettingsIsOpen}
              gamepadType={gamepadType}
              suspendCard={suspendCard}
              prevFlashcard={prevFlashcard}
            />
            {flashcard.yield > 0 && (
              <Tooltip
                title={windowWidth > 900 ? 'Flashcard Yield' : ''}
                enterTouchDelay={0}
              >
                <div className="yield-score">
                  <span className="yield-score_score">
                    {flashcard.yield.toFixed(0)}
                  </span>
                  <CircularProgress
                    variant="determinate"
                    color="success"
                    value={100}
                    sx={{ height: '2rem !important', width: '2rem !important' }}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={`flashcard_actions ${currentUser?.responseOptions}`}>
          {!isFlipped && (
            <>
              <div className="rating-wrapper">
                <Tooltip
                  title={windowWidth > 900 ? 'Shortcut: Spacebar' : ''}
                  placement="top"
                  enterDelay={500}
                  disableTouchListener
                  arrow
                  enterTouchDelay={0}
                >
                  <button
                    className="button button--reveal button--glass"
                    onClick={() => {
                      flipCard(true);
                      hapticsImpactLight();
                    }}
                  >
                    Reveal
                    {gamepadType && (
                      <GamepadButtonIcon
                        controllerType={gamepadType}
                        universalButton={'A'}
                      />
                    )}
                  </button>
                </Tooltip>
              </div>
            </>
          )}
          {isFlipped && (
            <>
              {currentUser?.responseOptions === 'four' && (
                <div className="rating-wrapper">
                  <Tooltip
                    title={windowWidth > 900 ? 'Shortcut: 1' : ''}
                    placement="top"
                    enterDelay={500}
                    disableTouchListener
                    arrow
                    enterTouchDelay={0}
                  >
                    <button
                      className="button button--rating button--rating--again button--glass"
                      onClick={() => {
                        handleResponse(0);
                        hapticsImpactLight();
                      }}
                    >
                      Again
                      {gamepadType && (
                        <GamepadButtonIcon
                          controllerType={gamepadType}
                          universalButton={'Y'}
                        />
                      )}
                    </button>
                  </Tooltip>
                  {showIntervals && (
                    <div className="interval">{newScheduling[0]}</div>
                  )}
                </div>
              )}
              <div className="rating-wrapper">
                <Tooltip
                  title={
                    windowWidth > 900
                      ? 'Shortcut: ' +
                        (currentUser?.responseOptions === 'four' ? '2' : '1')
                      : ''
                  }
                  placement="top"
                  enterDelay={500}
                  disableTouchListener
                  arrow
                  enterTouchDelay={0}
                >
                  <button
                    className="button button--rating button--rating--wrong button--glass"
                    onClick={() => {
                      handleResponse(1);
                      hapticsImpactLight();
                    }}
                  >
                    Wrong
                    {gamepadType && (
                      <GamepadButtonIcon
                        controllerType={gamepadType}
                        universalButton={'X'}
                      />
                    )}
                  </button>
                </Tooltip>
                {showIntervals && (
                  <div className="interval">{newScheduling[1]}</div>
                )}
              </div>
              <div className="rating-wrapper">
                <Tooltip
                  title={
                    windowWidth > 900
                      ? 'Shortcut: ' +
                        (currentUser?.responseOptions === 'four'
                          ? '3 or Spacebar'
                          : '2 or Spacebar')
                      : ''
                  }
                  placement="top"
                  enterDelay={500}
                  disableTouchListener
                  arrow
                  enterTouchDelay={0}
                >
                  <button
                    className="button button--rating button--rating--right button--glass"
                    onClick={() => {
                      handleResponse(2);
                      hapticsImpactLight();
                    }}
                  >
                    Right
                    {gamepadType && (
                      <GamepadButtonIcon
                        controllerType={gamepadType}
                        universalButton={'A'}
                      />
                    )}
                  </button>
                </Tooltip>
                {showIntervals && (
                  <div className="interval">{newScheduling[2]}</div>
                )}
              </div>
              {currentUser?.responseOptions === 'four' && (
                <div className="rating-wrapper">
                  <Tooltip
                    title={windowWidth > 900 ? 'Shortcut: 4' : ''}
                    placement="top"
                    enterDelay={500}
                    disableTouchListener
                    arrow
                    enterTouchDelay={0}
                  >
                    <button
                      className="button button--rating button--rating--easy button--glass"
                      onClick={() => {
                        handleResponse(3);
                        hapticsImpactLight();
                      }}
                    >
                      Easy
                      {gamepadType && (
                        <GamepadButtonIcon
                          controllerType={gamepadType}
                          universalButton={'B'}
                        />
                      )}
                    </button>
                  </Tooltip>
                  {showIntervals && (
                    <div className="interval">{newScheduling[3]}</div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <CustomModal
          open={flashcardSettingsIsOpen}
          onClose={() => setFlashcardSettingsIsOpen(false)}
          name={'flashcard-preferences'}
          glass={true}
        >
          <div className="modal_header">
            <h2>Flashcard Preferences</h2>
          </div>
          <div className="modal_content">
            <FlashcardSettings />
          </div>
        </CustomModal>
      </div>
      {/* {currentUser?.role === 'admin' && (
        <EditFlashcard
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          flashcardId={flashcard.id}
          activeFlashcard={flashcard}
          activeFlashcardVariant={activeFlashcardVariant}
          setKeybindsDisabled={setKeybindsDisabled}
        />
      )} */}
    </>
  );
};

export default Flashcard;
