import { ArrowLeft } from 'react-feather';
import { hapticsImpactLight } from '../../../utils/haptics';
import OraLogo from '../../Global/OraLogo';
import Tooltip from '@mui/material/Tooltip';

interface FrameProps {
  classes?: string;
  currentFrame: number;
  index: number;
  showNext: boolean;
  showBack: boolean;
  advanceAllowed: boolean;
  transitionClass: string;
  nextFrame: () => void;
  prevFrame: () => void;
  jumpToFrame: (frame: number) => void;
  isStepExam: boolean;
}

const Frame: React.FC<FrameProps & { children: React.ReactNode }> = ({
  classes,
  children,
  currentFrame,
  index,
  showNext,
  showBack,
  advanceAllowed,
  transitionClass,
  nextFrame,
  prevFrame,
  jumpToFrame,
  isStepExam,
}) => {
  return (
    <div
      className={`onboarding_frame onboarding_frame--${index} ${classes ? classes : ''}`}
      style={currentFrame === index ? { display: 'flex' } : { display: 'none' }}
    >
      <div className="onboarding_frame_header">
        <OraLogo />
        {/* Don't show on loader */}
        {currentFrame !== 5 && (
          <div className="step-indicator">
            <div className={`step ${currentFrame === 0 ? 'active' : ''}`}></div>
            <div className={`step ${currentFrame === 1 ? 'active' : ''}`}></div>
            <div className={`step ${currentFrame === 2 ? 'active' : ''}`}></div>
            {isStepExam && (
              <div
                className={`step ${currentFrame === 3 ? 'active' : ''}`}
              ></div>
            )}
            <div className={`step ${currentFrame === 4 ? 'active' : ''}`}></div>
          </div>
        )}
      </div>
      <div className={`onboarding_frame_content ${transitionClass}`}>
        <div className="onboarding_frame_content_body">{children}</div>
        <div className="onboarding_frame_content_actions">
          {showBack && (
            <button
              className="button button--prev"
              onClick={() => {
                if (!isStepExam && currentFrame === 4) {
                  jumpToFrame(2);
                } else {
                  prevFrame();
                }
                hapticsImpactLight();
              }}
            >
              <ArrowLeft />
            </button>
          )}
          {showNext && (
            <Tooltip
              title={
                currentFrame === 3 && !advanceAllowed
                  ? 'You must have at least one active topic.'
                  : ''
              }
            >
              <span style={{ marginLeft: 'auto' }}>
                <button
                  disabled={!advanceAllowed}
                  onClick={() => {
                    if (!isStepExam && currentFrame === 2) {
                      jumpToFrame(4);
                    } else {
                      nextFrame();
                    }
                    hapticsImpactLight();
                  }}
                  className="button button--glass button--next"
                >
                  {currentFrame === 4 ? 'Finish' : 'Next'}
                </button>
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default Frame;
