import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import Page from '../../components/Page';
import ArticleBrowser from '../../components/Library/ArticleBrowser';
import ArticlePage from '../../components/Library/ArticlePage';
import { hapticsImpactLight } from '../../utils/haptics';
import MSHeader from '../../components/NonAuth/MSHeader';
import MSFooter from '../../components/NonAuth/MSFooter';
import { Bookmark } from '../../types/Bookmark';
import { getBookmarks, removeBookmark } from '../../services/bookmarks';
import { useSnackbar } from 'notistack';
import CustomModal from '../../components/Global/CustomModal';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet';
import { getIncorrectVignetteResponses } from '../../services/vignetteResponse';
import { getTodayArticles } from '../../services/studySession';
import { getTopYieldTopicArticles } from '../../services/topics';
import { renderLoadingFrames } from '../../utils/renderLoadingFrames';
import { ExamNameToYieldKeyMap } from '../../types/Article';

interface TopicArticle {
  name: string;
  articleId: string;
  path?: string;
}

interface LibraryProps {}

const Library: React.FC<LibraryProps> = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary) || null;
  const [activeArticleId, setActiveArticleId] = useState<string | null>(null);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [userBookmarks, setUserBookmarks] = useState<Bookmark[]>([]);
  const [libraryConsentIsOpen, setLibraryConsentIsOpen] = useState(false);
  const [loadingLibrary, setLoadingLibrary] = useState(true);
  const [showAllRecentlyMissedArticles, setShowAllRecentlyMissedArticles] =
    useState(false);
  const [showAllTodayArticles, setShowAllTodayArticles] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [incorrectVignetteResponses, setIncorrectVignetteResponses] = useState<
    TopicArticle[] | []
  >([]);
  const [todaysSessionArticles, setTodaysSessionArticles] = useState<
    TopicArticle[] | []
  >([]);
  const [topYieldTopicArticles, setTopYieldTopicArticles] = useState<
    TopicArticle[] | []
  >([]);

  const { articleId } = useParams<{ articleId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    if (pathParts.length === 1) {
      setActiveArticleId(null);
      const contentWindow = document.querySelector('.page_content');
      if (contentWindow) {
        contentWindow.scrollTo(0, 0);
      }
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    loadBookmarks();
    renderConsentModal();

    const libraryTab = document.getElementById('library-tab');
    if (libraryTab) {
      libraryTab.addEventListener('click', resetLibraryView);
    }

    return () => {
      if (libraryTab) {
        libraryTab.removeEventListener('click', resetLibraryView);
      }
    };
  }, []);

  // Handle window resize to update windowWidth state
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (todaySession && currentUser) {
      loadDynamicLibraryData();
    }
  }, [todaySession, currentUser]);

  // Load Article by Parameter
  useEffect(() => {
    if (articleId) {
      setActiveArticleId(articleId);
      const contentWindow = document.querySelector('.page_content');
      if (contentWindow) {
        contentWindow.scrollTo(0, 0);
      }
      window.scrollTo(0, 0);
    }
  }, [articleId]);

  const resetLibraryView = () => {
    setActiveArticleId(null);
    navigate('/library');
    const contentWindow = document.querySelector('.page_content');
    if (contentWindow) {
      contentWindow.scrollTo(0, 0);
    }
  };

  const loadDynamicLibraryData = async () => {
    try {
      await Promise.all([
        fetchIncorrectVignetteResponses(),
        fetchTodaysSessionArticles(),
        fetchTopYieldTopicArticles(),
      ]);
    } catch (error) {
      console.error('Error loading library data:', error);
    } finally {
      setLoadingLibrary(false);
    }
  };

  const fetchIncorrectVignetteResponses = async () => {
    if (todaySession) {
      const incorrectResponses = await getIncorrectVignetteResponses(
        todaySession.id
      );
      if (incorrectResponses) {
        setIncorrectVignetteResponses(incorrectResponses.topics);
      }
    }
  };

  const fetchTodaysSessionArticles = async () => {
    if (todaySession) {
      const todaysSessionArticles = await getTodayArticles(todaySession.id);
      if (todaysSessionArticles) {
        setTodaysSessionArticles(todaysSessionArticles);
      }
    }
  };

  const fetchTopYieldTopicArticles = async () => {
    if (!primaryExam) return;
    const examYieldKey =
      ExamNameToYieldKeyMap[
        primaryExam?.name as keyof typeof ExamNameToYieldKeyMap
      ];
    const topYieldTopicArticles = await getTopYieldTopicArticles(examYieldKey);
    if (topYieldTopicArticles) {
      setTopYieldTopicArticles(topYieldTopicArticles);
    }
  };

  const loadBookmarks = async () => {
    if (currentUser) {
      const response = await getBookmarks(currentUser.id);
      if (response) {
        setUserBookmarks(response);
      }
    }
  };

  const renderConsentModal = async () => {
    const libraryConsent = localStorage.getItem('library-consent');
    if (!libraryConsent) {
      setTimeout(() => {
        setLibraryConsentIsOpen(true);
      }, 500);
    }
  };

  const handleBookmarkDelete = async (articleId: string) => {
    if (currentUser) {
      const response = await removeBookmark(currentUser?.id, articleId);
      if (response) {
        setUserBookmarks(response);
      }
    }
    enqueueSnackbar('Bookmark removed.', {
      autoHideDuration: 3000,
    });
  };

  // Function to render ArticleBrowser, using a portal for window widths less than 900px
  const renderArticleBrowser = () => {
    const browser = (
      <div
        className={`library_body_browser ${mobileMenuIsOpen ? 'is-open' : ''}`}
        onClick={() => {
          setMobileMenuIsOpen(false);
        }}
      >
        <div className="window" onClick={(e) => e.stopPropagation()}>
          <ArticleBrowser
            activeArticleId={activeArticleId}
            setActiveArticleId={setActiveArticleId}
            setMobileMenuIsOpen={setMobileMenuIsOpen}
            bookmarks={userBookmarks}
            removeBookmark={handleBookmarkDelete}
          />
        </div>
      </div>
    );

    if (windowWidth > 1200) {
      return browser;
    } else {
      if (!activeArticleId) {
        return browser;
      } else {
        const portalRoot = document.body;
        return createPortal(browser, portalRoot);
      }
    }
  };

  const renderIncorrectVignetteResponses = () => {
    if (showAllRecentlyMissedArticles) {
      return (
        <>
          {incorrectVignetteResponses.map((article) => (
            <button
              key={article.articleId}
              className="button button--glass"
              onClick={() => {
                setActiveArticleId(article.articleId);
                navigate(`/library/${article.articleId}`);
                const contentWindow = document.querySelector('.page_content');
                if (contentWindow) {
                  contentWindow.scrollTo(0, 0);
                }
              }}
            >
              <div className="topic-name">{article.name}</div>
            </button>
          ))}
          {incorrectVignetteResponses.length > 9 && (
            <button
              className="button button--link button--toggle"
              onClick={() => setShowAllRecentlyMissedArticles(false)}
            >
              Show Less
            </button>
          )}
        </>
      );
    } else {
      return (
        <>
          {incorrectVignetteResponses.slice(0, 9).map((article) => (
            <button
              key={article.articleId}
              className="button button--glass"
              onClick={() => {
                setActiveArticleId(article.articleId);
                navigate(`/library/${article.articleId}`);
                const contentWindow = document.querySelector('.page_content');
                if (contentWindow) {
                  contentWindow.scrollTo(0, 0);
                }
              }}
            >
              <div className="topic-name">{article.name}</div>
            </button>
          ))}
          {incorrectVignetteResponses.length > 9 && (
            <button
              className="button button--link button--toggle"
              onClick={() => setShowAllRecentlyMissedArticles(true)}
            >
              Show All
            </button>
          )}
        </>
      );
    }
  };

  const renderTodayArticles = () => {
    if (showAllTodayArticles) {
      return (
        <>
          {todaysSessionArticles.map((article) => (
            <button
              key={article.articleId}
              className="button button--glass"
              onClick={() => {
                setActiveArticleId(article.articleId);
                navigate(`/library/${article.articleId}`);
                hapticsImpactLight();
                const contentWindow = document.querySelector('.page_content');
                if (contentWindow) {
                  contentWindow.scrollTo(0, 0);
                }
              }}
            >
              <div className="topic-name">{article.name}</div>
            </button>
          ))}
          {todaysSessionArticles.length > 9 && (
            <button
              className="button button--link button--toggle"
              onClick={() => setShowAllTodayArticles(false)}
            >
              Show Less
            </button>
          )}
        </>
      );
    } else {
      return (
        <>
          {todaysSessionArticles.slice(0, 9).map((article) => (
            <button
              key={article.articleId}
              className="button button--glass"
              onClick={() => {
                setActiveArticleId(article.articleId);
                navigate(`/library/${article.articleId}`);
                hapticsImpactLight();
                const contentWindow = document.querySelector('.page_content');
                if (contentWindow) {
                  contentWindow.scrollTo(0, 0);
                }
              }}
            >
              <div className="topic-name">{article.name}</div>
            </button>
          ))}
          {todaysSessionArticles.length > 9 && (
            <button
              className="button button--link button--toggle"
              onClick={() => setShowAllTodayArticles(true)}
            >
              Show All
            </button>
          )}
        </>
      );
    }
  };

  const renderLibrary = () => {
    return (
      <div
        className={`library${
          activeArticleId ? ' is-article-page' : ' is-landing-page'
        }${!currentUser ? ' is-non-auth' : ''}`}
      >
        <div
          className={`library_body ${
            currentUser && !activeArticleId && userBookmarks.length > 0
              ? 'two-col'
              : ''
          }`}
        >
          <h1 className="library-title-h1">Library</h1>
          {renderArticleBrowser()}
          {activeArticleId && (
            <ArticlePage
              articleId={activeArticleId}
              isStudyPanel={false}
              bookmarks={userBookmarks}
              setBookmarks={setUserBookmarks}
              removeBookmark={handleBookmarkDelete}
              setMobileMenuIsOpen={setMobileMenuIsOpen}
            />
          )}
          {!activeArticleId && (
            <>
              <div className="library_body_listings">
                <h1 className="m-t-0 m-b-2">Library</h1>
                {loadingLibrary && renderLoadingFrames(false)}
                {!loadingLibrary && (
                  <>
                    {incorrectVignetteResponses &&
                      incorrectVignetteResponses.length > 0 && (
                        <div className="library_body_listings_set">
                          <h2 className="m-t-0">Recently Missed Topics</h2>
                          <div className="library_body_listings_set_articles">
                            {renderIncorrectVignetteResponses()}
                          </div>
                        </div>
                      )}
                    {todaysSessionArticles &&
                      todaysSessionArticles.length > 0 && (
                        <div className="library_body_listings_set">
                          <h2>Related to Today's Session</h2>
                          <div className="library_body_listings_set_articles">
                            {renderTodayArticles()}
                          </div>
                        </div>
                      )}
                    {topYieldTopicArticles &&
                      topYieldTopicArticles.length > 0 && (
                        <div className="library_body_listings_set">
                          <h2>
                            Top 20 Topics by Yield for{' '}
                            {primaryExam?.name === 'USMLE Step 1' ||
                            primaryExam?.name === 'USMLE Step 2'
                              ? primaryExam?.name
                              : 'the ' + primaryExam?.name}
                          </h2>
                          <div className="library_body_listings_set_articles">
                            {topYieldTopicArticles.map((article) => (
                              <button
                                key={article.articleId}
                                className="button button--glass"
                                onClick={() => {
                                  setActiveArticleId(article.articleId);
                                  navigate(`/library/${article.articleId}`);
                                  hapticsImpactLight();
                                  const contentWindow =
                                    document.querySelector('.page_content');
                                  if (contentWindow) {
                                    contentWindow.scrollTo(0, 0);
                                  }
                                }}
                              >
                                <div className="topic-name">{article.name}</div>
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {!currentUser && !activeArticleId && (
        <Helmet>
          <title>Library | Ora AI</title>
          <meta
            name="description"
            content="Explore a meticulously curated repository offering an extensive collection of medical education content, designed to support your learning journey."
          />
          <meta property="og:title" content="Library | Ora AI" />
        </Helmet>
      )}
      {!currentUser && (
        <div className="non-auth-library">
          <MSHeader />
          <div
            className={`non-auth-library_body ${
              activeArticleId ? 'is-article-page' : 'is-landing-page'
            }`}
          >
            {renderLibrary()}
          </div>
          <MSFooter />
          <CustomModal
            open={libraryConsentIsOpen}
            onClose={() => setLibraryConsentIsOpen(false)}
            name={'library-consent'}
            glass={true}
            blurs={true}
            disableBackdropClick={true}
          >
            <div className="modal_content">
              <h2 className="m-t-0">Welcome to the Ora Library</h2>
              <p style={{ fontSize: '1.125rem' }}>
                Over 1700 high-yield articles for Step &amp; Shelf prep.
              </p>
              <button
                className="button button--glass"
                onClick={() => {
                  hapticsImpactLight();
                  localStorage.setItem('library-consent', 'true');
                  setLibraryConsentIsOpen(false);
                }}
                style={{ width: '100%' }}
                tabIndex={1}
              >
                Accept &amp; Explore
              </button>
              <p className="acceptance-text">
                By accessing the Ora library, you agree to the{' '}
                <a href="/terms" tabIndex={0}>
                  Terms
                </a>{' '}
                and{' '}
                <a href="/privacy" tabIndex={0}>
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </CustomModal>
        </div>
      )}
      {currentUser && (
        <Page type={activeArticleId ? 'library-article' : ''}>
          {renderLibrary()}
        </Page>
      )}
    </>
  );
};

export default Library;
