import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TriangleRight from '../../assets/icons/triangle-right.svg';
import TriangleDown from '../../assets/icons/triangle-down.svg';
import Frame from '../../components/Onboarding/Frame';
import { ArrowRight, Check } from 'react-feather';
import CircularProgress from '@mui/material/CircularProgress';
import moment, { Moment } from 'moment';
import { setUserInfo } from '../../slices/auth/authSlice';
import { fetchExams } from '../../services/exams';
import { addOrUpdateUserExam } from '../../services/users';
import { RootState } from '../../store/store';
import { Exam } from '../../types/User';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { hapticsImpactLight } from '../../utils/haptics';
import { supportedTimezones } from '../../utils/supportedTimezones';
import { updateCurrentUserInfo } from '../../services/auth';
import {
  defaultFlashcards,
  defaultVignettes,
  getComprehensiveFlashcards,
  getComprehensiveQbank,
  getHighYieldFlashcards,
  getHighYieldQbank,
  getStandardFlashcards,
  getStandardQbank,
} from '../../utils/contentQuantityUtils';
import { useRefreshStudySessionAndContent } from '../../utils/refreshStudySession';
import { calcDefaultExamDate } from '../../helpers/userHelpers';
import { createAssents, VERSIONS } from '../../services/assent';
import { AssentType } from '../../types/Assent';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { handleCheckout } from '../../services/subscriptions';
import { Capacitor } from '@capacitor/core';
import GoalSelection from '../../components/Global/GoalSelection';
import { TopicTreeNode } from '../../types/TopicCustomization';
import OraSwitch from '../../components/CustomMUI/Switch';
import {
  fetchTopicTree,
  updateTopicTree,
} from '../../services/topicCustomization';
import { setActiveFilters } from '../../slices/activeTopics/activeTopicsSlice';
import { flattenTopicTree } from '../../utils/flattenTopicTree';
import { cloneDeep } from 'lodash';
import { updateUserSelfAssessment } from '../../services/users';

interface OnboardingProps {}

const Onboarding: React.FC<OnboardingProps> = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const parentOnboarding = useRef<HTMLDivElement>(null);
  const [onboardingFrame, setOnboardingFrame] = useState(0);
  const [availableExams, setAvailableExams] = useState<Exam[] | []>([]);
  const [primaryExam, setPrimaryExam] = useState<Exam | undefined>(undefined);
  const [examSelection, setExamSelection] = useState('');
  const [examDate, setExamDate] = useState<Moment | null>(null);
  const [userSelectedGoal, setUserSelectedGoal] = useState<
    'High Yield' | 'Standard' | 'Comprehensive' | ''
  >('');
  const [selectedQBankCount, setSelectedQBankCount] = useState(0);
  const [selectedFlashcardCount, setSelectedFlashcardCount] = useState(0);
  const [hideFlashLoader, setHideFlashLoader] = useState(false);
  const [noDateChosen, setNoDateChosen] = useState(false);
  const [isStepExam, setIsStepExam] = useState(false);
  const [availableTopics, setAvailableTopics] = useState<TopicTreeNode[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<TopicTreeNode[]>([]);
  const { refreshStudySessionAndContent } = useRefreshStudySessionAndContent();
  const [strengths, setStrengths] = useState('');
  const [weaknesses, setWeaknesses] = useState('');

  const inputDiv1 = useRef<HTMLDivElement>(null);
  const inputDiv2 = useRef<HTMLDivElement>(null);
  const hrRef = useRef<HTMLHRElement>(null);
  const autoSection = useRef<HTMLDivElement>(null);
  const auto1 = useRef<HTMLDivElement>(null);
  const auto2 = useRef<HTMLDivElement>(null);
  const auto3 = useRef<HTMLDivElement>(null);

  // ONBOARDING SCREENS
  // 0 - Welcome, Choose Exam
  // 1 - Choose Exam
  // 2 - Choose Goal
  // 3 - Choose Topics
  // 4 - Strengths/Weaknesses
  // 5 - Loader

  const [selectedTimezone, setSelectedTimezone] = useState('UTC');
  const [transitionClasses, setTransitionClasses] = useState([
    'reveal-frame',
    '',
    '',
    '',
    '',
    'reveal-frame',
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser && currentUser.buyIntent && !Capacitor.isNativePlatform()) {
      if (currentUser.buyIntent === 'GROUP') {
        const pendingGroupId = sessionStorage.getItem('ora-pending-group-id');
        sessionStorage.removeItem('ora-pending-group-id');

        if (pendingGroupId) {
          navigate(`/group/${pendingGroupId}?claim=true`);
        } else {
          navigate('/group?claim=true');
        }
      } else {
        handleCheckout(currentUser.buyIntent);
      }
      clearBuyIntent();
    } else if (currentUser && currentUser.ambassador) {
      createInitialAssents();
      handleAmbassadorOnboarding();
    } else if (currentUser && currentUser.isOnboarded) {
      navigate('/');
    } else {
      setHideFlashLoader(true);
      createInitialAssents();
      getAvailableExams();
      getUserTimezone();
    }
  }, []);

  const clearBuyIntent = async () => {
    const updateUser = await updateCurrentUserInfo({
      buyIntent: null,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  useEffect(() => {
    const exam = availableExams.find((exam) => exam.id === examSelection);
    if (exam) {
      setPrimaryExam(exam);
      setNoDateChosen(false);
      setUserSelectedGoal('');
      const isStep =
        exam.name === 'USMLE Step 1' || exam.name === 'USMLE Step 2';
      setIsStepExam(isStep);
      if (isStep) {
        loadExamTopics(exam.id);
      } else {
        setSelectedTopics([]);
        setAvailableTopics([]);
      }
    }
  }, [examSelection]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.isOnboarded) {
        navigate('/');
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (userSelectedGoal !== '' && primaryExam?.name) {
      if (userSelectedGoal === 'High Yield') {
        setSelectedQBankCount(getHighYieldQbank(primaryExam?.name));
        setSelectedFlashcardCount(getHighYieldFlashcards(primaryExam?.name));
      } else if (userSelectedGoal === 'Standard') {
        setSelectedQBankCount(getStandardQbank(primaryExam?.name));
        setSelectedFlashcardCount(getStandardFlashcards(primaryExam?.name));
      } else if (userSelectedGoal === 'Comprehensive') {
        setSelectedQBankCount(getComprehensiveQbank(primaryExam?.name));
        setSelectedFlashcardCount(
          getComprehensiveFlashcards(primaryExam?.name)
        );
      }
    }
  }, [userSelectedGoal, primaryExam]);

  useEffect(() => {
    if (onboardingFrame === 5) {
      (async () => {
        // Wait for both loading UI and user preparation to finish
        await Promise.all([triggerLoadingUI(), prepareUserForStudying()]);
        // Finish final loading UI
        await wait(1000);
        auto3.current?.classList.add('done');
        finishOnboarding();
      })();
    }
  }, [onboardingFrame]);

  const getAvailableExams = async () => {
    const response = await fetchExams();
    if (response) {
      setAvailableExams(response);
    }
  };

  const triggerLoadingUI = async () => {
    await wait(500);
    inputDiv1.current?.classList.add('reveal');

    await wait(1000);
    inputDiv2.current?.classList.add('reveal');

    await wait(1000);
    hrRef.current?.classList.add('reveal');

    await wait(1000);
    autoSection.current?.classList.add('reveal');

    // Finish loading curriculum UI
    await wait(2000);
    auto1.current?.classList.add('done');

    // Finish generating study session
    await wait(2000);
    auto2.current?.classList.add('done');

    await wait(1000);
  };

  const wait = (ms: number | undefined) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const loadExamTopics = async (examId: string) => {
    const response = await fetchTopicTree(examId);
    if (response) {
      const topicsWithActiveState = response.map((topic: TopicTreeNode) => ({
        ...topic,
        active: true,
      }));

      setAvailableTopics(topicsWithActiveState);
      setSelectedTopics(topicsWithActiveState);
    }
  };

  const createInitialAssents = async () => {
    if (!currentUser) return;
    const assents = [
      {
        type: AssentType.tos,
        version: VERSIONS.tos,
        dateAccepted: moment().toISOString(),
        location: 'signup',
      },
      {
        type: AssentType.privacyPolicy,
        version: VERSIONS.privacyPolicy,
        dateAccepted: moment().toISOString(),
        location: 'signup',
      },
    ];
    await createAssents(currentUser?.id, assents);
  };

  const handleAmbassadorOnboarding = async () => {
    if (!currentUser) return;
    // First fetch available exams
    const exams = await fetchExams();

    if (!exams || exams.length === 0) {
      console.error('Failed to fetch exams for peer advocate onboarding');
      return;
    }

    const timezones = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userTimezone = supportedTimezones[timezones]
      ? supportedTimezones[timezones]
      : supportedTimezones['UTC'];

    // Find the USMLE Step 1 exam
    const usmleStep1Exam = exams.find(
      (exam: Exam) => exam.name === 'USMLE Step 1'
    );

    if (usmleStep1Exam) {
      const examDateString = moment(
        calcDefaultExamDate(usmleStep1Exam?.name)
      ).toISOString();
      try {
        const response = await addOrUpdateUserExam({
          userId: currentUser.id,
          examId: usmleStep1Exam.id,
          examDate: examDateString,
          selectedFlashcardCount: defaultFlashcards(
            availableExams.find((exam) => exam.id === usmleStep1Exam.id)?.name
          ),
          selectedVignetteCount: defaultVignettes(
            availableExams.find((exam) => exam.id === usmleStep1Exam.id)?.name
          ),
          isUsingFlashcards: true,
          isUsingQbank: true,
          hasCompletedRealExam: false,
          realExamScore: null,
          ignoredExamDate: false,
          clearTodaySession: undefined,
        });

        const updatedUser = { ...currentUser };
        updatedUser.exams = response;
        updatedUser.timezone = userTimezone;
        dispatch(setUserInfo(updatedUser));

        // Refresh study session and content
        await refreshStudySessionAndContent().then(() => {
          finishOnboarding();
        });
      } catch (error) {
        console.error('Error while preparing user study experience:', error);
      }
    } else {
      console.error('USMLE Step 1 exam not found');
    }
  };

  const prepareUserForStudying = async () => {
    if (!currentUser) return;

    let examDateString = null;
    if (examDate && !noDateChosen) {
      examDateString = examDate.toISOString();
    } else {
      examDateString = moment(
        calcDefaultExamDate(primaryExam?.name)
      ).toISOString();
    }

    try {
      const examParams = {
        userId: currentUser.id,
        examId: examSelection,
        examDate: examDateString,
        selectedFlashcardCount: selectedFlashcardCount,
        selectedVignetteCount: selectedQBankCount,
        isUsingFlashcards: true,
        isUsingQbank: true,
        hasCompletedRealExam: false,
        realExamScore: null,
        ignoredExamDate: false,
        clearTodaySession: undefined,
      };

      const response = await addOrUpdateUserExam(examParams);

      // Save strengths and weaknesses if entered
      if (strengths.trim() || weaknesses.trim()) {
        await updateUserSelfAssessment(
          currentUser.id,
          strengths.trim(),
          weaknesses.trim()
        );
      }

      const updatedUser = { ...currentUser };
      updatedUser.exams = response;
      updatedUser.timezone = selectedTimezone;
      dispatch(setUserInfo(updatedUser));

      if (isStepExam) {
        const flatTopics = flattenTopicTree(selectedTopics);
        await updateTopicTree(flatTopics, true);
      }
      await refreshStudySessionAndContent();

      if (isStepExam) {
        dispatch(
          setActiveFilters({
            allTopicsActive: true,
            allTopicsInactive: false,
            topicTree: selectedTopics,
          })
        );
      }
    } catch (error) {
      console.error('Error while preparing user study experience:', error);
    }
  };

  const finishOnboarding = async () => {
    parentOnboarding.current?.classList.add('done');
    const updateUser = await updateCurrentUserInfo({
      isOnboarded: true,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
    navigate('/');
  };

  const nextFrame = () => {
    const targetFrame = onboardingFrame + 1;
    setTransitionClasses((prev) => {
      const newClasses = [...prev];
      newClasses[onboardingFrame] = 'slide-out';
      return newClasses;
    });
    setOnboardingFrame(targetFrame);
    setTimeout(() => {
      setTransitionClasses((prev) => {
        const newClasses = [...prev];
        newClasses[targetFrame] = 'reveal-frame';
        return newClasses;
      });
    });
  };

  const jumpToFrame = (frame: number) => {
    setTransitionClasses((prev) => {
      const newClasses = [...prev];
      newClasses[onboardingFrame] = 'slide-out';
      return newClasses;
    });
    setOnboardingFrame(frame);
    setTimeout(() => {
      setTransitionClasses((prev) => {
        const newClasses = [...prev];
        newClasses[frame] = 'reveal-frame';
        return newClasses;
      });
    });
  };

  const prevFrame = () => {
    const targetFrame = onboardingFrame - 1;
    setTransitionClasses((prev) => {
      const newClasses = [...prev];
      newClasses[onboardingFrame] = '';
      return newClasses;
    });
    setOnboardingFrame(targetFrame);
    setTimeout(() => {
      setTransitionClasses((prev) => {
        const newClasses = [...prev];
        newClasses[targetFrame] = 'reveal-frame';
        return newClasses;
      });
    });
  };

  const prioritizeExams = () => {
    const priorityExams = availableExams.filter(
      (exam) => exam.name === 'USMLE Step 1' || exam.name === 'USMLE Step 2'
    );
    const otherExams = availableExams.filter(
      (exam) => exam.name !== 'USMLE Step 1' && exam.name !== 'USMLE Step 2'
    );
    priorityExams.sort((a, b) => a.name.localeCompare(b.name));
    otherExams.sort((a, b) => a.name.localeCompare(b.name));
    return { priorityExams, otherExams };
  };

  const sortedExams = prioritizeExams();

  const getUserTimezone = () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setSelectedTimezone(
      supportedTimezones[userTimezone]
        ? supportedTimezones[userTimezone]
        : supportedTimezones['UTC']
    );
  };

  const dateIsInPast = (date: Moment | null) => {
    if (!date) return false;
    return date.isBefore(moment().startOf('day'));
  };

  const handleTopicChange = (topicId: string, active: boolean) => {
    // Create a copy of selectedTopics
    const updatedTopics = cloneDeep(selectedTopics);

    // Find the topic in updatedTopics and update its active state
    const topic = updatedTopics.find((topic) => topic.id === topicId);
    if (topic) {
      topic.active = active;
      // Update all children recursively
      const updateChildrenActive = (children: TopicTreeNode[]) => {
        if (!children) return;
        children.forEach((child) => {
          child.active = active;
          if (child.children) {
            updateChildrenActive(child.children);
          }
        });
      };

      if (topic.children) {
        updateChildrenActive(topic.children);
      }
    }

    setSelectedTopics(updatedTopics);
  };

  const handleEnableAllTopics = () => {
    // Set all topics to active
    const allTopicsActive = cloneDeep(availableTopics).map((topic) => {
      // Set the top-level topic to active
      topic.active = true;

      // Set all children to active recursively
      const updateChildrenActive = (children: TopicTreeNode[]) => {
        if (!children) return;
        children.forEach((child) => {
          child.active = true;
          if (child.children) {
            updateChildrenActive(child.children);
          }
        });
      };

      if (topic.children) {
        updateChildrenActive(topic.children);
      }

      return topic;
    });

    setSelectedTopics(allTopicsActive);
  };

  const handleDisableAllTopics = () => {
    // Set all topics to inactive
    const allTopicsInactive = cloneDeep(availableTopics).map((topic) => {
      // Set the top-level topic to inactive
      topic.active = false;

      // Set all children to inactive recursively
      const updateChildrenActive = (children: TopicTreeNode[]) => {
        if (!children) return;
        children.forEach((child) => {
          child.active = false;
          if (child.children) {
            updateChildrenActive(child.children);
          }
        });
      };

      if (topic.children) {
        updateChildrenActive(topic.children);
      }

      return topic;
    });

    setSelectedTopics(allTopicsInactive);
  };

  return (
    <>
      <div
        className={`onboarding ${examSelection ? 'exam-selected' : ''}`}
        ref={parentOnboarding}
      >
        {/* 0 - Exam Selection */}
        <Frame
          currentFrame={onboardingFrame}
          index={0}
          showNext={examSelection !== ''}
          showBack={false}
          advanceAllowed={examSelection !== ''}
          transitionClass={transitionClasses[0]}
          nextFrame={nextFrame}
          prevFrame={prevFrame}
          jumpToFrame={jumpToFrame}
          isStepExam={isStepExam}
        >
          <h1>What exam are you studying for?</h1>
          <p>You can add additional exams later.</p>
          <form className="exam-selections">
            {sortedExams.priorityExams.map((exam: Exam) => {
              return (
                <label htmlFor={exam.id} key={exam.id}>
                  <input
                    id={exam.id}
                    type="radio"
                    name="exam"
                    value={exam.id}
                    onChange={() => setExamSelection(exam.id)}
                    onClick={() => {
                      nextFrame();
                      hapticsImpactLight();
                    }}
                  />
                  <div className="option">
                    <span>{exam.name}</span>
                    <Check />
                  </div>
                </label>
              );
            })}
            <Accordion>
              <AccordionSummary
                aria-controls="exam-content"
                id="exam-header"
                sx={{ padding: '0', minHeight: '0 !important' }}
                onClick={() => {
                  hapticsImpactLight();
                }}
              >
                <label>
                  <div
                    className="option"
                    style={{ justifyContent: 'flex-start' }}
                  >
                    <img
                      className="expand-icon expand-icon--closed"
                      src={TriangleRight}
                      alt=""
                    />
                    <img
                      className="expand-icon expand-icon--open"
                      src={TriangleDown}
                      alt=""
                    />
                    <span>Shelf Exams</span>
                  </div>
                </label>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: '0' }}>
                {sortedExams.otherExams.map((exam: Exam) => {
                  return (
                    <label htmlFor={exam.id} key={exam.id}>
                      <input
                        id={exam.id}
                        type="radio"
                        name="exam"
                        value={exam.id}
                        onChange={() => setExamSelection(exam.id)}
                        onClick={() => {
                          nextFrame();
                          hapticsImpactLight();
                        }}
                      />
                      <div className="option">
                        <span>{exam.name}</span>
                        <Check />
                      </div>
                    </label>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          </form>
        </Frame>
        {/* 1 - Exam Date */}
        <Frame
          currentFrame={onboardingFrame}
          index={1}
          showNext={true}
          showBack={true}
          advanceAllowed={
            !dateIsInPast(examDate) && (noDateChosen || examDate !== null)
          }
          transitionClass={transitionClasses[1]}
          nextFrame={nextFrame}
          prevFrame={prevFrame}
          jumpToFrame={jumpToFrame}
          isStepExam={isStepExam}
        >
          <h1>Choose your exam date</h1>
          <p>
            Ora will schedule content based on your exam date and goal. You can
            change this later.
          </p>
          <DatePicker
            value={examDate}
            onChange={(newValue) => {
              setExamDate(newValue);
              setNoDateChosen(false);
            }}
            minDate={moment.utc().startOf('day')}
            maxDate={moment.utc().add(7, 'years')}
            openTo="month"
            views={['year', 'month', 'day']}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => {
                  setExamDate(null);
                },
              },
            }}
          />
          <button
            className={`button button--skip ${noDateChosen ? 'active' : ''}`}
            style={{ width: '100%', textAlign: 'left' }}
            onClick={() => {
              hapticsImpactLight();
              setExamDate(moment(calcDefaultExamDate(primaryExam?.name)));
            }}
          >
            Use Default Date
          </button>
        </Frame>
        {/* 2 - Exam Goal */}
        <Frame
          currentFrame={onboardingFrame}
          index={2}
          showNext={true}
          showBack={true}
          advanceAllowed={userSelectedGoal !== ''}
          transitionClass={transitionClasses[2]}
          nextFrame={nextFrame}
          prevFrame={prevFrame}
          jumpToFrame={jumpToFrame}
          isStepExam={isStepExam}
        >
          <h1>Choose your workload</h1>
          <p>
            Your workload determines the baseline amount of content you will
            cover.
          </p>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}
          >
            <GoalSelection
              selectedGoal={userSelectedGoal}
              setSelectedGoal={setUserSelectedGoal}
              isUsingQbank={true}
              isUsingFlashcards={true}
              primaryExamName={primaryExam?.name}
              showCheckmark={true}
            />
          </div>
        </Frame>
        {/* 3 - Topics */}
        <Frame
          currentFrame={onboardingFrame}
          index={3}
          showNext={true}
          showBack={true}
          advanceAllowed={selectedTopics.some((topic) => topic.active)}
          transitionClass={transitionClasses[3]}
          nextFrame={nextFrame}
          prevFrame={prevFrame}
          jumpToFrame={jumpToFrame}
          isStepExam={isStepExam}
        >
          <h1>Choose your topics</h1>
          <p className="m-b-2">
            Select the topics you want included in your sessions. You can change
            these later.
          </p>
          <div className="topic-selections">
            <div className="topic-selections_actions">
              <label>All Topics</label>
              <OraSwitch
                checked={selectedTopics.every((topic) => topic.active)}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleEnableAllTopics();
                  } else {
                    handleDisableAllTopics();
                  }
                }}
              />
            </div>
            <div className="topic-selections_topics">
              {availableTopics.map((topic) => {
                // Find the topic in selectedTopics and check its active property
                const selectedTopic = selectedTopics.find(
                  (selectedTopic) => selectedTopic.id === topic.id
                );
                const isActive = selectedTopic ? selectedTopic.active : false;

                return (
                  <div className="topic-wrapper" key={topic.id}>
                    <div className="topic-name">{topic.name}</div>
                    <OraSwitch
                      checked={isActive}
                      onChange={(e) =>
                        handleTopicChange(topic.id, e.target.checked)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Frame>
        {/* 4 - Strengths/Weaknesses */}
        <Frame
          currentFrame={onboardingFrame}
          index={4}
          showNext={true}
          showBack={true}
          advanceAllowed={true}
          transitionClass={transitionClasses[4]}
          nextFrame={nextFrame}
          prevFrame={prevFrame}
          jumpToFrame={jumpToFrame}
          isStepExam={isStepExam}
        >
          <h1>Tell Ora about your strengths and weaknesses</h1>
          <p>
            Ora instantly personalizes your study plan based on your input. This
            is optional&mdash;Ora adapts as you study!
          </p>
          <div className="strengths-weaknesses">
            <div>
              <label>My Strengths</label>
              <textarea
                placeholder="For example: I'm really good at cardio and pulm, especially pathophys and Dx. I also feel pretty solid in nephrology, except acid-base disorders (how in the world do you calculate a delta gap)"
                className="textarea"
                value={strengths}
                onChange={(e) => setStrengths(e.target.value)}
                maxLength={1000}
              ></textarea>
            </div>
            <div>
              <label>My Weaknesses</label>
              <textarea
                placeholder="For example: I mostly struggle with infectious disease Tx, histo slide interpretation, quality improvement, and biostats"
                className="textarea"
                value={weaknesses}
                onChange={(e) => setWeaknesses(e.target.value)}
                maxLength={1000}
              ></textarea>
            </div>
          </div>
        </Frame>
        {/* 5 - Loader */}
        <Frame
          currentFrame={onboardingFrame}
          index={5}
          showNext={false}
          showBack={false}
          advanceAllowed={false}
          transitionClass={transitionClasses[5]}
          isStepExam={isStepExam}
          nextFrame={nextFrame}
          prevFrame={prevFrame}
          jumpToFrame={jumpToFrame}
        >
          <h2>Setting things up...</h2>
          <div className="inputs">
            <div ref={inputDiv1}>
              <ArrowRight />
              {availableExams.find((exam) => exam.id === examSelection)?.name}
            </div>
            <div ref={inputDiv2}>
              <ArrowRight />
              {examDate && moment.isMoment(examDate)
                ? examDate.format('MM/DD/YYYY') + ' exam date'
                : 'No exam date'}
            </div>
          </div>
          <hr ref={hrRef} />
          <div className="automatons" ref={autoSection}>
            <div className="automaton" ref={auto1}>
              <CircularProgress
                sx={{
                  color: 'var(--type-body-inverse)',
                  height: '1rem !important',
                  width: '1rem !important',
                }}
              />
              <Check className="check" />
              {strengths.trim() === '' && weaknesses.trim() === ''
                ? 'Initializing your study profile'
                : 'Analyzing your strengths and weaknesses'}
            </div>
            <div className="automaton" ref={auto2}>
              <CircularProgress
                sx={{
                  color: 'var(--type-body-inverse)',
                  height: '1rem !important',
                  width: '1rem !important',
                }}
              />
              <Check className="check" />
              Calculating your workload
            </div>
            <div className="automaton" ref={auto3}>
              <CircularProgress
                sx={{
                  color: 'var(--type-body-inverse)',
                  height: '1rem !important',
                  width: '1rem !important',
                }}
              />
              <Check className="check" />
              Generating your study plan
            </div>
          </div>
        </Frame>
        <div className={`flash-loader ${hideFlashLoader ? 'hide' : ''}`}></div>
      </div>
    </>
  );
};

export default Onboarding;
