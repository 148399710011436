// src/hooks/useFeatureTours.ts
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import moment from 'moment';

import { User } from '../../../../types/User';
import { updateCurrentUserInfo } from '../../../../services/auth';
import { setUserInfo } from '../../../../slices/auth/authSlice';
import { Block } from '../../../../types/Study';

interface UseFeatureToursProps {
  isDemo: boolean | undefined;
  isStart: boolean;
  currentUser: User | null | undefined;
  nonAuthTourStep1Ref: React.RefObject<HTMLElement>;
  nonAuthTourStep2Ref: React.RefObject<HTMLElement>;
  windowWidth: number;
  activeBlock: Block | null;
}

interface UseFeatureToursReturn {
  // QBank Tour State
  showQBankTour: boolean;
  isQBankTourRunning: boolean;
  currentQBankTourStepIndex: number;
  qbankStepSelectors: string[];

  // QBank Tour Actions
  handleSeenQBankTour: () => Promise<void>;
  setIsQBankTourRunning: React.Dispatch<React.SetStateAction<boolean>>;

  // Non-Auth Tour State
  isNonAuthTourRunning: boolean;
  currentNonAuthStepIndex: number;
  nonAuthStepTargets: (React.RefObject<HTMLElement> | string)[];

  // Non-Auth Tour Actions
  setCurrentNonAuthStepIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsNonAuthTourRunning: React.Dispatch<React.SetStateAction<boolean>>;
  signalDemoFirstAnswerSubmitted: () => void;
  // Modal State
  createAccountModalIsOpen: boolean;
  setCreateAccountModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allDemoQuestionsSubmitted: boolean;

  // Common Tour Handlers
  handleStepChange: (type: 'non-auth' | 'qbank', newIndex: number) => void;
  handleTourEnd: (type: 'non-auth' | 'qbank') => void;
}

const useFeatureTours = ({
  isDemo,
  isStart,
  currentUser,
  nonAuthTourStep1Ref,
  nonAuthTourStep2Ref,
  windowWidth,
  activeBlock,
}: UseFeatureToursProps): UseFeatureToursReturn => {
  const dispatch = useDispatch();

  // --- QBank Feature Tour State ---
  const [showQBankTour, setShowQBankTour] = useState(false);
  const [currentQBankTourStepIndex, setCurrentQBankTourStepIndex] = useState(0);
  const [isQBankTourRunning, setIsQBankTourRunning] = useState(false);
  const qbankStepSelectors = [
    '#left-panel', // Step 0: Highlight question area
    '#left-panel', // Step 1: Highlight answer options / submit button (reuse selector, text changes)
    '.study-panel .button--flashcards', // Step 2: Highlight linked flashcards button
  ];

  // --- Non-Auth Feature Tour State ---
  const [createAccountModalIsOpen, setCreateAccountModalIsOpen] =
    useState(false);
  const [allDemoQuestionsSubmitted, setAllDemoQuestionsSubmitted] =
    useState(false);
  const [currentNonAuthStepIndex, setCurrentNonAuthStepIndex] = useState(0);
  const [isNonAuthTourRunning, setIsNonAuthTourRunning] = useState(false);
  const nonAuthStepSelectors = ['.button--flashcards']; // Selector for step 3
  // Combine refs and selectors for the tour library
  const nonAuthStepTargets = [
    nonAuthTourStep1Ref, // Step 0: Highlight Vignette (passed ref)
    nonAuthTourStep2Ref, // Step 1: Highlight Answer Block (passed ref)
    nonAuthStepSelectors[0], // Step 2: Highlight Flashcards Button (selector)
  ];

  // --- Tour Management Logic ---

  const handleStartTour = (type: 'non-auth' | 'qbank') => {
    if (type === 'non-auth') {
      setCurrentNonAuthStepIndex(0);
      setIsNonAuthTourRunning(true);
    } else {
      setCurrentQBankTourStepIndex(0);
      setIsQBankTourRunning(true);
    }
  };

  const handlePauseTour = (type: 'non-auth' | 'qbank') => {
    if (type === 'non-auth') {
      setIsNonAuthTourRunning(false);
    } else {
      setIsQBankTourRunning(false);
    }
  };

  const handleTourEnd = (type: 'non-auth' | 'qbank') => {
    if (type === 'non-auth') {
      setIsNonAuthTourRunning(false);
      setCurrentNonAuthStepIndex(0);
      Cookies.set('completedDemoTour', 'true', { expires: 365 });
    } else {
      setIsQBankTourRunning(false);
      setCurrentQBankTourStepIndex(0);
    }
  };

  const handleStepChange = (type: 'non-auth' | 'qbank', newIndex: number) => {
    if (type === 'non-auth') {
      setCurrentNonAuthStepIndex(newIndex);
    } else {
      setCurrentQBankTourStepIndex(newIndex);
    }
  };

  const handleSeenQBankTour = async () => {
    if (!currentUser) return;
    if (currentUser?.onboardingTips?.['qbank-tour']) return;
    const updateUser = await updateCurrentUserInfo({
      onboardingTips: {
        ...currentUser?.onboardingTips,
        'qbank-tour': true,
      },
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const signalDemoFirstAnswerSubmitted = () => {
    setCurrentNonAuthStepIndex(1);
    setTimeout(() => {
      setIsNonAuthTourRunning(true);
    }, 250);
  };

  // --- Effects ---

  // Handle Showing QBank Tour
  useEffect(() => {
    let qbankTourStartDate = moment.utc('2025-03-20');
    qbankTourStartDate = qbankTourStartDate.add(3, 'hours');

    // Exam Tour
    if (currentUser?.createdAt) {
      const userCreatedAt = moment.utc(currentUser.createdAt);
      if (userCreatedAt.isAfter(qbankTourStartDate)) {
        // Prevent showing tour to existing users
        if (currentUser?.onboardingTips) {
          if (!currentUser.onboardingTips['qbank-tour']) {
            setTimeout(() => {
              setShowQBankTour(true);
              handleStartTour('qbank');
            }, 1750);
          }
        }
      }
    }
  }, [currentUser, windowWidth]);

  // Start Feature Tour if Non Auth Demo
  useEffect(() => {
    if (isDemo) {
      handleStartTour('non-auth');
    }
  }, [isStart, isDemo]);

  // Non Auth Tour Step Management
  useEffect(() => {
    if (currentNonAuthStepIndex === 1) {
      handlePauseTour('non-auth');
    }
    if (currentNonAuthStepIndex === 2) {
      const linkedFlashcards = document.querySelector(
        '.button--flashcards'
      ) as HTMLButtonElement;
      if (linkedFlashcards) {
        linkedFlashcards.click();
      }
    }
  }, [currentNonAuthStepIndex]);

  // Effect: Close Non-Auth tour if Account Creation Modal opens
  useEffect(() => {
    if (createAccountModalIsOpen) {
      handleTourEnd('non-auth');
    }
  }, [createAccountModalIsOpen]);

  // Demo Mode Only: Show Create Account Modal After 3 Questions
  useEffect(() => {
    if (!activeBlock || !isDemo) return;
    if (
      isDemo &&
      Object.keys(activeBlock.answers).length === 3 &&
      Object.values(activeBlock.answers).every((answer) => answer.submitted)
    ) {
      setCreateAccountModalIsOpen(true);
      setAllDemoQuestionsSubmitted(true);
    }
  }, [activeBlock?.answers, isDemo]);

  // --- Return Values ---
  return {
    // QBank Tour State
    showQBankTour,
    isQBankTourRunning,
    currentQBankTourStepIndex,
    qbankStepSelectors,

    // QBank Tour Actions
    handleSeenQBankTour,
    setIsQBankTourRunning,

    // Non-Auth Tour State
    isNonAuthTourRunning,
    currentNonAuthStepIndex,
    nonAuthStepTargets,

    // Non-Auth Tour Actions
    setCurrentNonAuthStepIndex,
    setIsNonAuthTourRunning,
    signalDemoFirstAnswerSubmitted,

    // Modal State
    createAccountModalIsOpen,
    setCreateAccountModalIsOpen,
    allDemoQuestionsSubmitted,

    // Common Tour Handlers
    handleStepChange,
    handleTourEnd,
  };
};

export default useFeatureTours;
