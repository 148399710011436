import { enqueueSnackbar } from 'notistack';
import { hapticsImpactLight } from '../../../utils/haptics';
import CustomModal from '../CustomModal';
import { Info, Link } from 'react-feather';
import { User } from '../../../types/User';
import { useEffect, useState } from 'react';
import { getReferrals } from '../../../services/referralService';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';

interface AmbassadorModalProps {
  currentUser: User;
  ambassadorModalIsOpen: boolean;
  setAmbassadorModalIsOpen: (isOpen: boolean) => void;
}

const AmbassadorModal: React.FC<AmbassadorModalProps> = ({
  currentUser,
  ambassadorModalIsOpen,
  setAmbassadorModalIsOpen,
}) => {
  const [referralCount, setReferralCount] = useState<number>(0);
  const [subscribedCount, setSubscribedCount] = useState<number>(0);
  const [referralDaysRemaining, setReferralDaysRemaining] = useState<number>(0);

  useEffect(() => {
    if (currentUser) {
      fetchReferrals(currentUser.id);
      computeReferralDaysRemaining();
    }
  }, [currentUser]);

  const fetchReferrals = async (userId: string) => {
    const referrals = await getReferrals(userId);
    setReferralCount(referrals.referrals);
    setSubscribedCount(referrals.subscribed);
  };

  const computeReferralDaysRemaining = () => {
    const createdAt = currentUser?.ambassadorStartDate
      ? moment(currentUser.ambassadorStartDate)
      : moment(currentUser.createdAt);
    const oneMonthFromCreation = createdAt.add(36, 'days');
    const daysRemaining = oneMonthFromCreation.diff(moment(), 'days');
    setReferralDaysRemaining(daysRemaining);
  };

  return (
    <CustomModal
      open={ambassadorModalIsOpen}
      onClose={() => {
        setAmbassadorModalIsOpen(false);
        document.body.classList.remove('viewing-ambassador-tour');
      }}
      name={'ambassador'}
      glass={true}
      blurs={true}
    >
      <div className="modal_content">
        <div className="ambassador">
          <div className="ambassador_top">
            <div className="earnings">
              <h2>
                <span>$</span>
                {referralCount * 10}
              </h2>
              <div className="earnings_label">Earned</div>
              <div className="earnings_count">
                {referralCount} {referralCount === 1 ? 'Sign Up' : 'Sign Ups'} |{' '}
                {subscribedCount}{' '}
                {subscribedCount === 1 ? 'Subscribed' : 'Subscribed'}
              </div>
              <Tooltip title="$10 per sign up (limit $1,000), $100 per subscriber (limit $9,000)">
                <div className="earnings_time">
                  {referralDaysRemaining <= 0 ? 0 : referralDaysRemaining}{' '}
                  {referralDaysRemaining === 1 ? 'Day' : 'Days'} Left | $10,000
                  Limit{' '}
                  <Info
                    style={{
                      height: '.75rem',
                      width: '.75rem',
                      transform: 'translateY(2px)',
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="share-link">
            <div className="share-link_label">My Referral Link:</div>
            <div className="share-link_link-container">
              <div
                className="share-link_personal-link"
                role="button"
                onClick={() => {
                  hapticsImpactLight();
                  navigator.clipboard.writeText(
                    `https://app.oraai.com/?refId=${currentUser?.referralId}`
                  );
                  enqueueSnackbar('Copied to clipboard!');
                }}
              >
                https://app.oraai.com/?refId={currentUser?.referralId}
              </div>
              <button
                tabIndex={0}
                className="button button--glass button--copy"
                onClick={() => {
                  hapticsImpactLight();
                  navigator.clipboard.writeText(
                    `https://app.oraai.com/?refId=${currentUser?.referralId}`
                  );
                  enqueueSnackbar('Copied to clipboard!');
                }}
              >
                <Link />
                <span>Copy</span>
              </button>
              <button
                className="button button--glass"
                style={{ width: '100%' }}
                onClick={() => {
                  hapticsImpactLight();
                  window.Intercom('showNewMessage');
                }}
              >
                Share Feedback
              </button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default AmbassadorModal;
