export const Home = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5603 10.1887L13.0603 2.68875C12.779 2.40766 12.3977 2.24976 12 2.24976C11.6024 2.24976 11.221 2.40766 10.9397 2.68875L3.4397 10.1887C3.29973 10.3277 3.18878 10.493 3.1133 10.6752C3.03782 10.8574 2.99931 11.0528 3.00001 11.25V20.25C3.00001 20.4489 3.07903 20.6397 3.21968 20.7803C3.36033 20.921 3.5511 21 3.75001 21H9.75001C9.94892 21 10.1397 20.921 10.2803 20.7803C10.421 20.6397 10.5 20.4489 10.5 20.25V15H13.5V20.25C13.5 20.4489 13.579 20.6397 13.7197 20.7803C13.8603 20.921 14.0511 21 14.25 21H20.25C20.4489 21 20.6397 20.921 20.7803 20.7803C20.921 20.6397 21 20.4489 21 20.25V11.25C21.0007 11.0528 20.9622 10.8574 20.8867 10.6752C20.8112 10.493 20.7003 10.3277 20.5603 10.1887ZM19.5 19.5H15V14.25C15 14.0511 14.921 13.8603 14.7803 13.7197C14.6397 13.579 14.4489 13.5 14.25 13.5H9.75001C9.5511 13.5 9.36033 13.579 9.21968 13.7197C9.07903 13.8603 9.00001 14.0511 9.00001 14.25V19.5H4.50001V11.25L12 3.75L19.5 11.25V19.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
