// src/components/Exam/FeatureTours/FeatureTour.tsx
import React, { useState, useEffect, useMemo } from 'react';
import Joyride, { CallBackProps, STATUS, Step, Placement } from 'react-joyride';
import { Styles } from 'react-joyride';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { joyrideStyles } from '../../../../utils/joyrideStyles';

export interface TourStepConfig {
  /** The target element (CSS selector string or React ref). */
  target: string | React.RefObject<HTMLElement>;
  /** The main content JSX for the step tooltip. */
  content: React.ReactNode;
  /** The footer content JSX (usually buttons) for the step tooltip. */
  footerContent: React.ReactNode;
  /** Placement on desktop screens. */
  placementDesktop: Placement;
  /** Placement on mobile screens (< 900px). */
  placementMobile: Placement;
  /** Optional function to run *before* the step is displayed. */
  preStepAction?: () => void;
  /** Override default Joyride step options if needed. */
  joyrideStepProps?: Partial<Step>;
}

interface FeatureTourProps {
  /** Array defining the configuration for each step in the tour. */
  stepsConfig: TourStepConfig[];
  /** Whether the tour should be active. */
  run: boolean;
  /** The index of the current step. */
  stepIndex: number;
  /** Callback function when the step changes internally or needs to change. */
  onStepChange: (newIndex: number) => void;
  /** Callback function when the tour ends (finished or skipped). */
  onTourEnd?: () => void;
  /** Optional override for Joyride styles. */
  styles?: Partial<Styles>;
  /** Optional override/addition for root Joyride props. */
  joyrideProps?: Partial<
    Omit<
      React.ComponentProps<typeof Joyride>,
      'steps' | 'run' | 'stepIndex' | 'callback' | 'styles'
    >
  >;
  /** Unique key to force re-evaluation of steps if config changes dynamically */
  configKey?: string | number;
}

const FeatureTour: React.FC<FeatureTourProps> = ({
  stepsConfig,
  run,
  stepIndex,
  onTourEnd,
  styles = joyrideStyles,
  joyrideProps = {},
  configKey,
}) => {
  const [formattedSteps, setFormattedSteps] = useState<Step[]>([]);
  const [ready, setReady] = useState(false);
  const windowWidth = useWindowWidth();

  // Memoize stepsConfig to prevent unnecessary recalculations if parent re-renders
  const memoizedStepsConfig = useMemo(
    () => stepsConfig,
    [stepsConfig, configKey]
  );

  useEffect(() => {
    setReady(false);

    const checkTargets = async () => {
      let allReady = true;
      for (const config of memoizedStepsConfig) {
        if (typeof config.target === 'string') {
          continue;
        } else if (!config.target.current) {
          allReady = false;
          break;
        }
      }

      if (allReady) {
        // --- Format Steps for Joyride ---
        const newSteps: Step[] = memoizedStepsConfig.map((config, index) => {
          const stepTarget =
            typeof config.target === 'string'
              ? config.target
              : config.target.current!;

          return {
            target: stepTarget,
            placement:
              windowWidth > 900
                ? config.placementDesktop
                : config.placementMobile,
            disableBeacon: true,
            // --- Standard Content Wrapper ---
            content: (
              <div className="tour-wrapper">
                <div className="tour-wrapper_header">
                  <div className="step-number">
                    {index + 1} <span>of {memoizedStepsConfig.length}</span>
                  </div>
                  <div className="steps">
                    {[...Array(memoizedStepsConfig.length)].map((_, i) => (
                      <div
                        key={i}
                        className={`steps_step ${i === stepIndex ? 'active' : ''}`}
                      ></div>
                    ))}
                  </div>
                </div>
                {/* Render specific step content */}
                <div className="tour-wrapper_content">{config.content}</div>
                {/* Standard visual elements */}
                <div className="blur-1"></div>
                <div className="blur-2"></div>
                {/* Render specific step footer */}
                <div className="tour-wrapper_footer">
                  {config.footerContent}
                </div>
              </div>
            ),
            ...(config.joyrideStepProps || {}),
          };
        });
        setFormattedSteps(newSteps);
        setReady(true);
      } else {
        const timer = setTimeout(checkTargets, 100);
        return () => clearTimeout(timer);
      }
    };

    checkTargets();
  }, [memoizedStepsConfig, windowWidth, stepIndex]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { index, type, status, step } = data;

    if (type === 'step:before') {
      const currentConfig = memoizedStepsConfig[index];
      if (currentConfig?.preStepAction) {
        currentConfig.preStepAction();
      }
      requestAnimationFrame(() => {
        const floater = document.querySelector('.__floater') as HTMLDivElement;
        if (floater) {
          // Check if the step content itself might hide it via a button click
          if (
            !step.content?.toString().includes("floater.style.display = 'none'")
          ) {
            floater.style.display = 'block';
          }
        }
      });
    }

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      if (onTourEnd) {
        onTourEnd();
      }
      setReady(false);
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <Joyride
      steps={formattedSteps}
      run={run}
      stepIndex={stepIndex}
      callback={handleJoyrideCallback}
      styles={styles}
      continuous
      hideCloseButton={true}
      hideBackButton={true}
      disableScrolling={true}
      disableOverlay
      {...joyrideProps}
    />
  );
};

export default FeatureTour;
