import { useEffect, useState, useRef } from 'react';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import {
  fetchAllExamSessions,
  fetchAllExamSessionsAndBlocks,
} from '../../../services/studySession';
import moment from 'moment';
import { StudySession, StudySessionWithBlocks } from '../../../types/Study';
import { fetchStudyTopicsForDate } from '../../../services/study';
import { Topic } from '../../../types/Dashboard';
import { createPortal } from 'react-dom';
import { ArrowRight, Info } from 'react-feather';
import { CircularProgress, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

interface StudyDay {
  completedFlashcardCount: number;
  date: string;
  flashcardCount: number;
  isComplete: boolean;
  isPast: boolean;
  isToday: boolean;
  isFuture: boolean;
  isSnoozed: boolean;
  isStarted: boolean;
  vignetteCorrectCount: number;
  vignetteCount: number;
  vignetteIncorrectCount: number;
  vignetteOmittedCount: number;
}

interface PriorityDisplayProps {
  todayPosition: number;
  isTopicFlyoutVisible: boolean;
  setIsTopicFlyoutVisible: (isVisible: boolean) => void;
}

const PriorityDisplay: React.FC<PriorityDisplayProps> = ({
  todayPosition,
  isTopicFlyoutVisible,
  setIsTopicFlyoutVisible,
}) => {
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const activeTopics = useSelector((state: RootState) => state.activeTopics);
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const [filledStudyDays, setFilledStudyDays] = useState<StudyDay[]>([]);
  const [fetchedTopics, setFetchedTopics] = useState<Map<string, Topic[]>>(
    new Map()
  );
  const [pastSessionsWithBlocks, setPastSessionsWithBlocks] = useState<
    StudySessionWithBlocks[]
  >([]);
  const [flyoutPosition, setFlyoutPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [mouseLeaveTimer, setMouseLeaveTimer] = useState<NodeJS.Timeout | null>(
    null
  );
  const [activeDay, setActiveDay] = useState<string | null>(null);
  const [isFlyoutHovered, setIsFlyoutHovered] = useState(false);
  const [isDayHovered, setIsDayHovered] = useState(false);
  const [isFetchingTopics, setIsFetchingTopics] = useState(false);

  const priorityDisplayRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement | null>(null);
  const todayRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  // Add window blur handler
  useEffect(() => {
    const handleWindowBlur = () => {
      setIsTopicFlyoutVisible(false);
      setActiveDay(null);
      setFlyoutPosition(null);
    };

    window.addEventListener('blur', handleWindowBlur);
    return () => {
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, []);

  // Add mousemove handler to check if mouse is outside both timeline and flyout
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      // Only run if flyout is visible
      if (!isTopicFlyoutVisible) return;

      const priorityDisplay = priorityDisplayRef.current;
      const flyout = document.querySelector('.topics-flyout');

      if (priorityDisplay && flyout) {
        const priorityDisplayRect = priorityDisplay.getBoundingClientRect();
        const flyoutRect = flyout.getBoundingClientRect();

        // Check if mouse is outside both the priority display and flyout
        const isOutsidePriorityDisplay =
          event.clientX < priorityDisplayRect.left ||
          event.clientX > priorityDisplayRect.right ||
          event.clientY < priorityDisplayRect.top ||
          event.clientY > priorityDisplayRect.bottom;

        const isOutsideFlyout =
          event.clientX < flyoutRect.left ||
          event.clientX > flyoutRect.right ||
          event.clientY < flyoutRect.top ||
          event.clientY > flyoutRect.bottom;

        if (isOutsidePriorityDisplay && isOutsideFlyout) {
          setIsTopicFlyoutVisible(false);
          setActiveDay(null);
          setFlyoutPosition(null);
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isTopicFlyoutVisible]);

  useEffect(() => {
    if (primaryExam && primaryExam.isUsingQbank) {
      createAndFillPastStudyDays();
      setFetchedTopics(new Map());
      fetchPastStudySessionsWithBlocks();
    }
  }, [primaryExam, activeTopics, todaySession]);

  const fetchPastStudySessionsWithBlocks = async () => {
    if (primaryExam) {
      fetchAllExamSessionsAndBlocks(primaryExam.id).then((response) => {
        setPastSessionsWithBlocks(response);
      });
    }
  };

  // Scroll the chart to the today element
  useEffect(() => {
    if (todayRef.current && chartRef.current) {
      const chartElement = chartRef.current;
      const chartWidth = chartElement.scrollWidth - chartElement.clientWidth;
      const scrollPosition = (chartWidth * todayPosition) / 100 + 4;
      chartElement.scrollLeft = scrollPosition;
    }
  }, [filledStudyDays, todayPosition, window.innerWidth]);

  const createAndFillPastStudyDays = async () => {
    if (primaryExam) {
      try {
        const response = await fetchAllExamSessions(primaryExam.id);
        if (response) {
          const existingStudyDays: StudyDay[] = response.map(
            (session: StudySession) => {
              const formattedDate = moment(session.date).format('YYYY-MM-DD');
              const { isPast, isToday, isFuture } =
                getDateStatus(formattedDate);
              return {
                ...session,
                date: formattedDate,
                isPast,
                isToday,
                isFuture,
              };
            }
          );
          const completeStudyDays = fillMissingStudyDays(
            existingStudyDays,
            moment(primaryExam.examDate).format('YYYY-MM-DD')
          );
          setFilledStudyDays(completeStudyDays);
        }
      } catch (error) {
        console.error('Error fetching exam sessions:', error);
      }
    }
  };

  const getAllDates = (startDate: string, endDate: string): string[] => {
    const dates: string[] = [];
    let currentDate = moment(startDate);
    const lastDate = moment(endDate);
    while (currentDate.isSameOrBefore(lastDate, 'day')) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }
    return dates;
  };

  const getDateStatus = (
    dateString: string
  ): { isPast: boolean; isToday: boolean; isFuture: boolean } => {
    const date = moment(dateString, 'YYYY-MM-DD');
    const today = moment().startOf('day');
    const isToday = date.isSame(today, 'day');
    const isPast = date.isBefore(today, 'day');
    const isFuture = date.isAfter(today, 'day');
    return { isPast, isToday, isFuture };
  };

  const fillMissingStudyDays = (
    existingStudyDays: StudyDay[],
    examDate: string
  ): StudyDay[] => {
    if (existingStudyDays.length === 0) {
      console.warn(
        'No existing study days found. Filling all days with default values.'
      );
    }
    const existingDates = existingStudyDays.map((sd) => sd.date);
    const startDate =
      existingStudyDays.length > 0
        ? moment.min(existingStudyDays.map((sd) => moment(sd.date)))
        : moment(examDate).startOf('day');
    const endDate = moment(examDate).subtract(1, 'day').startOf('day');
    const allDates = getAllDates(
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD')
    );
    const existingDateSet = new Set(existingDates);
    const defaultStudyDay: Omit<
      StudyDay,
      'date' | 'isPast' | 'isToday' | 'isFuture'
    > = {
      completedFlashcardCount: 0,
      flashcardCount: 0,
      isComplete: false,
      isSnoozed: false,
      isStarted: false,
      vignetteCorrectCount: 0,
      vignetteCount: 0,
      vignetteIncorrectCount: 0,
      vignetteOmittedCount: 0,
    };
    const filledStudyDays: StudyDay[] = allDates.map((date) => {
      const { isPast, isToday, isFuture } = getDateStatus(date);
      if (existingDateSet.has(date)) {
        const existingDay = existingStudyDays.find((sd) => sd.date === date);
        if (existingDay) {
          return {
            ...existingDay,
            isPast,
            isToday,
            isFuture,
          };
        }
      }
      return {
        date,
        ...defaultStudyDay,
        isPast,
        isToday,
        isFuture,
      };
    });
    return filledStudyDays;
  };

  const getDayTopics = async (date: string) => {
    setIsFetchingTopics(true);
    // if not in the map, fetch the topics
    if (!fetchedTopics.has(date)) {
      try {
        const response = await fetchStudyTopicsForDate(date);
        // Store the topics in the map
        setFetchedTopics((prev) => new Map(prev).set(date, response.topics));
      } catch (error) {
        console.error('Error fetching topics for date:', date, error);
      }
    }
    setIsFetchingTopics(false);
  };

  const handleDayMouseEnter = (
    date: string,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    setIsDayHovered(true);
    setActiveDay(date);

    // Get topics for this day if we don't have them yet
    if (!fetchedTopics.has(date)) {
      getDayTopics(date);
    }

    // Position the flyout
    const element = event.currentTarget;
    const rect = element.getBoundingClientRect();
    setFlyoutPosition({
      top: rect.top - 10,
      left: rect.left + rect.width / 2,
    });

    setIsTopicFlyoutVisible(true);

    if (mouseLeaveTimer) {
      clearTimeout(mouseLeaveTimer);
      setMouseLeaveTimer(null);
    }
  };

  const handleDayMouseLeave = () => {
    setIsDayHovered(false);
    const timer = setTimeout(() => {
      if (!isDayHovered && !isFlyoutHovered) {
        setIsTopicFlyoutVisible(false);

        // Clean up after animation completes
        setTimeout(() => {
          if (!isDayHovered && !isFlyoutHovered) {
            setActiveDay(null);
            setFlyoutPosition(null);
          }
        }, 300);
      }
    }, 100);

    setMouseLeaveTimer(timer);
  };

  const handleFlyoutMouseEnter = () => {
    setIsFlyoutHovered(true);
    if (mouseLeaveTimer) {
      clearTimeout(mouseLeaveTimer);
      setMouseLeaveTimer(null);
    }
  };

  const handleFlyoutMouseLeave = () => {
    setIsFlyoutHovered(false);
    if (!isDayHovered) {
      setIsTopicFlyoutVisible(false);

      // Clean up after animation completes
      setTimeout(() => {
        if (!isDayHovered && !isFlyoutHovered) {
          setActiveDay(null);
          setFlyoutPosition(null);
        }
      }, 300);
    }
  };

  const renderTopicFlyout = (activeDay: string) => {
    if (
      moment(activeDay).startOf('day').isSameOrAfter(moment().startOf('day'))
    ) {
      const studyDay = filledStudyDays.find((day) => day.date === activeDay);
      if (!studyDay) return null;

      return (
        <div
          className="topics-flyout"
          style={{
            top: `${flyoutPosition?.top}px`,
            left: `${flyoutPosition?.left}px`,
            opacity: isTopicFlyoutVisible ? 1 : 0,
            pointerEvents: isTopicFlyoutVisible ? 'auto' : 'none',
          }}
          onMouseEnter={handleFlyoutMouseEnter}
          onMouseLeave={handleFlyoutMouseLeave}
        >
          <div className="topics-flyout_inner">
            {isFetchingTopics ? (
              <CircularProgress
                sx={{
                  color: 'var(--type-body-inverse)',
                  height: '1rem !important',
                  width: '1rem !important',
                }}
              />
            ) : fetchedTopics.has(activeDay) &&
              fetchedTopics.get(activeDay)!.length > 0 ? (
              <>
                <Tooltip
                  title={
                    activeDay === moment().format('YYYY-MM-DD')
                      ? "Topics in today's session, chosen by yield and performance."
                      : 'Topics may change based on performance.'
                  }
                >
                  <h3>
                    {activeDay === moment().format('YYYY-MM-DD')
                      ? "Today's Topics"
                      : `Topics on ${moment(activeDay).format('MMMM D, YYYY')}`}
                    <Info />
                  </h3>
                </Tooltip>
                <div className="topics-flyout_inner_topics">
                  {fetchedTopics.get(activeDay)?.map((topic) => (
                    <button
                      key={'topic-flyout--' + topic.id}
                      className="topics-flyout_inner_topics_topic"
                      onClick={() => {
                        navigate(`/library/${topic.articleId}`);
                      }}
                    >
                      {topic.name}
                      <ArrowRight />
                    </button>
                  ))}
                </div>
              </>
            ) : (
              <>
                <h3>{moment(activeDay).format('MMMM D, YYYY')}</h3>
                <p className="m-b-0">No topics scheduled for this day.</p>
              </>
            )}
          </div>
        </div>
      );
    }
  };

  const renderPastSession = (activeDay: string) => {
    if (moment(activeDay).startOf('day').isBefore(moment().startOf('day'))) {
      const pastSession = pastSessionsWithBlocks?.find(
        (session: StudySessionWithBlocks) =>
          moment(session.date)
            .startOf('day')
            .isSame(moment(activeDay).startOf('day'))
      );
      return (
        <div
          className="topics-flyout topics-flyout--past"
          style={{
            top: `${flyoutPosition?.top}px`,
            left: `${flyoutPosition?.left}px`,
            opacity: isTopicFlyoutVisible ? 1 : 0,
            pointerEvents: isTopicFlyoutVisible ? 'auto' : 'none',
          }}
          onMouseEnter={handleFlyoutMouseEnter}
          onMouseLeave={handleFlyoutMouseLeave}
        >
          <div className="topics-flyout_inner">
            {pastSession && pastSession !== undefined ? (
              <div className="past-session">
                <h3 className="past-session_title">
                  {moment(pastSession.date).format('MMMM D, YYYY')}
                </h3>
                <div className="percentages">
                  {pastSession.vignetteCount > 0 && (
                    <div className="overall-score">
                      <div className="overall-score_speedometer">
                        <CircularProgressbar
                          value={Math.round(
                            (pastSession.vignetteCorrectCount /
                              pastSession.vignetteCount) *
                              100
                          )}
                          text={`${Math.round(
                            (pastSession.vignetteCorrectCount /
                              pastSession.vignetteCount) *
                              100
                          )}%`}
                          strokeWidth={4}
                          styles={buildStyles({
                            pathColor: 'var(--success)',
                            textColor: 'var(--type-body-inverse)',
                            trailColor: 'var(--surface-glass-light)',
                            backgroundColor: '#fff',
                            pathTransitionDuration: 0,
                          })}
                        />
                      </div>
                      <div className="overall-score_label">QBank</div>
                    </div>
                  )}
                </div>
                {pastSession.flashcardCount > 0 && (
                  <div className="flashcards">
                    <div className="flashcards_count">
                      {pastSession.completedFlashcardCount}
                    </div>
                    <div className="flashcards_label">
                      Completed Flashcard
                      {pastSession.completedFlashcardCount === 1 ? '' : 's'}
                    </div>
                  </div>
                )}
                {pastSession.blocks.length > 0 && (
                  <button
                    className="button button--link"
                    onClick={() => {
                      navigate(`/past-blocks#${pastSession.id}`);
                    }}
                  >
                    <span>Review Blocks</span>
                    <ArrowRight />
                  </button>
                )}
              </div>
            ) : (
              <div className="past-session">
                <h3 className="past-session_title">
                  {moment(activeDay).format('MMMM D, YYYY')}
                </h3>
                <p className="m-b-0">You didn't study on this day.</p>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  // Clean up the timer when component unmounts
  useEffect(() => {
    return () => {
      if (mouseLeaveTimer) {
        clearTimeout(mouseLeaveTimer);
      }
    };
  }, [mouseLeaveTimer]);

  return (
    <>
      {primaryExam?.isUsingQbank && (
        <div
          className="priority-display"
          id="priority-display"
          ref={priorityDisplayRef}
        >
          <div
            className={`priority-display_chart${isTopicFlyoutVisible ? ' is-hovering-date' : ''}`}
            ref={chartRef}
          >
            {filledStudyDays.map((studyDay, index) => {
              let score = 0;
              if (studyDay.isPast && studyDay.isStarted) {
                score = studyDay.vignetteCorrectCount / studyDay.vignetteCount;
              }
              const barHeight = score * 1.5 + 0.5;
              return (
                <div
                  key={'studyDay-' + index}
                  className={`study-day${studyDay.isPast ? ' is-past' : ''}${studyDay.isToday ? ' study-day--today' : ''}`}
                  ref={(el) => {
                    if (studyDay.isToday) {
                      todayRef.current = el;
                    }
                  }}
                >
                  <div
                    className={`study-day_day
                        ${studyDay.isToday ? ' is-today' : ''}
                        ${studyDay.isFuture ? ' is-future' : ''}
                        ${!studyDay.isStarted && !studyDay.isToday ? ' is-missing' : ''}
                        ${studyDay.isStarted ? ' is-started' : ''}
                      `}
                    onMouseEnter={(e) => handleDayMouseEnter(studyDay.date, e)}
                    onMouseLeave={handleDayMouseLeave}
                  >
                    <div
                      className={`study-day_day_score`}
                      style={
                        !studyDay.isFuture
                          ? {
                              height: `${studyDay.isToday && !studyDay.isStarted ? 0 : barHeight}rem`,
                            }
                          : { height: '2rem' }
                      }
                    ></div>
                  </div>
                  {studyDay.date.split('-')[2] === '01' ? (
                    <span className="study-day_month-label">
                      {moment(studyDay.date).format('MMM')}
                    </span>
                  ) : null}
                </div>
              );
            })}
          </div>
          {activeDay &&
            createPortal(
              <>
                {renderTopicFlyout(activeDay)}
                {renderPastSession(activeDay)}
              </>,
              document.body
            )}
        </div>
      )}
    </>
  );
};

export default PriorityDisplay;
