export const UserActive = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="10.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M12 16C7.41176 16 6 19.6923 6 19.6923C7.5 20.4615 10.8 22 12 22C13.2 22 16.5 20.4615 18 19.6923C18 19.6923 16.5882 16 12 16Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <circle
        cx="12"
        cy="10"
        r="3.25"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
