import React, { useEffect, useState } from 'react';
import { hapticsImpactLight } from '../../../utils/haptics';
import { useNavigate } from 'react-router-dom';
import { TabTypes } from '../../../types/Global';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { Home } from '../../../assets/svgs/Home';
import { HomeActive } from '../../../assets/svgs/HomeActive';
import { Chart } from '../../../assets/svgs/Chart';
import { ChartActive } from '../../../assets/svgs/ChartActive';
import { Book } from '../../../assets/svgs/Book';
import { BookActive } from '../../../assets/svgs/BookActive';
import { User } from '../../../assets/svgs/User';
import { UserActive } from '../../../assets/svgs/UserActive';

interface AppBarProps {}

const AppBar: React.FC<AppBarProps> = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [activeTab, setActiveTab] = useState<TabTypes>(TabTypes.Home);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/') {
      setActiveTab(TabTypes.Home);
    } else if (window.location.pathname === '/performance') {
      setActiveTab(TabTypes.Stats);
    } else if (window.location.pathname.includes('/library')) {
      setActiveTab(TabTypes.Library);
    } else if (
      window.location.pathname === '/account' ||
      window.location.pathname === '/help'
    ) {
      setActiveTab(TabTypes.Account);
    } else {
      setActiveTab(TabTypes.null);
    }
  }, [navigate]);

  return (
    <>
      <nav
        className={`app-bar ${activeTab === TabTypes.Home ? 'app-bar--home' : ''}`}
      >
        <div className="app-bar_inner">
          <button
            className={`app-bar_inner_button button button--home ${activeTab === TabTypes.Home ? 'active' : ''}`}
            onClick={() => {
              hapticsImpactLight();
              navigate('/');
            }}
          >
            <div className="app-bar_inner_button_icon">
              {activeTab === TabTypes.Home ? <HomeActive /> : <Home />}
            </div>
            <span>Home</span>
          </button>
          {currentUser?.isOnboarded && (
            <>
              <button
                className={`app-bar_inner_button button button--performance ${activeTab === TabTypes.Stats ? 'active' : ''}`}
                onClick={() => {
                  hapticsImpactLight();
                  navigate('/performance');
                }}
              >
                <div className="app-bar_inner_button_icon">
                  {activeTab === TabTypes.Stats ? <ChartActive /> : <Chart />}
                </div>
                <span>Stats</span>
              </button>
              <button
                id="library-tab"
                role="link"
                className={`app-bar_inner_button button button--library ${activeTab === TabTypes.Library ? 'active' : ''}`}
                onClick={() => {
                  hapticsImpactLight();
                  navigate('/library');
                }}
              >
                <div className="app-bar_inner_button_icon">
                  {activeTab === TabTypes.Library ? <BookActive /> : <Book />}
                </div>
                <span>Library</span>
              </button>
              <button
                className={`app-bar_inner_button button button--account ${activeTab === TabTypes.Account ? 'active' : ''}`}
                onClick={() => {
                  hapticsImpactLight();
                  navigate('/account?open-menu=true');
                }}
              >
                <div className="app-bar_inner_button_icon">
                  {currentUser?.profilePictureUrl ? (
                    <img
                      className="app-bar-user-avatar"
                      src={currentUser?.profilePictureUrl}
                      alt={currentUser?.name || 'Profile Picture'}
                    />
                  ) : activeTab === TabTypes.Account ? (
                    <UserActive />
                  ) : (
                    <User />
                  )}
                </div>
                <span>Account</span>
              </button>
            </>
          )}
        </div>
      </nav>
    </>
  );
};

export default AppBar;
