const AnimatedCheckmark = ({
  ringDuration = 1,
  fillPulseDuration = 0.5,
  checkmarkDuration = 0.25,
}) => {
  const shortSegmentDuration = checkmarkDuration / 3;
  const longSegmentDuration = (2 * checkmarkDuration) / 3;
  const fillPulseStart = ringDuration;
  const checkmarkStart = fillPulseStart + fillPulseDuration - 0.5;

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(50,50)">
        <circle cx="0" cy="0" r="22.5" fill="#fff" opacity="0">
          <animate
            attributeName="opacity"
            to="1"
            begin={`${fillPulseStart}s`}
            fill="freeze"
          />
          <animateTransform
            attributeName="transform"
            type="scale"
            from="2"
            to="2.2"
            begin={`${fillPulseStart}s`}
            dur={`${fillPulseDuration}s`}
            fill="freeze"
          />
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            begin={`${fillPulseStart}s`}
            dur={`${fillPulseDuration}s`}
            fill="freeze"
          />
        </circle>
      </g>
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke="#fff"
        strokeWidth="5"
        strokeDasharray="282.743"
        strokeDashoffset="282.743"
        transform="rotate(-90 50 50)"
        opacity="1"
      >
        <animate
          attributeName="stroke-dashoffset"
          from="282.743"
          to="0"
          dur={`${ringDuration}s`}
          fill="freeze"
        />
        <animate
          attributeName="opacity"
          from="1"
          to="0"
          begin={`${fillPulseStart}s`}
          dur={`${fillPulseDuration}s`}
          fill="freeze"
        />
      </circle>
      <path
        d="M30 50 L45 65"
        fill="none"
        stroke="#fff"
        strokeWidth="5"
        strokeLinecap="round"
        strokeDasharray="21.21"
        strokeDashoffset="21.21"
        opacity="0"
      >
        <animate
          attributeName="opacity"
          from="0"
          to="1"
          begin={`${checkmarkStart}s`}
          dur="0.001s"
          fill="freeze"
        />
        <animate
          attributeName="stroke-dashoffset"
          from="21.21"
          to="0"
          begin={`${checkmarkStart}s`}
          dur={`${shortSegmentDuration}s`}
          fill="freeze"
        />
      </path>
      <path
        d="M45 65 L70 40"
        fill="none"
        stroke="#fff"
        strokeWidth="5"
        strokeLinecap="round"
        strokeDasharray="35.36"
        strokeDashoffset="35.36"
        opacity="0"
      >
        <animate
          attributeName="opacity"
          from="0"
          to="1"
          begin={`${checkmarkStart + shortSegmentDuration}s`}
          dur="0.001s"
          fill="freeze"
        />
        <animate
          attributeName="stroke-dashoffset"
          from="35.36"
          to="0"
          begin={`${checkmarkStart + shortSegmentDuration}s`}
          dur={`${longSegmentDuration}s`}
          fill="freeze"
        />
      </path>
    </svg>
  );
};

export default AnimatedCheckmark;
