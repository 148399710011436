import { enqueueSnackbar } from 'notistack';
import { hapticsImpactLight } from '../../../utils/haptics';
import CustomModal from '../CustomModal';
import { Link } from 'react-feather';
import { User } from '../../../types/User';

interface ReferralModalProps {
  currentUser: User;
  referFriendModalIsOpen: boolean;
  setReferFriendModalIsOpen: (isOpen: boolean) => void;
}

const ReferralModal: React.FC<ReferralModalProps> = ({
  currentUser,
  referFriendModalIsOpen,
  setReferFriendModalIsOpen,
}) => {
  return (
    <CustomModal
      open={referFriendModalIsOpen}
      onClose={() => setReferFriendModalIsOpen(false)}
      name={'refer'}
      glass={true}
      blurs={true}
    >
      <div className="modal_content">
        <div className="referral">
          <h2>Refer a Friend</h2>
          <p className="m-b-0-50">
            Enjoying your Ora experience? We're grateful to have you with us and
            would love your help in spreading the word. We want to show our
            gratitude for anyone who helps us grow, and here's how:
            <ol>
              <li style={{ marginBottom: '8px' }}>
                <strong>Share your signup link</strong> with any U.S. medical
                student who might enjoy Ora.
              </li>
              <li>
                <strong>Earn $25</strong> Amazon gift cards for every 5 signups,
                up to $200.
              </li>
            </ol>
          </p>
          <div className="share-link">
            <div className="share-link_label">My Referral Link:</div>
            <div
              className="share-link_personal-link"
              role="button"
              onClick={() => {
                hapticsImpactLight();
                navigator.clipboard.writeText(
                  `https://app.oraai.com/?refId=${currentUser?.referralId}`
                );
                enqueueSnackbar('Copied to clipboard!');
              }}
            >
              https://app.oraai.com/?refId={currentUser?.referralId}
            </div>
            <button
              tabIndex={0}
              className="button button--glass"
              onClick={() => {
                hapticsImpactLight();
                navigator.clipboard.writeText(
                  `https://app.oraai.com/?refId=${currentUser?.referralId}`
                );
                enqueueSnackbar('Copied to clipboard.');
              }}
            >
              <Link />
              <span>Copy Link</span>
            </button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ReferralModal;
