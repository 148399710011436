// src/components/Study/Exam/ConfirmEndModal.tsx
import React from 'react';
import { Modal } from '@mui/material';
import { hapticsImpactLight } from '../../../../utils/haptics';

interface ConfirmEndModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmEnd: () => void;
  vignettesRemainingCount: number;
}

const ConfirmEndModal: React.FC<ConfirmEndModalProps> = ({
  isOpen,
  onClose,
  onConfirmEnd,
  vignettesRemainingCount,
}) => {
  const hasRemaining = vignettesRemainingCount > 0;

  const handleConfirm = () => {
    onConfirmEnd();
    hapticsImpactLight();
    onClose();
  };

  const handleRemain = () => {
    onClose();
    hapticsImpactLight();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      disableRestoreFocus
      aria-labelledby="confirm-end-modal-title"
      aria-describedby="confirm-end-modal-description"
    >
      <div className="modal modal--end-block">
        <div
          id="confirm-end-modal-title"
          className={`modal_header ${hasRemaining ? '' : 'non-zero'}`}
        >
          {hasRemaining
            ? 'Warning - This block is incomplete!'
            : 'Warning - You are about to end the block!'}
        </div>
        <div id="confirm-end-modal-description" className="modal_content">
          {hasRemaining && (
            <p className="m-t-0">
              Number of unanswered items in this block:{' '}
              {vignettesRemainingCount}
            </p>
          )}
          <p>If you end this block you will not be able to return.</p>
        </div>
        <div className="modal_actions">
          <button className="button button--eb" onClick={handleConfirm}>
            End Block
          </button>
          <button onClick={handleRemain} className="button button--remain">
            Remain in Block
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmEndModal;
