import { Chart as ChartJS, TooltipItem } from 'chart.js';

export const centerCirclePlugin1 = {
  id: 'centerCircle1',
  afterDraw: (chart: ChartJS) => {
    const ctx = chart.ctx;
    if (!ctx) return;
    const radarScale = chart.scales.r;
    if (
      !radarScale ||
      radarScale.xCenter === undefined ||
      radarScale.yCenter === undefined
    )
      return;

    const centerPoint = {
      x: radarScale.xCenter,
      y: radarScale.yCenter,
    };
    const radius = (radarScale.drawingArea * 3) / 10;
    ctx.save();
    ctx.fillStyle = 'rgba(255, 255, 255, 0.05)';
    ctx.beginPath();
    ctx.arc(centerPoint.x, centerPoint.y, radius, 0, Math.PI * 2);
    ctx.fill();
    ctx.restore();
  },
};
export const centerCirclePlugin2 = {
  id: 'centerCircle2',
  afterDraw: (chart: ChartJS) => {
    const ctx = chart.ctx;
    if (!ctx) return;
    const radarScale = chart.scales.r;
    if (
      !radarScale ||
      radarScale.xCenter === undefined ||
      radarScale.yCenter === undefined
    )
      return;

    const centerPoint = {
      x: radarScale.xCenter,
      y: radarScale.yCenter,
    };
    const radius = (radarScale.drawingArea * 6.5) / 10;
    ctx.save();
    ctx.fillStyle = 'rgba(255, 255, 255, 0.05)';
    ctx.beginPath();
    ctx.arc(centerPoint.x, centerPoint.y, radius, 0, Math.PI * 2);
    ctx.fill();
    ctx.restore();
  },
};
export const centerCirclePlugin3 = {
  id: 'centerCircle3',
  afterDraw: (chart: ChartJS) => {
    const ctx = chart.ctx;
    if (!ctx) return;
    const radarScale = chart.scales.r;
    if (
      !radarScale ||
      radarScale.xCenter === undefined ||
      radarScale.yCenter === undefined
    )
      return;

    const centerPoint = {
      x: radarScale.xCenter,
      y: radarScale.yCenter,
    };
    const radius = radarScale.drawingArea;
    ctx.save();
    ctx.fillStyle = 'rgba(255, 255, 255, 0.1)';
    ctx.beginPath();
    ctx.arc(centerPoint.x, centerPoint.y, radius, 0, Math.PI * 2);
    ctx.fill();
    ctx.restore();
  },
};

export const formatTopicRadarLabels = (str: string) => {
  const shortLabels = {
    'Biochemistry, Genetics, & Metabolism': 'Basic',
    'Biostatistics & Epidemiology': 'Biostats',
    'Blood & Lymphatic System': 'Heme',
    'Cardiovascular System': 'Cardio',
    Dermatology: 'Derm',
    'Endocrine System': 'Endo',
    'Ethics & Healthcare Systems': 'Ethics',
    'Gastrointestinal System': 'GI',
    'Immune System': 'Immune',
    'Infectious Disease': 'ID',
    'Musculoskeletal System': 'MSK',
    'Nervous System': 'Neuro',
    'Psychiatry & Behavioral Health': 'Pysch',
    'Renal & Urinary System': 'Renal',
    'Reproductive Systems & Breast': 'Repro',
    'Respiratory System': 'Pulm',
  };

  return shortLabels[str as keyof typeof shortLabels] || str;
};

export const topicRadarOptions = {
  responsive: true,
  maintainAspectRatio: false, // Ensure the chart fills the container
  layout: {
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  elements: {
    line: {
      borderWidth: 1,
    },
  },
  scales: {
    r: {
      suggestedMin: 0,
      suggestedMax: 100,
      angleLines: {
        display: true,
        color: 'rgba(255, 255, 255, 0.3)',
      },
      grid: {
        display: false,
      },
      ticks: {
        color: 'transparent',
        backdropColor: 'transparent',
      },
      pointLabels: {
        color: '#FEFEFE',
        font: {
          size: 12,
          family: 'var(--font-family-base)',
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      backdropFilter: 'blur(20px)',
      WebkitBackdropFilter: 'blur(20px)',
      bodyFont: {
        family: 'var(--font-family-base)',
        size: 12,
      },
      padding: 12,
      cornerRadius: 6,
      displayColors: false,
      callbacks: {
        label: (context: TooltipItem<'radar'>) => {
          const value = (context.raw as number) || 0;
          return `Performance: ${value.toFixed(0)}%`;
        },
      },
    },
  },
};
