import { useCallback, useEffect, useState } from 'react';
import Search from '../Search';
import { TopicTreeNode } from '../../../types/TopicCustomization';
import { hapticsImpactLight } from '../../../utils/haptics';
import { fetchLibraryTopicTree } from '../../../services/library';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { TriangleRight } from '../../../assets/svgs/TriangleRight';
import { TriangleDown } from '../../../assets/svgs/TriangleDown';
import AccordionDetails from '@mui/material/AccordionDetails';
import { X } from 'react-feather';
import { Bookmark } from '../../../types/Bookmark';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

interface ArticleBrowserProps {
  activeArticleId: string | null;
  setActiveArticleId: (articleId: string) => void;
  setMobileMenuIsOpen: (isOpen: boolean) => void;
  bookmarks: Bookmark[];
  removeBookmark: (articleId: string) => void;
}

const ArticleBrowser: React.FC<ArticleBrowserProps> = ({
  activeArticleId,
  setActiveArticleId,
  setMobileMenuIsOpen,
  bookmarks,
  removeBookmark,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const [view, setView] = useState<'topics' | 'bookmarks'>('topics');
  const [topics, setTopics] = useState<TopicTreeNode[]>([]);
  const [expandedPanels, setExpandedPanels] = useState<{
    [key: string]: string | null;
  }>({});

  const navigate = useNavigate();

  useHotkeys('/', (e) => {
    e.preventDefault();
    setView('topics');
    setTimeout(() => {
      const input = document.getElementById('library-search-input');
      if (input) {
        input.focus();
      }
    }, 50);
  });

  useEffect(() => {
    loadTopics();
  }, []);

  const loadTopics = async () => {
    const response = await fetchLibraryTopicTree();
    if (response) {
      setTopics(response);
      const leafTopics: { name: string; id: string }[] = [];
      const getLeafTopics = (topics: TopicTreeNode[]) => {
        topics.forEach((topic) => {
          if (topic.children && topic.children.length > 0) {
            getLeafTopics(topic.children);
          } else {
            if (topic.articleId) {
              leafTopics.push({ name: topic.name, id: topic.articleId });
            }
          }
        });
      };
      getLeafTopics(response);
      // const randomTopics = leafTopics
      //   .sort(() => 0.5 - Math.random())
      //   .slice(0, 100);
      // setParticleTopics(randomTopics);
    }
  };

  const handleAccordionChange = useCallback(
    (level: number, panel: string) => () => {
      setExpandedPanels((prev) => ({
        ...prev,
        [`${level}`]: prev[`${level}`] === panel ? null : panel,
      }));
      hapticsImpactLight();
    },
    []
  );

  const handleClickTopic = (articleId: string | null) => {
    if (articleId) {
      setActiveArticleId(articleId);
      setMobileMenuIsOpen(false);
      navigate(`/library/${articleId}`);
    }
  };

  const renderTopicTree = useCallback(
    (topics: TopicTreeNode[], level: number) => {
      if (!topics || !topics.length) return null;

      return topics.map((t, i) => {
        const panelId = `p${level}${i}-${t.name.replace(/\s+/g, '').toLowerCase()}`;
        const isExpanded = expandedPanels[`${level}`] === panelId;

        if (t.children && t.children.length > 0) {
          return (
            <Accordion
              key={i}
              expanded={isExpanded}
              onChange={handleAccordionChange(level, panelId)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                sx={{
                  padding: 0,
                }}
                aria-controls={`${panelId}-content`}
                id={`${panelId}-header`}
              >
                {t.children && t.children.length > 0 && (
                  <>
                    {!isExpanded && <TriangleRight classes="expand-icon" />}
                    {isExpanded && <TriangleDown classes="expand-icon" />}
                  </>
                )}
                <div className="row">
                  <div>{t.name}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className={`level-${level}`}>
                  {renderTopicTree(t.children, level + 1)}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        } else {
          return (
            <div
              className={`article-row ${t.articleId === activeArticleId ? 'is-active' : ''}`}
              key={i}
              id={`${panelId}-header`}
            >
              <button
                onClick={() => {
                  handleClickTopic(t.articleId);
                  const contentWindow = document.querySelector('.page_content');
                  if (contentWindow) {
                    contentWindow.scrollTo(0, 0);
                  }
                }}
              >
                {t.name}
              </button>
            </div>
          );
        }
      });
    },
    [
      expandedPanels,
      handleAccordionChange,
      activeArticleId,
      setMobileMenuIsOpen,
    ]
  );

  const topicTree = renderTopicTree(topics, 0);

  const renderBrowserHeader = (view: 'topics' | 'bookmarks') => {
    if (currentUser && bookmarks.length > 0) {
      return (
        <div className="article-browser_header">
          <div className="article-browser_header_tabs">
            <button
              className={`button ${view === 'topics' ? 'is-active' : ''}`}
              onClick={() => {
                setView('topics');
              }}
            >
              Topics
            </button>
            <button
              className={`button ${view === 'bookmarks' ? 'is-active' : ''}`}
              onClick={() => {
                setView('bookmarks');
              }}
            >
              Bookmarks
            </button>
          </div>
          {view === 'topics' && (
            <Search
              loadArticleById={setActiveArticleId}
              setMobileMenuIsOpen={setMobileMenuIsOpen}
              isArticleBrowser={true}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="article-browser_header">
          <div className="article-browser_header_tabs">
            <button
              className={`button ${view === 'topics' ? 'is-active' : ''}`}
              style={{ cursor: 'default' }}
            >
              Topics
            </button>
          </div>
          {view === 'topics' && (
            <Search
              loadArticleById={setActiveArticleId}
              setMobileMenuIsOpen={setMobileMenuIsOpen}
              isArticleBrowser={true}
            />
          )}
        </div>
      );
    }
  };

  return (
    <div className="article-browser">
      {renderBrowserHeader(view)}
      {view === 'topics' && (
        <>
          {topicTree ? (
            <div className="article-accordion">{topicTree}</div>
          ) : (
            <Skeleton
              sx={{
                borderRadius: '.5rem',
              }}
              variant="rectangular"
              height={560}
            />
          )}
        </>
      )}
      {view === 'bookmarks' && (
        <>
          {currentUser && bookmarks.length > 0 && (
            <div className="article-browser_bookmarks">
              <div className="bookmark-list">
                {bookmarks.map((bookmark) => (
                  <div className="bookmark">
                    <button
                      key={bookmark.id}
                      className="button button--bookmark"
                      onClick={() => {
                        setActiveArticleId(bookmark.articleId);
                        navigate(`/library/${bookmark.articleId}`);
                      }}
                    >
                      {bookmark.articleTitle}
                    </button>
                    <button
                      className="button button--icon-only button--delete-bookmark"
                      key={'delete-' + bookmark.id}
                      onClick={() => {
                        if (bookmarks.length === 1) {
                          setView('topics');
                        }
                        removeBookmark(bookmark.articleId);
                      }}
                    >
                      <X />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ArticleBrowser;
