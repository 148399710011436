import React from 'react';
import Page from '../../../components/Page';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import MSHeader from '../../../components/NonAuth/MSHeader';
import MSFooter from '../../../components/NonAuth/MSFooter';
import NonAuthPage from '../../../components/NonAuth/NonAuthPage';

const Guarantee: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const renderGuarantee = () => {
    return (
      <div className="guarantee">
        <h1>Our Guarantee</h1>
        <p>
          We guarantee that you will pass your exams or your money back. No
          questions asked.
        </p>
      </div>
    );
  };

  return (
    <>
      {currentUser && <Page>{renderGuarantee()}</Page>}
      {!currentUser && (
        <>
          <MSHeader />
          <NonAuthPage>{renderGuarantee()}</NonAuthPage>
          <MSFooter />
        </>
      )}
    </>
  );
};

export default Guarantee;
