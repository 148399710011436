import React, { useEffect, useMemo, useState } from 'react';
import StopIcon from '../../../../assets/images/standard/stop.svg';
import Next from '../../../../assets/images/standard/arrow-right.svg';
import NextInactive from '../../../../assets/images/standard/arrow-right-inactive.svg';
import Previous from '../../../../assets/images/standard/arrow-left.svg';
import PreviousInactive from '../../../../assets/images/standard/arrow-left-inactive.svg';
import { convertSecondsToDisplayTime } from '../../../../utils/convertSecondsToDisplayTime';
import { BlockType, StudyPhase } from '../../../../types/Study';
import { ArrowRight, MoreVertical, Pause, X } from 'react-feather';
import {
  hapticsImpactLight,
  hapticsImpactMedium,
} from '../../../../utils/haptics';
import { closeSnackbar } from 'notistack';
import { RootState } from '../../../../store/store';
import QBankDonut from '../../../Misc/QBankDonut';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchVignettesForBlock } from '../../../../services/study';
import { setVignettes } from '../../../../slices/vignettes/vignettesSlice';
import posthog from 'posthog-js';

interface FooterProps {
  activeBlockId: string;
  blockType: BlockType;
  isSubmitted: boolean;
  continueExam: () => void;
  changePhase?: (phase: StudyPhase, options?: object) => void;
  timeRemaining: number;
  activeVignetteId: string;
  fadeInFlashLoader: () => void;
  pauseBlock: () => void;
  setConfirmEndIsOpen: (value: boolean) => void;
  nextQuestion: () => void;
  prevQuestion: () => void;
  currentQuestion: number;
  totalQuestions: number;
  isDemo?: boolean;
  allDemoQuestionsSubmitted?: boolean;
  createAccountModalIsOpen?: boolean;
  functionMenuIsOpen?: boolean;
  setFunctionMenuIsOpen: (value: boolean) => void;
  refreshFlashcards: () => void;
  renderSwitchPhaseButton: () => React.ReactNode;
}

const Footer: React.FC<FooterProps> = ({
  activeBlockId,
  blockType,
  isSubmitted,
  continueExam,
  changePhase,
  timeRemaining,
  activeVignetteId,
  fadeInFlashLoader,
  pauseBlock,
  setConfirmEndIsOpen,
  nextQuestion,
  prevQuestion,
  currentQuestion,
  totalQuestions,
  isDemo,
  allDemoQuestionsSubmitted,
  createAccountModalIsOpen,
  functionMenuIsOpen,
  setFunctionMenuIsOpen,
  refreshFlashcards,
  renderSwitchPhaseButton,
}) => {
  const activeBlock = useSelector(
    (state: RootState) => state.blocks.find((b) => b.id === activeBlockId)!
  );
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const blocks = useSelector((state: RootState) => state.blocks);

  const [tutorModeQuestionTimer, setTutorModeQuestionTimer] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (blockType === BlockType.tutor) {
      setTutorModeQuestionTimer(0);
      const timer = setInterval(() => {
        setTutorModeQuestionTimer((prevTime) => prevTime + 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [activeVignetteId]);

  const calculateStudySessionStats = () => {
    const correct =
      (activeBlock.correctCount / activeBlock.vignetteCount) * 100;
    const incorrect =
      (activeBlock.incorrectCount / activeBlock.vignetteCount) * 100;
    const omitted =
      (activeBlock.omittedCount / activeBlock.vignetteCount) * 100;
    const total = [
      { value: correct, label: 'Correct' },
      { value: incorrect, label: 'Incorrect' },
      { value: omitted, label: 'Omitted' },
    ];
    return total
      .filter((stat) =>
        ['Right', 'Wrong', 'Omitted', 'Correct', 'Incorrect'].includes(
          stat.label
        )
      )
      .map((stat) => ({
        ...stat,
        value:
          typeof stat.value === 'string' ? parseInt(stat.value) : stat.value,
      }));
  };

  const renderPerformanceDonut = useMemo(() => {
    if (blockType === BlockType.tutor) return null;
    if (!isSubmitted) return null;
    const stats = calculateStudySessionStats();
    const percentage = stats.find((stat) => stat.label === 'Correct')?.value;
    return (
      <QBankDonut
        percentage={percentage ? Math.round(percentage)?.toString() : '0'}
        data={stats}
      />
    );
  }, [isSubmitted, blockType, activeBlockId]);

  const renderTimeDisplay = () => {
    if (todaySession.phase === StudyPhase.qbankReview || isSubmitted)
      return null;
    return (
      <div className="time-display">
        {blockType === BlockType.tutor && (
          <div className="m-b-0-25" style={{ fontSize: '1.5rem' }}>
            {convertSecondsToDisplayTime(tutorModeQuestionTimer)}
          </div>
        )}
        {blockType === BlockType.timed ? 'Block Time Remaining: ' : ''}
        {convertSecondsToDisplayTime(timeRemaining)}
        {blockType === BlockType.tutor ? ' Overall' : ''}
      </div>
    );
  };

  const resumeExam = async () => {
    let currentBlockId = blocks
      .filter((block) => !block.isSubmitted)
      .sort((a, b) => (a.ord ?? 0) - (b.ord ?? 0))[0]?.id;
    if (!currentBlockId) {
      currentBlockId = blocks[blocks.length - 1].id;
    }
    if (todaySession.isComplete) {
      console.log('Today Session is Complete - Returning to Session Overview');
      if (changePhase)
        await changePhase(StudyPhase.sessionReview, {
          activeBlockId: currentBlockId,
        });
    } else {
      console.log(
        'Today Session is Incomplete - Loading Vignettes for Next Exam'
      );
      const vignettes = await fetchVignettesForBlock(currentBlockId);
      if (vignettes) {
        dispatch(setVignettes(vignettes.vignettes));
        if (changePhase) {
          await changePhase(StudyPhase.qbank, {
            activeBlockId: currentBlockId,
          });
        }
      }
    }
  };

  const handleEndReview = () => {
    setConfirmEndIsOpen(false);
    fadeInFlashLoader();
    hapticsImpactMedium();
    closeSnackbar();
    refreshFlashcards();
    // Reviewing the immediately completed block
    if (
      todaySession.phase !== StudyPhase.qbankReview &&
      !todaySession.isReview
    ) {
      console.log('Review Ended - Continue Exam');
      setTimeout(() => {
        continueExam();
      }, 250);
      return;
    } else {
      if (
        todaySession.phase === StudyPhase.qbankReview &&
        !todaySession.isReview
      ) {
        // Reviewing a previous today block
        console.log('Review of Past Today Block Ended - Resume Exam');
        localStorage.removeItem('ora-today-block-id');
        setTimeout(() => {
          resumeExam();
        }, 250);
      } else if (
        todaySession.phase === StudyPhase.qbankReview &&
        todaySession.isReview
      ) {
        // Reviewing a previous session block
        console.log(
          'Review of Past Session Block Ended - Return To Past Blocks'
        );
        setTimeout(() => {
          navigate('/past-blocks');
        }, 250);
      } else {
        console.log('No Other Review Conditions Match - Return Home');
        setTimeout(() => {
          navigate('/');
        }, 250);
      }
    }
  };

  const renderPreviousQuestionButton = () => {
    if (isDemo && allDemoQuestionsSubmitted) return null;
    return (
      <button
        className="button button--glass button--change-question"
        onClick={() => {
          prevQuestion();
          hapticsImpactLight();
        }}
      >
        {currentQuestion === 0 ? (
          <img src={PreviousInactive} alt="Previous Question" />
        ) : (
          <img src={Previous} alt="Previous Question" />
        )}
      </button>
    );
  };

  const renderNextQuestionButton = () => {
    if (isDemo && allDemoQuestionsSubmitted) return null;
    return (
      <button
        className="button button--glass button--change-question"
        onClick={() => {
          nextQuestion();
          hapticsImpactLight();
        }}
      >
        {currentQuestion === totalQuestions - 1 ? (
          <img src={NextInactive} alt="Next Question" />
        ) : (
          <img src={Next} alt="Next Question" />
        )}
      </button>
    );
  };

  const renderEndReviewButton = () => {
    if (!isSubmitted) return null;
    if (isDemo) return null;
    return (
      <button
        className="button button--glass button--end-review"
        onClick={handleEndReview}
      >
        <div>End Review</div>
        <ArrowRight />
      </button>
    );
  };

  const renderCreateAccountButton = () => {
    if (!isDemo) return null;
    return (
      <a
        className={`button button--glass button--create-account ${allDemoQuestionsSubmitted && !createAccountModalIsOpen ? 'pulse' : ''}`}
        href="/auth?signup=true"
        onClick={() => {
          posthog?.capture('ms_demo_create_account', {
            isFooterButton: true,
          });
        }}
      >
        Create Account
      </a>
    );
  };

  const renderDefaultMobileFooter = () => {
    return (
      <>
        <button
          className="button button--glass button--function-menu"
          onClick={() => {
            setFunctionMenuIsOpen(!functionMenuIsOpen);
            hapticsImpactLight();
          }}
        >
          {functionMenuIsOpen ? <X /> : <MoreVertical />}
        </button>
        {renderPreviousQuestionButton()}
        {renderEndReviewButton()}
        {renderNextQuestionButton()}
      </>
    );
  };

  const renderDemoMobileFooter = () => {
    return (
      <>
        <div style={{ display: 'flex', gap: '.5rem', width: '100%' }}>
          {renderPreviousQuestionButton()}
          {renderNextQuestionButton()}
        </div>
        {renderCreateAccountButton()}
      </>
    );
  };

  return (
    <div className={`footer ${isSubmitted ? 'is-submitted' : ''}`}>
      {renderTimeDisplay()}
      {renderPerformanceDonut}
      <div className="spacer"></div>
      <div className="footer_right">
        {renderSwitchPhaseButton()}
        {!(todaySession.phase === StudyPhase.qbankReview) &&
          !isDemo &&
          !isSubmitted && (
            <button
              onClick={pauseBlock}
              className="button button--glass button--pause"
              style={{ flexDirection: 'row' }}
            >
              <Pause />
              <div>Pause Block</div>
            </button>
          )}
        {!isSubmitted && !isDemo && (
          <>
            <button
              className="button button--end"
              onClick={() => {
                setConfirmEndIsOpen(true);
                hapticsImpactLight();
                closeSnackbar();
              }}
            >
              <img src={StopIcon} alt="End Session" />
              <div>End Block</div>
            </button>
          </>
        )}
        {renderCreateAccountButton()}
        {renderEndReviewButton()}
      </div>
      <div className="footer_mobile" style={isDemo ? { flexWrap: 'wrap' } : {}}>
        {!isDemo ? renderDefaultMobileFooter() : renderDemoMobileFooter()}
      </div>
    </div>
  );
};

export default Footer;
