import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Check, Home, Layers, List } from 'react-feather';
import { RootState } from '../../../store/store';
import { StudyPhase } from '../../../types/Study';
import { HeaderType } from '../../../types/Header';
import Tooltip from '@mui/material/Tooltip';
import StudyProgress from './StudyProgress';
import { hapticsImpactLight } from '../../../utils/haptics';
import { setUserInfo } from '../../../slices/auth/authSlice';
import { UserExam } from '../../../types/User';
import { fetchExams } from '../../../services/exams';
import {
  addOrUpdateUserExam,
  changePrimaryExam,
} from '../../../services/users';
import {
  defaultFlashcards,
  defaultVignettes,
} from '../../../utils/contentQuantityUtils';
import moment from 'moment';
import { calcDefaultExamDate } from '../../../helpers/userHelpers';
import OraLogo from '../OraLogo';
import WorkloadSelect from './WorkloadSelect';
import { useRef } from 'react';
import Menu from '@mui/material/Menu';
import { TriangleRight } from '../../../assets/svgs/TriangleRight';
import { TriangleDown } from '../../../assets/svgs/TriangleDown';
import ExamDateSelect from './ExamDateSelect';
import TopicSelect from './TopicSelect';

interface HeaderProps {
  type: HeaderType;
  changePhase?: (phase: StudyPhase, options?: object) => void;
  setIsSwitchingExam?: (isSwitchingExam: boolean) => void;
  setIsLoadingStudySessionAndContent?: (isLoading: boolean) => void;
  setIsLoadedStudySessionAndContent?: (isLoaded: boolean) => void;
  refreshStudySessionAndContent?: () => void;
}

const Header: React.FC<HeaderProps> = ({
  type,
  changePhase,
  setIsSwitchingExam,
  setIsLoadingStudySessionAndContent,
  setIsLoadedStudySessionAndContent,
  refreshStudySessionAndContent,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const flashcards = useSelector((state: RootState) => state.flashcards);
  const blocks = useSelector((state: RootState) => state.blocks);

  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const [availableExams, setAvailableExams] = useState<UserExam[] | []>([]);
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const [examSelection, setExamSelection] = useState(primaryExam?.id || '');
  const [examMenuIsOpen, setExamMenuIsOpen] = useState(false);

  const examMenuButtonRef = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      getAvailableExams();
    }
  }, [currentUser]);

  useEffect(() => {
    if (examSelection && examSelection !== primaryExam?.id) {
      if (setIsSwitchingExam) {
        setIsSwitchingExam(true);
      }
      assignExamToUser();
    }
  }, [examSelection]);

  const getAvailableExams = async () => {
    const response = await fetchExams();
    if (response) {
      setAvailableExams(response);
    }
  };

  const allBlocksSubmitted = () => {
    let allBlocksSubmitted = true;
    blocks.forEach((block) => {
      if (!block.isSubmitted) {
        allBlocksSubmitted = false;
      }
    });
    return allBlocksSubmitted;
  };

  const renderFlashcardButton = () => {
    if (!todaySession?.flashcardCount || todaySession?.isReview) return null;
    if (flashcards.new.length + flashcards.due.length === 0) {
      return (
        <Tooltip title="No flashcards remaining." enterTouchDelay={0}>
          <span>
            <button
              className="button button--glass button--phase"
              onClick={() => {
                hapticsImpactLight();
              }}
              disabled
            >
              <Check />
              <span>Flashcards</span>
            </button>
          </span>
        </Tooltip>
      );
    } else {
      if (changePhase) {
        return (
          <button
            className="button button--glass button--phase"
            onClick={() => {
              changePhase(StudyPhase.flashcard);
              localStorage.removeItem('ora-today-block-id');
              hapticsImpactLight();
            }}
          >
            <Layers />
            <span>Flashcards</span>
          </button>
        );
      }
    }
  };

  const assignExamToUser = async () => {
    if (!currentUser || !refreshStudySessionAndContent) return;
    const existingExam = currentUserExams.find(
      (exam) => exam.id === examSelection
    );
    if (existingExam) {
      const response = await changePrimaryExam(currentUser.id, examSelection);
      const updatedUser = { ...currentUser };
      updatedUser.exams = response;
      dispatch(setUserInfo(updatedUser));
    } else {
      const newExamName = availableExams.find(
        (exam) => exam.id === examSelection
      )?.name;
      if (newExamName) {
        const response = await addOrUpdateUserExam({
          userId: currentUser.id,
          examId: examSelection,
          examDate: moment(calcDefaultExamDate(newExamName)).toISOString(),
          selectedFlashcardCount: defaultFlashcards(newExamName),
          selectedVignetteCount: defaultVignettes(newExamName),
          isUsingFlashcards: true,
          isUsingQbank: true,
          hasCompletedRealExam: false,
          realExamScore: null,
          ignoredExamDate: false,
          clearTodaySession: undefined,
        });
        const updatedUser = { ...currentUser };
        updatedUser.exams = response;
        dispatch(setUserInfo(updatedUser));
      }
    }

    // Refresh study session and content
    await refreshStudySessionAndContent();
    if (setIsSwitchingExam) {
      setIsSwitchingExam(false);
    }
  };

  const renderOraLogo = () => {
    if (type === 'study' || type === 'home') return;
    if (currentUser && currentUser.id) {
      return <OraLogo />;
    } else {
      return <OraLogo type="link" url={'https://www.oraai.com'} />;
    }
  };

  const sortedExams = availableExams.sort((a, b) => {
    if (a.name.includes('Step') && b.name.includes('Step')) {
      return a.name.localeCompare(b.name);
    } else if (a.name.includes('Step')) {
      return -1;
    } else if (b.name.includes('Step')) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });

  const nonStepExams = sortedExams.filter(
    (exam) => !exam.name.includes('Step')
  );
  const firstSet = nonStepExams.slice(0, 4);
  const secondSet = nonStepExams.slice(4, 8);

  return (
    <div
      className={`header ${type ? 'header--' + type : ''} ${
        !primaryExam?.isUsingQbank ? 'header--study--flashcard-only' : ''
      }`}
    >
      <Tooltip
        title={
          type === 'study' &&
          (todaySession.phase === StudyPhase.flashcard ||
            todaySession.phase === StudyPhase.qbank)
            ? 'Your progress will be saved.'
            : ''
        }
        enterTouchDelay={0}
      >
        <button
          role="link"
          className={type === 'study' ? 'button button--glass' : 'button--ora'}
          style={type === 'generic' ? { cursor: 'default' } : {}}
          onClick={() => {
            if (type !== 'generic') {
              navigate('/');
              hapticsImpactLight();
            }
          }}
        >
          {renderOraLogo()}
          {type === 'study' &&
            (todaySession.phase === StudyPhase.qbankReview ||
              todaySession.phase === StudyPhase.sessionReview) && (
              <>
                <Home />
                <span>Home</span>
              </>
            )}
          {type === 'study' &&
            (todaySession.phase === StudyPhase.flashcard ||
              todaySession.phase === StudyPhase.qbank) && (
              <>
                <Home />
                <span>Home</span>
              </>
            )}
        </button>
      </Tooltip>
      {type === 'study' && (
        <div className="study-middle">
          {todaySession.phase === StudyPhase.flashcard && <StudyProgress />}
        </div>
      )}
      {type === 'study' && changePhase && (
        <>
          {todaySession.phase === StudyPhase.flashcard &&
          todaySession?.vignetteCount > 0 ? (
            <button
              className="button button--glass button--phase"
              onClick={() => {
                changePhase(StudyPhase.qbank);
                hapticsImpactLight();
              }}
            >
              {allBlocksSubmitted() ? <Check /> : <List />}
              <span>QBank</span>
            </button>
          ) : (
            <div className="spacer-button"></div>
          )}
          {todaySession.phase === StudyPhase.qbank && renderFlashcardButton()}
        </>
      )}
      {type === HeaderType.home && (
        <>
          <div className="exam-selector">
            <div className="exam-selector_top">
              <button
                className="button button--glass"
                id="exam-menu-button"
                ref={examMenuButtonRef}
                aria-controls={examMenuIsOpen ? 'exam-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={examMenuIsOpen ? 'true' : undefined}
                onClick={() => {
                  setExamMenuIsOpen(true);
                  hapticsImpactLight();
                }}
              >
                {primaryExam?.name}
                <TriangleDown />
              </button>
              <Menu
                id="exam-menu"
                anchorEl={examMenuButtonRef.current}
                open={examMenuIsOpen}
                onClose={() => setExamMenuIsOpen(false)}
                MenuListProps={{
                  'aria-labelledby': 'exam-menu-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div className="available-exams">
                  <div className="available-exams_set">
                    <div className="available-exams_set_title">Step</div>
                    <div className="available-exams_set_exams">
                      {sortedExams.map((exam) => {
                        if (exam.name.includes('Step')) {
                          return (
                            <button
                              key={'exam-' + exam.id}
                              className={`available-exams_set_exams_exam ${exam.id === examSelection ? 'selected' : ''}`}
                              onClick={() => {
                                setExamSelection(exam.id);
                                setExamMenuIsOpen(false);
                              }}
                            >
                              <TriangleRight />
                              {exam.name}
                            </button>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div className="available-exams_set" style={{ flex: 1 }}>
                    <div className="available-exams_set_title">Shelf</div>
                    <div className="available-exams_set_exams available-exams_set_exams--shelf">
                      <div>
                        {firstSet.map((exam) => (
                          <button
                            className={`available-exams_set_exams_exam ${exam.id === examSelection ? 'selected' : ''}`}
                            key={exam.id}
                            onClick={() => {
                              setExamSelection(exam.id);
                              setExamMenuIsOpen(false);
                            }}
                          >
                            <TriangleRight />
                            {exam.name.replace(' Shelf Exam', '')}
                          </button>
                        ))}
                      </div>
                      <div>
                        {secondSet.map((exam) => (
                          <button
                            className={`available-exams_set_exams_exam ${exam.id === examSelection ? 'selected' : ''}`}
                            key={exam.id}
                            onClick={() => {
                              setExamSelection(exam.id);
                              setExamMenuIsOpen(false);
                            }}
                          >
                            <TriangleRight />
                            {exam.name.replace(' Shelf Exam', '')}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Menu>
            </div>
            {setIsLoadingStudySessionAndContent &&
              setIsLoadedStudySessionAndContent &&
              refreshStudySessionAndContent && (
                <div className="exam-selector_bottom">
                  <ExamDateSelect
                    setIsLoadingStudySessionAndContent={
                      setIsLoadingStudySessionAndContent
                    }
                    setIsLoadedStudySessionAndContent={
                      setIsLoadedStudySessionAndContent
                    }
                    refreshStudySessionAndContent={
                      refreshStudySessionAndContent
                    }
                  />
                  <TopicSelect
                    setIsLoadingStudySessionAndContent={
                      setIsLoadingStudySessionAndContent
                    }
                    setIsLoadedStudySessionAndContent={
                      setIsLoadedStudySessionAndContent
                    }
                    refreshStudySessionAndContent={
                      refreshStudySessionAndContent
                    }
                  />
                  <WorkloadSelect
                    setIsLoadingStudySessionAndContent={
                      setIsLoadingStudySessionAndContent
                    }
                    setIsLoadedStudySessionAndContent={
                      setIsLoadedStudySessionAndContent
                    }
                    refreshStudySessionAndContent={
                      refreshStudySessionAndContent
                    }
                  />
                </div>
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
