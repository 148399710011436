import React, { useEffect } from 'react';
import { ArrowRight, Check, ChevronRight } from 'react-feather';
import Page from '../../components/Page';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import MSHeader from '../../components/NonAuth/MSHeader';
import MSFooter from '../../components/NonAuth/MSFooter';
import NonAuthPage from '../../components/NonAuth/NonAuthPage';
import { useNavigate } from 'react-router-dom';
import LightStreak from '../../assets/images/light-streak.png';
import { handleCheckout } from '../../services/subscriptions';

const Pricing: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const subscription = useSelector((state: RootState) => state.subscription);

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      document.body.classList.add('pricing-page-authed');
      if (currentUser?.isOnboarded === false) {
        navigate('/onboarding');
      }
    } else {
      document.body.classList.add('pricing-page');
    }
    return () => {
      document.body.classList.remove('pricing-page');
      document.body.classList.remove('pricing-page-authed');
    };
  }, [currentUser]);

  useEffect(() => {
    if (
      currentUser &&
      subscription?.subscription?.status !== 'TRIAL' &&
      subscription?.subscription?.status !== 'CANCELED'
    ) {
      navigate('/account?sub=billing');
    }
  }, [subscription, currentUser]);

  const renderFeatures = () => {
    return (
      <div className="pricing-option_inner_bottom_features">
        <h3>
          <span>Features</span>
          <span className="line"></span>
        </h3>
        <ul>
          <li>
            <Check />
            USMLE Step 1 &amp; 2CK exams
          </li>
          <li>
            <Check />
            All 8 NBME Shelf exams
          </li>
          <li>
            <Check />
            12,000+ QBank questions
          </li>
          <li>
            <Check />
            25,000+ flashcards
          </li>
          <li>
            <Check />
            AI Autopilot
          </li>
        </ul>
      </div>
    );
  };

  const renderPricingOption = (
    key: string,
    title: string,
    description: string,
    price: string,
    link: string,
    savings?: string,
    savingPercent?: string,
    isGroupOption?: boolean
  ) => {
    return (
      <div
        className={`pricing-option pricing-option--${key}${currentUser ? ' pricing-option--authed' : ''}${
          key === '3' ? ' pricing-option--primary' : ''
        }`}
      >
        <div className="pricing-option_inner">
          {savingPercent && (
            <div className="savings-pill">Save {savingPercent}</div>
          )}
          <div className="pricing-option_inner_top">
            <h2>{title}</h2>
            <p>{description}</p>
            <div className="pricing-option_inner_top_price">
              <div className="pricing-option_inner_top_price_amount">
                <span className="dollar-sign">$</span>
                {price}
              </div>
              <div className="pricing-option_inner_top_price_label">
                per month
              </div>
              <div className="savings">{savings && <p>{savings}</p>}</div>
            </div>
            <button
              className="button button--glass button--get-started"
              onClick={() => {
                if (isGroupOption) {
                  navigate('/group');
                } else if (currentUser) {
                  handleCheckout(
                    key === '1'
                      ? 'MONTHLY'
                      : key === '2'
                        ? 'ANNUAL'
                        : 'FOUR_YEAR'
                  );
                } else {
                  navigate(link);
                }
              }}
            >
              {isGroupOption
                ? 'Create Group'
                : currentUser
                  ? 'Get Started'
                  : 'Subscribe Now'}
            </button>
          </div>
          <div className="pricing-option_inner_bottom">{renderFeatures()}</div>
          {key === '3' && <div className="gradient-blur"></div>}
        </div>
        {key === '3' && (
          <>
            <img className="light-streak" src={LightStreak} alt="" />
            <img
              className="light-streak light-streak--2"
              src={LightStreak}
              alt=""
            />
            <img
              className="light-streak light-streak--3"
              src={LightStreak}
              alt=""
            />
          </>
        )}
      </div>
    );
  };

  const renderPricing = () => {
    return (
      <div className="pricing">
        <div className="pricing_header">
          <h1 className="font-gradient">
            Welcome to the era of AI-guided learning.
          </h1>
          {!currentUser && (
            <button
              className="button button--glass"
              onClick={() => {
                navigate('/auth?signup=true');
              }}
            >
              5 Day Free Trial
              <ChevronRight className="default-icon" />
              <ArrowRight className="hover-icon" />
            </button>
          )}
        </div>
        <div className="pricing_body">
          {renderPricingOption(
            '1',
            'Monthly',
            'Best deal for last-minute cramming',
            '100',
            !currentUser ? '/auth?signup=true&plan=MONTHLY' : '/'
          )}
          {renderPricingOption(
            '2',
            'Annual',
            'Best deal for most MS1s, MS2s and MS3s',
            '50',
            !currentUser ? '/auth?signup=true&plan=ANNUAL' : '/',
            '$600 annual commitment, billed monthly',
            '50%'
          )}
          {renderPricingOption(
            '3',
            'Group Discount',
            `Create a ${import.meta.env.VITE_GROUP_DISCOUNT_SIZE || '5'}-student group to save 75%`,
            '25',
            !currentUser ? '/auth?signup=true&plan=GROUP' : '/',
            '$300 annual commitment, billed monthly',
            '75%',
            true
          )}
        </div>
        {/* <div className="pricing_footer">
          <button className="button button--link">
            Discounts are always available for low-income and URM students
            <ArrowRight />
          </button>
        </div> */}
      </div>
    );
  };

  return (
    <>
      {currentUser && <Page>{renderPricing()}</Page>}
      {!currentUser && (
        <>
          <MSHeader />
          <NonAuthPage>{renderPricing()}</NonAuthPage>
          <MSFooter />
        </>
      )}
      <div className="green-blur"></div>
    </>
  );
};

export default Pricing;
