// src/components/Exam/FeatureTours/AuthFeatureTour.tsx
import React, { useMemo, useEffect } from 'react';
import parse from 'html-react-parser';
import { useHotkeys } from 'react-hotkeys-hook';
import { Placement } from 'react-joyride';

import FeatureTour, { TourStepConfig } from '../FeatureTour';

interface AuthFeatureTourProps {
  // --- Data Dependencies ---
  qBankDemoQuestionCorrect: boolean | null;
  stepTargets: (React.RefObject<HTMLElement> | string)[];

  // --- Control Props (from useFeatureTours or parent) ---
  run: boolean;
  stepIndex: number;
  onStepChange: (newIndex: number) => void;
  onTourEnd?: () => void;

  // --- Action Props (specific to this tour) ---
  handleSeenQBankTour: () => void;
}

const AuthFeatureTour: React.FC<AuthFeatureTourProps> = ({
  qBankDemoQuestionCorrect,
  stepTargets,
  run,
  stepIndex,
  onStepChange,
  onTourEnd,
  handleSeenQBankTour,
}) => {
  // --- Hotkey Specific to this Tour ---
  useHotkeys('f', (event) => keybinds('f', event), {
    keyup: true,
    enableOnFormTags: true,
    preventDefault: true,
  });

  const keybinds = (key: string, event?: KeyboardEvent) => {
    // Only trigger if tour is running and on the correct step
    if (run && key === 'f' && stepIndex === 2) {
      if (event) event.preventDefault();
      onStepChange(3);
      // Also simulate clicking the flashcards tab
      const flashcardsButton = document.querySelector(
        stepTargets[2] as string
      ) as HTMLButtonElement;
      if (flashcardsButton) flashcardsButton.click();

      hideFloater();
    }
  };

  // --- Effect Specific to this Tour (Advance on Correctness Change) ---
  useEffect(() => {
    // Only advance if the answer state changes *from* null *to* boolean, and we are on step 0
    if (run && qBankDemoQuestionCorrect !== null && stepIndex === 0) {
      // Add a small delay for the user to read the initial message if needed
      const timer = setTimeout(() => {
        onStepChange(1);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [qBankDemoQuestionCorrect, run, stepIndex, onStepChange]);

  // Helper to hide the Joyride floater
  const hideFloater = () => {
    const floater = document.querySelector('.__floater') as HTMLDivElement;
    if (floater) {
      floater.style.display = 'none';
    }
  };

  // --- Define Steps Configuration ---
  const stepsConfig: TourStepConfig[] = useMemo(() => {
    return [
      // Step 0: Welcome
      {
        target: stepTargets[0],
        placementDesktop: 'right' as Placement,
        placementMobile: 'center' as Placement,
        content: parse(
          `Welcome to Ora's QBank! Let's start by answering this question.`
        ),
        footerContent: (
          <button
            onClick={() => {
              // Closing doesn't advance step, just hides floater
              hideFloater();
              // If you want 'Close' to end the tour: onTourEnd?.();
            }}
            className="button button--glass m-b-0-75"
          >
            Close
          </button>
        ),
        // Mark tour as seen when the first step is shown
        preStepAction: handleSeenQBankTour,
      },
      // Step 1: Feedback
      {
        target: stepTargets[1],
        placementDesktop: 'right' as Placement,
        placementMobile: 'center' as Placement,
        content:
          qBankDemoQuestionCorrect === null
            ? 'Submit your answer.' // Fallback content
            : qBankDemoQuestionCorrect
              ? parse(
                  'Nice job! Because you got this question right, Ora will show you this topic less frequently in the future.'
                )
              : parse(
                  'Nice try! Because you got this question wrong, Ora will show you this topic more frequently until you know it.'
                ),
        footerContent: (
          <>
            <button
              onClick={() => {
                onStepChange(2);
                hideFloater();
              }}
              className="button button--glass"
              disabled={qBankDemoQuestionCorrect === null}
            >
              Next
            </button>
            <button
              className="button button--skip"
              onClick={() => {
                onTourEnd?.();
                hideFloater();
              }}
            >
              Skip Tour
            </button>
          </>
        ),
      },
      // Step 2: Linked Flashcards
      {
        target: stepTargets[2],
        placementDesktop: 'left' as Placement,
        placementMobile: 'center' as Placement,
        content: parse(
          'Every QBank question has a set of linked flashcards. Click the Flashcards tab (or press F) to view them.'
        ),
        footerContent: (
          <>
            <button
              onClick={() => {
                onTourEnd?.();
                hideFloater();
              }}
              className="button button--glass"
              style={{ marginBottom: '1rem' }}
            >
              Finish
            </button>
          </>
        ),
      },
    ];
  }, [
    stepTargets,
    qBankDemoQuestionCorrect,
    onStepChange,
    onTourEnd,
    handleSeenQBankTour,
  ]);

  // --- Render Generic Tour ---
  return (
    <FeatureTour
      stepsConfig={stepsConfig}
      run={run}
      stepIndex={stepIndex}
      onStepChange={onStepChange}
      onTourEnd={onTourEnd}
      configKey={
        qBankDemoQuestionCorrect === null
          ? 'initial'
          : qBankDemoQuestionCorrect
            ? 'correct'
            : 'incorrect'
      }
    />
  );
};

export default AuthFeatureTour;
