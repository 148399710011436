export const HomeActive = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5603 10.1885L13.0603 2.6885C12.779 2.40741 12.3977 2.24951 12 2.24951C11.6024 2.24951 11.221 2.40741 10.9397 2.6885L3.4397 10.1885C3.29973 10.3274 3.18878 10.4928 3.1133 10.675C3.03782 10.8572 2.99931 11.0525 3.00001 11.2498V20.2498C3.00001 20.4487 3.07903 20.6394 3.21968 20.7801C3.36033 20.9207 3.5511 20.9998 3.75001 20.9998H9.75001C9.94892 20.9998 10.1397 20.9207 10.2803 20.7801C10.421 20.6394 10.5 20.4487 10.5 20.2498V14.9998H13.5V20.2498C13.5 20.4487 13.579 20.6394 13.7197 20.7801C13.8603 20.9207 14.0511 20.9998 14.25 20.9998H20.25C20.4489 20.9998 20.6397 20.9207 20.7803 20.7801C20.921 20.6394 21 20.4487 21 20.2498V11.2498C21.0007 11.0525 20.9622 10.8572 20.8867 10.675C20.8112 10.4928 20.7003 10.3274 20.5603 10.1885Z"
        fill="currentColor"
      />
    </svg>
  );
};
