import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionStatus } from '../services/subscriptions';
import { setSubscription } from '../slices/subscriptions/subscriptionsSlice';
import { RootState } from '../store/store';

interface SubscriptionProviderProps {
  children: React.ReactNode;
}

const SubscriptionProvider = ({ children }: SubscriptionProviderProps) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  useEffect(() => {
    if (!currentUser) return; // Only fetch subscription data if a user is logged in

    const fetchSubscriptionData = async () => {
      const subscriptionData = await getSubscriptionStatus();
      if (subscriptionData) {
        dispatch(setSubscription(subscriptionData));
      }
    };

    // Fetch subscription data when a user is available
    fetchSubscriptionData();
  }, [dispatch, currentUser]);

  return <>{children}</>;
};

export default SubscriptionProvider;
