import React, { useState, useEffect } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import parse from 'html-react-parser';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { joyrideStyles } from '../../../utils/joyrideStyles';

interface AmbassadorTourProps {
  stepTargets: (React.RefObject<HTMLElement> | string)[];
  stepFunctions: (() => void)[];
  run: boolean;
  stepIndex: number;
  onStepChange: (newIndex: number) => void;
  onTourEnd?: () => void;
}

const AmbassadorTour: React.FC<AmbassadorTourProps> = ({
  stepTargets,
  stepFunctions,
  run,
  stepIndex,
  onStepChange,
  onTourEnd,
}) => {
  const [steps, setSteps] = useState<Step[]>([]);
  const [ready, setReady] = useState(false);
  const windowWidth = useWindowWidth();
  const placementsDesktop = ['right', 'bottom', 'bottom', 'bottom', 'right'];
  const placementsMobile = ['center', 'auto', 'auto', 'auto', 'top'];

  const renderContents = (index: number) => {
    if (index === 0) {
      const text =
        'Welcome to the Peer Advocate program! Click here to see your referral link, track your referrals, and provide feedback. Thank you for your support!';
      return parse(text);
    }
  };

  useEffect(() => {
    document.body.classList.add('viewing-ambassador-tour');
  }, []);

  useEffect(() => {
    const areTargetsReady = stepTargets.every((target) => {
      if (typeof target === 'string') {
        return true;
      } else {
        return target.current !== null;
      }
    });
    if (areTargetsReady) {
      // @ts-expect-error - TS doesn't know about all step props
      const newSteps: Step[] = stepTargets.map((target, index) => {
        const stepTarget =
          typeof target === 'string' ? target : target.current!;
        return {
          target: stepTarget,
          placement:
            windowWidth > 900
              ? placementsDesktop[index]
              : placementsMobile[index],
          disableBeacon: true,
          content: (
            <div className="tour-wrapper">
              <div className="tour-wrapper_content p-b-1">
                {renderContents(index)}
              </div>
              <div className="blur-1"></div>
              <div className="blur-2"></div>
            </div>
          ),
        };
      });
      setSteps(newSteps);
      setReady(true);
    }
  }, [stepTargets, onStepChange, onTourEnd]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { index, type, status } = data;

    if (type === 'step:before') {
      if (stepFunctions[index]) {
        stepFunctions[index]();
      }
    }

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      if (onTourEnd) {
        onTourEnd();
      }
    }
  };

  if (!ready) {
    return null;
  }
  return (
    <>
      <Joyride
        steps={steps}
        run={run}
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton={true}
        hideBackButton={true}
        disableScrolling={true}
        disableOverlay
        styles={joyrideStyles}
      />
    </>
  );
};

export default AmbassadorTour;
