// src/components/Exam/FeatureTours/NonAuthFeatureTour.tsx
import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import { ArrowRight, ChevronRight } from 'react-feather';
import posthog from 'posthog-js';

import FeatureTour, { TourStepConfig } from '../FeatureTour';
import CustomModal from '../../../Global/CustomModal';
import { Ora } from '../../../../assets/svgs/Ora';
import { Placement } from 'react-joyride';

interface NonAuthFeatureTourProps {
  // --- Data Dependencies ---
  answeredCorrectly: boolean | null;
  stepTargets: (React.RefObject<HTMLElement> | string)[];

  // --- Control Props (from useFeatureTours or parent) ---
  run: boolean;
  stepIndex: number;
  onStepChange: (newIndex: number) => void;
  onTourEnd?: () => void;

  // --- Modal Props (specific to this tour) ---
  createAccountModalIsOpen: boolean;
  setCreateAccountModalIsOpen: (isOpen: boolean) => void;
}

const NonAuthFeatureTour: React.FC<NonAuthFeatureTourProps> = ({
  answeredCorrectly,
  stepTargets,
  run,
  stepIndex,
  onStepChange,
  onTourEnd,
  createAccountModalIsOpen,
  setCreateAccountModalIsOpen,
}) => {
  // --- Define Steps Configuration ---
  const stepsConfig: TourStepConfig[] = useMemo(() => {
    // Helper to hide the Joyride floater if it exists
    const hideFloater = () => {
      const floater = document.querySelector('.__floater') as HTMLDivElement;
      if (floater) {
        floater.style.display = 'none';
      }
    };

    return [
      // Step 0: Intro
      {
        target: stepTargets[0],
        placementDesktop: 'right' as Placement,
        placementMobile: 'center' as Placement,
        content: parse(
          'This is the highest-yield topic for Step.<br/><br/>Every question is designed by USMLE experts and vetted by doctors.<br/><br/>Answer this question to see what makes Ora special.'
        ),
        footerContent: (
          <button
            onClick={() => {
              hideFloater();
            }}
            className="button button--glass m-b-1"
          >
            Got It
          </button>
        ),
      },
      // Step 1: Feedback & Next Topic
      {
        target: stepTargets[1],
        placementDesktop: 'right' as Placement,
        placementMobile: 'center' as Placement,
        content:
          answeredCorrectly === null // Handle case before answer submitted
            ? 'Submit your answer.' // Placeholder if somehow shown before answer
            : answeredCorrectly
              ? parse(
                  "Nice work! Ora is already building a blueprint of your knowledge.<br/><br/>Every day, Ora assigns the highest-yield topics where you need the most support. By test day, you'll learn all 1,500 topics."
                )
              : parse(
                  "Good try! Ora is already building a blueprint of your knowledge.<br/><br/>Every day, Ora assigns the highest-yield topics where you need the most support. By test day, you'll learn all 1,500 topics."
                ),
        footerContent: (
          <button
            onClick={() => {
              onStepChange(2);
              hideFloater(); // Hide on explicit next click
            }}
            className="button button--glass m-b-1"
            // Only enable this button once the answer is submitted
            disabled={answeredCorrectly === null}
          >
            Next
          </button>
        ),
      },
      // Step 2: Flashcards & Sign Up
      {
        target: stepTargets[2],
        placementDesktop: 'left' as Placement,
        placementMobile: 'center' as Placement,
        content: parse(
          'Ora automatically adds flashcards to your deck when you get a question wrong.<br/><br/>Ora flashcards use spaced repetition powered by machine learning. Complete your flashcards to learn the basics with active recall.'
        ),
        footerContent: (
          <>
            <button
              onClick={() => {
                if (onTourEnd) onTourEnd();
                hideFloater(); // Hide on finishing
              }}
              className="button button--glass"
            >
              Got It
            </button>
            <a
              className="button button--glass button--free-account m-b-1"
              href="/auth?signup=true"
              onClick={() => {
                posthog?.capture('ms_demo_create_account', {
                  isLastStep: true,
                });
                if (onTourEnd) onTourEnd(); // Also end tour on clicking sign up
                hideFloater();
              }}
            >
              Create Free Account Now
              <ChevronRight className="icon" />
              <ArrowRight className="hover-icon" />
            </a>
          </>
        ),
        // Auto-click logic should ideally live in the hook managing state,
        // triggered when stepIndex becomes 2. Forcing click via preStepAction:
        preStepAction: () => {
          const linkedFlashcards = document.querySelector(
            stepTargets[2] as string // Assuming target 2 is a selector
          ) as HTMLButtonElement;
          if (linkedFlashcards) {
            linkedFlashcards.click();
          }
        },
      },
    ];
  }, [stepTargets, answeredCorrectly, onStepChange, onTourEnd]);

  // --- Render Generic Tour & Specific Modal ---
  return (
    <>
      <FeatureTour
        stepsConfig={stepsConfig}
        run={run}
        stepIndex={stepIndex}
        onStepChange={onStepChange}
        onTourEnd={onTourEnd}
        configKey={
          answeredCorrectly === null
            ? 'initial'
            : answeredCorrectly
              ? 'correct'
              : 'incorrect'
        }
      />
      {/* NonAuth tour specific modal */}
      <CustomModal
        open={createAccountModalIsOpen}
        onClose={() => setCreateAccountModalIsOpen(false)}
        name={'create-account'}
        blurs={true}
      >
        <div className="modal_content">
          <Ora />
          <p>Create an account now to continue learning with Ora.</p>
          <div className="actions">
            <a
              className="button button--glass button--create-account"
              href="/auth?signup=true"
              onClick={() => {
                posthog?.capture('ms_demo_create_account', {
                  isCreateAccountModal: true,
                });
              }}
            >
              Create Account
            </a>
            <div>Free. No sign-up required.</div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default NonAuthFeatureTour;
