import { useEffect } from 'react';
import { router } from './routes/Routes';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { darkTheme, theme } from './theme/theme';
import { RootState } from './store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import { posthog } from 'posthog-js';
import KeywordPopoversManager from './components/Global/KeywordsPopoverManager';
import { updateCurrentUserInfo } from './services/auth';
import { setUserInfo } from './slices/auth/authSlice';
import { GamepadProvider } from './context/GamepadProvider';
import { initializePushNotifications } from './services/pushNotifications';
import { useSystemTheme } from './hooks/useSystemTheme';
import {
  getOraCookieAcceptCookie,
  setOraCookieAcceptCookie,
} from './utils/cookiesUtils';
import { User } from './types/User';
import { setStatsData } from './slices/stats/statsSlice';
import { fetchTimeStats } from './services/stats';
import SubscriptionProvider from './components/SubscriptionProvider';
import { Detector } from 'react-detect-offline';
import { X } from 'react-feather';

const posthogApiKey = import.meta.env.VITE_PUBLIC_POSTHOG_KEY;
const posthogApiHost = import.meta.env.VITE_PUBLIC_POSTHOG_HOST;

function App() {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const dispatch = useDispatch();
  const systemTheme = useSystemTheme();

  useEffect(() => {
    window.Intercom('shutdown');
    initializePostHog(currentUser);
    if (currentUser && currentUser.email) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'x6oeppkc',
        source: 'ora',
        name: currentUser.name || '',
        email: currentUser.email,
        user_id: currentUser.id,
        created_at: currentUser.createdAt,
      });

      loadTimeStats();

      if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
        initializePushNotifications(currentUser.id);
        if (!currentUser.hasIOSApp) {
          userOnApp('ios');
        }
      } else if (
        Capacitor.isNativePlatform() &&
        Capacitor.getPlatform() === 'android'
      ) {
        if (!currentUser.hasAndroidApp) {
          userOnApp('android');
        }
      }
    } else {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'x6oeppkc',
        source: 'ora',
      });
    }

    // Update body class based on user's dark mode preference
    if (
      location.pathname === '/pricing' ||
      (currentUser && currentUser.theme === 'dark') ||
      (currentUser && currentUser.theme === 'system' && systemTheme === 'dark')
    ) {
      document.body.classList.add('theme-dark');
    } else {
      document.body.classList.remove('theme-dark');
    }

    if (currentUser && currentUser.demoMode) {
      document.body.classList.add('DEMO-MODE');
    } else {
      document.body.classList.remove('DEMO-MODE');
    }
  }, [currentUser]);

  // Add classes based on platform
  if (Capacitor.getPlatform() === 'ios') {
    document.body.classList.add('CAPACITOR-IOS');
  }
  if (Capacitor.getPlatform() === 'android') {
    document.body.classList.add('CAPACITOR-ANDROID');
  }
  if (Capacitor.isNativePlatform()) {
    document.body.classList.add('IS-CAPACITOR');
  }

  const userOnApp = async (platformType: 'ios' | 'android') => {
    let updateInfo = {};
    if (platformType === 'ios') {
      updateInfo = { hasIOSApp: true };
    } else {
      updateInfo = { hasAndroidApp: true };
    }
    const updateUser = await updateCurrentUserInfo(updateInfo);
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const initializePostHog = (currentUser: User | null) => {
    if (process.env.NODE_ENV === 'production') {
      const cookiesAccepted = getOraCookieAcceptCookie();
      if (
        (currentUser && currentUser?.email) ||
        cookiesAccepted === 'accepted'
      ) {
        console.log('Initializing PostHog');
        posthog.init(posthogApiKey, {
          api_host: posthogApiHost,
          autocapture: false,
          disable_session_recording: true,
        });
        posthog.debug(false);

        if (currentUser && currentUser?.email) {
          // Reset the Cookie to Accepted Since Authed
          setOraCookieAcceptCookie('accepted');

          // Identify user in PostHog
          posthog.identify(currentUser.id, {
            email: currentUser.email,
            name: currentUser.name,
            created_at: currentUser.createdAt,
            role: currentUser.role,
            isPeerAdvocate: currentUser.ambassador,
            has_ios_app: currentUser.hasIOSApp,
            has_android_app: currentUser.hasAndroidApp,
          });

          // Start Recording user session for Authenticated Users
          if (
            !currentUser.email.includes('jctest') &&
            !currentUser.email.includes('kbtest') &&
            !currentUser.email.includes('rptest') &&
            !currentUser.email.includes('jacob.caccamo') &&
            !currentUser.email.includes('ryanllewellynphelps') &&
            !currentUser.email.includes('ryanrlphelps') &&
            !currentUser.email.includes('losaltoslandscaping.com') &&
            !currentUser.email.includes('kaycbas') &&
            !currentUser.email.includes('kevin@synaptiq.co') &&
            !currentUser.email.includes('jacob@synaptiq.co') &&
            !currentUser.email.includes('ryan@synaptiq.co') &&
            !currentUser.email.includes('kevin@oraai.com') &&
            !currentUser.email.includes('jacob@oraai.com') &&
            !currentUser.email.includes('ryan@oraai.com') &&
            !currentUser.email.includes('vkbzs8xkvd@privaterelay.appleid.com') // Jake's Apple Account
          ) {
            posthog.startSessionRecording();
          }
        }
      } else {
        console.log('PostHog Not Initialized');
        if (cookiesAccepted === 'rejected') {
          console.log('Non Essential Cookies Rejected');
        }
      }
    }
  };

  const loadTimeStats = async () => {
    const timeStats = await fetchTimeStats();
    dispatch(
      setStatsData({
        averageFlashcardTime: timeStats,
        averageVignetteTime: null,
      })
    );
  };

  return (
    <SubscriptionProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider
          theme={
            (currentUser && currentUser.theme === 'dark') ||
            (currentUser &&
              currentUser.theme === 'system' &&
              systemTheme === 'dark')
              ? darkTheme
              : theme
          }
        >
          <GamepadProvider>
            {currentUser && (
              <Detector
                render={({ online }) =>
                  !online ? (
                    <div className="offline-indicator">
                      You may be offline. Ora requires an internet connection.
                      <button
                        onClick={() => {
                          const offlineIndicator = document.querySelector(
                            '.offline-indicator'
                          ) as HTMLElement;
                          if (offlineIndicator) {
                            offlineIndicator.style.display = 'none';
                          }
                        }}
                      >
                        <X />
                      </button>
                    </div>
                  ) : null
                }
              />
            )}
            <RouterProvider router={router} />
          </GamepadProvider>
          <KeywordPopoversManager />
        </ThemeProvider>
      </LocalizationProvider>
    </SubscriptionProvider>
  );
}

export default App;
