export const ChartActive = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5303 20.0303C21.671 19.8897 21.75 19.6989 21.75 19.5L21.7471 8.7395C21.7156 8.64222 21.6646 8.5524 21.5971 8.47558C21.5296 8.39875 21.4471 8.33653 21.3547 8.29275C21.2623 8.24896 21.1619 8.22454 21.0597 8.22097C20.9575 8.21741 20.8557 8.23478 20.7605 8.27201C20.6652 8.30925 20.5786 8.36556 20.5059 8.4375L14.9634 13.2872L9.45 9.15188C9.31241 9.04866 9.14353 8.99587 8.97166 9.00236C8.79978 9.00885 8.63536 9.07421 8.50594 9.1875L3.75 13.3472V4.5C3.75 4.30109 3.67098 4.11032 3.53033 3.96967C3.38968 3.82902 3.19891 3.75 3 3.75C2.80109 3.75 2.61032 3.82902 2.46967 3.96967C2.32902 4.11032 2.25 4.30109 2.25 4.5V19.5C2.25 19.6989 2.32902 19.8897 2.46967 20.0303C2.61032 20.171 2.80109 20.25 3 20.25H21C21.1989 20.25 21.3897 20.171 21.5303 20.0303Z"
        fill="currentColor"
      />
    </svg>
  );
};
