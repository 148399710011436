import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import StatBlock from './StatBlock';
import { Home } from 'react-feather';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { Block, StudyPhase, StudySession } from '../../../types/Study';
import { useEffect, useRef, useState, useCallback } from 'react';
import { fetchFlashcardResponses } from '../../../services/studySession';
import moment from 'moment';
import { hapticsImpactLight } from '../../../utils/haptics';
import { interpolateGradient } from '../../../utils/dynamicBackground';
import FadeInComponent from '../../Onboarding/OnboardingSummary/FadeInComponent';
import ThreeWave from '../../Home/ThreeWave';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import TopicRadar from '../../Onboarding/OnboardingSummary/TopicRadar';
import {
  fetchPreviousQBankScore,
  fetchTopicTreeStats,
} from '../../../services/stats';
import _ from 'lodash';
import AnimatedCheckmark from '../../Global/AnimatedCheckmark';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { TriangleDown } from '../../../assets/svgs/TriangleDown';
import { Capacitor } from '@capacitor/core';
import { formatTopicStats } from '../../../utils/formatTopicStats';

interface SessionSummaryProps {
  todaySession: StudySession;
  reviewQbankBlock: (blockId: string) => void;
  revealReview: boolean;
  fadeInOutFlashLoader: () => void;
  changePhase: (phase: StudyPhase, options?: object) => void;
}

interface FlashcardResponse {
  flashcardId: string;
  id: string;
  lapse: boolean;
  response: number;
  studySessionId: string;
  updatedAt: string;
  userId: string;
}

// interface Stats {
//   value: number | string;
//   label: string;
// }

interface ResponsePercentage {
  percentage: number;
  count: number;
}

interface ResponsePercentages {
  [key: string]: ResponsePercentage;
}

const SessionSummary: React.FC<SessionSummaryProps> = ({
  todaySession,
  reviewQbankBlock,
  revealReview,
  fadeInOutFlashLoader,
  changePhase,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const blocks = useSelector((state: RootState) => state.blocks);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);

  const [background, setBackground] = useState('');
  const [particleOpacity, setParticleOpacity] = useState(0.5);
  const [qbankOverallScore, setQbankOverallScore] = useState(0);
  const [qbankPreviousScore, setQbankPreviousScore] = useState(0);
  const [qbankPercentChange, setQbankPercentChange] = useState(0);
  const [flashcardPercentage, setFlashcardPercentage] = useState(0);
  const [scoresAnimated, setScoresAnimated] = useState(false);
  const [animatedQbankScore, setAnimatedQbankScore] = useState(0);
  const [showBlockBreakdown, setShowBlockBreakdown] = useState(false);
  const [animatedFlashcardScore, setAnimatedFlashcardScore] = useState(0);
  // const [flashcardStats, setFlashcardStats] = useState<Stats[]>([]);
  const [responsePercentages, setResponsePercentages] =
    useState<ResponsePercentages>({});

  const [blueBlurOpacity, setBlueBlurOpacity] = useState(0);
  const [greenBlurOpacity, setGreenBlurOpacity] = useState(0);
  const [purpleBlurOpacity, setPurpleBlurOpacity] = useState(0);
  const [whiteBlurOpacity, setWhiteBlurOpacity] = useState(0);

  const [checkmarkAnimated, setCheckmarkAnimated] = useState(false);
  const checkmarkSectionRef = useRef<HTMLDivElement>(null);
  const flashcardResponsesSectionRef = useRef<HTMLDivElement>(null);
  const [flashcardResponsesAnimated, setFlashcardResponsesAnimated] =
    useState(false);

  const [topicPerformance, setTopicPerformance] = useState<{
    [topicName: string]: {
      correct: number;
      total: number;
      percentage: number;
    };
  } | null>();

  const headerRef = useRef<HTMLDivElement>(null);
  const heroRef = useRef<HTMLDivElement>(null);
  const scoresContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const windowWidth = useWindowWidth();

  const sectionsRef = useRef<NodeListOf<Element> | null>(null);

  useEffect(() => {
    getFlashcardResponses(todaySession.id);
    getTopicStats();

    document.body.classList.add('study--session-summary');
    return () => {
      document.body.classList.remove('study--session-summary');
    };
  }, []);

  useEffect(() => {
    getPreviousQBankScore(primaryExam?.id);
  }, [primaryExam]);

  useEffect(() => {
    if (qbankPreviousScore && qbankOverallScore) {
      setQbankPercentChange(
        Math.round(
          ((qbankOverallScore - qbankPreviousScore) / qbankPreviousScore) * 100
        )
      );
    }
  }, [qbankPreviousScore, qbankOverallScore]);

  useEffect(() => {
    const allBlocksComplete = blocks.every((block) => block.isSubmitted);
    if (!allBlocksComplete) {
      console.log(
        'Session Summary Loaded but Not All Blocks Complete, Redirecting to QBank'
      );
      changePhase(StudyPhase.qbank);
    } else {
      setQbankOverallScore(calculateOverallQBankScore(blocks));
    }
  }, [blocks, todaySession]);

  useEffect(() => {
    sectionsRef.current = document.querySelectorAll('.session-summary_section');
  }, [flashcardPercentage, blocks]);

  useEffect(() => {
    if (!scoresAnimated) return;

    const animationSpeed = 0.1;
    let startTime: number | null = null;

    const animate = (timestamp: number) => {
      if (startTime === null) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const newQbank = Math.min(
        qbankOverallScore,
        Math.round(elapsed * animationSpeed)
      );
      const newFlash = Math.min(
        flashcardPercentage,
        Math.round(elapsed * animationSpeed)
      );

      setAnimatedQbankScore(newQbank);
      setAnimatedFlashcardScore(newFlash);
      if (newQbank < qbankOverallScore || newFlash < flashcardPercentage) {
        requestAnimationFrame(animate);
      }
    };

    const requestId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestId);
  }, [scoresAnimated, qbankOverallScore, flashcardPercentage]);

  const calculateSectionOpacity = useCallback(
    (sectionSelector: string, opacityMultiplier: number = 0.5) => {
      const section = document.querySelector(sectionSelector);
      if (section) {
        const rect = section.getBoundingClientRect();
        const distanceFromTop = Math.abs(rect.top);
        const maxDistance = window.innerHeight;
        const normalizedDistance = Math.min(distanceFromTop / maxDistance, 1);
        const opacity = 1 - normalizedDistance;
        return opacity * opacityMultiplier;
      }
      return 0;
    },
    []
  );

  const handleScroll = useCallback(() => {
    const windowCenter = window.innerHeight / 2;

    if (heroRef.current) {
      const sectionTop = heroRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight - 12 * 16; // padding
      const startScroll = windowHeight - heroRef.current.offsetHeight;
      const endScroll = -heroRef.current.offsetHeight;

      let scrollPercent =
        (sectionTop - startScroll) / (endScroll - startScroll);
      scrollPercent = Math.min(Math.max(scrollPercent, 0), 1);

      // Interpolate background
      setBackground(interpolateGradient(scrollPercent));
      setParticleOpacity(mapPercentToRange(scrollPercent));

      let scrollPercentForHeader = window.scrollY / 100;
      if (window.scrollY > 100) {
        scrollPercentForHeader = 1;
      }

      if (headerRef.current) {
        const padding = 3 - scrollPercentForHeader * 2;
        const horizontalPadding = windowWidth > 560 ? 3 : 1.5;
        const isCapacitor = Capacitor.isNativePlatform();
        const topPadding = isCapacitor
          ? `calc(${padding}rem + env(safe-area-inset-top))`
          : `${padding}rem`;
        const sidePadding = isCapacitor
          ? `calc(${horizontalPadding}rem + env(safe-area-inset-right)) calc(${horizontalPadding}rem + env(safe-area-inset-left))`
          : `${horizontalPadding}rem`;

        headerRef.current.style.padding = isCapacitor
          ? `${topPadding} ${sidePadding}`
          : `${padding}rem ${horizontalPadding}rem`;

        headerRef.current.style.borderBottom = `solid rgba(253, 253, 253, ${
          scrollPercentForHeader / 10
        }) 1px`;
        headerRef.current.style.backdropFilter = `blur(${
          scrollPercentForHeader * 10
        }px)`;
        headerRef.current.style.setProperty(
          '-webkit-backdrop-filter',
          `blur(${scrollPercentForHeader * 10}px)`
        );
      }
    }

    // Calculate section opacities
    setBlueBlurOpacity(
      calculateSectionOpacity('.session-summary_section--qbank', 0.5)
    );
    setPurpleBlurOpacity(
      calculateSectionOpacity('.session-summary_section--flashcards', 0.5)
    );
    setWhiteBlurOpacity(
      calculateSectionOpacity(
        '.session-summary_section--topic-performance',
        0.7
      )
    );

    // Special case for done section (calculating from bottom)
    const doneSection = document.querySelector(
      '.session-summary_section--done'
    );
    if (doneSection) {
      const rect = doneSection.getBoundingClientRect();
      // Calculate distance from bottom edge of section to bottom of window
      const distanceFromBottom = Math.abs(window.innerHeight - rect.bottom);
      const maxDistance = window.innerHeight;
      const normalizedDistance = Math.min(distanceFromBottom / maxDistance, 1);
      const opacity = 1 - normalizedDistance;
      const mappedOpacity = opacity * 0.3;
      setGreenBlurOpacity(mappedOpacity);
    }

    if (!scoresAnimated && scoresContainerRef.current) {
      const rect = scoresContainerRef.current.getBoundingClientRect();
      const elementCenter = rect.top + rect.height / 2;
      if (elementCenter <= windowCenter) {
        setScoresAnimated(true);
      }
    }

    if (!checkmarkAnimated && checkmarkSectionRef.current) {
      const rect = checkmarkSectionRef.current.getBoundingClientRect();
      if (rect.top <= 0 && windowWidth > 560) {
        setCheckmarkAnimated(true);
      } else if (rect.top <= window.innerHeight - 200) {
        setCheckmarkAnimated(true);
      }
    }

    if (!flashcardResponsesAnimated && flashcardResponsesSectionRef.current) {
      const rect = flashcardResponsesSectionRef.current.getBoundingClientRect();
      if (rect.top <= 0 && windowWidth > 560) {
        setFlashcardResponsesAnimated(true);
      } else if (rect.top <= windowCenter) {
        setFlashcardResponsesAnimated(true);
      }
    }
  }, [scoresAnimated, windowWidth, calculateSectionOpacity]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const getTopicStats = async () => {
    const res = await fetchTopicTreeStats();
    if (res) {
      const formattedStats = formatTopicStats(res);
      setTopicPerformance(formattedStats);
    }
  };

  const getPreviousQBankScore = async (examId: string | undefined) => {
    if (!examId) return;
    const res = await fetchPreviousQBankScore(examId);
    if (res !== 0) {
      setQbankPreviousScore(res);
    }
  };

  const renderBestAndWorstTopics = (top: boolean) => {
    if (!topicPerformance) return;
    const tPerformance = _.clone(topicPerformance);

    let topics;
    if (top) {
      topics = Object.entries(tPerformance).sort(
        (a, b) =>
          (isNaN(b[1].percentage) ? 0 : b[1].percentage) -
          (isNaN(a[1].percentage) ? 0 : a[1].percentage)
      );
    } else {
      topics = Object.entries(tPerformance).sort(
        (a, b) =>
          (isNaN(a[1].percentage) ? 0 : a[1].percentage) -
          (isNaN(b[1].percentage) ? 0 : b[1].percentage)
      );
    }
    const filteredTopics = topics.slice(0, 3);

    return (
      <>
        <h3 className="font-gradient">
          {top ? (
            <>
              Three <span>Strongest</span> Topics
            </>
          ) : (
            <>
              Top Three Areas for <span>Growth</span>
            </>
          )}
        </h3>
        {top ? (
          <p className="m-t-0 m-b-1-50">
            Here are your strongest topics so far.
          </p>
        ) : (
          <p className="m-t-0 m-b-1-50">
            Ora will prioritize these topics for upcoming sessions.
          </p>
        )}
        <div style={{ width: '100%' }}>
          {filteredTopics.map(([topicName, data]) => (
            <div className="perf-row" key={topicName}>
              <p style={{ color: 'var(--type-body-inverse)' }} key={topicName}>
                {topicName}
              </p>
              <div className="stat-ring">
                <span className="stat-ring_score">
                  {isNaN(data.percentage)
                    ? '0%'
                    : data.percentage.toFixed(0) + '%'}
                </span>
                <CircularProgress
                  variant="determinate"
                  color="success"
                  value={isNaN(data.percentage) ? 0 : data.percentage}
                  sx={{ height: '3rem !important', width: '3rem !important' }}
                />
                <div className="track"></div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const mapPercentToRange = (percent: number): number => {
    const start = 0.5;
    const end = 0.15;
    return (1 - percent) * start + percent * end;
  };

  const calculateOverallQBankScore = (blocks: Block[]) => {
    if (blocks.length === 0) return 0;
    let correct = 0;
    let total = 0;
    blocks.forEach((block) => {
      correct += block.correctCount;
      total += block.vignetteCount;
    });
    return Math.round((correct / total) * 100);
  };

  const getFlashcardResponses = async (sessionId: string) => {
    const res = await fetchFlashcardResponses(sessionId);
    calculateFlashcardStats(res);
  };

  const calculateFlashcardStats = (responses: FlashcardResponse[]) => {
    if (!responses || responses.length === 0) return;

    let again = 0;
    let wrong = 0;
    let right = 0;
    let easy = 0;
    let correct = 0;
    // let incorrect = 0;
    const total = responses.length;

    responses.forEach((r) => {
      if (r.response === 0) {
        again++;
        // incorrect++;
      } else if (r.response === 1) {
        wrong++;
        // incorrect++;
      } else if (r.response === 2) {
        right++;
        correct++;
      } else if (r.response === 3) {
        easy++;
        correct++;
      }
    });

    setResponsePercentages({
      again: { percentage: Math.round((again / total) * 100), count: again },
      wrong: { percentage: Math.round((wrong / total) * 100), count: wrong },
      right: { percentage: Math.round((right / total) * 100), count: right },
      easy: { percentage: Math.round((easy / total) * 100), count: easy },
    });
    setFlashcardPercentage(Math.round((correct / total) * 100));
    // setFlashcardStats([
    //   { value: total, label: 'Responses' },
    //   { value: correct, label: 'Correct' },
    //   { value: incorrect, label: 'Incorrect' },
    // ]);
  };

  const calculateBlockPercentage = (block: Block) => {
    const correct = block.correctCount;
    return Math.round((correct / block.vignetteCount) * 100);
  };

  const calculateBlockStats = (block: Block) => {
    const correct = block.correctCount;
    const incorrect = block.incorrectCount;
    const omitted = block.omittedCount;
    return [
      { value: correct, label: 'Correct' },
      { value: incorrect, label: 'Incorrect' },
      { value: omitted, label: 'Omitted' },
    ];
  };

  const renderPercentChange = () => {
    if (qbankPercentChange === 0) return null;
    return (
      <div className="percent-change">
        {qbankPercentChange > 0 && (
          <p>
            <TriangleDown classes="gain-loss-icon gain-loss-icon--gain" />
            <span>{qbankPercentChange}% from yesterday.</span>
          </p>
        )}
        {qbankPercentChange === 0 && (
          <p>
            <span>No change from yesterday.</span>
          </p>
        )}
        {qbankPercentChange < 0 && (
          <p>
            <TriangleDown classes="gain-loss-icon gain-loss-icon--loss" />
            <span>{qbankPercentChange}% from yesterday,</span>
          </p>
        )}
      </div>
    );
  };

  const calculateStudySessionStats = () => {
    const correct = todaySession.vignetteCorrectCount;
    const incorrect = todaySession.vignetteIncorrectCount;
    const omitted = todaySession.vignetteOmittedCount;
    return [
      { value: correct, label: 'Correct' },
      { value: incorrect, label: 'Incorrect' },
      { value: omitted, label: 'Omitted' },
    ];
  };

  const renderOverallQBankStats = () => {
    const stats = calculateStudySessionStats();
    const total = stats.reduce((acc, stat) => acc + stat.value, 0);
    const percentageDecimal =
      (stats.find((stat) => stat.label === 'Correct')?.value || 0) / total;
    const percentage = Math.round(percentageDecimal * 100);
    return (
      <>
        <FadeInComponent>
          <h2 className="font-gradient">QBank Performance</h2>
          {renderPercentChange()}
        </FadeInComponent>
        <FadeInComponent>
          <StatBlock
            percentage={percentage ? Math.round(percentage) : 0}
            stats={stats}
            fadeInOutFlashLoader={fadeInOutFlashLoader}
          />
        </FadeInComponent>
      </>
    );
  };

  const handleSpacebarScroll = useCallback((event: KeyboardEvent) => {
    if (event.code === 'Space' || event.code === 'ArrowDown') {
      event.preventDefault();
      scrollToNextSection();
    }
  }, []);

  const scrollToNextSection = () => {
    if (sectionsRef.current) {
      const currentScroll = window.scrollY;
      const nextSection = Array.from(sectionsRef.current).find(
        (section) =>
          section.getBoundingClientRect().top + currentScroll > currentScroll
      );
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: 'smooth' });
      } else {
        navigate('/');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleSpacebarScroll);
    return () => {
      window.removeEventListener('keydown', handleSpacebarScroll);
    };
  }, [handleSpacebarScroll]);

  return (
    <div className={`session-summary ${revealReview ? 'is-loaded' : ''}`}>
      <div ref={headerRef} className="session-summary_header">
        <button
          className="button button--glass"
          onClick={() => {
            hapticsImpactLight();
            navigate('/');
          }}
        >
          <Home />
          Home
        </button>
      </div>
      <div className="session-summary_hero" ref={heroRef}>
        <FadeInComponent>
          <div className="content-wrapper">
            <div className="superhead">
              {primaryExam?.name} |{' '}
              {moment(todaySession.date).format('MMMM D, YYYY')}
            </div>
            <h1 style={{ margin: '0' }} className="font-gradient">
              Session Summary
            </h1>
            <div className="space shimmer space--mobile">
              Scroll Down to Explore
            </div>
            <div className="space shimmer space--desktop">
              Press Space to Explore
            </div>
          </div>
        </FadeInComponent>
        <div className="three-wrap" style={{ opacity: particleOpacity }}>
          <ThreeWave showLabels={false} transitionStarted={false} />
        </div>
      </div>
      <div
        className="session-summary_section session-summary_section--overall"
        ref={scoresContainerRef}
      >
        <FadeInComponent>
          <h2 className="font-gradient">Today's Performance</h2>
          <p>Here's how you did on each section of the session.</p>
        </FadeInComponent>
        <FadeInComponent>
          <div className="overall-scores">
            {blocks.length > 0 && (
              <div className="overall-score">
                <div className="overall-score_speedometer">
                  <CircularProgressbar
                    value={animatedQbankScore}
                    text={`${animatedQbankScore}%`}
                    strokeWidth={4}
                    styles={buildStyles({
                      pathColor: 'var(--success)',
                      textColor: 'var(--type-body-inverse)',
                      trailColor: 'var(--surface-glass-light)',
                      backgroundColor: '#fff',
                      pathTransitionDuration: 0,
                    })}
                  />
                </div>
                <div className="overall-score_label">QBank</div>
              </div>
            )}
            {flashcardPercentage > 0 && (
              <div className="overall-score">
                <div className="overall-score_speedometer">
                  <CircularProgressbar
                    value={animatedFlashcardScore}
                    text={`${animatedFlashcardScore}%`}
                    strokeWidth={4}
                    styles={buildStyles({
                      pathColor: 'var(--success)',
                      textColor: 'var(--type-body-inverse)',
                      trailColor: 'var(--surface-glass-light)',
                      backgroundColor: '#fff',
                      pathTransitionDuration: 0,
                    })}
                  />
                </div>
                <div className="overall-score_label">Flashcards</div>
              </div>
            )}
          </div>
        </FadeInComponent>
      </div>
      {blocks.length > 0 && (
        <div className="session-summary_section session-summary_section--qbank">
          {renderOverallQBankStats()}
          <FadeInComponent>
            <Accordion expanded={showBlockBreakdown}>
              <AccordionSummary>
                <button
                  className="button button--glass"
                  onClick={() => {
                    hapticsImpactLight();
                    setShowBlockBreakdown(!showBlockBreakdown);
                  }}
                >
                  {showBlockBreakdown
                    ? 'Hide Block Breakdown'
                    : 'Show Block Breakdown'}
                </button>
              </AccordionSummary>
              <AccordionDetails>
                <h2 className="font-gradient m-b-1-50">Block Breakdown</h2>
                {[...blocks]
                  .sort((a, b) => (a.ord ?? 0) - (b.ord ?? 0))
                  .map((block, i) => (
                    <StatBlock
                      key={block.id}
                      header={`Block ${i + 1}`}
                      percentage={calculateBlockPercentage(block)}
                      blockId={block.id}
                      reviewQbankBlock={reviewQbankBlock}
                      stats={calculateBlockStats(block)}
                      fadeInOutFlashLoader={fadeInOutFlashLoader}
                    />
                  ))}
              </AccordionDetails>
            </Accordion>
          </FadeInComponent>
        </div>
      )}
      {flashcardPercentage > 0 && (
        <div
          className="session-summary_section session-summary_section--flashcards"
          ref={flashcardResponsesSectionRef}
        >
          <FadeInComponent>
            <div>
              <div>
                <h2 className="font-gradient">Flashcard Responses</h2>
                <p>
                  You responded correctly to {flashcardPercentage}% of the
                  flashcards.
                </p>
              </div>
              <div>
                <div className="card-performance">
                  <div className="card-performance_chart">
                    {Object.keys(responsePercentages).map((key) => (
                      <Tooltip
                        key={key}
                        title={`${responsePercentages[key].percentage}%`}
                        enterTouchDelay={0}
                      >
                        <div
                          className="card-performance_chart_bar"
                          style={{
                            height: flashcardResponsesAnimated
                              ? `${responsePercentages[key].percentage}%`
                              : '0',
                            backgroundColor: `${key === 'right' ? 'var(--green)' : `rgba(255, 255, 255, 0.2)`}`,
                            display:
                              currentUser?.responseOptions === 'two' &&
                              (key === 'easy' || key === 'again')
                                ? 'none'
                                : 'block',
                          }}
                        >
                          <div className="card-performance_chart_bar_label">
                            {responsePercentages[key].count}
                          </div>
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                  <div className="card-performance_labels">
                    {currentUser?.responseOptions === 'four' && (
                      <div className="card-performance_labels_label">
                        <div className="card-performance_labels_label_main">
                          Again
                        </div>
                      </div>
                    )}
                    <div className="card-performance_labels_label">
                      <div className="card-performance_labels_label_main">
                        Wrong
                      </div>
                    </div>
                    <div className="card-performance_labels_label">
                      <div className="card-performance_labels_label_main">
                        Right
                      </div>
                    </div>
                    {currentUser?.responseOptions === 'four' && (
                      <div className="card-performance_labels_label">
                        <div className="card-performance_labels_label_main">
                          Easy
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </FadeInComponent>
        </div>
      )}
      <div className="session-summary_section session-summary_section--topic-performance">
        <FadeInComponent>
          <h2 className="font-gradient">Overall Topic Performance</h2>
          <p>
            Let's look at your overall performance so far across each topic.
          </p>
        </FadeInComponent>
        <FadeInComponent>
          <div className="radar-container">
            <TopicRadar isSummary={true} topicProgression={topicPerformance} />
          </div>
        </FadeInComponent>
      </div>
      <div className="session-summary_section session-summary_section--strengths">
        <div className="area-strengths">
          <div className="col">
            <FadeInComponent>{renderBestAndWorstTopics(true)}</FadeInComponent>
          </div>
        </div>
      </div>
      <div className="session-summary_section session-summary_section--strengths">
        <div className="area-strengths">
          <div className="col">
            <FadeInComponent>{renderBestAndWorstTopics(false)}</FadeInComponent>
          </div>
        </div>
      </div>
      <div
        className="session-summary_section session-summary_section--done"
        ref={checkmarkSectionRef}
      >
        <FadeInComponent>
          <div style={{ height: '6rem', width: '6rem' }}>
            {checkmarkAnimated && <AnimatedCheckmark />}
          </div>
          <h2 className="font-gradient m-t-0">You're Done for Today</h2>
          <p>We'll see you tomorrow.</p>
          <button
            role="link"
            className="button button--glass"
            style={{ width: '100%' }}
            onClick={() => {
              navigate('/');
              hapticsImpactLight();
            }}
          >
            {todaySession.isReview ? 'End Review' : 'End Session'}
          </button>
        </FadeInComponent>
      </div>
      <div className="fixed-background" style={{ background }}>
        <div
          className="blue-blur"
          style={{
            opacity: blueBlurOpacity,
            filter: `blur(80px)`,
            WebkitFilter: `blur(80px)`,
            transform: 'translate(-40%, 50%)',
            transition: 'opacity 0.2s linear',
            willChange: 'opacity, filter',
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',
          }}
        ></div>
        <div
          className="purple-blur"
          style={{
            opacity: purpleBlurOpacity,
            filter: `blur(80px)`,
            WebkitFilter: `blur(80px)`,
            transform: 'translate(40%, 50%)',
            transition: 'opacity 0.2s linear',
            willChange: 'opacity, filter',
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',
          }}
        ></div>
        <div
          className="white-blur"
          style={{
            opacity: whiteBlurOpacity,
            filter: `blur(80px)`,
            WebkitFilter: `blur(80px)`,
            transform: 'translate(-50%, 50%)',
            transition: 'opacity 0.2s linear',
            willChange: 'opacity, filter',
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',
          }}
        ></div>
        <div
          className="green-blur"
          style={{
            opacity: greenBlurOpacity,
            filter: `blur(80px)`,
            WebkitFilter: `blur(80px)`,
            transform: 'translate(-50%, -80%)',
            transition: 'opacity 0.2s linear',
            willChange: 'opacity, filter',
            backfaceVisibility: 'hidden',
            WebkitBackfaceVisibility: 'hidden',
          }}
        ></div>
      </div>
    </div>
  );
};

export default SessionSummary;
