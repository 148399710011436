// src/components/Study/Exam/QuestionListDrawer.tsx
import React from 'react';
import { X, Check } from 'react-feather';
import { Vignette } from '../../../../types/Vignette';
import { Block } from '../../../../types/Study';
import StandardFlag from '../../../../assets/images/standard/flag.svg';
import FlagDark from '../../../../assets/images/standard/flag-dark.svg';
import Pencil from '../../../../assets/images/standard/pencil.svg';

interface QuestionListDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  vignettes: Vignette[];
  activeQuestionIndex: number;
  activeBlock: Block | undefined;
  onQuestionSelect: (index: number) => void;
  isAnswered: (vignetteId: string) => boolean;
}

const QuestionListDrawer: React.FC<QuestionListDrawerProps> = ({
  isOpen,
  onClose,
  vignettes,
  activeQuestionIndex,
  activeBlock,
  onQuestionSelect,
  isAnswered,
}) => {
  if (!activeBlock) {
    return null;
  }

  return (
    <div className={`questions ${isOpen ? 'show' : ''}`}>
      <div className="questions_header">
        <h3>Select a Question</h3>
        <button
          className="button button--icon-only"
          aria-label="Close Question List"
          onClick={onClose}
        >
          <X />
        </button>
      </div>
      <div className="questions_list">
        {vignettes.map((v, i) => {
          const vignetteId = v.id;
          const answerData = activeBlock.answers?.[vignetteId];
          const storedAnswer = answerData?.answer;
          const isCorrect =
            storedAnswer && storedAnswer === answerData?.correctAnswer;
          const isSubmittedOrReviewed =
            activeBlock.isSubmitted || answerData?.submitted;
          const showCheckmark = isCorrect && isSubmittedOrReviewed;

          return (
            <button
              className={`item ${i === activeQuestionIndex ? 'active' : ''} ${isAnswered(vignetteId) ? 'answered' : ''}`}
              key={'q-' + vignetteId}
              value={i}
              onClick={() => onQuestionSelect(i)}
            >
              <li>
                {showCheckmark && <Check />}
                <span>{i + 1}</span>
                <div>
                  {activeBlock.flags?.[vignetteId] && (
                    <>
                      <img className="light" src={StandardFlag} alt="Flag" />
                      <img className="dark" src={FlagDark} alt="Flag" />
                    </>
                  )}
                  {activeBlock.notes?.[vignetteId] && (
                    <img className="pencil" src={Pencil} alt="Pencil" />
                  )}
                </div>
              </li>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default QuestionListDrawer;
