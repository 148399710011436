// -------- State Definition --------
export interface ExamUIState {
  showLabValues: boolean;
  showCalculator: boolean;
  showNotes: boolean;
  functionMenuIsOpen: boolean;
  confirmEndIsOpen: boolean;
  vignetteMenuIsOpen: boolean;
  questionsMenuIsOpen: boolean;
  reportCardClassActive: boolean;
}

// -------- Initial State --------
export const initialUIState: ExamUIState = {
  showLabValues: false,
  showCalculator: false,
  showNotes: false,
  functionMenuIsOpen: false,
  confirmEndIsOpen: false,
  vignetteMenuIsOpen: false,
  questionsMenuIsOpen: false,
  reportCardClassActive: false,
};

// -------- Action Definitions --------
export type ExamUIAction =
  | { type: 'TOGGLE_LAB_VALUES' }
  | { type: 'TOGGLE_CALCULATOR' }
  | { type: 'TOGGLE_NOTES' }
  | { type: 'SET_FUNCTION_MENU_OPEN'; payload: boolean }
  | { type: 'SET_CONFIRM_END_MODAL_OPEN'; payload: boolean }
  | { type: 'SET_VIGNETTE_MENU_OPEN'; payload: boolean }
  | { type: 'SET_QUESTIONS_MENU_OPEN'; payload: boolean }
  | { type: 'SET_REPORT_CARD_CLASS_ACTIVE'; payload: boolean };

// -------- Reducer Function --------
export function examUIReducer(
  state: ExamUIState,
  action: ExamUIAction
): ExamUIState {
  switch (action.type) {
    case 'TOGGLE_LAB_VALUES':
      return { ...state, showLabValues: !state.showLabValues };

    case 'TOGGLE_CALCULATOR': {
      const isOpeningCalculator = !state.showCalculator;
      return {
        ...state,
        showCalculator: isOpeningCalculator,
        showNotes: isOpeningCalculator ? false : state.showNotes,
      };
    }
    case 'TOGGLE_NOTES': {
      const isOpeningNotes = !state.showNotes;
      return {
        ...state,
        showNotes: isOpeningNotes,
        showCalculator: isOpeningNotes ? false : state.showCalculator,
      };
    }
    case 'SET_FUNCTION_MENU_OPEN':
      return { ...state, functionMenuIsOpen: action.payload };

    case 'SET_CONFIRM_END_MODAL_OPEN':
      return { ...state, confirmEndIsOpen: action.payload };

    case 'SET_VIGNETTE_MENU_OPEN':
      return { ...state, vignetteMenuIsOpen: action.payload };

    case 'SET_QUESTIONS_MENU_OPEN':
      return { ...state, questionsMenuIsOpen: action.payload };

    case 'SET_REPORT_CARD_CLASS_ACTIVE':
      return { ...state, reportCardClassActive: action.payload };

    default:
      return state;
  }
}
