export const Help = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10.15" stroke="white" strokeWidth="1.7" />
      <path
        d="M12.4765 6C13.5414 6 14.3922 6.31461 15.0291 6.94382C15.6764 7.57303 16 8.43258 16 9.52247C16 10.6573 15.6659 11.5112 14.9978 12.0843C14.3296 12.6573 13.4422 12.9438 12.3356 12.9438L12.2729 14.2753H10.7226L10.6443 11.6292H11.1611C12.1738 11.6292 12.9463 11.4831 13.4787 11.191C14.0216 10.8989 14.2931 10.3427 14.2931 9.52247C14.2931 8.92697 14.1312 8.46067 13.8076 8.1236C13.4944 7.78652 13.0559 7.61798 12.4922 7.61798C11.9284 7.61798 11.4847 7.7809 11.1611 8.10674C10.8374 8.43258 10.6756 8.88764 10.6756 9.47191H9C9 8.79775 9.14094 8.19663 9.42282 7.66854C9.7047 7.14045 10.1066 6.73034 10.6286 6.4382C11.1611 6.14607 11.777 6 12.4765 6ZM11.4743 18C11.1506 18 10.8792 17.882 10.66 17.6461C10.4407 17.4101 10.3311 17.118 10.3311 16.7697C10.3311 16.4213 10.4407 16.1292 10.66 15.8933C10.8792 15.6573 11.1506 15.5393 11.4743 15.5393C11.7875 15.5393 12.0537 15.6573 12.2729 15.8933C12.4922 16.1292 12.6018 16.4213 12.6018 16.7697C12.6018 17.118 12.4922 17.4101 12.2729 17.6461C12.0537 17.882 11.7875 18 11.4743 18Z"
        fill="currentColor"
      />
    </svg>
  );
};
