import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionStatusResponse } from '../../services/subscriptions';

interface SubscriptionState {
  subscription: SubscriptionStatusResponse | null;
}

const initialState: SubscriptionState = {
  subscription: null,
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    /**
     * Stores the subscription status response from the backend.
     */
    setSubscription: (
      state,
      action: PayloadAction<SubscriptionStatusResponse>
    ) => {
      state.subscription = action.payload;
    },
    /**
     * Clears the subscription data (for example on logout).
     */
    clearSubscription: (state) => {
      state.subscription = null;
    },
  },
});

export const { setSubscription, clearSubscription } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
