export const OraMuiTooltip = {
  styleOverrides: {
    tooltip: {
      backgroundColor: 'rgba(0,0,0,.7)',
      WebkitBackdropFilter: 'blur(20px)',
      backdropFilter: 'blur(20px)',
      color: '#fff',
    },
    popper: {
      zIndex: 2000000000,
    },
  },
};
