import { useState } from 'react';
import { ResponseOption } from '../../../../types/User';
import { updateCurrentUserInfo } from '../../../../services/auth';
import { setUserInfo } from '../../../../slices/auth/authSlice';
import OraSwitch from '../../../CustomMUI/Switch';
import { RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

const FlashcardSettings: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);

  const [responseOptions, setResponseOptions] = useState(
    currentUser?.responseOptions || 'two'
  );
  const [flashcardTextSize, setFlashcardTextSize] = useState(
    currentUser?.flashcardTextSize || 'default'
  );
  const [showIntervals, setShowIntervals] = useState(
    currentUser?.showIntervals || false
  );

  const dispatch = useDispatch();

  const handleResponseOptionChange = (
    _event: React.MouseEvent<HTMLElement>,
    newResponseOptions: ResponseOption
  ) => {
    if (newResponseOptions !== null) {
      setResponseOptions(newResponseOptions);
      updateResponseOptions(newResponseOptions);
    }
  };

  const updateResponseOptions = async (newResponseOptions: ResponseOption) => {
    const updateUser = await updateCurrentUserInfo({
      responseOptions: newResponseOptions,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const handleTextSizeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newTextSize: 'smaller' | 'default' | 'larger'
  ) => {
    if (newTextSize !== null) {
      setFlashcardTextSize(newTextSize);
      updateTextSize(newTextSize);
    }
  };

  const updateTextSize = async (newSize: 'smaller' | 'default' | 'larger') => {
    const updateUser = await updateCurrentUserInfo({
      flashcardTextSize: newSize,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const handleShowIntervalsChange = (showIntervals: boolean) => {
    setShowIntervals(showIntervals);
    updateShowIntervals(showIntervals);
  };

  const updateShowIntervals = async (showIntervals: boolean) => {
    const updateUser = await updateCurrentUserInfo({
      showIntervals: showIntervals,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  return (
    <>
      <div className="control control--intervals">
        <InputLabel>Show Intervals</InputLabel>
        <OraSwitch
          checked={showIntervals}
          onChange={(e) => handleShowIntervalsChange(e.target.checked)}
        />
      </div>
      <div className="control control--response-options">
        <InputLabel className="m-b-0-50">Response Options</InputLabel>
        <ToggleButtonGroup
          value={responseOptions}
          exclusive
          onChange={handleResponseOptionChange}
          aria-label="response options"
        >
          <ToggleButton value="two" aria-label="2 response">
            Two Buttons
          </ToggleButton>
          <ToggleButton value="four" aria-label="4 response">
            Four Buttons
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="control control--text-size m-t-1">
        <InputLabel className="m-b-0-50">Text Size</InputLabel>
        <ToggleButtonGroup
          value={flashcardTextSize}
          exclusive
          onChange={handleTextSizeChange}
          aria-label="Text size options"
        >
          <ToggleButton value="smaller" aria-label="Smaller text size">
            Smaller
          </ToggleButton>
          <ToggleButton value="default" aria-label="Default text size">
            Default
          </ToggleButton>
          <ToggleButton value="larger" aria-label="Larger text size">
            Larger
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </>
  );
};

export default FlashcardSettings;
