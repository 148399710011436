import { Flashcard } from '../types/Flashcard';
import { Vignette } from '../types/Vignette';

export const cacheVignetteImages = async (vignettes: Vignette[]) => {
  if (vignettes[0] === null) return;
  for (const vignette of vignettes) {
    let urls: string[] = [];
    for (const content of vignette.contents) {
      urls = getImageUrls(content.question);
      urls = urls.concat(getImageUrls(content.explanation));
      if (content.exhibit) {
        urls = urls.concat(getImageUrls(content.exhibit));
      }
      if (urls.length > 0) preloadImages(urls);
    }
  }
};

export const cacheFlashcardImages = async (flashcards: Flashcard[]) => {
  if (flashcards[0] === null) return;
  for (const flashcard of flashcards) {
    let urls: string[] = [];
    for (const content of flashcard.contents) {
      urls = getImageUrls(content.original);
      urls = urls.concat(getImageUrls(content.explanation));
      if (urls.length > 0) preloadImages(urls);
    }
  }
};

const getImageUrls = (string: string) => {
  const imgRex = /<img.*?src="(.*?)"[^>]*?>/g;
  const images = [];
  let img;
  while ((img = imgRex.exec(string))) {
    images.push(img[1]);
  }
  return images;
};

const preloadImages = (array: string[]) => {
  // @ts-expect-error ignore
  if (!preloadImages.list) {
    // @ts-expect-error ignore
    preloadImages.list = [];
  }
  // @ts-expect-error ignore
  const list = preloadImages.list;
  for (let i = 0; i < array.length; i++) {
    const img = new Image();
    img.onload = function () {
      const index = list.indexOf(this);
      if (index !== -1) {
        // remove image from the array once it's loaded
        // for memory consumption reasons
        list.splice(index, 1);
      }
    };
    list.push(img);
    img.src = array[i];
  }
};
