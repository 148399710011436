import { X } from 'react-feather';
import { posthog } from 'posthog-js';

interface MSMobileMenuProps {}

const MSMobileMenu: React.FC<MSMobileMenuProps> = () => {
  return (
    <div className="ms-mobile-menu">
      <div className="ms-mobile-menu_drawer">
        <button
          className="ms-mobile-menu_drawer_close"
          onClick={() => {
            const mobileMenu = document.querySelector('.ms-mobile-menu_drawer');
            if (mobileMenu) {
              mobileMenu.classList.remove('is-open');
            }
          }}
        >
          <X />
        </button>
        <ul>
          <li>
            <a
              href="https://app.oraai.com/"
              onClick={() => {
                posthog?.capture('ms_sign_in', { isHero: false });
              }}
            >
              Sign In
            </a>
          </li>
          <li>
            <a
              href="https://app.oraai.com/auth?signup=true"
              onClick={() => {
                posthog?.capture('ms_sign_up', { isHero: false });
              }}
            >
              5 Day Free Trial
            </a>
          </li>
          <hr />
          <li>
            <a href="https://oraai.com/content">Content</a>
          </li>
          <li>
            <a href="https://oraai.com/team">Team</a>
          </li>
          <li>
            <button
              onClick={() => {
                window.Intercom('showNewMessage');
              }}
            >
              Contact
            </button>
            <li>
              <a href="/pricing">Pricing</a>
            </li>
          </li>
        </ul>
      </div>
      <div
        className="mobile-menu_overlay"
        onClick={() => {
          const mobileMenu = document.querySelector('.mobile-menu_drawer');
          if (mobileMenu) {
            mobileMenu.classList.remove('is-open');
          }
        }}
      ></div>
    </div>
  );
};

export default MSMobileMenu;
