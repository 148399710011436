import axiosInstance from './axiosCreator';

export const fetchQbankPerformanceStats = async () => {
  try {
    const response = await axiosInstance.get('/stats/qbankPerformance');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTopicsForFlashcards = async (flashcardIds: string[]) => {
  try {
    const response = await axiosInstance.post('/stats/flashcardTopics', {
      flashcardIds,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTopLevelTopics = async () => {
  try {
    const response = await axiosInstance.get('/stats/topLevelTopics');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTimeStats = async () => {
  try {
    const response = await axiosInstance.get('/stats/averageFlashcardTime');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTopicTreeStats = async () => {
  try {
    const response = await axiosInstance.get('/stats/topicTreeStats');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchPreviousQBankScore = async (examId: string) => {
  try {
    const response = await axiosInstance.get(
      `/stats/previousQbankScore/${examId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
