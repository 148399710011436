// src/components/Study/Exam/Hooks/useAnswerCrossouts.ts
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateBlockCrossouts } from '../../../../services/blocks';
import { updateBlockFE } from '../../../../slices/blocks/blocksSlice';
import { Block } from '../../../../types/Study';
import { Vignette, VignetteContent } from '../../../../types/Vignette';

interface UseAnswerCrossoutsProps {
  activeBlock: Block | undefined;
  activeVignette: Vignette | undefined;
  activeVignetteContent: VignetteContent | undefined;
  isDemo: boolean | undefined;
}

const useAnswerCrossouts = ({
  activeBlock,
  activeVignette,
  activeVignetteContent,
  isDemo,
}: UseAnswerCrossoutsProps): void => {
  const dispatch = useDispatch();

  const handleCrossouts = async () => {
    if (!activeBlock || !activeVignette) return;

    const selection = window.getSelection();
    if (selection?.toString().trim().length === 0) return;
    if (!selection || !selection.rangeCount || !selection.anchorNode) return;
    const selectedRange = selection.getRangeAt(0);
    let startNode: Node | null = selection.anchorNode;
    if (startNode.nodeType !== Node.ELEMENT_NODE) {
      startNode = startNode.parentElement;
    }
    while (startNode && startNode.nodeName === 'SPAN') {
      startNode = startNode.parentElement;
    }
    if (!startNode) return;

    const labels: Element[] = [];
    if (startNode.nodeName === 'LABEL') {
      const siblingsWalker = document.createTreeWalker(
        startNode.parentNode!,
        NodeFilter.SHOW_ELEMENT,
        {
          acceptNode: (node: Node) => {
            if (
              node instanceof Element &&
              node !== startNode &&
              node.tagName === 'LABEL'
            ) {
              const range = document.createRange();
              range.selectNodeContents(node);
              const intersects =
                selectedRange.compareBoundaryPoints(Range.END_TO_START, range) <
                  0 &&
                selectedRange.compareBoundaryPoints(Range.START_TO_END, range) >
                  0;
              range.detach();
              return intersects
                ? NodeFilter.FILTER_ACCEPT
                : NodeFilter.FILTER_SKIP;
            }
            return NodeFilter.FILTER_SKIP;
          },
        }
      );
      // Add the startNode if it's selected
      labels.push(startNode as Element);
      while (siblingsWalker.nextNode()) {
        labels.push(siblingsWalker.currentNode as Element);
      }
    } else {
      const walker = document.createTreeWalker(
        startNode,
        NodeFilter.SHOW_ELEMENT,
        {
          acceptNode: (node: Node) => {
            if (node instanceof Element && node.tagName === 'LABEL') {
              const range = document.createRange();
              range.selectNodeContents(node);
              const intersects =
                selectedRange.compareBoundaryPoints(Range.END_TO_START, range) <
                  0 &&
                selectedRange.compareBoundaryPoints(Range.START_TO_END, range) >
                  0;
              range.detach();
              return intersects
                ? NodeFilter.FILTER_ACCEPT
                : NodeFilter.FILTER_SKIP;
            }
            return NodeFilter.FILTER_SKIP;
          },
        }
      );
      while (walker.nextNode()) {
        labels.push(walker.currentNode as Element);
      }
    }

    if (labels.length) {
      const answerCrossouts = activeBlock.answerCrossouts || {};

      if (answerCrossouts[activeVignette.id]) {
        const updatedCrossouts = { ...answerCrossouts };
        updatedCrossouts[activeVignette.id] = [
          ...updatedCrossouts[activeVignette.id],
        ];
        labels.forEach((label) => {
          const index = Number(label.getAttribute('data-index'));
          updatedCrossouts[activeVignette.id][index] =
            !updatedCrossouts[activeVignette.id][index];
        });
        const newAnswerCrossouts = await updateBlockCrossouts(
          activeBlock.id,
          activeVignette.id,
          updatedCrossouts[activeVignette.id]
        );
        const updatedBlock = {
          ...activeBlock,
          answerCrossouts: newAnswerCrossouts,
        };
        dispatch(updateBlockFE(updatedBlock));
      } else {
        const newCrossouts = Array(
          activeVignetteContent?.answerOptions.length || 0
        ).fill(false);
        const updatedCrossouts = {
          ...answerCrossouts,
          [activeVignette.id]: newCrossouts,
        };
        labels.forEach((label) => {
          const index = Number(label.getAttribute('data-index'));
          updatedCrossouts[activeVignette.id][index] = true;
        });
        const newAnswerCrossouts = await updateBlockCrossouts(
          activeBlock.id,
          activeVignette.id,
          updatedCrossouts[activeVignette.id]
        );
        const updatedBlock = {
          ...activeBlock,
          answerCrossouts: newAnswerCrossouts,
        };
        dispatch(updateBlockFE(updatedBlock));
      }
      window.getSelection()?.removeAllRanges();
    }
  };

  const handleRightClickCrossout = async (event: MouseEvent) => {
    event.preventDefault();

    if (!activeBlock || !activeVignette) return;

    if (event.target instanceof HTMLElement) {
      let labelElement: HTMLLabelElement | null = null;
      if (event.target.tagName === 'LABEL') {
        labelElement = event.target as HTMLLabelElement;
      } else if (event.target.closest('label')) {
        labelElement = event.target.closest('label') as HTMLLabelElement;
      }
      if (labelElement && labelElement.getAttribute('data-index') !== null) {
        const index = Number(labelElement.getAttribute('data-index'));
        const answerCrossouts = activeBlock.answerCrossouts || {};
        const updatedCrossouts = { ...answerCrossouts }; // Shallow copy of the outer object

        if (!updatedCrossouts[activeVignette.id]) {
          updatedCrossouts[activeVignette.id] = Array(
            activeVignetteContent?.answerOptions.length || 0
          ).fill(false);
        } else {
          updatedCrossouts[activeVignette.id] = [
            ...updatedCrossouts[activeVignette.id],
          ];
        }

        if (updatedCrossouts[activeVignette.id].length <= index) {
          const neededLength =
            activeVignetteContent?.answerOptions.length || index + 1;
          while (updatedCrossouts[activeVignette.id].length < neededLength) {
            updatedCrossouts[activeVignette.id].push(false);
          }
        }

        const isCrossedOut = updatedCrossouts[activeVignette.id][index];
        updatedCrossouts[activeVignette.id][index] = !isCrossedOut;

        const newAnswerCrossouts = await updateBlockCrossouts(
          activeBlock.id,
          activeVignette.id,
          updatedCrossouts[activeVignette.id]
        );
        const updatedBlock = {
          ...activeBlock,
          answerCrossouts: newAnswerCrossouts,
        };
        dispatch(updateBlockFE(updatedBlock));
      }
    }
  };

  useEffect(() => {
    let listenerAttached = false;
    if (activeBlock && !activeBlock.isSubmitted) {
      if (!isDemo) {
        if (activeVignette && activeVignette.scheduling) {
          document.addEventListener('mouseup', handleCrossouts);
          document.addEventListener('contextmenu', handleRightClickCrossout);
          listenerAttached = true;
        }
      } else if (isDemo) {
        document.addEventListener('mouseup', handleCrossouts);
        document.addEventListener('contextmenu', handleRightClickCrossout);
        listenerAttached = true;
      }
    }

    return () => {
      if (listenerAttached) {
        document.removeEventListener('mouseup', handleCrossouts);
        document.removeEventListener('contextmenu', handleRightClickCrossout);
      }
    };
  }, [activeBlock, activeVignette, activeVignetteContent, isDemo, dispatch]);
};

export default useAnswerCrossouts;
