import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Global/Header';
import Start from '../../components/Home/Start';
import { HeaderType, SecondaryScreen } from '../../types/Header';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearTodaySession,
  setTodaySession,
} from '../../slices/todaySession/todaySessionSlice';
import { clearBlocks } from '../../slices/blocks/blocksSlice';
import { clearPastFlashcards } from '../../slices/pastFlashcards/pastFlashcardsSlice';
import { ArrowRight, Clock, X } from 'react-feather';
import { RootState } from '../../store/store';
import { useHotkeys } from 'react-hotkeys-hook';
import { hapticsImpactLight } from '../../utils/haptics';
import CircularProgress from '@mui/material/CircularProgress';
import { StartType } from '../../types/Study';
import { useRefreshStudySessionAndContent } from '../../utils/refreshStudySession';
import { updateCurrentUserInfo } from '../../services/auth';
import { setUserInfo } from '../../slices/auth/authSlice';
import useIdle from '../../utils/useIdle';
import moment, { Moment } from 'moment';
import { updateStudySession } from '../../services/studySession';
import { addOrUpdateUserExam } from '../../services/users';
import { enqueueSnackbar } from 'notistack';
import { posthog } from 'posthog-js';
import AppBar from '../../components/Global/AppBar';
import ExamCompletion from './ExamCompletion';
import Search from '../../components/Library/Search';
import WelcomeVideo from '../../components/Onboarding/WelcomeVideo';
import HomeFeatureTour from '../../components/Home/HomeFeatureTour';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useGamepadContext } from '../../context/useGamepadContext';
import { getAssentsToAccept, getLatestAssents } from '../../services/assent';
import { AssentType } from '../../types/Assent';
import AssentsModal from '../../components/Home/AssentsModal';
import NavBar from '../../components/Global/NavBar';
import AppPromo from '../../assets/images/app-promo.png';
import QRCode from '../../assets/ora-qr.svg';
import { createPortal } from 'react-dom';
import CustomModal from '../../components/Global/CustomModal';
import Screensaver from './Screensaver';
import { getLearningObjectivesForToday } from '../../services/vignettes';
import { getScreensaverTime } from '../../utils/getScreensaverTime';
import Timeline from '../../components/Home/Timeline';
import LegacyModal from '../../components/Home/LegacyModal';
import AmbassadorTour from '../../components/Home/AmbassadorTour';
import AnimatedCheckmark from '../../components/Global/AnimatedCheckmark';
import { Capacitor } from '@capacitor/core';

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const subscription = useSelector((state: RootState) => state.subscription);

  const [showSecondaryScreen, setShowSecondaryScreen] = useState(false);
  const [secondaryScreenType, setSecondaryScreenType] =
    useState<SecondaryScreen | null>(null);
  const [transitionStarted, setTransitionStarted] = useState(false);
  const [isSwitchingExam, setIsSwitchingExam] = useState(false);
  const [isWatched, setIsWatched] = useState(false);
  const [isNoContent, setIsNoContent] = useState(false);
  const [showAppPromo, setShowAppPromo] = useState(false);
  const [showLegacyMessage, setShowLegacyMessage] = useState(false);
  const [justShowedTip, setJustShowedTip] = useState(false);
  const [showTopicPriorities, setShowTopicPriorities] = useState(false);
  const [isExtendingSession, setIsExtendingSession] = useState(false);
  const [openWelcomeModal, setOpenWelcomeModal] = useState(false);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [screenSaverDisplay, setScreenSaverDisplay] = useState('none');
  const [learningObjectives, setLearningObjectives] = useState<string[]>([]);
  const [assentsToAccept, setAssentsToAccept] = useState<
    { type: AssentType; version: string }[]
  >([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Feature Tour
  const [showHomeTour, setShowHomeTour] = useState(false);
  const [showAmbassadorTour, setShowAmbassadorTour] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isTourRunning, setIsTourRunning] = useState(false);
  const tourStep1Ref = useRef<HTMLDivElement>(null);
  const stepSelectors = ['.session', '.timeline', '.start'];

  const {
    isLoadingStudySessionAndContent,
    isLoadedStudySessionAndContent,
    setIsLoadingStudySessionAndContent,
    setIsLoadedStudySessionAndContent,
    refreshStudySessionAndContent,
  } = useRefreshStudySessionAndContent();
  const windowWidth = useWindowWidth();

  const flashLoaderRef = useRef<HTMLDivElement>(null);

  const { isIdle } = useIdle(
    getScreensaverTime(currentUser?.screensaverPreference),
    isLoadedStudySessionAndContent &&
      !showSecondaryScreen &&
      currentUser?.screensaverPreference !== 'none'
  );

  useHotkeys('d', () => updateDemoMode(!currentUser?.demoMode), {
    keyup: true,
  });
  useHotkeys('space', () => initiateTransitionToStudy(), { keyup: true });

  // Gamepad
  const { lastButtonEvent } = useGamepadContext();
  useEffect(() => {
    if (lastButtonEvent && lastButtonEvent.pressed) {
      const { universalButton } = lastButtonEvent;
      if (!universalButton) {
        return;
      }
      switch (universalButton) {
        case 'A':
          initiateTransitionToStudy();
          break;
        default:
          break;
      }
    }
  }, [lastButtonEvent]);

  useEffect(() => {
    // Users shouldn't be able to 'undo' flashcards from a previous session
    dispatch(clearPastFlashcards());

    // Clear todaySession if it's not for today
    // TODO: Factor in timezone / offset (?)
    if (todaySession && todaySession.date && !isToday(todaySession.date)) {
      dispatch(clearTodaySession());
      dispatch(clearBlocks());
    }

    determineAssentStatuses();
    fetchLearningObjectives();
    // loadStreak();
    sessionStorage.removeItem('ora-refId');

    if (currentUser?.isOnboarded === false) {
      navigate('/onboarding');
    } else {
      // Loads today's study session and content (flashcards, blocks, vignettes)
      refreshStudySessionAndContent();
    }

    const params = new URLSearchParams(window.location.search);
    if (params.get('delete-request') === 'true') {
      navigate('/account');
    }
  }, []);

  useEffect(() => {
    const ambassadorButton = document.getElementById('ambassador-button');
    if (ambassadorButton && showAmbassadorTour) {
      ambassadorButton.addEventListener('click', handleAmbassadorClick);
      return () => {
        ambassadorButton.removeEventListener('click', handleAmbassadorClick);
      };
    }
  }, [showAmbassadorTour]);

  // Show exam completion screen two days after exam date
  useEffect(() => {
    if (
      primaryExam &&
      primaryExam.examDate &&
      !showSecondaryScreen &&
      !primaryExam.ignoredExamDate &&
      !primaryExam.hasCompletedRealExam &&
      !isLoadingStudySessionAndContent
    ) {
      // Calculate the date that is two days after the exam date
      const twoDaysAfterExam = moment.utc(primaryExam.examDate).add(2, 'days');

      // Check if today is the same as or after the two-day mark
      const isTwoDaysOrMoreAfter = moment
        .utc()
        .isSameOrAfter(twoDaysAfterExam, 'day');

      if (isTwoDaysOrMoreAfter) {
        setShowSecondaryScreen(true);
        setSecondaryScreenType(SecondaryScreen.ExamCompletion);
      }
    }
  }, [primaryExam, showSecondaryScreen, isLoadingStudySessionAndContent]);

  useEffect(() => {
    if (isWatched) {
      handleWatchedVideo();
    }
  }, [isWatched]);

  useEffect(() => {
    if (isIdle) {
      setScreenSaverDisplay('flex');
    } else {
      setTimeout(() => {
        setScreenSaverDisplay('none');
      }, 1000);
    }
  }, [isIdle]);

  useEffect(() => {
    setIsNoContent(false); // Reset isNoContent
    if (todaySession && todaySession.id && primaryExam) {
      if (todaySession.isComplete) return;
      console.log('Checking if No Content Available for Today');
      if (primaryExam.isUsingFlashcards && !primaryExam.isUsingQbank) {
        setIsNoContent(todaySession.flashcardCount === 0);
      }
      if (primaryExam.isUsingQbank && !primaryExam.isUsingFlashcards) {
        setIsNoContent(todaySession.vignetteCount === 0);
      }
      if (primaryExam.isUsingFlashcards && primaryExam.isUsingQbank) {
        setIsNoContent(
          todaySession.flashcardCount === 0 && todaySession.vignetteCount === 0
        );
      }
    }
  }, [todaySession, primaryExam]);

  // Handle Onboarding Tips
  useEffect(() => {
    let homeTourStartDate = moment.utc('2025-03-20'); // Do Not Change This Date
    homeTourStartDate = homeTourStartDate.add(3, 'hours');

    // Home Tour
    if (currentUser?.createdAt) {
      const userCreatedAt = moment.utc(currentUser.createdAt);
      if (userCreatedAt.isAfter(homeTourStartDate)) {
        // Prevent showing tour to existing users
        if (currentUser?.onboardingTips && !currentUser.ambassador) {
          if (!currentUser.onboardingTips['home-tour']) {
            setTimeout(() => {
              setShowHomeTour(true);
              handleStartTour();
            }, 1500);
          }
        }
      }
    }

    // Ambassador Tour
    if (
      currentUser?.onboardingTips &&
      windowWidth > 900 &&
      currentUser.ambassador &&
      !justShowedTip
    ) {
      if (!currentUser.onboardingTips['ambassador-tour']) {
        setTimeout(() => {
          setShowAmbassadorTour(true);
          handleStartTour();
        }, 2000);
      }
    }

    // App Promo
    if (
      currentUser &&
      !currentUser?.onboardingTips['app-promo'] &&
      (currentUser.onboardingTips['home-tour'] ||
        currentUser.onboardingTips['ambassador-tour']) &&
      currentUser.hasWatchedWelcomeVideo &&
      !(currentUser?.hasIOSApp || currentUser?.hasAndroidApp) &&
      !justShowedTip &&
      !Capacitor.isNativePlatform() &&
      windowWidth > 900
    ) {
      setShowAppPromo(true);
    }

    // Legacy Message
    if (
      currentUser &&
      !currentUser.ambassador &&
      !currentUser?.onboardingTips['grandfathered-message'] &&
      subscription?.subscription?.status === 'GRANDFATHERED' &&
      !justShowedTip
    ) {
      setShowLegacyMessage(true);
    }
  }, [
    currentUser?.createdAt,
    currentUser?.onboardingTips,
    windowWidth,
    justShowedTip,
    subscription?.subscription?.status,
  ]);

  const isToday = (dateString: string) => {
    const eventDate = new Date(dateString);
    const today = new Date();

    return (
      eventDate.getUTCFullYear() === today.getUTCFullYear() &&
      eventDate.getUTCMonth() === today.getUTCMonth() &&
      eventDate.getUTCDate() === today.getUTCDate()
    );
  };

  const handleAmbassadorClick = () => {
    setShowAmbassadorTour(false);
    handleSeenMessage('ambassador-tour', true);
  };

  const determineAssentStatuses = async () => {
    if (!currentUser) return;
    const latestAssents = await getLatestAssents(currentUser.id);

    if (latestAssents) {
      const assentsToAccept = getAssentsToAccept(latestAssents);
      if (assentsToAccept.length > 0) {
        setAssentsToAccept(assentsToAccept);
      } else {
        console.log('User has accepted all the latest policies.');
      }
    } else {
      console.error('Failed to fetch latest assents.');
    }
  };

  const handleWatchedVideo = async () => {
    const updateUser = await updateCurrentUserInfo({
      hasWatchedWelcomeVideo: true,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const initiateTransitionToStudy = () => {
    if (!isLoadedStudySessionAndContent) return;
    const examSelector = document.querySelector(
      '.exam-selector'
    ) as HTMLElement;
    if (examSelector) {
      examSelector.style.display = 'none';
    }
    posthog?.capture('study_session_start');
    setTransitionStarted(true);
    setTimeout(() => {
      navigate('/study');
    }, 1000);
  };

  const updateDemoMode = async (demoMode: boolean) => {
    if (currentUser?.role !== 'admin') return;
    const updatedUser = await updateCurrentUserInfo({ demoMode });
    if (updatedUser) {
      dispatch(setUserInfo(updatedUser));
    }
    enqueueSnackbar(demoMode ? 'Demo Mode Enabled' : 'Demo Mode Disabled', {
      autoHideDuration: 3000,
    });
  };

  const closeSecondaryScreen = async () => {
    setShowSecondaryScreen(false);
    setSecondaryScreenType(null);
  };

  const fetchLearningObjectives = async () => {
    if (!currentUser) return;
    const learningObjectives = await getLearningObjectivesForToday(
      currentUser.id
    );
    if (learningObjectives) {
      setLearningObjectives(learningObjectives);
    }
  };

  const handleDateChangeOrConfirmation = async (
    examDate: Moment | null,
    ignored: boolean
  ) => {
    if (!currentUser || !primaryExam) return;

    // Refresh study session and content
    setIsLoadingStudySessionAndContent(true);
    setIsLoadedStudySessionAndContent(false);
    await closeSecondaryScreen();

    const response = await addOrUpdateUserExam({
      userId: currentUser.id,
      examId: primaryExam.id,
      examDate: examDate ? examDate.toISOString() : null,
      selectedFlashcardCount: primaryExam.selectedFlashcardCount,
      selectedVignetteCount: primaryExam.selectedVignetteCount,
      isUsingFlashcards: primaryExam.isUsingFlashcards,
      isUsingQbank: primaryExam.isUsingQbank,
      hasCompletedRealExam: false, // Has Not Completed Real Exam (In No Path)
      realExamScore: primaryExam.realExamScore || null,
      ignoredExamDate: ignored,
      clearTodaySession: true,
    });
    const updatedUser = { ...currentUser };
    updatedUser.exams = response;
    dispatch(setUserInfo(updatedUser));

    if (!ignored) {
      await refreshStudySessionAndContent();
      enqueueSnackbar('Exam updated.', {
        autoHideDuration: 3000,
        style: { width: 'fit-content' },
      });
    }

    setIsLoadingStudySessionAndContent(false);
    setIsLoadedStudySessionAndContent(true);

    if (document.activeElement && document.activeElement !== document.body) {
      (document.activeElement as HTMLElement).blur();
    }
  };

  const handleUnsnoozeSession = async () => {
    setIsLoadingStudySessionAndContent(true);
    setIsLoadedStudySessionAndContent(false);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const updatedSession = { ...todaySession, isSnoozed: false };
    const response = await updateStudySession(todaySession.id, updatedSession);
    dispatch(setTodaySession(response));
    await refreshStudySessionAndContent();
    setIsLoadingStudySessionAndContent(false);
    setIsLoadedStudySessionAndContent(true);
  };

  const renderSearchBar = () => {
    return (
      <div className="search-bar">
        <Search isArticleBrowser={false} />
      </div>
    );
  };

  const renderHomeLoader = () => {
    return (
      <div
        className={`home-loader${isLoadedStudySessionAndContent ? ' is-loaded' : ''}${isLoadingStudySessionAndContent ? ' start-loading' : ''}`}
        style={showSecondaryScreen ? { opacity: 0 } : {}}
      >
        <div>
          <h2 style={{ marginBottom: 0 }}>
            Hi, {currentUser?.name ? currentUser?.name : 'there'}.
          </h2>
          <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
            <CircularProgress
              sx={{
                color: 'var(--type-body-inverse)',
                height: '1rem !important',
                width: '1rem !important',
              }}
            />
            <p>Optimizing your session...</p>
          </div>
        </div>
      </div>
    );
  };

  // Tour Functions
  const handleStartTour = () => {
    setCurrentStepIndex(0);
    setIsTourRunning(true);
  };

  const handleTourEnd = () => {
    setIsTourRunning(false);
    setCurrentStepIndex(0);
  };

  const handleStepChange = (newIndex: number) => {
    setCurrentStepIndex(newIndex);
  };

  const renderAppPromo = (show: boolean) => {
    if (!show) return null;
    return (
      <>
        <div
          className="app-promo"
          onClick={() => {
            setShowQrCodeModal(true);
          }}
        >
          <div className="app-promo_header">
            <h2>Download the Ora App</h2>
            <div style={{ alignSelf: 'baseline' }}>
              <button
                className="button button--close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAppPromo(false);
                  handleSeenMessage('app-promo', true);
                }}
              >
                <X />
              </button>
            </div>
          </div>
          <div className="app-promo_body">
            <div>
              <img src={AppPromo} alt="Download Ora Mobile Apps" />
            </div>
            <div className="qr">
              <img src={QRCode} alt="Ora QR Code" />
            </div>
          </div>
        </div>
        <CustomModal
          open={showQrCodeModal}
          onClose={() => {
            setShowQrCodeModal(false);
          }}
          name={'qr-code'}
        >
          <img src={QRCode} alt="Ora QR Code" />
        </CustomModal>
      </>
    );
  };

  const renderStartButton = () => {
    if (
      (!todaySession.isComplete &&
        !isNoContent &&
        !todaySession.isSnoozed &&
        !showTopicPriorities) ||
      isExtendingSession
    ) {
      return (
        <Start
          startTransition={initiateTransitionToStudy}
          isStarted={todaySession.isStarted}
          isExtendingSession={isExtendingSession}
          setIsExtendingSession={setIsExtendingSession}
          type={StartType.Home}
          isSwitchingExam={isSwitchingExam}
          isLoadedStudySessionAndContent={isLoadedStudySessionAndContent}
          isLoadingStudySessionAndContent={isLoadingStudySessionAndContent}
        />
      );
    } else {
      return null;
    }
  };

  const renderExamCompletion = () => {
    if (todaySession.isComplete || isNoContent || todaySession.isSnoozed) {
      return (
        <div className="is-done">
          {!isExtendingSession && !showTopicPriorities && (
            <>
              <div className="check-wrapper">
                <AnimatedCheckmark />
              </div>
              <h1>
                {todaySession.isSnoozed ? (
                  <>
                    <span>Your schedule has been rebalanced.</span>
                    <br />
                    <span>Enjoy your day!</span>
                  </>
                ) : (
                  "You're Done for Today"
                )}
              </h1>
            </>
          )}
          {!isNoContent &&
            !todaySession.isSnoozed &&
            !isExtendingSession &&
            !showTopicPriorities && (
              <div className="is-done_buttons">
                <button
                  role="link"
                  className="button button--link button--link--glass"
                  onClick={() => {
                    navigate('/study');
                    hapticsImpactLight();
                  }}
                >
                  Today's Stats
                  <ArrowRight />
                </button>
                <button
                  role="link"
                  className="button button--link button--link--glass"
                  onClick={() => {
                    setIsExtendingSession(true);
                    hapticsImpactLight();
                  }}
                >
                  Keep Studying
                  <ArrowRight />
                </button>
              </div>
            )}
          {!isNoContent && todaySession.isSnoozed && !showTopicPriorities && (
            <button
              className="button button--link button--unsnooze button--link--glass"
              onClick={() => {
                handleUnsnoozeSession();
                hapticsImpactLight();
              }}
            >
              <Clock />
              Unsnooze Today's Session
            </button>
          )}
          {isNoContent && (
            <p style={{ textAlign: 'center', padding: '0 2rem' }}>
              You don't have any due content today. Enjoy your day off!
            </p>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const handleSeenMessage = async (
    messageId: string,
    activateJustShowedTip: boolean
  ) => {
    if (!currentUser) return;
    if (activateJustShowedTip) {
      setJustShowedTip(true);
    }
    const updateUser = await updateCurrentUserInfo({
      onboardingTips: {
        ...currentUser?.onboardingTips,
        [messageId]: true,
      },
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const renderTimeline = () => {
    if (
      primaryExam?.examDate &&
      moment(primaryExam.examDate).isBefore(moment())
    ) {
      return null;
    }
    return (
      <Timeline
        showTopicPriorities={showTopicPriorities}
        setShowTopicPriorities={setShowTopicPriorities}
        setIsLoadingStudySessionAndContent={setIsLoadingStudySessionAndContent}
        setIsLoadedStudySessionAndContent={setIsLoadedStudySessionAndContent}
        refreshStudySessionAndContent={refreshStudySessionAndContent}
      />
    );
  };

  return (
    <div className="index-container">
      {renderHomeLoader()}
      <div
        className={`home ${transitionStarted ? 'transitioning' : ''} ${isLoadedStudySessionAndContent ? 'is-loaded' : ''}`}
      >
        {!showSecondaryScreen && <NavBar />}
        <div className="home_body" ref={tourStep1Ref}>
          {!showSecondaryScreen && (
            <>
              <Header
                type={HeaderType.home}
                setIsSwitchingExam={setIsSwitchingExam}
                setIsLoadingStudySessionAndContent={
                  setIsLoadingStudySessionAndContent
                }
                setIsLoadedStudySessionAndContent={
                  setIsLoadedStudySessionAndContent
                }
                refreshStudySessionAndContent={refreshStudySessionAndContent}
              />
              <div className="home_body_content">
                {renderStartButton()}
                {renderExamCompletion()}
                {renderTimeline()}
              </div>
              {renderSearchBar()}
              <WelcomeVideo
                setIsWatched={setIsWatched}
                setJustShowedTip={setJustShowedTip}
                showHomeTour={showHomeTour}
              />
              {renderAppPromo(showAppPromo)}
            </>
          )}
          {showSecondaryScreen && (
            <div className="secondary-screen">
              {secondaryScreenType === SecondaryScreen.ExamCompletion && (
                <ExamCompletion
                  closeSecondaryScreen={closeSecondaryScreen}
                  handleDateChangeOrConfirmation={
                    handleDateChangeOrConfirmation
                  }
                />
              )}
            </div>
          )}
        </div>
        {!showSecondaryScreen && <AppBar />}
      </div>
      <div id="side-drawer-root"></div>
      <div className="flash-loader" ref={flashLoaderRef}></div>
      {showHomeTour && (
        <HomeFeatureTour
          stepTargets={[...stepSelectors]}
          stepFunctions={[() => {}, () => {}, () => {}, () => {}, () => {}]}
          run={isTourRunning}
          stepIndex={currentStepIndex}
          onStepChange={handleStepChange}
          onTourEnd={handleTourEnd}
          handleSeenHomeTour={() => handleSeenMessage('home-tour', false)}
        />
      )}
      {showAmbassadorTour && (
        <AmbassadorTour
          stepTargets={['#ambassador-button']}
          stepFunctions={[() => {}]}
          run={isTourRunning}
          stepIndex={currentStepIndex}
          onStepChange={handleStepChange}
          onTourEnd={handleTourEnd}
        />
      )}
      {showLegacyMessage && (
        <LegacyModal
          isOpen={showLegacyMessage}
          onClose={() => {
            setShowLegacyMessage(false);
            handleSeenMessage('grandfathered-message', true);
          }}
        />
      )}
      <CustomModal
        open={openWelcomeModal}
        onClose={() => {
          setOpenWelcomeModal(false);
        }}
        name="welcome"
        blurs={true}
        glass={true}
      >
        <div className="modal_content">
          <h2>Welcome to Ora</h2>
          <p className="m-b-0-25">
            The platform designed to simplify your Step and Shelf prep.
          </p>
        </div>
        <div className="modal_actions">
          <button
            className="button button--glass"
            tabIndex={0}
            onClick={() => {
              setShowHomeTour(true);
              setOpenWelcomeModal(false);
              handleStartTour();
            }}
          >
            Take Brief Tour
          </button>
          <button
            className="button button--link"
            tabIndex={0}
            onClick={() => {
              setOpenWelcomeModal(false);
              handleSeenMessage('home-tour', false);
            }}
          >
            Skip Tour
          </button>
        </div>
      </CustomModal>
      <AssentsModal
        currentUser={currentUser}
        assentsToAccept={assentsToAccept}
        setAssentsToAccept={setAssentsToAccept}
      />
      {windowWidth > 900 &&
        createPortal(
          <Screensaver
            isIdle={isIdle}
            screenSaverDisplay={screenSaverDisplay}
            learningObjectives={learningObjectives}
          />,
          document.body
        )}
    </div>
  );
};

export default Home;
